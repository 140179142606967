.legendContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  // border: 5px solid #ffffff4d;
  // border-radius: 10px;
  
  &.hidden {
    display: none;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legendDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;

  &.live {
    background-color: #4caf50; // Green dot for live
  }

  &.future {
    background-color: #2196f3; // Blue dot for future
  }
}

.legendText {
  font-size: 1.25rem; // Adjust the font size as needed
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.75;
}

.explorerContainer {
  // max-width: 800px;
  margin: 0 auto;
}

.explorerRow {
  margin: 5px 0;
  padding-top: 0px;
}

.explorerCol {
  padding: 10px;

  &.live {
    .square {
      .backgroundImage {
        border: 8px solid #4caf50; // Green border for live tools
      }
    }
  }

  &.future {
    .square {
      .backgroundImage {
        border: 8px solid #2196f3; // Blue border for future tools
      }
    }
  }

  .square {
    border-radius: 10px;
    background: #171941; 
    box-shadow: 5px 5px 10px #0a0a23, -5px -5px 10px #242456; 
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 3px 3px 6px #0a0a23, -3px -3px 6px #242456;
    }

    &.disabled {
      box-shadow: none;
      background: #0a0a23;
    }

    .backgroundImage {
      border-radius: 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.square {
  position: relative;
  // padding-bottom: 100%;
  padding-bottom: 70%;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.squareText {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background: rgba(0,0,0,0.7); // semi-transparent black background
  padding: 2px 5px; // some padding to add space around the text
  font-weight: bold;
  font-size: 2em;
}

.squareSubtext {
  display: none;
  position: absolute;
  top: 35px; // adjust this according to the size of your text and desired spacing
  left: 10px;
  color: white;
  background: rgba(0,0,0,0.7); // semi-transparent black background
  padding: 2px 5px; // some padding to add space around the text
  // font-size: 1.1rem;
}

.breadcrumbContainer {
  margin-top: 20px;
}

.breadcrumbBtn {
  display: inline-block;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  font-size: 1.85em;
  text-transform: uppercase;
  font-weight: 600;
  /* margin-bottom: 20px; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.breadcrumbBtn:hover {
  background-color: #e9ecef;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.inputBar {
  font-size: 18px;
}

#searchIcon {
  font-size: 16px;
}

@media (min-width: 0px) and (max-width: 319px) {
  .square {
    padding: 75px;
  }

}

@media (min-width: 320px) and (max-width: 465px) {
  .square {
    padding: 75px;
  }
}


@media (min-width: 466px) and (max-width: 768px) { 
  .square {
    padding: 75px;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {

}

@media (min-width: 1367px) {
  .squareText {
    font-size: 2em;
  }
}


// File: ExplorerTabPluginExpanded.jsx

.title {
  margin-top: 50px;
  font-size: 1.25em;
  text-transform: uppercase;
  font-weight: 600;
  color: #EAA221;
}

.secureAddressDisplay {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // background: #17c21761;
  background: #00ff1b80;
  // border-style: solid;
  // border-width: 2px; 
  // border-color: green;
  border-radius: 7px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 20px;
  color: white;
  font-size: 1.65em;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 600;
  // background: linear-gradient(90deg, hsl(200, 85%, 66%), hsl(210, 85%, 66%), hsl(220, 85%, 66%), hsl(230, 85%, 66%), hsl(240, 85%, 66%), hsl(250, 85%, 66%), hsl(260, 85%, 66%), hsl(270, 85%, 66%));
  // border-radius: 0px;
border: 1px #171941 solid;
border-radius: 5px;
box-shadow:  5px 5px 12px #0f102a,
             -5px -5px 12px #1f2258;
}

.secureAddress {
  // padding-top: 0.5em;
  display: flex;
  flex-direction: row;
  align-self: center;
  opacity: 0.85;
  font-weight: 600;
}

#aboutText {
  font-size: 1.25em;
  opacity: 0.75;
}

#aboutCaret {
  display: inline;
  font-size: 2em;
  margin-right: 20px;
}

///
/// 
/// 


#plusImage {
  background-size: contain;
  opacity: 0.4;
}

//

#bannerImageContainer {
  margin-left: 20px;
  margin-right: 20px;
}

#bannerImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  opacity: 0.55;
  // background: linear-gradient(90deg, #5f86f2, #a65ff2, #f25fd0, #f25f61, #f2cb5f, #abf25f, #5ff281, #5ff2f0);
  // background-blend-mode: initial;
  // mix-blend-mode: difference;
  // border: 5px transparent solid;
  border-radius: 15px;
  // mix-blend-mode: saturation;
  // padding-top: 20px;
  // padding-bottom: 20px;
  // padding-left: 20px;
  // padding-right: 20px;
}

#bannerImage1 {
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 0.55;
    background: linear-gradient( 90deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%), hsl(89, 85%, 66%), hsl(134, 85%, 66%), hsl(179, 85%, 66%) );
    background-blend-mode: initial;
    mix-blend-mode: difference;
    border: 1px white solid;
    border-radius: 5px;
}

#butterflyImage {
  height: 100px;
  // width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  // mix-blend-mode: plus-lighter;
}

#mirrorsImage {
  height: 150px;
  // width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  mix-blend-mode: plus-lighter;
}