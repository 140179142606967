.container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.navigationRow {
  margin-bottom: 15px;

  button {
    margin-right: 10px;
  }
}

.gridContainer {
  display: grid;
  gap: 1px;
  background-color: #ccc;
  overflow: auto;
  max-height: 60vh;
  max-width: 90vw;
  margin-bottom: 20px;

  .gridItem {
    background-color: #fff;
    text-align: center;
    color: black;
    border: 1px solid #ccc;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 50px;

    &.gridItemDisabled {
      background-color: #eee;
    }

    &.gridItemHeader {
      font-weight: bold;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
    }

    &.highlighted {
      background-color: #e6f3ff;
      border: 2px solid #007bff;
    }
  }
}

.riskMatrixCommentModal {
  color: black;
  font-size: 16px;
  background-color: #fff;

  .modalHeader,
  .modalBody,
  .modalFooter {
    padding: 15px;
    color: black;
  }

  .modalHeader {
    border-bottom: 1px solid #dee2e6;
    background-color: #f8f9fa;
  }

  .modalBody {
    h5 {
      margin-bottom: 15px;
      color: black;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 15px;
      color: black;

      li {
        color: black;
      }
      
    }

    input[type='textarea'],
    input[type='url'] {
      width: 100%;
      padding: 8px;
      margin-bottom: 15px;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }

    fieldset {
      margin-bottom: 15px;
    }

    label {
      display: inline-block;
      margin-bottom: 5px;
      color: black;
    }

    input[type='radio'] {
      margin-right: 5px;
    }

    input[type='range'] {
      width: 100%;
    }

    li {
      color: black;
    }
  }

  .modalFooter {
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
}

.verticalText {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}