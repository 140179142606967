$user-page-background: #171941;
$user-page-text-color: white;
$neumorphism-shadow-dark: #121433;
$neumorphism-shadow-light: #1c1e4f;
$stat-item-background: rgba(255, 255, 255, 0.2);
$clickable-color: #4DFFA4;
$button-color: $user-page-text-color;
$input-background-color: rgba(255, 255, 255, 0.457);
$input-border-color: rgba(255, 255, 255, 0.3);
$light-blue: #5e72e4;

.userPage {
  display: flex;
  flex-direction: column;
  background-color: $user-page-background;
  color: $user-page-text-color;
  font-family: 'Share Tech Mono', monospace;
  padding: 20px;

  &.minimized {
    .content, .compareSection, .analyzeButton {
      display: none;
    }

    .header {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    #userPageAddress {
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    .usernameSection {
      display: none;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.avatarContainer {
  margin-right: 15px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $light-blue;
}

#userPageAddress {
  display: flex;
  align-items: center;
  font-size: 2.25rem;
  margin: 0;
}

.simulatedBadge {
  color: yellow;
  margin-left: 10px;
  font-size: 1.5rem;
}

.copyButton, .expandButton, .bookmarkButton {
  background: none;
  border: none;
  color: $button-color;
  cursor: pointer;
  margin-left: 10px;
  // font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    color: $clickable-color;
  }
}

.expandButton {
  // font-size: 1.75rem;
}

#externalLinkIcon {
  font-size: 1.75rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.bookmarkButton {
  font-size: 2rem;
}

.plusIcon {
  font-size: 1rem;
  margin-right: 10px;
}

.userActions {
  display: flex;
  align-items: center;
}

.bookmarksLink {
  color: $user-page-text-color;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: $clickable-color;
  }
}

.usernameSection {
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    padding: 5px 10px;
    background-color: $input-background-color;
    border: 1px solid $input-border-color;
    color: $user-page-text-color;
    border-radius: 4px;
  }

  button {
    padding: 5px 10px;
    background-color: $light-blue;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($light-blue, 10%);
    }
  }

  .error {
    color: red;
    margin-left: 10px;
  }
}

.content {
  display: flex;
  gap: 20px;
}

.leftColumn, .rightColumn {
  flex: 1;
}

.surveySection, .questionSection, .sbtSection {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;

  h2 {
    margin-bottom: 15px;
  }
}

.surveyPreview, .questionPreview {
  display: block;
  background: linear-gradient(145deg, #6e48aa, #9d50bb);
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(145deg, #9d50bb, #6e48aa);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .surveyTitle, .questionPrompt {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .surveyInfo, .questionInfo {
    font-size: 14px;
  }

  .questionID {
    font-size: 12px;
    color: #ccc;
  }
}

.compareSection {
  margin-top: 20px;
}

.collapseButton, .analyzeButton {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: $light-blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;

  &:hover {
    background-color: darken($light-blue, 10%);
  }
}

.compareSection {
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  input {
    padding: 5px;
    background-color: $input-background-color;
    border: 1px solid $input-border-color;
    color: $user-page-text-color;
    border-radius: 4px;
    margin-right: 10px;
    min-width: 400px;
  }

  button {
    padding: 5px 10px;
    background-color: $light-blue;
    text-transform: uppercase;
    font-weight: 600;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      background-color: gray;
      cursor: not-allowed;
    }
  }
}

.comparisonSummary {
  margin-top: 10px;
  background: $stat-item-background;
  padding: 10px;
  border-radius: 5px;
}

.simulatedUserActions {
  display: flex;
  gap: 10px;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    background-color: $light-blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($light-blue, 10%);
    }
  }
}

// Modal styles
.modalContent {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
}

.modalHeader {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 20px;

  h5 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.modalBody {
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .userPage {
    padding: 10px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .userInfo {
    margin-bottom: 10px;
  }

  #userPageAddress {
    font-size: 1.75rem;
  }

  .content {
    flex-direction: column;
  }

  .compareSection input {
    min-width: auto;
    width: 100%;
    margin-bottom: 10px;
  }

  .collapseButton, .analyzeButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .simulatedUserActions {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}

#loadingIcon {
  font-size: 2em;
  color: white;
}

.sbtSection {
  margin-top: 0px;
  margin-bottom: 0px;

  h2 {
    margin-bottom: 15px;
  }

  #loadingIcon {
    display: block;
    margin: 20px auto;
    font-size: 2rem;
  }
}

.sbtGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.analyzeButton {
  position: relative;
  
  .comingSoon {
    position: absolute;
    top: -5px;
    right: -25px;
    font-size: 0.7em;
    color: #00adb5;
  }
}

.placeholderNote {
  font-style: italic;
  color: #888;
  margin-bottom: 15px;
}

.tabContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  button {
    flex: 1;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.1);
    color: $user-page-text-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &.activeTab {
      background-color: $light-blue;
      color: black;
    }

    &:hover {
      background-color: $light-blue;
      color: black;
    }
  }
}
