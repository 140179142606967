.dropdown {
  // width: 50%;
  position: relative;
  display: flex !important;
  flex-direction: row;
  // justify-content: center;
  justify-content: flex-start;
}

.dropdownButton {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 1.75em;
  // background: #ff000000;
  background: #00bdffc7;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  // border: 2px white solid;
  border-radius: 5px;
  opacity: .5;
  font-weight: 700;
  border-width: 0.5px;
  display: flex;
  justify-self: center;
    
  label {
    display: flex;
    align-items: center;
    gap: 10px; // Add gap between checkbox and text
  }

  input[type="checkbox"] {
    margin-right: 10px; // Alternatively, you can add margin to the checkbox
  }
}

.demoModal {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #202251;
  border-radius: 5px;
  color: #fff;
  max-height: 80vh;

  .title {
    margin-bottom: 15px;
    font-size: 1.5em;
    // text-transform: uppercase;
    font-weight: 600;
  }

  .demoItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #232769;
    width: 100%;

    label {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #fff;
      font-size: 1.5em;

      input[type="checkbox"] {
        margin: 0;
        font-size: 1.85em;
        padding: 5px;
      }
    }
  }

  .arrowButton {
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    font-size: 2em;
    margin-top: 20px;
  }
}
