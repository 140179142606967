// PolicyTracker.module.scss

.policyHubContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1f2251;
    padding: 20px;
    border-radius: 8px;
    box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;
    width: 100%;
  }
  
  .countryList {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  
    li {
      padding: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: rgba(255, 255, 255, 0.25);
      &:hover {
        background: #272b65;
      }
    }
  }
  
  .countryListItem {
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid #272b65;
    &:last-child {
      border-bottom: none;
    }
  }

  .countryListLabel {
    font-size: 25px;
  }
  
  .modeButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    
    button {
      color: rgba(255, 255, 255, 0.25);
      background: #1f2251;
      border: 1px solid #272b65;
      text-transform: uppercase;
      font-weight: 600;
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.3s ease;
    
      &:hover {
        background: #272b65;
      }
    }
  }
  
  .content {
    width: 100%;
    margin-top: 20px;
    h3 {
      color: white;
      font-weight: 600;
      margin-bottom: 10px;
    }
    ul {
      list-style-type: disc;
      padding-left: 20px;
      li {
        color: rgba(255, 255, 255, 0.25);
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
  
  .checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    label {
      color: rgba(255, 255, 255, 0.25);
      font-weight: 600;
      cursor: pointer;
      input[type="checkbox"] {
        margin-right: 10px;
      }
    }
  }

  
  /* Styling for User Comments */
.surveyComments {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .commentCard {
      // ... (you can adjust styling specific to a comment card here)
    }
  }
  
  /* Styling for 2D Grid Checkbox */
  .checkboxGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // Adjust this based on your needs
    gap: 10px;
    
    header {
      font-weight: bold;
      background-color: #272b65;
      color: white;
      padding: 5px;
      border-radius: 4px;
    }
  }
  
  .dropdownCountrySelector {
    margin-bottom: 20px;
  }

  .countryList {
    list-style-type: none;
    padding: 0;
  
    .countryListItem {
      .dropdown-toggle {
        font-size: 1.5em; // Increase the text size
        background-color: white; // Set the background color to white
        color: darkblue; // Set the text color to dark blue
  
        &:hover, &:focus {
          background-color: #f5f5f5; // A light grey for hover/focus state
        }
  
        .caret {
          border-top-color: darkblue; // Match caret color with text
        }
      }
  
      .dropdown-menu {
        .dropdown-item {
          &:hover, &:focus {
            background-color: #f5f5f5; // A light grey for hover/focus state
          }
        }
      }
    }
  }
  
  .modeButtons {
    padding-top: 0; // Remove top padding
  
    button {
      color: white; // Set the text color to white
      border: 1px solid white; // Set a white border
      background-color: transparent; // Transparent background
  
      &:hover, &:focus {
        background-color: rgba(255, 255, 255, 0.1); // Slight white overlay on hover/focus for effect
      }
    }
  }