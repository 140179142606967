/* ---------------------------------------------------- MemeMatchLoader.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #MemeMatchLoader {
    /* border-style: solid;
    border-color: #ced4da00;
    border-width: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    min-height: 200px;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #MemeMatchLoader {
    /* border-style: solid;
    border-color: #ced4da00;
    border-width: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    min-height: 200px;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #MemeMatchLoader {
    /* border-style: solid;
    border-color: #ced4da00;
    border-width: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    min-height: 250px;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #MemeMatchLoader {
    /* border-style: solid;
    border-color: #ced4da00;
    border-width: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    min-height: 400px;
  }
}

@media (min-width: 1367px) {
  #MemeMatchLoader {
    /* border-style: solid;
    border-color: #ced4da00;
    border-width: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    min-height: 375px;
  }
}

#paginationContainer {
}

// #MemeMatchLoader {
//     /* border-style: solid;
//     border-color: #ced4da00;
//     border-width: 10px; */
//     padding: 0px 20px 0px 20px;
//   }

/* ---------------------------------------------------- MemeMatch.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #memeMatchContainer {
    padding: 0px;
    background: #1f2251;
    background-color: #1f2251;
    width: 100%;
    border-radius: 7px;
  }

  #entriesAndStats {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    // padding-left: 5px;
    // padding-right: 5px;

    #twoEntries {
      // order: 0;
      // width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      #cancelledMemeCard {
        height: 175px;
        // width: 50%;
      }

      .activeCardContent {
        padding: 7.5px;
      }

      #activeMemeCardFooter {
        // padding-left: .5em;
        // padding-right: .5em;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        /* align-content: center; */
        margin-top: 0px;
        // padding-bottom: 1em;
      }
    }

    .leftEntry {
      order: 0;
      width: 50%;
    }

    .rightEntry {
      order: 1;
      width: 50%;
    }

    .matchStats {
      width: 100%;
      order: 1; // TODO: with submitBidAndMeme
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      // align-items: center;
      // margin-top: 5px;
      // margin-bottom: 10px;
      font-family: 'Share Tech Mono', monospace;
      // font-size: 1em;
      font-size: .85em;
      /* font-family: 'VT323', monospace; */
      // padding-bottom: 3px;
      // padding-top: 5px;
      padding-bottom: 5px;
      padding-top: 5px;

    }
  }

  .activeMemeCard {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #activeMemeCardLeft {
    border-radius: 8px;
    background: #1f2251;
    box-shadow: 7px 7px 14px #131532, -7px -7px 14px #2b2f70;
    transform: scale(0.80);
  }

  #activeMemeCardRight {
    border-radius: 8px;
    background: #1f2251;
    box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70;
    transform: scale(0.80);
  }

  .winner {
    // box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70, 0px 0px 30px 5px #1d8cf8 !important;
    box-shadow: 0px 0px 30px 5px #1d8cf8 !important;
  }

  .matchStat {
    display: inline;
    // padding-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .matchStatRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
  }

  #submitBidAndMeme {
    order: 0;
  }

  #matchStatsAndEntryPrompt {
    order: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media (min-width: 320px) and (max-width: 465px) {
  #memeMatchContainer {
    padding: 0px;
    background: #1f2251;
    background-color: #1f2251;
    // min-height: 260px;
    width: 100%;
    border-radius: 7px;
  }

  #entriesAndStats {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    min-height: 220px;
    // padding-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

    #twoEntries {
      // order: 0;
      // width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      #cancelledMemeCard {
        min-height: 150px;
        max-height: 200px;
        height: 175px;
        // width: 50%;
      }

      .activeCardContent {
        padding: 7.5px;
      }

      #activeMemeCardFooter {
        padding-left: .35em;
        padding-right: .35em;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        /* align-content: center; */
        margin-top: 0px;
        // padding-bottom: 1em;
      }
    }

    .leftEntry {
      order: 0;
      width: 50%;
    }

    .rightEntry {
      order: 1;
      width: 50%;
    }

    .matchStats {
      width: 100%;
      order: 1;
      display: flex;
      // flex-direction: column;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: center;
      justify-content: space-evenly;
      align-content: center;
      // align-items: center;
      // margin-top: 5px;
      // margin-bottom: 10px;
      font-family: 'Share Tech Mono', monospace;
      // font-size: 1em;
      font-size: .9em;
      /* font-family: 'VT323', monospace; */
      // padding-top: 5px;
      // padding-bottom: 5px;
      // padding-top: 7.5px;
      padding-bottom: 7.5px;
      padding-top: 7.5px;
    }
  }

  #activeMemeCardLeft {
    border-radius: 8px;
    background: #1f2251;
    box-shadow: 7px 7px 14px #131532, -7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  #activeMemeCardRight {
    border-radius: 8px;
    background: #1f2251;
    box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  .winner {
    box-shadow: 0px 0px 30px 5px #1d8cf8 !important;
  }

  .matchStat { // TODO
    display: inline;
    // padding-left: 5px;
    // padding-left: 10px;
    // padding-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .matchStatRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
  }

  #submitBidAndMeme {
    order: 0;
  }

  #matchStatsAndEntryPrompt {
    order: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media (min-width: 466px) and (max-width: 768px) {
  #memeMatchContainer {
    padding: 0px;
    background: #1f2251;
    background-color: #1f2251;
    width: 100%;
    border-radius: 7px;
  }

  #entriesAndStats {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    #twoEntries {
      // order: 0;
      // width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      #cancelledMemeCard {
        min-height: 250px;
        max-height: 300px;
        height: 250px !important;
        // width: 50%;
      }

      .activeCardContent {
        padding: 7.5px;
      }

      #activeMemeCardFooter {
        padding-left: .5em;
        padding-right: .5em;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        /* align-content: center; */
        margin-top: 0px;
        // padding-bottom: 1.25em;
      }
    }

    .leftEntry {
      order: 0;
      width: 50%;
    }

    .rightEntry {
      order: 1;
      width: 50%;
    }

    .matchStats {
      width: 100%;
      order: 1;
      display: flex;
      // flex-direction: column;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: space-around;
      justify-content: space-evenly;
      align-content: center;
      // align-items: center;
      padding-bottom: 10px;
      font-family: 'Share Tech Mono', monospace;
      // font-size: 1.15em;
      font-size: 1em;
      /* font-family: 'VT323', monospace; */
      // padding-bottom: 10px;
      // padding-top: 5px;
      // padding-bottom: 5px;
      // padding-top: 10px;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  .activeMemeCard {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #activeMemeCardLeft {
    border-radius: 8px;
    background: #1f2251;
    box-shadow: 7px 7px 14px #131532, -7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  #activeMemeCardRight {
    border-radius: 8px;
    background: #1f2251;
    box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  .winner {
    box-shadow: 0px 0px 30px 5px #1d8cf8 !important;
  }

  .matchStat { // TODO
    display: inline;
    // padding-left: 5px;
    // padding-left: 10px;
    // padding-right:10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .matchStatRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
  }

  #submitBidAndMeme {
    order: 0;
  }

  #matchStatsAndEntryPrompt {
    order: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media (min-width: 769px) and (max-width: 1366px) {
  #memeMatchContainer {
    padding: 0px;
    background: #1f2251;
    background-color: #1f2251;
    width: 100%;
    border-radius: 7px;
  }

  #entriesAndStats {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    #twoEntries {
      // order: 0;
      // width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      #cancelledMemeCard {
        // min-height: 200px;
        // max-height: 450px;
        height: 350px;
        // width: 50%;
      }

      .activeCardContent {
        padding: 7.5px;
      }

      #activeMemeCardFooter {
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        /* align-content: center; */
        margin-top: 0px;
        // padding-bottom: 1.5em;
      }
    }

    .leftEntry {
      order: 0;
      width: 50%;
    }

    .rightEntry {
      order: 1;
      width: 50%;
    }

    .matchStats {
      // width: 100%;
      // order: 2;
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // justify-content: center;
      // align-content: center;
      // align-items: center;
      // margin-top: 5px;
      // // margin-bottom: 10px;
      // font-family: 'Share Tech Mono', monospace;
      // font-size: 1.25em;
      // /* font-family: 'VT323', monospace; */
      width: 100%;
    order: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: center;
    justify-content: space-evenly;
    align-content: center;
    // align-items: center;
    padding-bottom: 10px;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1.25em;
    font-size: 1.1em;
    /* font-family: 'VT323', monospace; */
    // padding-top: 15px;
    // padding-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    }
  }

  .activeMemeCard {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #activeMemeCardLeft {
    margin-top: 0px;
    border-radius: 8px;
    background: #1f2251;
    box-shadow: 7px 7px 14px #131532, -7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  #activeMemeCardRight {
    margin-top: 0px;
    border-radius: 8px;
    background: #1f2251;
    box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  .winner {
    box-shadow: 0px 0px 25px 10px #1d8cf8 !important;
  }

  .matchStat { // TODO
    display: inline;
    // padding-left: 5px;
    // padding-left: 15px;
    // padding-right: 15px;
        padding-left: 10px;
    padding-right:10px;
  }

  .matchStatRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
  }

  #submitBidAndMeme {
    order: 0;
  }

  #matchStatsAndEntryPrompt {
    order: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media (min-width: 1367px) {
  #memeMatchContainer {
    padding: 0px;
    background: #1f2251;
    background-color: #1f2251;
    width: 100%;
    border-radius: 7px;
  }

  #entriesAndStats {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around; // TODO: put chat in middle
    padding-top: 10px;
    padding-bottom: 10px;

    #twoEntries {
      // order: 0;
      // width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      #cancelledMemeCard {
        // min-height: 200px;
        // max-height: 300px;
        height: 450px;
        // width: 50%;
      }

      .activeCardContent {
        padding: 7.5px;
      }

      #activeMemeCardFooter {
        padding-left: 1.25em;
        padding-right: 1.25em;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        /* align-content: center; */
        margin-top: 0px;
        // padding-bottom: 1.75em;
      }
    }

    .leftEntry {
      order: 0;
      width: 35%;
    }

    .rightEntry {
      order: 2;
      width: 35%;
    }

    .matchStats {
      // width: 30%;
      order: 1;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-content: center;
      align-items: center;
      // align-items: center;
      font-family: 'Share Tech Mono', monospace;
      font-size: 1.5em;
      // font-size: 1.1em;
      /* font-family: 'VT323', monospace;
      /* font-family: 'VT323', monospace; */
      // width: 100%;
      // order: 2;
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // justify-content: center;
      // align-content: center;
      // align-items: center;
      // padding-bottom: 10px;
      // font-family: 'Share Tech Mono', monospace;
      // font-size: 1.25em;
      // /* font-family: 'VT323', monospace; */

    }

    #matchStatsAndEntryPrompt {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  .activeMemeCard {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #activeMemeCardLeft {
    margin-top: 0px;
    border-radius: 8px;
    background: #1f2251;
    box-shadow: 7px 7px 14px #131532, -7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  #activeMemeCardRight {
    margin-top: 0px;
    border-radius: 8px;
    background: #1f2251;
    box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70;
    transform: scale(0.85);
  }

  .winner {
    box-shadow: 0px 0px 35px 15px #1d8cf8 !important;
  }

  .matchStat { // TODO
    display: inline;
    // padding-left: 5px;
    // padding-left: 20px;
    // padding-right: 20px;
    padding-bottom: 10px;
  }

  .matchStatRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
  }
}

.activeMemeCard {
  margin-top: 0px;
  display: inherit;
  border-radius: 0px;
  background: #1f2251;
  box-shadow: none;
}

#activeMemeCardLeft {
  margin-top: 0px;
  border-radius: 8px;
  background: #1f2251;
  // box-shadow: 7px 7px 14px #131532, -7px -7px 14px #2b2f70;
  transform: scale(0.85);
}

#activeMemeCardRight {
  margin-top: 0px;
  border-radius: 8px;
  background: #1f2251;
  // box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70;
  transform: scale(0.85);
}

.matchStats {
  // padding-top: 10px;
}

.matchStat { // TODO
  display: inline;
  // padding-left: 5px;
  // padding-left: 10px;
  margin: 0px;
  // margin-right: 10px;

  #matchStatTitle {
    display: inline;
    color: #ffffff33;
    // opacity: .35;
  }

  .matchStatRow {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-evenly;
    // padding: 5px;
    width: 100%;
  }

  #submitBidAndMeme {
    order: 2;
  }

}

#matchStatsAndEntryPrompt {
  // width: 30%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}

#submitBidAndMeme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // transform: scale(.75);
  // padding: 10px;
}

#notEnoughCompetitors {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.winner {
  // box-shadow: 0px 0px 85px 14px #1d8cf8 !important;
}

.nonWinner {
}

#memeMatchContainer {
    width: 100%;
    padding: 0px;
    background: #1f225100;
    background-color: #1f225100;
    border-radius: 7px;
    // background: #1f2251;
    // background-color: #1f2251;
    /* border-color: #ffffff00; */
    /* border-width: 5px; */
    /* background: linear-gradient( 60deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%), hsl(89, 85%, 66%), hsl(134, 85%, 66%), hsl(179, 85%, 66%) ); */
    /* border-style: solid; */
    /* position: relative; */
    /* box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1); */
    /* margin: 0px 0px; */
    /* padding: 0px 0px; */
    /* z-index: 3; */
    /* margin-bottom: 5px; */
    /* margin-left: 5px; */
    /* margin-right: 5px; */
  }

  #entriesAndStats {
    background: #1f2251;
    background-color: #1f2251;
  }

// #twoEntries {
//   padding-left: 0px;
//   padding-right: 0px;
//   display: flex;
//   /* margin-top: 30px; */
//   /* margin-bottom: 30px; */
//   /* padding-bottom: 30px; */
//   /* padding-top: 30px; */
// }

// #leftMemeCardCol {
//   display: flex;
//   flex-direction: column;
// }

// #rightMemeCardCol {
//   display: flex;
//   flex-direction: column;
// }

// #memeMatchStatsCol {
//   padding: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* text-align: center; */
// }

/* ---------------------------------------------------- MemeMatchLoadingScreen.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

  #memeMatchLoadingScreen {
    font-size: 1.5em;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #loadingScreenSpinner {
    margin-left: 10px;
    // margin-top: 10px;
    // margin-bottom: 10px;
  }

  

}

@media (min-width: 320px) and (max-width: 465px) {

  #memeMatchLoadingScreen {
    font-size: 1.75em;
    flex-direction: row;
    /* padding-top: 15px; */
    padding-bottom: 25px;
  }

  #loadingScreenSpinner {
    margin-left: 10px;
    // margin-top: 15px;
    // margin-bottom: 15px;
  }

}

@media (min-width: 466px) and (max-width: 768px) {

  #memeMatchLoadingScreen {
    font-size: 2em;
    flex-direction: row;
    // padding-top: 25px;
    // padding-bottom: 25px;
    padding-bottom: 40px;
  }


  #loadingScreenSpinner {
    margin-left: 10px;
  }

}

@media (min-width: 769px) and (max-width: 1366px) {

  #memeMatchLoadingScreen {
    font-size: 2.5em;
    flex-direction: row;
    // padding-top: 25px;
    // padding-bottom: 25px;
    padding-bottom: 60px;
  }


  #loadingScreenSpinner {
    margin-left: 10px;
  }
}

@media (min-width: 1367px) {

  #memeMatchLoadingScreen {
    font-size: 3em;
    flex-direction: row;
    padding-top: 80px;
    // padding-bottom: 25px;
    padding-bottom: 80px;
  }

  #loadingScreenSpinner {
    margin-left: 10px;
  }
}

// TODO: this is currently read from m_w.css
//
// button#loadingScreenInitButton { // currently read 
//   color: #ffffff;
//   font-weight: 600;
//   font-size: 1.5em;
//   /* border-radius: 0px;
//   border-style: solid;
//   border-width: 1px;
//   border-color: #fb6340; */
//     /* background: #cecece; */
//   /* background-color: #cecece; */
//   /* background-image: none; */
//   /* margin-left: 5px; */
//   /* padding-top: 5px;
//   padding-right: 10px;
//   padding-bottom: 5px;
//   padding-left: 10px; */
//     /*  margin-top: 10px;
//   margin-bottom: 10px; */
//   /* opacity: .75; */
//   text-transform: uppercase;
//   border-radius: 2px;
//   /* background: #171941; */
//   /* background:  #5e72e4b3; */
//   background: #97da1385;
//   box-shadow:  5px 5px 10px #0c0d21, 
//                -5px -5px 10px #222561;
// }

#memeMatchLoadingScreen {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  color: #ffffff4a;
  // padding-top: 15px;
  // padding-bottom: 15px;
  font-weight: 500;
  font-family: "Share Tech Mono",monospace !important;
  margin-bottom: 0px;
}

#loadingScreenSpinner {
  // margin-left: 10px;
  color: #ffffff4a;
}

/* ---------------------------------------------------- MemeMatchTopper.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

  #statusAndTime {
    font-size: 1em;
  }

  #matchStatusSection {
    font-size: 1.1em;
    #matchStatus {
      display: inline;
    }
    #matchStatusColon {
      display: inline;
      margin-right: 0.2em;
    }
  }

  .matchTopperCountdown {
    margin-top: 0.1em;
    #cancelledMatchTimestamp {
      display: none;
    }
  }

  .cancelledMatchTopInfo {
  }

  .pendingMatchTopInfo {
    #statusAndTime {
      font-size: 1.1em;

      #matchStatusSection {
        display: none;
        font-size: 0.75em;


        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        margin-top: 0px;
        font-size: 1.35em;
      }
    }
  }

  .activeMatchTopInfo {
    #statusAndTime {
      font-size: .9em;

      #matchStatusSection {
        display: none;
        font-size: 0.75em;


        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        margin-top: 0px;
        font-size: 1.5em;
      }
    }
  }

  .overMatchTopInfo {
    // #matchStatusSection {
    //   display: unset;
    // }
  }
}

@media (min-width: 320px) and (max-width: 465px) {

  #statusAndTime {
    font-size: 1.1em;
  }

  #matchStatusSection {
    font-size: 1.1em;

    #matchStatus {
      display: inline;
    }
    #matchStatusColon {
      display: inline;
      margin-right: 0.2em;
    }
  }

  .matchTopperCountdown {
    margin-top: 0.1em;
    #cancelledMatchTimestamp {
      display: none;
      text-transform: uppercase;
      font-size: 0.525em;
      margin-top: 0em;
      color: #0000002b;
      // opacity: 0.8;
    }
  }

  .cancelledMatchTopInfo {
    // default state
  }

  .pendingMatchTopInfo {
    #statusAndTime {
      #matchStatusSection {
        display: none;
        font-size: 0.85em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.7em;
      }
    }
  }

  .activeMatchTopInfo {
    #statusAndTime {
      font-size: 0.9em;
      #matchStatusSection {
        display: none;
        font-size: 0.85em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.7em;
      }
    }
  }

  .overMatchTopInfo {
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #statusAndTime {
    font-size: 1.2em;
    padding: 5px;
  }

  #matchStatusSection {
    font-size: 1.1em;

    #matchStatus {
      display: inline;
    }
    #matchStatusColon {
      display: inline;
      margin-right: 0.2em;
    }
  }

  .matchTopperCountdown {
    margin-top: 0.1em;
    #cancelledMatchTimestamp {
      text-transform: uppercase;
      font-size: 0.65em;
      margin-top: 0em;
      color: #0000002b;
      // opacity: 0.8;
    }
  }

  .cancelledMatchTopInfo {
    // default state
    #statusAndTime {
      font-size: 1.2em;
    }
  }

  .pendingMatchTopInfo {
    #statusAndTime {
      #matchStatusSection {
        display: none;
        font-size: 0.85em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.7em;
      }
    }
  }

  .activeMatchTopInfo {
    #statusAndTime {
      #matchStatusSection {
        display: none;
        font-size: 1em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.7em;
      }
    }
  }

  .overMatchTopInfo {
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #statusAndTime {
    font-size: 1.25em;
    padding: 7.5px;
  }

  #matchStatusSection {
    font-size: 1.1em;

    #matchStatus {
      display: inline;
    }
    #matchStatusColon {
      display: inline;
      margin-right: 0.2em;
    }
  }

  .matchTopperCountdown {
    margin-top: 0.1em;
    #cancelledMatchTimestamp {
      text-transform: uppercase;
      font-size: 0.7em;
      margin-top: 0em;
      color: #0000002b;
      // opacity: 0.8;
    }
  }

  .cancelledMatchTopInfo {
    // default state
    #statusAndTime {
      font-size: 1.8em;
    }
  }

  .pendingMatchTopInfo {
    #statusAndTime {
      #matchStatusSection {
        display: none;
        font-size: 0.85em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.7em;
      }
    }
  }

  .activeMatchTopInfo {
    #statusAndTime {
      #matchStatusSection {
        display: none;
        font-size: 0.85em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.7em;
      }
    }
  }

  .overMatchTopInfo {
  }
}

@media (min-width: 1367px) {
  #statusAndTime {
    font-size: 1.75em;
    padding: 10px;
  }

  #matchStatusSection {
    font-size: 1.1em;

    #matchStatus {
      display: inline;
    }
    #matchStatusColon {
      display: inline;
      margin-right: 0.2em;
    }
  }

  .matchTopperCountdown {
    margin-top: 0.1em;
    #cancelledMatchTimestamp {
      text-transform: uppercase;
      font-size: .9em;
      margin-top: 0em;
      color: #0000002b;
      // opacity: 0.8;
    }
  }

  .cancelledMatchTopInfo {
    // default state
    #statusAndTime {
      font-size: 2em;
    }
  }

  .pendingMatchTopInfo {
    #statusAndTime {
      #matchStatusSection {
        display: none;
        font-size: 0.85em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.5em;
      }
    }
  }

  .activeMatchTopInfo {
    #statusAndTime {
      #matchStatusSection {
        display: none;
        font-size: 0.85em;

        #matchStatus {
          display: none;
        }

        #matchStatusColon {
          display: none;
        }
      }

      .matchTopperCountdown {
        flex-direction: column;
        margin-top: 0em;
        font-size: 1.5em;
      }
    }
  }

  .overMatchTopInfo {
  }


}

#memeMatchTopper {
  // background-color: #ced4da;
  // border-style: solid;
  // border-color: #ced4da;
  // border-radius: 5px;
  // border-bottom-left-radius: 0px;
  // border-bottom-right-radius: 0px;
  background-color: #00adff00;
  border-style: solid;
  border-color: #00adff00;
  // border-width: 10px;
  border-width: 0px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  h1 {
    color: #212529;
  }

  #matchTopInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // background: #fffa0087;
    // background: #bdbdbd87;
    // background: #fffa007d;
    // background: #0087ff33;
    // background: #66686b94;
    background: #b3b4b5c7;
    // background: transparent;
    margin-top: 0px;
    color: white;
    padding-bottom: 0px;
    max-width: 100%;
    /* margin-left: 2%; */
    /* margin-right: 2%; */
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px;

    #statusAndTime {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      #matchStatusSection {
        line-height: 1;
        margin-bottom: 0px;
        text-transform: uppercase;
        text-align: left;
        color: #212529;
        font-weight: 700;
        margin-bottom: 0;
        // padding-bottom: 0.5px;

        #matchNumberText {
          display: inline;
          // color: #2125297d;
          color: #21252961;
          // font-weight: 600;
        }

        #matchNumberSign {
          display: inline;
        }

        #matchID {
          display: inline;
        }

        #matchStatusColon {
          // display: inline;
          // margin-right: .2em;
        }

        #matchStatus {
          // display: inline;
        }
      }

      .matchTopperCountdown {
        display: flex;
        // flex-direction: row;
        flex-wrap: wrap;
        line-height: 1.15;
        margin-bottom: 0px;
        color: #212529;
        text-transform: uppercase;
        font-weight: 600;

        #votingTimeLeftText {
          color: #2125297d;
          display: inline;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 0.75em;

          h3 {
            display: inline;
            font-weight: 600;
          }
        }

        #startsOnFirstVoteText {
          display: inline;
          font-size: .5em;
          font-weight: 600;
          margin-bottom: 0px;
          // color:#5e72e4;
          color: #ffffff;
        }

        #cancelledMatchTimestamp {
          text-transform: uppercase;
          color: #0000002b;
          // opacity: .8;
        }
      }
    }

    #matchFormat {
      justify-self: flex-end;

      #proposalCard {
        .card.defaultProposalFooter.card-footer {
          display: none;
        }
      }
    }
  }
}

#countdownText {
  color: #2125297d;
  display: inline;
  font-weight: 600;
  text-transform: uppercase;
  // font-size: 0.55em;
  font-size: 0.47em; // TODO: wtf?
}

#countdownTime {
  display: inline;
  // font-size: 0.5em;
  font-weight: 600;
  // color: #5e72e4;
  color: #ffffff;
  // padding-left: 3px;
}

.overMatchTopInfo {
  // #matchStatusSection {
  //   display: unset;
  // }
}

/* ---------------------------------------------------- PendingMemeCard.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #pendingCardBody {
    padding: 5px 5px 5px 5px;
    min-height: 100px;
  }

  #noCompetitorIcon {
    // font-size: 1.5em;
    margin-right: .25em;
    margin-left: .25em;
  }

  #noCompetitorText {
    font-size: 0.5em;
    padding: 0.25em;
  }

  #currentHighBidText {
    font-size: 0.7em;
  }

  // #pendingCardGlowUpdateOne {
  //   font-size: 0.7em;
  // }

  // #pendingCardGlowUpdateTwo {
  //   font-size: 0.7em;
  // }

  #pendingMemeCardRight {
    transform: scale(.8);
  }

  #pendingMemeCardLeft {
    transform: scale(.8);
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #pendingCardBody {
    padding: 5px 5px 5px 5px;
    min-height: 150px;
  }

  #noCompetitorIcon {
    // font-size: 1.5em;
    margin-right: .25em;
    margin-left: .25em;
  }

  #noCompetitorText {
    font-size: 0.5em;
    // padding-top: 0.5em;
    // padding-left: 0.5em;
    // padding-right: 0.5em;
    padding: 0.35em;
  }

  // #pendingCardGlowUpdateLoad {
  //   font-size: 1em;
  // }

  #pendingMemeCardRight {
    transform: scale(.92);
  }

  #pendingMemeCardLeft {
    transform: scale(.92);
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #pendingCardBody {
    padding: 5px 5px 5px 5px;
    min-height: 200px;
  }

  // #noCompetitorMessage {
  //   // padding-right: .5em;
  // }

  #noCompetitorIcon {
    font-size: 1.5em;
    margin-right: .25em;
    margin-left: .25em;
    // margin-bottom: 1em;
  }

  #noCompetitorText {
    font-size: 0.67em;
    // padding-top: 1em;
    // padding-left: 0.5em;
    // padding-right: 0.5em;
    padding: 0.5em;
  }

  #currentHighBidText {
    font-size: 0.85em;
  }

  // #pendingCardGlowUpdateOne {
  //   font-size: 0.85em;
  // }

  // #pendingCardGlowUpdateTwo {
  //   font-size: 0.85em;
  // }

  #pendingMemeCardRight {
    transform: scale(.85);
  }

  #pendingMemeCardLeft {
    transform: scale(.85);
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #pendingCardBody {
    padding: 5px 5px 5px 5px;
    min-height: 250px;
  }

  #noCompetitorIcon {
    font-size: 2em;
    margin-right: .25em;
    margin-left: .25em;
    // margin-bottom: 1em;
  }

  #noCompetitorText {
    font-size: 0.75em;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-right: .5em;
    // padding-right: 0.5em;
    // padding: 0.5em;
  }

  #currentHighBidText {
    font-size: 0.85em;
  }

  #pendingMemeCardRight {
    transform: scale(.85);
  }

  #pendingMemeCardLeft {
    transform: scale(.85);
  }
}

@media (min-width: 1367px) {
  #pendingCardBody {
    padding: 5px 5px 5px 5px;
    min-height: 350px;
  }


  #noCompetitorIcon {
    font-size: 2.5em;
    margin-right: .25em;
    margin-left: .25em;
    // margin-bottom: 1em;
  }

  #noCompetitorText {
    font-size: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
    // padding-left: 0.5em;
    // padding-right: .5em;
    // padding-right: 0.5em;
    // padding: 0.5em;
  }

  #currentHighBidText {
    font-size: 0.85em;
  }

  #pendingMemeCardRight {
    transform: scale(.85);
  }

  #pendingMemeCardLeft {
    transform: scale(.85);
  }
}

#pendingMemeCard {
  margin-top: 0px;
  display: inherit;
  border-radius: 0px;
  background: #1f2251;
  box-shadow: none;
}

#pendingMemeCardLeft {
  margin-top: 0px;
  display: flex;
  border-radius: 8px;
  background: #1f2251;
  box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;
  // transform: scale(0.92);
}

#pendingMemeCardRight {
  display: flex;
  margin-top: 0px;
  border-radius: 8px;
  background: #1f2251;
  box-shadow: 7px 7px 14px #171a3d, -7px -7px 14px #272b65;
  // border-style: solid;
  // border-width: 0.5px;
  // border-color: #ffffff;
  // transform: scale(0.92);
}

#pendingCardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#currentHighBidText {
  display: flex;
  /* flex-direction: row; */
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  font-weight: 600;
  /* align-items: center; */
  /* align-content: center; */
  /* align-self: center; */
  margin-bottom: 0px;
  /* font-size: .9em; */
  /* padding-top: .5em; */
  /* padding-bottom: .5em; */
  padding: .5em;
}

#noCompetitorMessage {
  display: flex;
  // flex-direction: row;
  flex-direction: column;
  flex-wrap: wrap;
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  font-weight: 600;
  align-items: center;
  font-size: 1em;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

#noCompetitorIcon {
  display: inline;
  // margin-right: 0.25em;
  // margin-left: 0.25em;
}

#NoCompetitorText {
  // line-height: 1.5;
  // padding-right: 0.25em;
}

#currentHighBidTextNumber {
  margin-left: 5px;
  display: inline;
  color: white;
}

#highBidIcon {
  margin-left: 5px;
  display: inline;
  color: white;
}

#youTheHighBidderText {
  display: inline;
  color: rgba(255, 255, 255, 0.753);
  text-transform: uppercase;
  font-weight: 600;
  // padding: 5px;
  padding: 15px;
}

#pendingMemeCardFooter {
  margin: 0;
}

#submitBidButton {
  color: white;
  border-color: white;
}

.glowUpdateText {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#pendingCardGlowUpdateLoad {
  animation: pendingCardLoad 2s 1;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}

@keyframes pendingCardLoad {
  0% {
    background: #2dce89;
    /* color: #2dce89; */
  }
  100% {
    background: transparent;
    /* color: #2dce89; */
  }
}

#pendingCardGlowUpdateOne {
  font-size: 1.5em;
  animation: pendingCardOne 2s 1;
}

@keyframes pendingCardOne {
  0% {
    background: #2dce89;
    /* color: #2dce89; */
  }
  100% {
    background: transparent;
    /* color: #2dce89; */
  }
}

#pendingCardGlowUpdateTwo {
  font-size: 1.5em;
  animation: pendingCardTwo 2s 1;
}

h3#pendingCardGlowUpdateTwo {
  margin-bottom: 0px;
}

@keyframes pendingCardTwo {
  0% {
    background: #2dce89;
  }
  100% {
    background: transparent;
  }
}

/* ---------------------------------------------------- CollapseBidPrompt.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #pendingMatchSubmitButton {
    margin-bottom: 5px;
  }

  #collapseBidCard {
    padding-left: .1em;
    padding-right: .1em;
  }

  #collapseBidCardHeader {
    font-size: .9em;
  }

  #pendingMemeCardBidPrompt {
    font-size: 1.35em;
    padding-top: 7.5px;
  }

  #collapseBidCardHeader {
    padding: 3px;
  }

  #collapseCompetitorIcon {
    margin-left: 5px;
    font-size: 1.5em;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #pendingMatchSubmitButton {
    margin-bottom: 5px;
  }

  #collapseBidCard {
    padding-left: .1em;
    padding-right: .1em;
  }

  #collapseBidCardHeader {
    font-size: .95em;
  }

  #pendingMemeCardBidPrompt {
    // font-size: .84em;
    // font-size: .95em;
    // font-size: 2em;
    font-size: 1.45em;
    padding-top: 10px;
  }

  #collapseBidCardHeader {
    padding-left: 3px;
    padding-right: 3px; 
    padding-bottom: 5px;
    padding-top: 5px;
  }

  #collapseCompetitorIcon {
    margin-left: 5px;
    font-size: 1.5em;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #pendingMatchSubmitButton {
    margin-bottom: 5px;
  }

  #collapseBidCard {
    padding-left: .75em;
    padding-right: .75em;
  }

  #collapseBidCardHeader {
    font-size: .95em;
  }

  #pendingMemeCardBidPrompt {
    // font-size: 1em;
    font-size: 1.65em;
    padding-top: 15px;
  }

  #collapseBidCardHeader {
    padding: 5px;
  }

  #collapseCompetitorIcon {
    margin-left: 5px;
    font-size: 1.5em;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #pendingMatchSubmitButton {
    margin-bottom: 5px;
  }

  #collapseBidCard {
    padding-left: 1em;
    padding-right: 1em;
  }

  #collapseBidCardHeader {
    font-size: .95em;
    padding: 10px;
  }

  #pendingMemeCardBidPrompt {
    // font-size: 1.35em;
    font-size: 1.75em;
    padding-top: 20px;
  }

  #collapseCompetitorIcon {
    margin-left: 7.5px;
    font-size: 2em;
  }

}

@media (min-width: 1367px) {
  #pendingMatchSubmitButton {
    margin-bottom: 5px;
  }

  #collapseBidCard {
    padding-left: 1.25em;
    padding-right: 1.25em;
  }

  #collapseBidCardHeader {
    // font-size: .95em;
    font-size: .65em;
    padding: 10px;
  }

  #pendingMemeCardBidPrompt {
    // font-size: 1.5em;
    // font-size: 2.75em;
    font-size: 2em;
  }

  #collapseCompetitorIcon {
    margin-left: 10px;
    font-size: 2.15em;
  }
}

#collapseBidCard {
  display: flex;
  flex-direction: column;
  // margin-top: 10px;
  margin-top: 0px;
  background: transparent;
  // opacity: .99;
}

#collapseBidCardHeader {
  display: flex;
  justify-content: center;
  // padding: 1px;
}

#pendingMemeCardBidPrompt {
  // display: flex;
  // display: inline;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  /* justify-content: flex-start; */
  align-content: center;
  flex-wrap: wrap;
  color: white;
}

#collapseCompetitorIcon {
  display: inline-flex;;
  // font-size: 2.5em;
  // margin-left: 9px;
}

#yourPendingBidText {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1em;
}

#linkInput {
  display: inline;
  font-family: inherit;
  font-weight: 700;
  color: white;
}

#pendingMemeCardSubmitFooter {
  display: flex;
  justify-content: flex-end;
}

#pendingMatchSubmitButton {
  // margin-bottom: 5px;
}

#bidPromptWarningButton {
  font-size: .9em;
}

#accordionZero {
  width: 100%; // TODO make accordion a class
}

#accordionOne {
  width: 100%; // TODO make accordion a class
}

#invisibleCollapseBidPrompt {
  display: none;
}

/* ---------------------------------------------------- ActiveMemeCard.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #activeCardContent {
    padding: 1px;
    max-height: 275px;

    #activeMemeCardLeft {
      height: 150px;
    }    
  
    #activeMemeCardRight {
      height: 150px;
    }
  }

  #entryModal {
    transform: none;
    // margin-top: 5%;
    max-width: 90%;
    display: flex;
    justify-content: center;
  
    #entryModalCard {
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      // margin-top: 0px;
      // background: #ffffff !important;
      overflow: hidden;
      // text-align: left;
      z-index: 2;
      // padding: 10px;
      box-shadow: none;
    }
  
    #closeModalIcon {
      font-size: 2em;
      color: white;
    }
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #activeCardContent {
    padding: 1px;
    // max-height: 400px;
    object-fit: contain;

    #activeMemeCardLeft {
      height: 150px;
    }    
  
    #activeMemeCardRight {
      height: 150px;
    }
  }

  #entryModal {
    transform: none;
    // margin-top: 5%;
    max-width: 90%;
  
    #entryModalCard {
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      // margin-top: 0px;
      // background: #ffffff !important;
      overflow: hidden;
      // text-align: left;
      z-index: 2;
      padding: 10px;
      box-shadow: none;
    }
  
    #closeModalIcon {
      font-size: 2em;
      color: white;
    }
  }

  // #tiktokIframe {
  //   transform: scale(0.5);
  // }
}

@media (min-width: 466px) and (max-width: 768px) {
  #activeCardContent {
    padding: 1px;
    max-height: 365px;

    #activeMemeCardLeft {
      height: 250px;
    }    
  
    #activeMemeCardRight {
      height: 250px;
    }
  }

  #entryModal {
    transform: none;
    // margin-top: 5%;
    max-width: 90%;
  
    #entryModalCard {
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      // margin-top: 0px;
      // background: #ffffff !important;
      overflow: hidden;
      // text-align: left;
      z-index: 2;
      padding: 15px;
      box-shadow: none;
    }
  
    #closeModalIcon {
      font-size: 2em;
      color: white;
    }
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #activeCardContent {
    padding: 5px;

    #activeMemeCardLeft {
      height: 350px;
    }    
  
    #activeMemeCardRight {
      height: 350px;
    }
  }

  #entryModal {
    transform: none;
    // margin-top: 5%;
    max-width: 90%;
  
    #entryModalCard {
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      // margin-top: 0px;
      // background: #ffffff !important;
      overflow: hidden;
      // text-align: left;
      z-index: 2;
      padding: 20px;
      box-shadow: none;
    }
  
    #closeModalIcon {
      font-size: 2em;
      color: white;
    }
  }
}

@media (min-width: 1367px) {
  #activeCardContent {
    padding: 5px;
  }

  // #activeMemeCardLeft {
  //   min-height: 450px;
  // }    

  // #activeMemeCardRight {
  //   min-height: 450px;
  // }

  #entryModal {
    transform: none;
    // margin-top: 5%;
    max-width: 90%;
  
    #entryModalCard {
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      // margin-top: 0px;
      // background: #ffffff !important;
      overflow: hidden;
      // text-align: left;
      z-index: 2;
      padding: 20px;
      box-shadow: none;
    }
  
    #closeModalIcon {
      font-size: 2em;
      color: white;
    }
  }
}

// #activeCardContent {
//   padding: 5px;
// }

#pendingMemeCardRight {
  // transform: scale(.92);
}

#pendingMemeCardLeft {
  // transform: scale(.92);
}

#activeMemeCardFooter {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding-bottom: 10px;
}

#tiktokEmbed {
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;

  body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

#tiktokIframe {
  justify-self: center;
  align-self: center;
  // height: 150px;
  // TODO: not in use
}

#iframeContainer {
  object-fit: contain;
  // TODO: not in use
}

#imageThumbnail {
  // TODO
}

#tiktokThumbnail {
  background: rgba(0,0,0,0.6) url("http://wptf.com/wp-content/uploads/2014/05/play-button.png") no-repeat scroll center center / 50px 50px;
}

#entryModal {
  transform: none;
  // margin-top: 5%;
  max-width: 90%;

  #entryModalCard {
    object-fit: contain;
    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    // margin-top: 0px;
    // background: #ffffff !important;
    overflow: hidden;
    // text-align: left;
    z-index: 2;
    padding: 15px;
    border-radius: 0px;
  }

  #closeModalIcon {
    font-size: 2em;
    color: white;
  }
}

#fullFormImage {
  max-height: 600px; // TODO: make dynamic
  object-fit: contain;
  // object-fit: cover;
  width: 90%;
}

/* ---------------------------------------------------- OverMemeCardBottom.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  // TODO

  #voteCountRatioText {
    margin-bottom: 5px;
    font-size: 1.15em;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #overMatchFooter {
  
  }

  #voteCountRatioText {
    margin-bottom: 5px;
    font-size: 1.3em;
  }
}

@media (min-width: 466px) and (max-width: 768px) {

  #voteCountRatioText {
    font-size: 1.5em;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #voteCountRatioText {
    font-size: 1.75em;
  }
}

@media (min-width: 1367px) {
  #voteCountRatioText {
    font-size: 2em;
  }
}

#overMatchFooter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
}

#voteCountRatioText {
  font-family: Poppins;
  // font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 0px;
  // color: #ffffff87;
  color:   #ffffff38;

  // opacity: .5;
}


/* ---------------------------------------------------- OverMemeCardCollapse.jsx ---------------------------------------------------- */


@media (min-width: 0px) and (max-width: 319px) {

  #entryDetailsCard {
    margin-top: 0px;
    background: transparent;
  }

  #entryDetailsCardHeader {
    // padding-left: 0px;
    // padding-right: 0px;
    // padding-top: 3px;
    // padding-bottom: 3px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #overCollapseSubmitterPrompt {
    font-size: .85em;
  } 

  #overCollapseSubmitterPromptMinimal {
    font-size: 1em;
  } 

  #overCollapseDetailsText {
    display: none; // TODO:?
  }
  

  #collapseCompetitorIcon {
    // font-size: .85em;
    font-size: 1.85em;
  }
  
}

@media (min-width: 320px) and (max-width: 465px) {

  #entryDetailsCard {
    margin-top: 0px;
    background: transparent;
  }

  #entryDetailsCardHeader {
    // padding-left: 0px;
    // padding-right: 0px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #overCollapseSubmitterPrompt {
    font-size: 1em;
  } 

  #overCollapseSubmitterPromptMinimal {
    font-size: 1.5em;
  } 

  #overCollapseDetailsText {
    display: none; // TODO:?
  }

  #collapseCompetitorIcon {
    // font-size: .85em;
    font-size: 1.5em;
  }
}

@media (min-width: 466px) and (max-width: 768px) {

  #entryDetailsCard {
    // margin-top: 10px;
    margin-top: 0px;
    background: transparent;
  }

  #entryDetailsCardHeader {
    // padding-left: 0px;
    // padding-right: 0px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #overCollapseSubmitterPrompt {
    font-size: 1em;
  } 

  #overCollapseSubmitterPromptMinimal {
    font-size: 1.85em;
  } 

  #overCollapseDetailsText {
    display: none; // TODO:?
  }

  #collapseCompetitorIcon {
    font-size: 2.15em;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {

  #entryDetailsCard {
    // margin-top: 10px;
    margin-top: 0px;
    background: transparent;
  }

  #entryDetailsCardHeader {
    // padding-left: 0px;
    // padding-right: 0px;
    // padding-top: 15px;
    // padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #overCollapseSubmitterPrompt {
    font-size: 1.25em;
  } 

  #overCollapseSubmitterPromptMinimal {
    font-size: 2.15em;
  } 

  #overCollapseDetailsText {
    display: none; // TODO:?
  }

  #collapseCompetitorIcon {
    // font-size: 3.5em;
    font-size: 2.15em;
  }
}

@media (min-width: 1367px) {

  #entryDetailsCard {
    // margin-top: 15px;
    margin-top: 0px;
  }


  #entryDetailsCardHeader {
    // padding-left: 0px;
    // padding-right: 0px;
    // padding-top: 20px;
    // padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #overCollapseSubmitterPrompt {
    font-size: 1.5em;
  } 

  #overCollapseSubmitterPromptMinimal {
    font-size: 2.5em;
  } 

  #overCollapseDetailsText {
    display: none; // TODO:?
  }

  #collapseCompetitorIcon {
    // font-size: 3em;
    // font-size: 5.5em;
    font-size: 2.35em;
  }
}

#overCollapseSubmitterPrompt {
  display: inline;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
} 

#overCollapseSubmitterPromptMinimal {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: flex-end;
  // /* flex-wrap: wrap; */
  // color: white;
  // text-transform: uppercase;
  // font-weight: 600;
  // /* width: 100%; */
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    /* width: 100%; */
    /* padding-right: 10px; */
    color: #ffffff33;
} 


#collapseCompetitorIcon {
  // display: inline-flex;
  // padding: 0px;
  // margin: 0px;
  // justify-self: flex-end;
  // margin-left: 5px;
  // margin-bottom: 2.5px; // TODO

  display: inline-flex;
    /* padding: 0px; */
    /* margin: 0px; */
    justify-self: flex-end;
    /* margin-left: 5px; */
    /* margin-bottom: 2.5px; */
    padding-left: 5px;
} 

#entryDetailsCard {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  // background: none !important;
  align-content: center;
  // opacity: .35; // TODO
  background: transparent;
}

#entryDetailsCardBody {
  // padding-top: 5px;
  // padding-bottom: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

#toggleEntryDetailsLink {
  // TODO:
}

#progressAndArrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  justify-content: space-between;

  #voteCountProgressBar {
    width: 75%;
  }
}

#submitterDisplay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 1.25em;

  #submitterIcon {
    display: inline;
  }

  #submitterAddressText {
    // display: inline;
  }
}


/* ---------------------------------------------------- MemeMemeMatchPaginate.jsx ---------------------------------------------------- */


@media (min-width: 0px) and (max-width: 319px) {
}

@media (min-width: 320px) and (max-width: 465px) {
}

@media (min-width: 466px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 1366px) {
}

@media (min-width: 1367px) {
}

.disabledArrow {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .001;
  background: none;
  display: inline;
}

// .index-page .card.card-background[data-animation=true] {
//   height: 380px;
// }
