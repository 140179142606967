// Variables
$primary-color: #4A90E2;
$border-color: #E5E7EB;
$text-color: #374151;
$background-color: #FFFFFF;
$hover-color: #F3F4F6;
$shadow-color: rgba(0, 0, 0, 0.1);

.questionFilter {
  position: relative;
  
  #filterButton {
    background: $background-color;
    border: 1px solid $border-color;
    color: $text-color;
    padding: 0.5rem;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
    
    &:hover {
      background: $hover-color;
      border-color: $primary-color;
    }
  }
}

// Modal Styles
.modalHeader {
  border-bottom: 1px solid $border-color;
  padding: 1.25rem 1.5rem;
  
  :global(.modal-title) {
    font-size: 1.25rem;
    font-weight: 600;
    color: $text-color;
  }
}

.modalBody {
  padding: 1.5rem;
}

.modalFooter {
  border-top: 1px solid $border-color;
  padding: 1rem 1.5rem;
}

// Collapsible Section Styles
.filterSection {
  margin-bottom: 1.5rem;
  
  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background: $background-color;
    border: 1px solid $border-color;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      background: $hover-color;
    }
    
    h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
      color: $text-color;
    }
    
    .icon {
      transition: transform 0.2s ease;
      
      &.expanded {
        transform: rotate(180deg);
      }
    }
  }
  
  .sectionContent {
    padding: 1rem;
    border: 1px solid $border-color;
    border-top: none;
    border-radius: 0 0 0.375rem 0.375rem;
    background: $background-color;
  }
}

// Checkbox Styles
.filterOption {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: $text-color;
  
  input[type="checkbox"] {
    margin-right: 0.75rem;
    cursor: pointer;
  }
  
  &:hover {
    color: $primary-color;
  }
}

// Search Input Styles
.searchInput {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid $border-color;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  
  &:focus {
    outline: none;
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
  }
}

// Popular Questions Badge
.popularBadge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background: rgba($primary-color, 0.1);
  color: $primary-color;
  border-radius: 1rem;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

///
/// 
/// 

.disabledSection {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .disabledText {
    color: gray;
    font-style: italic;
  }

// Add this to your existing SCSS file
#topQuestionsCountInput {
    min-width: 70px !important;
    max-width: 70px !important;
    background: transparent !important;
    color: black !important;
    font-size: 1.1em !important;
    font-weight: 600 !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding: 5px !important; 
  }
  