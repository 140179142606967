/* ---------------------------------------------------- ProposalCard.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  .topperBadge {
    display: flex;
    flex-direction: row;
    align-content: center;
    transform: scale(0.9);
  }

  #cardIcon {
    display: inline;
    margin-right: 0.15em;
  }

  #cardFormat {
    display: inline;
    margin-right: 0.15em;
  }

  #cardExpandIcon {
    display: inline;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  .topperBadge {
    display: flex;
    flex-direction: row;
    align-content: center;
    transform: scale(0.9);
  }

  #cardIcon {
    display: inline;
    margin-right: 0.3em;
  }

  #cardFormat {
    display: inline;
    margin-right: 0.3em;
  }

  #cardExpandIcon {
    display: inline;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  .topperBadge {
    display: flex;
    flex-direction: row;
    align-content: center;
    transform: scale(0.9);
  }

  #cardIcon {
    display: inline;
    margin-right: 0.3em;
  }

  #cardFormat {
    display: inline;
    margin-right: 0.3em;
  }

  #cardExpandIcon {
    display: inline;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  .topperBadge {
    display: flex;
    flex-direction: row;
    align-content: center;
    transform: scale(0.9);
  }

  #cardIcon {
    display: inline;
    margin-right: 0.3em;
    color: #ffffff70;
  }

  #cardFormat {
    display: inline;
    margin-right: 0.3em;
  }

  #cardExpandIcon {
    display: inline;
  }
}

@media (min-width: 1367px) {
  .topperBadge {
    display: flex;
    flex-direction: row;
    align-content: center;
    transform: scale(0.9);
  }

  #cardIcon {
    display: inline;
    margin-right: 0.3em;
  }

  #cardFormat {
    display: inline;
    margin-right: 0.3em;
  }

  #cardExpandIcon {
    display: inline;
  }
}

.topperBadge {
  // display: flex;
  // flex-direction: row-reverse;
  // align-items: center;
  margin-bottom: 0px;
  h2 {
    margin: 0px;
  }
}

.topperProposalCard {
  margin-top: 0px;
  .card {
    margin-top: 0px;
  }

  #proposalPromptTextContainer {
    display: none;
  }

  .proposalPromptText {
    display: none !important;
  }
}

.proposalPromptText {
  padding-top: 10px;
  font-weight: 600;
  font-size: 1.35em;
  text-align: end;
}

/* ---------------------------------------------------- FutureMatchesTab.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #upcomingMatchesTab {
    // display: unset;
  }

  #upNextMatchContainer {
    order: 0;
    width: 100%;
    // padding-left: 1%;
    // padding-right: 1%;
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #upcomingMatchesTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
  }

  #upNextMatchContainer {
    order: 0;
    width: 100%;
    // padding-left: 1%;
    // padding-right: 1%;
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #upcomingMatchesTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
  }

  #upNextMatchContainer {
    order: 0;
    width: 100%;
    // padding-left: 1%;
    // padding-right: 1%;
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #upcomingMatchesTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
  }

  #upNextMatchContainer {
    order: 0;
    width: 100%;
    // padding-left: 1%;
    // padding-right: 1%;
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (min-width: 1367px) {
  #upcomingMatchesTab {
    display: flex;
    flex-direction: row;
    // align-items: center;
    padding-right: 2%;
    padding-left: 2%;
    height: 480px;
  }

  #upNextMatchContainer {
    order: 0;
    width: 100%;
    // padding-left: 1%;
    // padding-right: 1%;
    padding-left: 2%;
    padding-right: 2%;
  }
}

#upcomingMatchesTab {
  background: #1f2251;
  border-style: solid;
  border-width: 0px;
  border-color: transparent;
  border-radius: 5px;
  margin-bottom: 10px;
  // margin-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
  h1 {
    color: white;
  }
  h2 {
    color: white;
  }
}

#upNextMatchContainer {
  order: 0;
  width: 100%;
  // padding-left: 1%;
  // padding-right: 1%;
  // padding-left: 1%;
  // padding-right: 1%;
}

#proposalsListContainer {
  width: 100%;
  order: 1;
  // margin-top: 20px;
  margin-top: 0px;
  padding-left: 1%;
  padding-right: 1%;
  transform: scale(.95);
}

.demoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.demoContent {
  text-align: center;
  max-width: 600px;
  padding: 2rem;
}

.demoDescription {
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  font-weight: 400;
}

.demoButton {
  background: #4a90e2;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #357abd;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }

  svg {
    font-size: 1.4rem;
  }
}

/* ---------------------------------------------------- UpNextMatch.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #nextUp {
    // transform: scale(.5);
    width: 100%;
    transform: scale(.8);
    margin-top: 3px;
  }

  #upNextCard {
    margin-top: 15px;
    border-radius: 8px;
    background: linear-gradient(145deg, #1c1f49, #212457);
    box-shadow: 6px 6px 12px #10122a, -6px -6px 12px #2e3278;
    // transform: scale(.6);

    // .h6 {
    //     font-size: 18px;
    //     margin-top: 0px;
    // }

    .card {
      margin-top: 20px;
      box-shadow: none;
    }

    h6.upNextCardTopRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1em;
      margin-bottom: 10px;

      #upNextHeaderIconAndText {
        display: flex;
        align-items: center;

        i {
          font-weight: 700;
          margin-right: 5px;
        }

        #nextMatchIcon {
          display: inline;
        }
      }
    }
  }

  #upNextCardBody {
    padding: 10px;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #nextUp {
    // transform: scale(.5);
    width: 100%;
    transform: scale(.9);
    margin-top: 5px;
  }

  #upNextCard {
    margin-top: 15px;
    border-radius: 8px;
    background: linear-gradient(145deg, #1c1f49, #212457);
    box-shadow: 6px 6px 12px #10122a, -6px -6px 12px #2e3278;
    // transform: scale(.6);

    // .h6 {
    //     font-size: 18px;
    //     margin-top: 0px;
    // }

    .card {
      margin-top: 20px;
      box-shadow: none;
    }

    h6.upNextCardTopRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1em;
      margin-bottom: 10px;

      #upNextHeaderIconAndText {
        display: flex;
        align-items: center;

        i {
          font-weight: 700;
          margin-right: 8px;
        }

        #nextMatchIcon {
          display: inline;
        }
      }
    }
  }

  #upNextCardBody {
    padding: 10px;
  }
}

@media (min-width: 466px) and (max-width: 768px) {

  #nextUp {
    // transform: scale(.5);
    width: 100%;
    /* padding: 10px; */
    transform: scale(0.95);
    margin-top: 25px;
  }

  #upNextCard {
    margin-top: 15px;
    border-radius: 8px;
    background: linear-gradient(145deg, #1c1f49, #212457);
    box-shadow: 6px 6px 12px #10122a, -6px -6px 12px #2e3278;
    // transform: scale(.6);

    // .h6 {
    //     font-size: 18px;
    //     margin-top: 0px;
    // }

    .card {
      margin-top: 20px;
      box-shadow: none;
    }

    h6.upNextCardTopRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1.25em;
      margin-bottom: 10px;

      #upNextHeaderIconAndText {
        display: flex;
        align-items: center;

        i {
          font-weight: 700;
          margin-right: 10px;
        }

        #nextMatchIcon {
          display: inline;
        }
      }
    }
  }

  #upNextCardBody {
    padding: 15px;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {

  #nextUp {
    // transform: scale(.5);
    width: 100%;
    /* padding: 10px; */
    transform: scale(0.95);
    margin-top: 45px;
  }

  #upNextCard {
    margin-top: 20px;
    border-radius: 8px;
    background: linear-gradient(145deg, #1c1f49, #212457);
    box-shadow: 6px 6px 12px #10122a, -6px -6px 12px #2e3278;
    // transform: scale(.6);

    // .h6 {
    //     font-size: 18px;
    //     margin-top: 0px;
    // }

    .card {
      margin-top: 20px;
      box-shadow: none;
    }

    h6.upNextCardTopRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1.45em;
      margin-bottom: 10px;

      #upNextHeaderIconAndText {
        display: flex;
        align-items: center;

        i {
          font-weight: 700;
          margin-right: 10px;
        }

        #nextMatchIcon {
          display: inline;
        }
      }
    }
  }

  #upNextCardBody {
    padding: 15px;
  }
}

@media (min-width: 1367px) {

  #nextUp {
    // transform: scale(.5);
    width: 100%;
    /* padding: 10px; */
    transform: scale(0.95);
    margin-top: 50px;
  }

  #upNextCard {
    margin-top: 35px;
    border-radius: 8px;
    background: linear-gradient(145deg, #1c1f49, #212457);
    box-shadow: 6px 6px 12px #10122a, -6px -6px 12px #2e3278;
    // transform: scale(.6);

    // .h6 {
    //     font-size: 18px;
    //     margin-top: 0px;
    // }

    .card {
      margin-top: 20px;
      box-shadow: none;
    }

    h6.upNextCardTopRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 1.65em;
      margin-bottom: 10px;

      #upNextHeaderIconAndText {
        display: flex;
        align-items: center;

        i {
          font-weight: 700;
          margin-right: 3px;
        }

        #nextMatchIcon {
          display: inline;
        }
      }
    }
  }

  #upNextCardBody {
    padding: 15px;
  }
}

#nextUp {
  // margin-top: 20px;
}

#cannotStartMatchTooltip {
  z-index: 5;
  color: #00000073;
  font-weight: 600;
  font-size: 0.75em;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  background: #cecece;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  opacity: 0.75;
  text-transform: uppercase;
}

// #upNextCard{
//     margin-top: 10px;
//     border-radius: 8px;
//     background: linear-gradient(145deg, #1c1f49, #212457);
//     box-shadow:  6px 6px 12px #10122a,
//                  -6px -6px 12px #2e3278;

//         h6 {
//             font-size: 18px;
//             margin-top: 0px;
//         }

//         .card {
//             margin-top: 20px;
//             box-shadow: none;
//         }
//   }

//   #upNextCard h6 {
//     font-size: 18px;
//     margin-top: 0px;
//   }

//   #upNextCard .card {
//     margin-top: 20px;
//     box-shadow: none;
//   }

/* ---------------------------------------------------- ProposalsList.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

  #proposalsList {
    // padding: 10px 10px 10px 10px;
    padding: 10px;
  }

  #proposalsListBody {
    // padding: 20px;
    margin-bottom: 0px;
  }

  #otherProposalsText {
    font-size: 1em;
  }

  #noProposalsText {
    font-size: 16px;
  }
  
}

@media (min-width: 320px) and (max-width: 465px) {

  #proposalsList {
    // padding: 10px 10px 10px 10px;
    padding: 10px;
  }

  #proposalsListBody {
    // padding: 20px;
    margin-bottom: 0px;
  }


  #otherProposalsText {
    font-size: 1em;
  }

  #noProposalsText {
    font-size: 16px;
  }
}

@media (min-width: 466px) and (max-width: 768px) {

  #proposalsList {
    // padding: 10px 10px 10px 10px;
    padding: 20px;
  }

  #otherProposalsText {
    font-size: 1.2em;
  }

  #noProposalsText {
    font-size: 22px;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {

  #proposalsList {
    // padding: 10px 10px 10px 10px;
    padding: 25px;
  }

  #otherProposalsText {
    font-size: 1.3em;
  }
}

@media (min-width: 1367px) {

  #proposalsList {
    // padding: 10px 10px 10px 10px;
    padding: 20px;
  }

  #otherProposalsText {
    font-size: 1.4em;
  }
}

#proposalPromptCard {
  // // background-color: #1d8cf824;
  // background-color: transparent;
  // // margin-top: 0px;
  // // margin-bottom: 15px;
  // // margin-top: 10px;
  // // margin-bottom: 5px;
  // margin-top: 5px;
  // margin-bottom: 5px;
  background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    // padding: 2.5%;
    // opacity: .2;
}

#collapseBody {
  margin-right: 10px;
}

#proposalPromptHeader {
  // padding: 10px;
  // padding: 0px;
  // display: flex;
  // justify-content: center;
  // padding: 0px;
  display: flex;
  // justify-content: flex-end;
  padding: 0px 0px 0px 0px;
}

#proposalsList {
    // padding: 10px 10px 10px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    background: #1f2251;
    // box-shadow: inset 6px 6px 12px #0f1128, inset -6px -6px 12px #2f337a;
  }

#proposalsListHeader {
  padding: 0px;
}

#noProposalsText {
  // margin-top: 10px;
  font-weight: 400;
  // opacity: .3;
  // margin-bottom: 10px;
  margin-bottom: 0px;
  color: #ffffff24;
}

#noProposalsIcon {
  margin-left: .5em;
}



#proposalsListBody {
  // padding: 20px;
  // border: white solid 1px;
  // margin-top: 20px;
}

#otherProposalsHeader {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h6 {
    font-weight: 700;

    i {
      margin-right: 10px;
    }
  }
}

h6 {
  // font-size: 18px;
  margin-bottom: 0px;
}

#otherProposalsText {
  display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-between;
  justify-content: flex-start;
  padding-left: 10px;
  // padding-left: 4%;
  // padding-top: 10px;
  // padding-right: 1em;
  // font-size: 1em;
  // overflow: hidden;
}

/* ---------------------------------------------------- ProposalCard.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  .proposalCard {
    margin-top: 10px;
    .card {
      margin-top: 0px;
      // margin-bottom: 10px;
      display: inherit;
      border-radius: 0px;
      // background: #1f2251;
      // background: transparent;
      background-color: pink;
      box-shadow: none;
    }
  }

  #proposalCardBody {
    padding: 5px;
  }

  #proposalCardFormatBadge {
    display: flex;
    flex-direction: row;
    // padding: 5%;
    align-content: center;
    font-size: 1.5em;
    padding-left: 10px;
    padding-top: 10px;
  }

  #proposalCardIcon {
    margin-right: 10%;
    display: inline;
  }

  #proposalCardFormat {
    display: inline;
  }

  #proposalPromptTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5%;
    padding-right: 5%;
    align-self: center;
    justify-content: flex-end;
    font-size: 0.85em;
    font-weight: 600;
  }
  

  #normalProposalFooter {
    margin-top: 0px;
    // padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-bottom: 5%;

    .img {
      margin-right: .5em;
    }
  }

  #defaultProposalFooter {
    margin-top: 0px;
    padding-top: 7.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-bottom: 5%;

    #defaultProposalFooterBadge {
      font-size: 1em;
      color: #ffd600;
      opacity: 0.5;
    }

    #lobbyDefaultIcon {
      display: inline;
      margin-right: 5px;
      color: #ffd600;
    }

    #defaultLobbyText {
    }

    #submitterIcon {
      display: inline;
      margin-right: .5em;
    }

    #submitterAddressButton {
      z-index: 3;
      display: inline;
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
    }

    #submitterIcon {
      display: unset;
      margin-right: .5em !important;
    }

    #submitterAddressText {
      margin-left: .5em !important;
    }


    .addressButtonLink {
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
      margin-top: 0px;
      margin-left: .5em;
    }

    #upvoteCount {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }

  #listProposalCard {
    margin-bottom: 10px;
  }

  #topperProposalUpvoteCount {
    // display: flex; 
    // flex-direction: row;
    // align-content: center;
    // align-items: center;
  }

}

@media (min-width: 320px) and (max-width: 465px) {
  .proposalCard {
    margin-top: 10px;
    .card {
      margin-top: 0px;
      // margin-bottom: 10px;
      display: inherit;
      border-radius: 0px;
      // background: #1f2251;
      // background: transparent;
      background-color: pink;
      box-shadow: none;
    }
  }

  #proposalCardBody {
    padding: 5px;
  }

  #proposalCardFormatBadge {
    display: flex;
    flex-direction: row;
    // padding: 5%;
    align-content: center;
    font-size: 1.5em;
  }

  #proposalCardIcon {
    margin-right: 10%;
    display: inline;
  }

  #proposalCardFormat {
    display: inline;
  }

  #proposalPromptTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5%;
    padding-right: 5%;
    align-self: center;
    justify-content: flex-end;
    font-size: 0.95em;
    font-weight: 600;
  }

  #normalProposalFooter {
    margin-top: 0px;
    // padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .img {
      margin-right: .5em;
    }
  }

  #defaultProposalFooter {
    margin-top: 0px;
    padding-top: 7.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-bottom: 2%;

    #defaultProposalFooterBadge {
      font-size: 1em;
      color: #ffd600;
      opacity: 0.5;
    }

    #lobbyDefaultIcon {
      display: inline;
      margin-right: 5px;
      color: #ffd600;
    }

    #defaultLobbyText {
    }

    #submitterIcon {
      display: inline;
      margin-right: .5em;
    }

    #submitterAddressButton {
      z-index: 3;
      display: inline;
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
    }

    #submitterIcon {
      display: unset;
      margin-right: .5em !important;
    }

    #submitterAddressText {
      margin-left: .5em !important;
    }


    .addressButtonLink {
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
      margin-top: 0px;
      margin-left: .5em;
    }

    #upvoteCount {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }

  #listProposalCard {
    margin-bottom: 10px;
  }

  #topperProposalUpvoteCount {
    // display: flex; 
    // flex-direction: row;
    // align-content: center;
    // align-items: center;
  }

}

@media (min-width: 466px) and (max-width: 768px) {
  .proposalCard {
    margin-top: 10px;
    .card {
      margin-top: 0px;
      // margin-bottom: 10px;
      display: inherit;
      border-radius: 0px;
      // background: #1f2251;
      // background: transparent;
      background-color: pink;
      box-shadow: none;
    }
  }

  #proposalCardBody {
    padding: 5px;
  }

  #proposalCardFormatBadge {
    display: flex;
    flex-direction: row;
    // padding: 5%;
    align-content: center;
    font-size: 1.75em;
  }

  #proposalCardIcon {
    margin-right: 10%;
    display: inline;
  }

  #proposalCardFormat {
    display: inline;
  }

  #proposalPromptTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5%;
    padding-right: 5%;
    align-self: center;
    justify-content: flex-end;
    // font-size: 1.5em;
    font-weight: 600;
  }

  #normalProposalFooter {
    margin-top: 0px;
    // padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;

    .img {
      margin-right: .5em;
    }
  }

  #defaultProposalFooter {
    margin-top: 0px;
    padding-top: 7.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-bottom: 2%;
    padding-left: 2%;

    #defaultProposalFooterBadge {
      font-size: 1em;
      color: #ffd600;
      opacity: 0.5;
    }

    #lobbyDefaultIcon {
      display: inline;
      margin-right: 5px;
      color: #ffd600;
    }

    #defaultLobbyText {
    }

    #submitterIcon {
      display: inline;
      margin-right: .5em;
    }

    #submitterAddressButton {
      z-index: 3;
      display: inline;
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
    }

    #submitterIcon {
      display: unset;
      margin-right: .5em !important;
    }

    #submitterAddressText {
      margin-left: .5em !important;
    }


    .addressButtonLink {
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
      margin-top: 0px;
      margin-left: .5em;
    }

    #upvoteCount {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }

  #listProposalCard {
    margin-bottom: 10px;
  }

  #topperProposalUpvoteCount {
    // display: flex; 
    // flex-direction: row;
    // align-content: center;
    // align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  .proposalCard {
    margin-top: 10px;
    .card {
      margin-top: 0px;
      // margin-bottom: 10px;
      display: inherit;
      border-radius: 0px;
      // background: #1f2251;
      // background: transparent;
      background-color: pink;
      box-shadow: none;
    }
  }

  #proposalCardBody {
    padding: 5px;
  }

  #proposalCardFormatBadge {
    display: flex;
    flex-direction: row;
    // padding: 5%;
    align-content: center;
    font-size: 1.85em;
  }

  #proposalCardIcon {
    margin-right: 10%;
    display: inline;
  }

  #proposalCardFormat {
    display: inline;
  }

  #proposalPromptTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5%;
    padding-right: 5%;
    align-self: center;
    justify-content: flex-end;
    // font-size: 1.5em;
    font-weight: 600;
  }

  #normalProposalFooter {
    margin-top: 0px;
    // padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;

    .img {
      margin-right: .5em;
    }
  }

  #defaultProposalFooter {
    margin-top: 0px;
    padding-top: 7.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-bottom: 2%;
    padding-left: 2%;

    #defaultProposalFooterBadge {
      font-size: 1em;
      color: #ffd600;
      opacity: 0.5;
    }

    #lobbyDefaultIcon {
      display: inline;
      margin-right: 5px;
      color: #ffd600;
    }

    #defaultLobbyText {
    }

    #submitterIcon {
      display: inline;
      margin-right: .5em;
    }

    #submitterAddressButton {
      z-index: 3;
      display: inline;
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
    }

    #submitterIcon {
      display: unset;
      margin-right: .5em !important;
    }

    #submitterAddressText {
      margin-left: .5em !important;
    }


    .addressButtonLink {
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
      margin-top: 0px;
      margin-left: .5em;
    }

    #upvoteCount {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }

  #listProposalCard {
    margin-bottom: 10px;
  }

  #topperProposalUpvoteCount {
    // display: flex; 
    // flex-direction: row;
    // align-content: center;
    // align-items: center;
  }
}

@media (min-width: 1367px) {
  .proposalCard {
    margin-top: 10px;
    .card {
      margin-top: 0px;
      // margin-bottom: 10px;
      display: inherit;
      border-radius: 0px;
      // background: #1f2251;
      // background: transparent;
      background-color: pink;
      box-shadow: none;
    }
  }

  #proposalCardBody {
    padding: 5px;
  }

  #proposalCardFormatBadge {
    display: flex;
    flex-direction: row;
    // padding: 5%;
    align-content: center;
    font-size: 1.95em;
  }

  #proposalCardIcon {
    margin-right: 10%;
    display: inline;
  }

  #proposalCardFormat {
    display: inline;
  }

  #proposalPromptTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5%;
    padding-right: 5%;
    align-self: center;
    justify-content: flex-end;
    // font-size: 1.5em;
    font-weight: 600;
  }

  #normalProposalFooter {
    margin-top: 0px;
    // padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;

    .img {
      margin-right: .5em;
    }
  }

  #defaultProposalFooter {
    margin-top: 0px;
    padding-top: 7.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-bottom: 2%;
    padding-left: 2%;

    #defaultProposalFooterBadge {
      font-size: 1em;
      color: #ffd600;
      opacity: 0.5;
    }

    #lobbyDefaultIcon {
      display: inline;
      margin-right: 5px;
      color: #ffd600;
    }

    #defaultLobbyText {
    }

    #submitterIcon {
      display: inline;
      margin-right: .5em;
    }

    #submitterAddressButton {
      z-index: 3;
      display: inline;
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
    }

    #submitterIcon {
      display: unset;
      margin-right: .5em !important;
    }

    #submitterAddressText {
      margin-left: .5em !important;
    }


    .addressButtonLink {
      background-color: transparent !important;
      background-image: none !important;
      color: #69c9d0 !important;
      text-decoration: none;
      text-shadow: 2px 2px #ee1d52;
      box-shadow: none;
      margin-top: 0px;
      margin-left: .5em;
    }

    #upvoteCount {
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }

  #listProposalCard {
    margin-bottom: 10px;
  }

  #topperProposalUpvoteCount {
    // display: flex; 
    // flex-direction: row;
    // align-content: center;
    // align-items: center;
  }
}

// TODO: this is a bit wonked up
.proposalCard { 
  // background-color: unset;

  // border-radius: 7px;
  // z-index: 10000;
}

#proposalCardBody {
}

#topperProposalCardBody {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

#proposalCardFormat {
  display: inline;
  color: white;
}

#proposalCardIcon {
  margin-right: 10px;
  display: inline;
  color: #ffffff7d;
  // color: yellow;
}

#normalProposalFooter {
  padding-right: 0%;
  padding-bottom: 0%;
}

#defaultProposalFooter {
  padding-right: 5%;
  padding-bottom: 0%;
}

#defaultProposalCard {
  // background-color: #ff8d72; // TODO: image color
  // border: solid white 0.5px;
  background-color: transparent;
  border: none;
}

.promptAddressDisplay {
  z-index: 3;
}

#proposalCardFormatBadge {
  // color: white;
  background: transparent;
  padding-left: 5%;
  padding-top: 5%;
  
}

#defaultProposalCard {
  #proposalCardFormatBadge {
    // color: white;
    background: transparent;
    padding-left: 5%;
    padding-top: 0%;
    
  }

  #defaultProposalFooterBadge {
    padding-left: 5%;
  }

}


/* ---------------------------------------------------- CollapseProposalPrompt.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #enterProposalCard {
    margin-top: 0;
    background-color: transparent;
  }

  #collapseTitleProposal {
    font-size: 1.25em;
    padding-left: 10px !important;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #enterProposalCard {
    margin-top: 0;
    background-color: transparent;
  }

  #collapseTitleProposal {
    font-size: 1.65em;
    padding-left: 10px !important;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #enterProposalCard {
    margin-top: 10px;
    // color: #ffd600;
  }
  #collapseTitleProposal {
    font-size: 1.75em;
    padding-left: 15px !important;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #enterProposalCard {
    margin-top: 10px;
    // color: #ffd600;
  }
  #collapseTitleProposal {
    font-size: 1.95em;
    padding-left: 20px !important;
  }
}

@media (min-width: 1367px) {
  #enterProposalCard {
    margin-top: 10px;
    // color: #ffd600;
  }
  #collapseTitleProposal {
    font-size: 1.25em;
    padding-left: 20px !important;
  }
}

#collapseTitleProposal {
  margin: 0;
  padding: 0;
  font-weight: 700;
  // font-size: 1em;
  color: #5e72e4;
  opacity: .35;
}

#proposalFormSelectBox {
  margin-bottom: 10px;
  display: inline-flex;
  text-transform: uppercase;
  overflow: visible;
  // font-size: 1.5em;
  font-size: 1em;
  font-weight: 800;
}

#pleaseBeKind {
  display: inline;
  font-weight: 700;
}

#promptInputForm {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#enterProposalCard {
  margin-top: 0;
  // background-color: #5e72e4;
  box-shadow: inset 6px 6px 12px #0f1128, inset -6px -6px 12px #2f337a;
  padding: 3%;
  background-color: transparent;
}

#enterProposalCardFooter {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#promptInputField {

}

/* ---------------------------------------------------- ProposalForm.jsx ---------------------------------------------------- */

#submitProposalButton {
  color: white;
  border-color: white;
  background: transparent;
}

/* ---------------------------------------------------- ProposalVote.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #nonDefaultUpvoteCount {
    width: 50%;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #nonDefaultUpvoteCount {
    width: 45%;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #nonDefaultUpvoteCount {
    width: 45%;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #nonDefaultUpvoteCount {
    width: 30%;
  }
}

@media (min-width: 1367px) {
    #nonDefaultUpvoteCount {
    width: 30%;
  }
}

#defaultUpvoteCount {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0px;

  h3 {
    font-size: 1em;
    margin-bottom: 0px;
  }
}

#defaultUpvoteArrow {
  font-size: 1em;
  text-transform: uppercase;
  margin-left: 0.25em;
  font-weight: 700;
  // TODO: opacity to indicate cannot vote?
  // opacity: .75;
}

#nonDefaultUpvoteCount {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-end;
  margin-bottom: 0px;
  font-size: 1em;
  text-transform: uppercase;
  padding-right: 5%;

  h3 {
    font-size: 1em;
    margin-bottom: 0px;
  }
}

#nonDefaultUpvoteArrow {
  z-index: 3;
  font-size: 1.25em;
  padding: 0;
  // margin-right: .5em;
  padding-right: .15em;
  padding-left: .15em;
  font-weight: 700;
}

#proposalVoteNumber {
  font-size: 1.5em;
  padding-right: .25em;
  padding-left: .25em;
}

#nonDefaultDownvoteArrow {
  z-index: 3;
  font-size: 1.25em;
  padding: 0;
  // margin-left: .5em;
  padding-right: .15em;
  padding-left: .15em;
  font-weight: 700;
}

#proposalVoteLoadingIcon {
  margin-left: .5em;
  font-size: 1.5em;
  // font-weight: 700;
}

/* ---------------------------------------------------- UpNextMatch.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
}

@media (min-width: 320px) and (max-width: 465px) {
}

@media (min-width: 466px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 1366px) {
}

@media (min-width: 1367px) {
}
