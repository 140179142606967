/* ---------------------------------------------------- Rightside.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
    #rightSideContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transform: scale(.85);
        // opacity: .5;
        // margin-top: -45px;
    }

    .rightSideCard {
      width: 100%;
      border-radius: 8px;
      // background: #171941;
      // box-shadow:  -5px 5px 10px #10122e, 
      //              5px -5px 10px #1e2155;
      background: transparent;
      box-shadow: none;
      margin-top: 0px;
      // margin-bottom: 10%;
      // margin-bottom: 4%;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 2%;
      margin-left: 2%;
    }
}

@media (min-width: 320px) and (max-width: 465px) {
    #rightSideContainer {
        display: flex;
        // flex-direction: row;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // transform: scale(.85);
        // opacity: .5;
        // margin-top: -40px
    }

    .rightSideCard {
      // width: 100%;
      // border-radius: 8px;
      // background: transparent;
      // box-shadow: none;
      // margin-top: 0px;
      // // margin-bottom: 10%;
      // // margin-bottom: 4%;
      // padding-left: 0px;
      // padding-right: 0px;
      // margin-right: 2%;
      // margin-left: 2%;
      width: 100%;
      border-radius: 8px;
      background: transparent;
      box-shadow: none;
      margin-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 2%;
      padding-left: 1em;
      // padding-top: 25px;
      
    }
}


@media (min-width: 466px) and (max-width: 768px) { 
    #rightSideContainer {
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: space-between;
        // transform: scale(.85);
        // opacity: .5;
        margin-top: 0px
    }

    .rightSideCard {
      width: 100%;
      border-radius: 8px;
      background: transparent;
      box-shadow: none;
      // background: #171941;
      // box-shadow:  -5px 5px 10px #10122e, 
      //              5px -5px 10px #1e2155;
      margin-top: 0px;
      // margin-bottom: 10%;
      // margin-bottom: 4%;
      // padding-left: 0px;
      // padding-right: 0px;
      // margin-right: 2%;
      // margin-left: 2%;
      margin-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 2%;
      padding-left: 1em;
    }
}

@media (min-width: 769px) and (max-width: 1366px) {
    #rightSideContainer {
      // width: 25%;
      width: 30%;
      margin-right: 0px;
      padding-right: 0px;
        display: flex;
    // flex-direction: column; // TODO: change order of right sidebar
    flex-direction: column-reverse;
        // align-items: center;
        justify-content: space-between;
        align-content: flex-end;
        // transform: scale(.9);
    }

    .rightSideCard {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: #171941;
      box-shadow:  -5px 5px 10px #10122e, 
                   5px -5px 10px #1e2155;
      margin-top: 0px;
      // margin-bottom: 10%;
      // margin-bottom: 4%;
      padding-left: 0px;
      padding-right: 0px;
      // margin-bottom: 20px;
      // margin-right: 2%;
      // margin-left: 2%;
    }
}

@media (min-width: 1367px) {
  #rightSideContainer {
    // margin-right: 2%;
    width: 25%;
    display: flex;
    // flex-direction: column; // TODO: change order of right sidebar
    flex-direction: column-reverse;
    // align-items: center;
    justify-content: space-between;
    // transform: scale(.9);
    margin-right: 0px;
    padding-right: 0;
}

    .rightSideCard {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: #171941;
      box-shadow:  -5px 5px 10px #10122e, 
                   5px -5px 10px #1e2155;
      margin-top: 0px;
      // margin-bottom: 10%;
      // margin-bottom: 4%;
      padding-left: 0px;
      padding-right: 0px;
      // margin-bottom: 25px;
      // margin-right: 2%;
      // margin-left: 2%;
    }
}

// #rightSideContainer {
//     display: flex;
//     flex-direction: column;
//     // max-width: 200px;
// }

// .rightside .card {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     border-radius: 8px;
//     background: #171941;
//     box-shadow:  -5px 5px 10px #10122e, 
//                  5px -5px 10px #1e2155;
//     margin-top: 0px;
//     margin-bottom: 10%;
//     padding-left: 0px;
//     padding-right: 0px;
//   }

  .rightSideCard {
    position: initial;
  }

    /* ---------------------------------------------------- PlayersOnline.jsx ---------------------------------------------------- */


@media (min-width: 0px) and (max-width: 319px) {

  #playersOnline {
    width: 100%;

    h3 {
        line-height: 1.25;
    }

    .playersOnlineCardBody {
      padding: 0px 0px 0px 0px;
      // margin-left: 1em;
      margin-top: 0.5em;
    }
}


  h3#playersOnlineNumber {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: white;
    
    font-weight: 500;
    // margin-left: 25px;
    font-size: 2em;
    margin-bottom: 0;
  }

  #playersOnlineText {
      display: inline;
      margin-left: .5em;
      font-size: .8em;
      // opacity: .5;
  }

  .chartContainer {
    display: none;
  }

}

@media (min-width: 320px) and (max-width: 465px) {

  #playersOnline {
    width: 100%;

    h3 {
        line-height: 1.25;
    }

    .playersOnlineCardBody {
      padding: 0px 0px 0px 0px;
      // margin-left: 1em;
      margin-top: 0.65em;
    }
}


  h3#playersOnlineNumber {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: white;
    
    font-weight: 500;
    // margin-left: 25px;
    font-size: 2.5em;
    margin-bottom: 0;
  }

  #playersOnlineText {
      display: inline;
      margin-left: .5em;
      font-size: .8em;
      // opacity: .5;
  }

  .chartContainer {
    display: none !important;
  }
}


@media (min-width: 466px) and (max-width: 768px) { 

  #playersOnline {

    width: 100%;

    h3 {
        line-height: 1.25;
    }

    .playersOnlineCardBody {
      padding: 0px 0px 0px 0px;
      // margin-left: 1em;
      margin-top: 1em;
      // margin-top: -2.5em;
    }

    .chartContainer {
      display: none;
    }

}


  h3#playersOnlineNumber {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: white;
    
    font-weight: 500;
    // margin-left: 25px;
    font-size: 3em;
    margin-bottom: 0;
  }

  #playersOnlineText {
      display: inline;
      margin-left: .5em;
      font-size: .8em;
      // opacity: .5;
  }

  .chartContainer {
    display: none !important;
  }

}

@media (min-width: 769px) and (max-width: 1366px) {
  #playersOnline {
    order: 2;
    width: 100%;
    height: 100%;
    margin-top: 8px;
    // margin-bottom: 0px; // TODO: change order of right sidebar
    // margin-bottom: 25px; // TODO: change order of right sidebar
    display: none;
    

    h3 {
        line-height: 1.25;
    }

    .playersOnlineCardBody {
      // margin-left: 1em;
      // padding: 5px;
      // padding: 20px;
    }

}


  h3#playersOnlineNumber {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: white;
    
    font-weight: 500;
    // margin-left: 25px;
    font-size: 3em;
    margin-bottom: 0;
  }

  #playersOnlineText {
      display: inline;
      margin-left: .5em;
      font-size: .8em;
      // opacity: .5;
  }

}

@media (min-width: 1367px) {

  #playersOnline {
    order: 2;
    width: 100%;
    height: 100%;
    margin-top: 8px;
    // margin-bottom: 0px; // TODO: change order of right sidebar
    // margin-bottom: 25px; // TODO: change order of right sidebar

    h3 {
        line-height: 1.25;
    }

    .playersOnlineCardBody {
      margin-left: 1em;
      // padding: 5px;
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      justify-content: flex-start;
    }

}

.playersOnlineCardBody {

  }

  h3#playersOnlineNumber {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: white;
    
    font-weight: 500;
    // margin-left: 25px;
    font-size: 3.5em;
    margin-bottom: 0;
  }

  #playersOnlineText {
      display: inline;
      margin-left: .5em;
      font-size: .8em;
      // opacity: .5;
  }

  
}

h3#playersOnlineNumber {
  align-items: baseline;
}

h3#playersOnlineNumber {
  color: #ffffff70;
  // font-family: 'Share Tech Mono', monospace;
  // opacity: .3;
}

#playersOnlineText {
  color: #ffffff50;
  // font-family: 'VT323', monospace;
  // font-family: 'Share Tech Mono', monospace;
  // opacity: .15;
}

   
.numPlayersGlowsUpdateFirst {
  animation: numPlayersFirst 2s 1;
}

@keyframes numPlayersFirst {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  100% {
    background: transparent;
    color: #2dce89;
  }
}

// Chart specific styles
.chartContainer {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100px; // Adjust this based on your layout
  // scale: 0.7;
  scale: 0.8;
  margin-top: 15px;
  // opacity: 0.5;
}

.chartLine {
  display: grid;
  opacity: 0.75;
  filter: drop-shadow(0 0 10px #2dce89);
}


  /* ---------------------------------------------------- AdSpot.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

    #adSpot {
        display: none;
        margin-left: 1.25em;
    
      }
    
      #adspotMessage {
        display: flex;
        // display: none;
        // flex-direction: row;
        justify-content: center;
        color: white;
        font-weight: 600;
        font-size: 2em;
        margin-bottom: 0;
        // opacity: .01;
        text-transform: uppercase;
      }


}

@media (min-width: 320px) and (max-width: 465px) {

    #adSpot {
      display: none;
      // width: 50%
        // display: none;
        // margin-left: 1.5em;

        // #adSpotCardBody {
        //   display: flex;
        //   flex-direction: row;
        //   justify-items: center;
        //   align-content: center;
        //   align-items: center;
        // }

        // rightSideCard {
        //   width: 100%;
        //   border-radius: 8px;
        //   background: transparent;
        //   box-shadow:  none;
        //   margin-top: 0px;
        //   // margin-bottom: 10%;
        //   margin-bottom: 4%;
        //   padding-left: 0px;
        //   padding-right: 0px;
        //   margin-right: 2%;
        //   margin-left: 2%;
        // }
      }

      #adspotMessage {
        display: flex;
        // display: none;
        // flex-direction: row;
        justify-content: center;
        color: white;
        font-weight: 600;
        font-size: 2em;
        margin-bottom: 0;
        // opacity: .0;
        text-transform: uppercase;
      }


}


@media (min-width: 466px) and (max-width: 768px) { 

      #adSpot {
        display: none;
        // margin-left: 1.5em;
      }
    
      #adspotMessage {
        display: flex;
        // flex-direction: row;
        justify-content: center;
        color: #ffffff10;
        font-weight: 600;
        font-size: 2em;
        margin-bottom: 0px;
        // opacity: .1;
        text-transform: uppercase;
      }

      #adSpotCardBody {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
      }

}

@media (min-width: 769px) and (max-width: 1366px) {
    #adSpot {
order: 1;
      height: 100%;
      margin-bottom: 25px;

      // height: auto;
      // display: flex;
      // align-items: center;
      // justify-content: center;

    
      }
    
      #adspotMessage {
        display: flex;
        // flex-direction: row;
        justify-content: center;
        color: #ffffff10;
        font-weight: 600;
        font-size: 2em;
        margin-bottom: 0;
        // opacity: .1;
        text-transform: uppercase;
      }

      #adSpotCardBody {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
      }

      
}

@media (min-width: 1367px) {
    #adSpot {

      order: 1;
      height: 100%;
      margin-bottom: 25px;
    
      }
    
      #adspotMessage {
        display: flex;
        // flex-direction: row;
        justify-content: center;
        color: #ffffff10;
        font-weight: 600;
        font-size: 2em;
        margin-bottom: 0;
        // opacity: .1;
        text-transform: uppercase;
      }

      #adSpotCardBody {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
      }
    
}

// ... your existing styles ...

#adSpot {
  order: 1;
  height: 100%;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  .panelSelect {
    display: flex;
    justify-content: space-between;
  }

  #adSpotCardBody {
    display: flex;
    flex-direction: column;
    // flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  #adspotMessage {
    color: #ffffff10;
    font-weight: 600;
    font-size: 2em;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.inputArea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 10px;

  input {
    flex: 1;
    padding: 5px;
    border: none;
    border-radius: 4px;
  }

  button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5em;

    &:hover {
      color: #272b65;
    }
  }
}

.chatHeader {
  display: flex;
  justify-content: flex-end;  /* Setting to flex-start as a base */
  width: 100%;
}

.closeButton {
  align-self: flex-end;  /* This should push the button to the right */
  background: transparent;
  border: none;
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.6);
  padding: 0;
  margin: 0;

  &:hover {
    color: white;
  }
}

.chatArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  overflow-y: auto;
}

.sent, .received {
  max-width: 70%;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  color: white;
}

.sent {
  align-self: flex-end;
  background-color: #272b65;
}

.received {
  align-self: flex-start;
  background-color: #1f2251;
}

.glowingTimestamp {
  font-size: 0.7em;
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff2d95, 0 0 20px #ff2d95, 0 0 25px #ff2d95, 0 0 30px #ff2d95, 0 0 35px #ff2d95;
}

.flexRow, .flexColumn {
  display: flex;
  justify-content: space-between;
  label {
    font-weight: 600;
  }
  input[type="radio"] {
    margin: 0 5px;
  }
}

.flexColumn {
  flex-direction: column;
  label {
    margin-top: 15px;
  }
}

#chatSettingsText {
  font-size: 1.25em;
  text-transform: uppercase;
  font-weight: 600;
}

#chatSettingsLabel {
  font-size: 1.5em;
  font-family: 'Share Tech Mono', monospace;
  margin-bottom: 10px;
  opacity: 0.5;
}

.goToChat {
  margin-top: 15px;
  cursor: pointer;
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 600;
  align-self: flex-end;
  // font-family: 'Share Tech Mono', monospace
}

// ... existing styles
.glowingMessage {
  font-size: 0.9em;
  color: white;
  animation: glow 1.5s infinite alternate;
}

.glowingMessageGreen {
  font-size: 0.9em;
  color: green;
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff2d95, 0 0 20px #ff2d95, 0 0 25px #ff2d95, 0 0 30px #ff2d95, 0 0 35px #ff2d95;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #ff2d95, 0 0 18px #ff2d95, 0 0 24px #ff2d95, 0 0 34px #ff2d95, 0 0 40px #ff2d95, 0 0 45px #ff2d95, 0 0 50px #ff2d95, 0 0 55px #ff2d95;
  }
}

#chatSettingsIcon {
  display: inline;
  font-size: 2em;
  margin-left: 15px;
  opacity: 0.5;
  cursor: pointer;
  align-self: center;
}

#chatSettingsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.glowingMessageGreen {
  color: #32CD32; // Lime green text color
  font-size: 14px; // Small font size
  text-shadow: 0 0 5px #32CD32, 0 0 10px #32CD32, 0 0 15px #32CD32, 0 0 20px #32CD32; // Glowing effect
  animation: spin 1.5s linear infinite; // Spinning animation
}

.glowingMessageCenter {
  color: #32CD32;
  font-size: 14px;
  text-align: center; // Center-align the text
  text-shadow: 0 0 5px #32CD32, 0 0 10px #32CD32, 0 0 15px #32CD32, 0 0 20px #32CD32;
}

.spinningIcon {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//   #adspotMessage {
//     color: white;
//     font-weight: 600;
//     font-size: 2.9em;
//     margin-bottom: 0;
//     opacity: .1;
//     text-transform: uppercase;
//   }

  /* ---------------------------------------------------- NetworkStats.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
    #networkStats {
        display: none;
    }

}

@media (min-width: 320px) and (max-width: 465px) {
    #networkStats {
        display: none;
    }
}


@media (min-width: 466px) and (max-width: 768px) { 
    #networkStats {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #networkStats {
    height: 100%;
  }

  #chartOptionButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // transform: scale(.8);
  }
}

@media (min-width: 1367px) {
  #networkStats {
    height: 100%;
  }

  #chartOptionButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // transform: scale(.8);
  }
}

  // #networkStats {

  // }

  
//   .card-chart.card {
//     margin-top: 0px;
//     padding-right: 10px;
//     padding-left: 10px;
//     padding-bottom: 5px;
//     padding-top: 5px;
//   }
  
//   #dataChartRightSide {
//     display: flex;
//     justify-content: center;
//   }


 /* ---------------------------------------------------- Profile.jsx ---------------------------------------------------- */

 @media (min-width: 0px) and (max-width: 319px) {

  #profileSection {
    display: none;
  }
   

}

@media (min-width: 320px) and (max-width: 465px) {


  #profileSection {
    display: none;
  }

}


@media (min-width: 466px) and (max-width: 768px) { 
  #profileSection {
    display: none;
  }

}

@media (min-width: 769px) and (max-width: 1366px) {
  #profileSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    // margin-bottom: 25px; // TODO: change order of right sidebar
    margin-bottom: 0px; // TODO: change order of right sidebar
  }

  #yourAccountText {
    font-size: 1.5em;
    color: #ffffff00;
  }

}

@media (min-width: 1367px) {
  #profileSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    // margin-bottom: 25px; // TODO: change order of right sidebar
    margin-bottom: 0px; // TODO: change order of right sidebar
  }

  #yourAccountText {
    font-size: 2em;
    color: #ffffff00;
  }
}

// #profileSection {
//   display: flex;
//   flex-direction: column;
// }

#profileSectionCardBody {

}

#yourAccountText {
  // font-size: .75em;
  // color: #ffffff80;
  font-weight: 600;
  margin-bottom: 0px;
  text-transform: uppercase;
}

#yourAccountTextDetail {
  display: inline;
  font-size: .75em;
  color: #ffffff50;
  font-weight: 600;
}

.threeboxProfileHover {

.ShortAddress {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.015em;
  display: block;
  padding: 0 16px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
}

 /* ---------------------------------------------------- AdSpotWithImages.jsx ---------------------------------------------------- */

 @media (min-width: 0px) and (max-width: 319px) {
   

 }
 
 @media (min-width: 320px) and (max-width: 465px) {
 
 }
 
 
 @media (min-width: 466px) and (max-width: 768px) { 
 
 }
 
 @media (min-width: 769px) and (max-width: 1366px) {
 
 }
 
 @media (min-width: 1367px) {
 
 }

#adSpotWithImages {
  order: 0;
  height: 100%;
  // margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url('../../assets/img/ladyOutline.png');

  #adSpotWithImagesText {
    color: #ffffff10;
    font-weight: 600;
    font-size: 2em;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0px;
  }
}

#adSpotWithImagesCardBody {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.6);
  padding: 0;
  margin: 0;

  &:hover {
    color: white;
  }
}


.adImage {
  cursor: pointer;
  // width: 50%;
  // margin: 15px 0;
  padding: 10px;
}

.adLink {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-weight: bold;
}

.adSpotWithImagesText {
  cursor: pointer;
}

#adModal {
  background-color: white !important;
}

#adModalTitle {
  color: black;
  font-family: 'Roboto', sans-serif !important;
  font-size: 2em;
  font-weight: 600;
  margin: 0px;
}

// Modal images
.modalImage {
  width: 45%;
  margin: 10px;
}

// Close button
// .closeButton {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
//   color: white;
// }

 



 /* ---------------------------------------------------- Component.jsx ---------------------------------------------------- */

 @media (min-width: 0px) and (max-width: 319px) {
   

}

@media (min-width: 320px) and (max-width: 465px) {

}


@media (min-width: 466px) and (max-width: 768px) { 

}

@media (min-width: 769px) and (max-width: 1366px) {

}

@media (min-width: 1367px) {

}


