#root {
  padding-right: 2%;
  padding-left: 2%;
}

/* ---------------------------------------------------- OVERRIDES START ---------------------------------------------------- */

.btn:hover {
  background-color: unset !important;
  background-image: none !important;
}

.btn:active {
  background-color: unset !important;
  background-image: none !important;
}

.btn:active:hover {
  background-color: unset !important;
  background-image: none !important;
}

.footer:after {
  content: unset !important;
}

@media (min-width: 0px) and (max-width: 319px) {
  /* ----------------------------------------------- LoginModal.jsx ----------------------------------------------- */
  .modal.modal-login .modal-dialog {
    /* margin-top: 20%; */
    max-width: 92% !important;
    background: #1f2251 !important;
    /* margin: -0.5rem auto; */
  }

  .modal-content {
    padding: 0 0px !important;
    /* border: 0px solid white !important; */
    font-family: 'Roboto', sans-serif !important;
    flex-wrap: wrap;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    background-color: #ffffff00 !important;
  }

  .card-login img {
    width: unset;
  }

  /* ----------------------------------------------- MainAreaTabs.jsx ----------------------------------------------- */

  .nav-tabs {
    border: 0;
    padding: 0rem 0rem;
  }

  .nav-tabs-info {
    font-size: 0.93em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* // TODO REPLACE WITH FONTAWESOME */
  .nav-tabs > .nav-item > .nav-link i.tim-icons {
    font-size: 14px;
    position: relative;
    top: 0px;
    margin-right: 3px;
  }

  .nav-tabs > .nav-item > .nav-link {
    color: #ffffff;
    margin: 0;
    margin-right: 0px;
    background-color: transparent;
    padding: 6px 8px;
    line-height: 1.2;
  }

  #mainContentTabTitle {
    font-size: .85em;
  }


  /* ----------------------------------------------- MemeMatchTopper.jsx ----------------------------------------------- */

  #topperProposalCard .card .card-body {
    padding: 5px !important;
  }

  #topperProposalCard .category-social {
    font-size: 1em;
    margin: 0;
  }

  #topperExpandIcon {
    /* padding-right:.1em; */
    margin-left: 0.2em;
    /* opacity: 0.75; */
    display: inline;
  }

  /* ----------------------------------------------- PendingMemeCard.jsx ----------------------------------------------- */

  /* // TODO ALL OF THESE MIGHT BE USELESS */
  #pendingCardGlowUpdate {
    font-size: 1em;
    margin-bottom: 0px;
  }

  /* ---------------------------------------------------- ProposalCard.jsx ---------------------------------------------------- */

  .proposalCard .card {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #proposalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;

  }
  
  #proposalPromptText {
    margin-bottom: 0;
    font-weight: 600;
    font-size: .85em;
  }


  #topperProposalHeader {
    display: unset;
  }
} 

@media (min-width: 320px) and (max-width: 465px) {
  /* ----------------------------------------------- LoginModal.jsx ----------------------------------------------- */
  .modal.modal-login .modal-dialog {
    /* margin-top: 20%; */
    max-width: 90% !important;
    /* background: #1f2251 !important; */
    background: #1f225100 !important;
    /* margin-top: -100px; */
  }

  .modal-content {
    padding: 0 0px !important;
    /* border: 0px solid white !important; */
    font-family: 'Roboto', sans-serif !important;
    flex-wrap: wrap;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    background-color: #ffffff00 !important;
  }

  .card-login img {
    width: unset;
  }

  /* ----------------------------------------------- MainAreaTabs.jsx ----------------------------------------------- */

  .nav-tabs {
    border: 0;
    padding: 0rem 0rem;
  }

  .nav-tabs-info {
    font-size: 0.93em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* // TODO REPLACE WITH FONTAWESOME */
  .nav-tabs > .nav-item > .nav-link i.tim-icons {
    font-size: 14px;
    position: relative;
    top: 0px;
    margin-right: 4px;
  }

  .nav-tabs > .nav-item > .nav-link {
    color: #ffffff;
    margin: 0;
    margin-right: 0px;
    background-color: transparent;
    padding: 3px 4px 3px 4px;
    line-height: 1.2;
  }

  #mainContentTabTitle {
    font-size: 1.15em;
  }

  /* ----------------------------------------------- MemeMatchTopper.jsx ----------------------------------------------- */

  #topperProposalCard .card .card-body {
    padding: 5px !important;
  }

  #topperProposalCard .category-social {
    font-size: 1.25em;
    margin: 0;
  }

  /* TODO ALL #topperExpandIcon can be deleted? */
  #topperExpandIcon {
    /* padding-right:.1em; */
    margin-left: 0.2em;
    opacity: 0.75;
    display: inline;
  }

  /* ----------------------------------------------- PendingMemeCard.jsx ----------------------------------------------- */

  #pendingCardGlowUpdate {
    font-size: 1em;
    margin-bottom: 0px;
  }

/* ---------------------------------------------------- ProposalCard.jsx ---------------------------------------------------- */

  .proposalCard .card {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #proposalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;

  } 

  #proposalPromptText {
    margin-bottom: 0;
    font-weight: 600;
    font-size: .95em;
  }

  #topperProposalHeader {
    flex-direction: unset;
  }
  
}

@media (min-width: 466px) and (max-width: 768px) {
  /* ----------------------------------------------- LoginModal.jsx ----------------------------------------------- */
  .modal.modal-login .modal-dialog {
    margin-top: 5%;
    max-width: 85% !important;
    /* background: #1f2251 !important; */
    background: #1f225100 !important;
  }

  .modal-content {
    padding: 0 0px !important;
    /* border: 0px solid white !important; */
    font-family: 'Roboto', sans-serif !important;
    flex-wrap: wrap;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    background-color: #ffffff00 !important;
  }

  .card-login img {
    width: unset;
  }

  /* ----------------------------------------------- MainAreaTabs.jsx ----------------------------------------------- */

  .nav-tabs {
    border: 0;
    padding: 0rem 0rem;
  }

  /* // TODO REPLACE WITH FONTAWESOME */
  .nav-tabs > .nav-item > .nav-link i.tim-icons {
    font-size: 20px;
    position: relative;
    top: 0px;
    margin-right: 6px;
  }

  .nav-tabs-info {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-tabs > .nav-item > .nav-link {
    color: #ffffff;
    margin: 0;
    margin-right: 0px;
    background-color: transparent;
    padding: 6px 8px;
    line-height: 1.2;
  }

  #mainContentTabTitle {
    font-size: 1.5em;
  }

  /* ----------------------------------------------- MemeMatchTopper.jsx ----------------------------------------------- */

  #topperProposalCard .card .card-body {
    padding: 5px !important;
  }

  #topperProposalCard .category-social {
    font-size: 1.5em;
    margin: 0;
  }

  #topperExpandIcon {
    /* padding-right:.1em; */
    margin-left: 0.3em;
    opacity: 0.75;
    display: inline;
  }

  #topperProposalHeader {
    /* display: flex; */
    display: unset;

    /* #proposalPromptText {
      display: none;
    } */
  }

  /* ----------------------------------------------- PendingMemeCard.jsx ----------------------------------------------- */

  #pendingCardGlowUpdate {
    font-size: 1.5em;
    margin-bottom: 0px;
  }

  /* ---------------------------------------------------- ProposalCard.jsx ---------------------------------------------------- */

  .proposalCard .card {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #proposalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;

  }

  #proposalPromptText {
    font-weight: 600;
    font-size: 1.15em;
    text-align: end;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  /* ----------------------------------------------- LoginModal.jsx ----------------------------------------------- */
  .modal.modal-login .modal-dialog {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 60%;
    
    margin-top: 5%;
    max-width: 75% !important;
    /* background: #1f2251 !important; */
    background: #1f225100 !important;
  }

  .modal-content {
    padding: 0 0px !important;
    /* border: 0px solid white !important; */
    font-family: 'Roboto', sans-serif !important;
    flex-wrap: wrap;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    background-color: #ffffff00 !important;
  }

  .card-login img {
    width: unset;
  }

  /* ----------------------------------------------- MainAreaTabs.jsx ----------------------------------------------- */

  .nav-tabs {
    border: 0;
    padding: 0rem 0rem;
  }

  /* // TODO REPLACE WITH FONTAWESOME */
  .nav-tabs > .nav-item > .nav-link i.tim-icons {
    font-size: 22px;
    position: relative;
    top: 0px;
    margin-right: 8px;
  }

  .nav-tabs-info {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-tabs > .nav-item > .nav-link {
    color: #ffffff;
    margin: 0;
    margin-right: 0px;
    background-color: transparent;
    padding: 10px 12px;
    line-height: 1.2;
  }

  #mainContentTabTitle {
    font-size: 1.5em;
  }

  /* ----------------------------------------------- MemeMatchTopper.jsx ----------------------------------------------- */

  #topperProposalCard .card .card-body {
    padding: 7.5px !important;
  }

  #topperProposalCard .category-social {
    font-size: 2em;
    margin: 0;
  }

  #topperProposalHeader {
    /* display: flex; */
    flex-direction: unset;
  }

  /* ----------------------------------------------- PendingMemeCard.jsx ----------------------------------------------- */

  #pendingCardGlowUpdate {
    font-size: 1.75em;
    margin-bottom: 0px;
  }

  /* ---------------------------------------------------- ProposalCard.jsx ---------------------------------------------------- */

  .proposalCard .card {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #proposalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;

  }

  #proposalPromptText {
    font-weight: 600;
    font-size: 1.25em;
    text-align: end;
  }
}

@media (min-width: 1367px) {
  /* ----------------------------------------------- LoginModal.jsx ----------------------------------------------- */
  .modal.modal-login .modal-dialog {
    margin-top: 5%;
    max-width: 60% !important;
    /* background: #1f2251 !important; */
    background: #1f225100 !important;
  }

  .modal-content {
    padding: 0 0px !important;
    /* border: 0px solid white !important; */
    font-family: 'Roboto', sans-serif !important;
    flex-wrap: wrap;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    background-color: #ffffff00 !important;
  }

  .card-login img {
    width: unset;
  }

  /* ----------------------------------------------- MainAreaTabs.jsx ----------------------------------------------- */

  .nav-tabs {
    border: 0;
    padding: 0rem 0rem;
  }

  /* // TODO REPLACE WITH FONTAWESOME */
  .nav-tabs > .nav-item > .nav-link i.tim-icons {
    font-size: 40px;
    position: relative;
    top: 0px;
    margin-right: 12px;
  }

  .nav-tabs-info {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-tabs > .nav-item > .nav-link {
    padding: 12px 16px;
  }

  .nav-tabs > .nav-item > .nav-link {
    color: #ffffff;
    margin: 0;
    margin-right: 0px;
    background-color: transparent;
    padding: 12px 16px;
    line-height: 1.2;
  }

  #mainContentTabTitle {
    font-size: 1.5em;
  }

  /* ----------------------------------------------- MemeMatchTopper.jsx ----------------------------------------------- */

  #topperProposalCard .card .card-body {
    padding: 10px !important;
  }

  #topperProposalCard .category-social {
    font-size: 2.5em;
    margin: 0;
  }

  #topperProposalHeader {
    /* display: flex; */
    flex-direction: unset;
  }

  /* ----------------------------------------------- PendingMemeCard.jsx ----------------------------------------------- */

  #pendingCardGlowUpdate {
    font-size: 2em;
    /* margin-bottom: 0px; */
  }

  /* ---------------------------------------------------- ProposalCard.jsx ---------------------------------------------------- */

  .proposalCard .card {
    margin-top: 0px;
    display: inherit;
    border-radius: 0px;
    background: #1f2251;
    box-shadow: none;
  }

  #proposalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;

  }

  #proposalPromptText {
    font-weight: 600;
    font-size: 1.35em;
    text-align: end;
    padding-top: 10px;
    /* padding-top: 50px;
    padding-right: 50px */
  }
}

.modal-entry {
  height: -webkit-fill-available;
  position: fixed;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* top: -150px; */
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  outline: 0;
}

.modal-rules {
  position: fixed;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  /* top: 0px; */
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  /* height: 100%; */
  /* overflow: hidden; */
  outline: 0;
}
/* ----------------------------------------------------- OVERRIDES END ----------------------------------------------------- */

/* ----------------------------------------------------- BUTTONS START ----------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  button#matchContainerCancelButton {
    /* display: none; */
    color: #ffffff;
    font-weight: 600;
    font-size: 1em;
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: 0px;
    text-transform: uppercase;
    opacity: 0.15;
  }

  button#joinMWButton {
    color: #ffffff;
    font-weight: 600;
    font-size: 1em;
    padding: 2.5px;
    margin: 5px;
    text-transform: uppercase;
  }

  #plaintextjoinAsVoterButton {
    text-transform: uppercase;
    /* font-family: 'Share Tech Mono', monospace; */
    font-size: 1em;
    font-weight: 600;
    border-radius: inherit;
    border-style: solid;
    padding: 3px;
    z-index: 5;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  button#matchContainerCancelButton {
    /* display: none; */
    color: #ffffff;
    font-weight: 600;
    /* font-size: 1em; */
    font-size: 1.15em;
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: 0px;
    text-transform: uppercase;
    opacity: 0.15;
  }

  button#joinMWButton {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.25em;
    padding: 3px;
    margin: 5px;
    text-transform: uppercase;
  }

  #plaintextjoinAsVoterButton {
    text-transform: uppercase;
    /* font-family: 'Share Tech Mono', monospace; */
    font-size: 1em;
    font-weight: 600;
    border-radius: inherit;
    border-style: solid;
    border-width: 2px;
    padding: 7px;
    z-index: 5;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  button#matchContainerCancelButton {
    /* display: none; */
    color: #ffffff;
    font-weight: 600;
    /* font-size: 1em; */
    font-size: 1.15em;
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: 0px;
    text-transform: uppercase;
    opacity: 0.15;
  }

  button#joinMWButton {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.25em;
    padding: 4px;
    margin-left: 10px;
    text-transform: uppercase;
  }

  #plaintextjoinAsVoterButton {
    text-transform: uppercase;
    /* font-family: 'Share Tech Mono', monospace; */
    font-size: 1.5em;
    font-weight: 600;
    border-radius: inherit;
    border-style: solid;
    border-width: 2px;
    padding: 7px;
    z-index: 5;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  button#matchContainerCancelButton {
    /* display: none; */
    color: #ffffff;
    font-weight: 600;
    /* font-size: 1em; */
    font-size: 1.15em;
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: 0px;
    text-transform: uppercase;
    opacity: 0.15;
  }

  button#joinMWButton {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.25em;
    padding: 5px;
    margin-left: 15px;
    text-transform: uppercase;
  }

  #plaintextjoinAsVoterButton {
    text-transform: uppercase;
    /* font-family: 'Share Tech Mono', monospace; */
    font-size: 1.5em;
    font-weight: 600;
    border-radius: inherit;
    border-style: solid;
    border-width: 2px;
    padding: 7px;
    /* z-index: 5; */
  }
}

@media (min-width: 1367px) {
  button#matchContainerCancelButton {
    /* display: none; */
    color: #ffffff;
    font-weight: 600;
    /* font-size: .8em; */
    font-size: 1.25em;
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: 0px;
    text-transform: uppercase;
    opacity: 0.15;
  }

  button#joinMWButton {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.5em;
    padding: 7.5px;
    margin-left: 20px;
    text-transform: uppercase;
  }

  #plaintextjoinAsVoterButton {
    text-transform: uppercase;
    /* font-family: 'Share Tech Mono', monospace; */
    font-size: 1.5em;
    font-weight: 600;
    border-radius: inherit;
    border-style: solid;
    border-width: 2px;
    padding: 15px;
    z-index: 5;
  }
}

button#startMatchButton.btn {
  z-index: 5;
  font-weight: 600;
  font-size: 0.85em;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  background-color: #ff000000;
  /* padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px; */
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  /* opacity: 0.75; */
  text-transform: uppercase;
}

.btn-startMatch {
  background: #ff8d72;
  background-image: none;
  background-size: 210% 210%;
  background-position: top right;
  background-color: transparent;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
}
.btn-startMatch.animation-on-hover:hover {
  background-image: none;
}
.btn-startMatch:hover,
.btn-startMatch:focus,
.btn-startMatch:active,
.btn-startMatch.active,
.btn-startMatch:active:focus,
.btn-startMatch:active:hover,
.btn-startMatch.active:focus,
.btn-startMatch.active:hover {
  background-color: #5e72e4 !important;
  color: black;
  background-image: unset !important;
  box-shadow: none;
}



/* button#matchContainerCancelButton {
    color: #ffffff;
    font-weight: 600;
    font-size: 1em;
    border-radius: 0px;
    border-style: solid;
    border-width: 1px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: 10px;
    text-transform: uppercase;
  } */


  #plaintextjoinAsVoterButton {
        /* font-family: 'Share Tech Mono', monospace; */
        opacity: .35;
        font-weight: 700;
        border-width: 0.5px;
        /* z-index: 10; */
  }

/* ------------------------------------------------------ BUTTONS END ------------------------------------------------------ */

/* ----------------------------------------------------- GRADIENT / BORDERS START ----------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  .block-gradient {
    --border-width: 2px;
    /* padding: 10px; */
    /* margin: 10px; */
    position: relative;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      90deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    /* background-position: center center; */
    background-position: 15% 20%;
    background-repeat: no-repeat;
    /* border-radius: calc(2 * var(--border-width)); */
    /* animation: moveGradientRainbow 12s alternate infinite; */
    animation: moveGradientRainbow 12s alternate infinite;
    border-style: solid;
    border-color: transparent;
    border-radius: .5em;
    border-width: 3px;
    /* margin: 100px; */
  }

  .gradient-border {
    --border-width: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* color: white; */
    /* background: #222;
    background-color: #222; */
    /* border-radius: var(--border-width); */
    /* z-index: 1; */
  }

  .gradient-border ::after {
    /* border-width: 0px; */
    position: absolute;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%));
    background-size: 500% 500%;
    background-position: 0 50%;
    background-repeat: none;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 12s infinite;
    opacity: 0.1;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  .block-gradient {
    --border-width: 2px;
    /* padding: 10px; */
    /* margin: 10px; */
    position: relative;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      90deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    /* background-position: center center; */
    background-position: 15% 20%;
    background-repeat: no-repeat;
    /* border-radius: calc(2 * var(--border-width)); */
    animation: moveGradientRainbow 12s alternate infinite;
    border-style: solid;
    border-color: transparent;
    /* border-radius: 7px;
    border-width: 2px; */
    border-radius: .5em;
    border-width: 3px;
    /* margin: 100px; */
  }

  .gradient-border {
    --border-width: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* color: white; */
    /* background: #222;
    background-color: #222; */
    /* border-radius: var(--border-width); */
    /* z-index: 1; */
  }

  .gradient-border ::after {
    border-width: 0px;
    position: absolute;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%));
    background-size: 500% 500%;
    background-position: 0 50%;
    background-repeat: none;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 12s infinite;
    opacity: 0.1;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  .block-gradient {
    --border-width: 4px;
    /* padding: 10px; */
    /* margin: 10px; */
    position: relative;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      90deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    /* background-position: center center; */
    background-position: 15% 20%;
    background-repeat: no-repeat;
    /* border-radius: calc(2 * var(--border-width)); */
    animation: moveGradientRainbow 12s alternate infinite;
    border-style: solid;
    border-color: transparent;
    border-radius: 0.5em;
    border-width: 4px;
    /* margin: 100px; */
  }

  .gradient-border {
    --border-width: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* color: white; */
    /* background: #222;
    background-color: #222; */
    /* border-radius: var(--border-width); */
    /* z-index: 1; */
  }

  .gradient-border ::after {
    border-width: 0px;
    position: absolute;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%));
    background-size: 500% 500%;
    background-position: 0 50%;
    background-repeat: none;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 12s infinite;
    opacity: 0.1;
  }
  
}

@media (min-width: 769px) and (max-width: 1366px) {
  .block-gradient {
    --border-width: 4px;
    /* padding: 10px; */
    /* margin: 10px; */
    position: relative;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      90deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    /* background-position: center center; */
    background-position: 15% 20%;
    background-repeat: no-repeat;
    /* border-radius: calc(2 * var(--border-width)); */
    animation: moveGradientRainbow 12s alternate infinite;
    border-style: solid;
    border-color: transparent;
    border-radius: 0.5em;
    border-width: 4px;
    /* margin: 100px; */
  }

  .gradient-border {
    --border-width: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* color: white; */
    /* background: #222;
    background-color: #222; */
    /* border-radius: var(--border-width); */
    /* z-index: 1; */
  }

  .gradient-border ::after {
    border-width: 0px;
    position: absolute;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%));
    background-size: 500% 500%;
    background-position: 0 50%;
    background-repeat: none;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 12s infinite;
    opacity: 0.1;
  }
}

@media (min-width: 1367px) {
  .block-gradient {
    --border-width: 4px;
    /* padding: 10px; */
    /* margin: 10px; */
    position: relative;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      90deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    /* background-position: center center; */
    background-position: 15% 20%;
    background-repeat: no-repeat;
    /* border-radius: calc(2 * var(--border-width)); */
    animation: moveGradientRainbow 12s alternate infinite;
    border-style: solid;
    border-color: transparent;
    border-radius: 0.5em;
    border-width: 4px;
    /* margin: 100px; */
  }

  .gradient-border {
    --border-width: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* color: white; */
    /* background: #222;
    background-color: #222; */
    /* border-radius: var(--border-width); */
    /* z-index: 1; */
  }

  .gradient-border ::after {
    border-width: 0px;
    position: absolute;
    content: '';
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%));
    background-size: 500% 500%;
    background-position: 0 50%;
    background-repeat: none;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 12s infinite;
    opacity: 0.1;
  }
}

.block-gradient-slow {
  /* --border-width: 4px; */
  /* padding: 10px; */
  /* margin: 10px; */
  position: relative;
  content: '';
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    90deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  );
  /* background: linear-gradient(90deg, hsl(200, 85%, 66%), hsl(210, 85%, 66%), hsl(220, 85%, 66%), hsl(230, 85%, 66%), hsl(240, 85%, 66%), hsl(250, 85%, 66%), hsl(260, 85%, 66%), hsl(270, 85%, 66%)); */
  background-size: 300% 300%;
  /* background-position: center center; */
  background-position: 15% 20%;
  background-repeat: no-repeat;
  /* border-radius: calc(2 * var(--border-width)); */
  animation: moveGradientRainbow 12s alternate infinite;
  border-style: solid;
  border-color: transparent;
  /* border-radius: .3em; */
  border-radius: 3px;
  border-width: 3px;
  /* margin: 100px; */
}

.gradient-border {
  --border-width: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* color: white; */
  /* background: #222;
  background-color: #222; */
  /* border-radius: var(--border-width); */
  /* z-index: 1; */
}

.gradient-border ::after {
  border-width: 0px;
  position: absolute;
  content: '';
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%));
  background-size: 500% 500%;
  background-position: 0 50%;
  background-repeat: none;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 12s infinite;
  /* opacity: 0.1; */
  opacity: 0;
}

.animLine {
  background-size: cover;
  float: none;
  position: relative;
  transition: 0.1s;
}

.animLine:before,
.animLine:hover:after {
  position: absolute;
  content: '';
  background: linear-gradient(90deg, #fff 40%, transparent 40%);
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  z-index: 2;
  border-radius: 25px;
  background-size: 200% 4px;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0 8px #fff);
  background-position: 80% 0;
}

.animLine:before {
  animation: beforeLineAnim 2s linear infinite;
}

.animLine:after {
  transform: rotate(-90deg);
  background-position: -20% 0;
}

@keyframes beforeLineAnim {
  45%,
  50% {
    background-position: -100% 0;
  }
  45% {
    transform: scale(1);
  }
  50%,
  95% {
    transform: scale(1, -1);
  }
  95% {
    background-position: 80% 0;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@keyframes moveGradientRainbow {
  50% {
    background-position: 85% 80%;
  }
}

/* ----------------------------------------------------- GRADIENT / BORDERS END ----------------------------------------------------- */

/* ---------------------------------------------------------- MODALS START ---------------------------------------------------------- */

.modal {
  background: rgba(0, 0, 0, 0.9);
}

.modal-content {
  box-shadow: none !important;
  border: none !important;
}

/* .modal-content .transparentModal {
  box-shadow: none !important;
} */

/* .transparentModal {
  box-shadow: none !important;
} */

/* ----------------------------------------------------------- MODALS END ----------------------------------------------------------- */


/* ----------------------------------------------------- ProposalCard.jsx START ----------------------------------------------------- */
/* #topperProposalHeader {
  display: inline-flex;
  flex-direction: unset;
} */
/* ----------------------------------------------------- ProposalCard.jsx END ----------------------------------------------------- */

/* ----------------------------------------------------- TO SORT START ----------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  /* ACTIVE MEME CARD */
  .card-background .full-background {
    opacity: .5;
  }
  .winner {
    /* box-shadow: 0px 0px 40px 5px #1d8cf8 !important; */
    /* box-shadow: 0px 0px 40px 5px #ffffff60 !important; */
    box-shadow: 0px 0px 40px 5px #1d8cf880 !important;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  /* ACTIVE MEME CARD */
  .card-background .full-background {
    opacity: .65;
  }
  .winner {
    /* box-shadow: 0px 0px 40px 5px #1d8cf8 !important; */
    /* box-shadow: 0px 0px 40px 5px #ffffff60 !important; */
    box-shadow: 0px 0px 40px 5px #1d8cf880 !important;
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  /* ACTIVE MEME CARD */
  .card-background .full-background {
    opacity: .5;
  }
  .winner {
    /* box-shadow: 0px 0px 45px 10px #1d8cf8 !important; */
    /* box-shadow: 0px 0px 45px 10px #ffffff50 !important; */
    box-shadow: 0px 0px 45px 10px #1d8cf880 !important;  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  /* ACTIVE MEME CARD */
  .card-background .full-background {
    opacity: .5;
  }
  .winner {
    /* box-shadow: 0px 0px 65px 15px #1d8cf8 !important; */
    /* box-shadow: 0px 0px 60px 10px #ffffff50 !important; */
    box-shadow: 0px 0px 60px 10px #1d8cf880 !important;
  }
}

@media (min-width: 1367px) {
  /* ACTIVE MEME CARD */
  .card-background .full-background {
    opacity: .5;
  }
  .winner {
    /* box-shadow: 0px 0px 85px 20px #1d8cf8 !important; */
    box-shadow: 0px 0px 60px 10px #1d8cf880 !important;
    /* box-shadow: 0px 0px 80px 15px #ffffff50 !important; */
  }
}

#topperProposalCard .card:hover .card-footer {
  /* padding-left: 5%;
  padding-right: 5%; */
  padding-left: 2%;
  padding-right: 2%;
  display: none;
  margin-top: 0px;
  /* padding: 0px; */
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  /* align-content: center; */
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 1.5em;
  padding-top: 0px;
}

#accordion.card-collapse {
  /* box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.671); */
  box-shadow: none;
  overflow: hidden;
  /* TODO - ADD PROPOSAL BUTTON */ 
  width: 100%;
}

.css-kj6f9i-menu {
  /* // TODO: Select box for add proposal button */
  /* overflow: visible; */
  /* overflow-x: hidden;
  overflow-y: visible; */
}

.card-background .full-background {
  background-position: center center;
  background-size: cover;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  position: relative;
  /* position: absolute; */
  border-radius: 7px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all 1s cubic-bezier(0.2, 1, 0.22, 1);
  -webkit-transition: all 1s cubic-bezier(0.2, 1, 0.22, 1);
  /* opacity: .65;  */
  /* TODO */
  /* padding-bottom: 30px; */
  padding-bottom: 0px;
  /* transform: scale(.85); */
}

/* .d-flex {
  display: inline !important;
} */

.rangeslider-horizontal {
  max-width: 200px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: transparent;
  border-radius: 10px;
  top: 0;
  background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  );
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  /* border-radius: 30px; */
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: aqua;
  background-color: blue;
  background-image: linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
}

.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
  z-index: 5;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider .rangeslider__handle {
  height: 1.5em !important;
  width: 1.5em !important;
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
  border-style: solid;
  border-width: 20px;
  border-color: black;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  /* background-color: #7df300;
  box-shadow: 0 10px 3px rgb(0, 0, 0) inset, 0 -10px 3px rgba(0, 0, 0) inset; */
  z-index: 10;
  background: black;
  background-color: black;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 0px;
  height: 0px;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  /* z-index: 10; */
  background: black;
  background-color: black;
}

.card-background .card-body {
  min-height: unset;
}

.videoBox {
  position: inherit;
}

.ReactTypeformEmbed {
  position: inherit !important;
  top: 0px !important;
  left: 0px !important;
  /* width: 50% !important;
  height: 50% !important; */
  width: 100% !important;
    height: 100% !important;
  overflow: hidden !important;
  /* overflow-x:hidden;
  overflow-y:hidden; */
}

/* BETA SURVEY (TYPEFORM) */

@media (min-width: 0px) and (max-width: 319px) {
  .css-1ig7ubs {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000000d9;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  .css-1ig7ubs {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000000d9;
  }
}


@media (min-width: 466px) and (max-width: 768px) { 
}

@media (min-width: 769px) and (max-width: 1366px) {
}

@media (min-width: 1367px) {
}

.ReactTypeformEmbed {
  position: inherit !important;
  top: 0px !important;
  left: 0px !important;
  width: 0% !important;
  height: 0% !important;
  overflow: hidden !important;
}

/* // FOR DEFAULT "IMAGE" PROPOSAL CARD */

.card[data-background-color=orange] {
  background-color: #ff8d72;
}




/* // for winning memecard */
/* .winner {
  box-shadow: 0px 0px 45px 10px #1d8cf8 !important;
} */


/* ----------------------------------------------------- TO SORT END ----------------------------------------------------- */


/* ----------------------------------------------------- TO SIZE START ----------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
}

@media (min-width: 320px) and (max-width: 465px) {
}

@media (min-width: 466px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 1366px) {
}

@media (min-width: 1367px) {
}

button#loadingScreenInitButton {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.5em;
  /* border-radius: 0px; */
  border-style: solid;
  border-width: 2px;
  border-color: #fb634000;
  /* background: #cecece; */
  /* background-color: #cecece; */
  /* background-image: none; */
  /* margin-left: 5px; */
  /* margin-top: 10px; */
  margin-bottom: 30px;
  /* opacity: .75; */
  text-transform: uppercase;
  border-radius: 7px;
  /* background: #171941; */
  /* background: #5e72e4b3; */
  /* background: #97da1385; */
  background: #5e72e461;
  box-shadow: 5px 5px 10px #0c0d21, -5px -5px 10px #222561;
  margin: 0;
}


/* ----------------------------------------------------- TO SIZE END ----------------------------------------------------- */

.index-page {
  background-image: none;
}

.card .p {
  color: white;
}

/* CONTAINERS */
.container {
  max-width: 100%;
}

.nav-tabs > .nav-item > .nav-link.active {
  border-radius: 0px;
}

/* TORUS BUTTON & MODAL */

#torusAlert p {
  color: black;
}

#torusAlert h1 {
  color: black;
}

#torus-login-modal__login-subtitle {
  color: black;
}

.aws-btn {
  --button-default-height: 81px;
  --button-default-font-size: 23px;
  --button-default-border-radius: 7px;
  --button-horizontal-padding: 46px;
  --button-raise-level: 4px;
  --button-hover-pressure: 2.5;
  --transform-speed: 0.3s;
  --button-primary-color: #5e72e4;
  --button-primary-color-dark: #d0b400;
  --button-primary-color-light: #ffe11d;
  --button-primary-color-hover: #00a0ab;
  --button-primary-border: 0px solid #ffe11d;
  --button-secondary-color: #ee3253;
  --button-secondary-color-dark: #d0b400;
  --button-secondary-color-light: #ffe11d;
  --button-secondary-color-hover: #ec1a3f;
  --button-secondary-border: 1px solid #ffe11d;
  --button-anchor-color: #293b83;
  --button-anchor-color-dark: #772250;
  --button-anchor-color-light: #b33377;
  --button-anchor-color-hover: #293b83;
  --button-anchor-border: 1px solid #9f2d6a;
}

#login-modal .login-modal {
  font-family: 'Poppins', sans-serif !important;
}

#torusAlert {
  z-index: 999999;
  position: fixed;
  overflow: hidden;
  top: 34px;
  right: 34px;
  width: 260px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: #ffffff;
  /* font-family: 'Roboto', sans-serif !important; */
  padding: 20px;
}

#torusAlert .torus-alert__title {
  margin: 0;
  font-size: 22px;
  font-weight: 200;
}

#torusAlert .torus-alert__desc {
  margin: 16px 0 0;
  font-size: 14px;
}

#torusAlert .torus-alert-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

#torusAlert .torus-alert-btn {
  font-size: 14px;
  background-color: #0364ff;
  margin: 24px 0 0;
  width: 100px;
  height: 40px;
  border: 0;
  cursor: pointer;
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

.widget {
  z-index: 999999;
  position: fixed;
}

/* FAB Speed dial */
.speed-dial-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.speed-dial-list li {
  margin-bottom: 16px;
  opacity: 0;
}

.speed-dial-list.active li {
  opacity: 1;
}

.torus-btn {
  border-radius: 10px;
  background-color: #0364ff;
  padding: 14px;
  width: 60px;
  height: 60px;
  border: 0;
  cursor: pointer;
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

/* .torus-btn--loading {
  display: none;
  padding: 0;
} */

.torus-btn--loading.active {
  display: block;
}

.torus-btn--login {
  /* background-image: url('/images/login.png'); */
  transition: width 0.3s;
}

.torus-btn--login:hover {
  width: 150px;
  /* background-image: url('/images/login-with-torus.png'); */
  background-size: 85%;
  transition: width 0.3s;
}

.torus-btn img {
  width: 100%;
  height: auto;
}

.torus-btn--main {
  display: none;
  padding: 10px 8px 10px 12px;
  transition: transform 0.3s;
  /* background-image: url('/img/icons/torus-icon-light.svg'); */
  background-size: 55%;
  background-position: 55% 52%;
}

.torus-btn--main.active {
  padding: 12px;
  background-color: #eef2f4;
  transform: scale(0.7);
  transition: transform 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  /* background-image: url('/img/icons/torus-icon-dark.svg'); */
}

.torus-btn--text {
  padding: 10px;
  /* font-family: 'Roboto', sans-serif !important; */
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.7px;
  line-height: 19px;
}

.torus-btn--home {
  /* background-image: url('/img/icons/home.svg'); */
}

.torus-btn--transfer {
  /* background-image: url('/img/icons/indent-increase.svg'); */
}

.torus-btn:focus {
  outline: none;
}

.tooltip {
  position: relative;
}

.tooltip-text {
  display: none;
  position: absolute;
  opacity: 0.8;
  background-color: #2f3136;
  color: #ffffff;
  /* font-family: 'Roboto', sans-serif !important; */
  border-radius: 5px;
  padding: 3px 5px;
  text-align: center;
  font-size: 12px;
  left: 0;
  top: 0;
  transform: translate(70px, 10px);
}

.tooltip-copied {
  transform: translate(70px, 20px);
}

.tooltip-copied.tooltip-text.active {
  display: block;
}

.tooltip-note.tooltip-text {
  width: 150px;
}

.tooltip-note.tooltip-text.active {
  display: none;
}

.tooltip:hover .tooltip-note:not(.active) {
  display: block;
}

.spinner .beat {
  background-color: #ffffff;
  height: 10px;
  width: 10px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation: beatStretchDelay 0.7s infinite linear;
  animation: beatStretchDelay 0.7s infinite linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}
.spinner .beat-odd {
  animation-delay: 0s;
}
.spinner .beat-even {
  animation-delay: 0.35s;
}
@-webkit-keyframes beatStretchDelay {
  50% {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-opacity: 0.2;
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-opacity: 1;
    opacity: 1;
  }
}
@keyframes beatStretchDelay {
  50% {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-opacity: 0.2;
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-opacity: 1;
    opacity: 1;
  }
}

#closeOverlayBtn {
  cursor: pointer;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  /* background-image: url('/images/red-x.png'); */
  background-size: 60%;
  background-repeat: no-repeat;
  border-width: 0px;
  background-position: center;
  background-color: #75b4fd;
  transition: background-color, background-image, width 0.1s ease-in-out;
  user-select: none;
  outline: none;
}

#torusIframeContainer {
  display: none;
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* this below 2 css blocks makes it so that the Torus T button / widget 
only shows up when the login / web3 settings modal is open */
#torusIframe {
  /* height: 100%;
  width: 100%;  */
  z-index: 1051;
  display: none !important;
}

.modal-open #torusIframe {
  display: unset !important;
}

/* .torus-widget {
  position: absolute;
  --positionWidth: 14px;
  display: none !important;
}

.v-application {
  display: none;
  position: relative;
} */

/*/----------------------------------------------------------**** FREE/PAID TOGGLE ****-----------------------------------------------------------/*/

/* FREE PAID TOGGLE */
/* ========================================================================
 * bootstrap-switch - v3.3.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 * http://www.apache.org/licenses/LICENSE-2.0
 */
.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 0px;
  border: 0;
  position: relative;
  text-align: left;
  font-weight: 1000;
  overflow: hidden;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.13);
  margin-bottom: 0px;
  line-height: 8px;
  width: 59px !important;
  height: 22px;
  outline: none;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  /* background: url("https://d2v9y0dukr6mq2.cloudfront.net/video/thumbnail/J9H9WF0/night-sky-full-of-stars-fantasy-animation-made-of-magical-sparkly-light-particles-flickering-on-a-purple-blue-gradient-background_bajacpz7x_thumbnail-full01.png"); */
  background: #228b22;
  background-position: center center;
  background-size: cover;
}

.bootstrap-switch .bootstrap-switch-container {
  display: inline-flex;
  top: 0;
  height: 22px;
  border-radius: 4px;
  border-radius: 0px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  color: #fff;
  padding: 6px 10px;
  font-size: 11px;
  text-indent: -5px;
  line-height: 15px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
  float: left;
  line-height: 11px;
  width: 50% !important;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-brown,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-brown {
  color: #fff;
  background: #e14eca;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-blue,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-blue {
  color: #fff;
  background: #1d8cf8;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-green,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-green {
  color: #fff;
  background: #00f2c3;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-orange,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-orange {
  background: #ff8d72;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-red,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-red {
  color: #fff;
  background: #fd5d93;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background: #ffffff;
  width: 18px !important;
  height: 18px !important;
  /* width: 100px !important;
  height: 50px !important; */
  margin: 0px -11px;
  border-radius: 0px;
  position: absolute;
  float: left;
  top: 2px;
  left: 51%;
  padding: 0;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  background-color: rgba(156, 156, 156, 0.9);
  left: 52%;
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
  width: 27px !important;
  margin-left: -20px;
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
  width: 27px !important;
  margin-left: -11px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 6px;
}

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  margin-left: -39px !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label:before {
  background-color: #ffffff;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-red ~ .bootstrap-switch-default {
  background-color: #fd5d93;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-orange ~ .bootstrap-switch-default {
  background-color: #ff8d72;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-green ~ .bootstrap-switch-default {
  background-color: #00f2c3;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-brown ~ .bootstrap-switch-default {
  background-color: #e14eca;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-blue ~ .bootstrap-switch-default {
  background-color: #1d8cf8;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-red,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-brown,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-blue,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-orange,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-green {
  background-color: #e3e3e3;
}

.bootstrap-switch-on .bootstrap-switch-handle-off,
.bootstrap-switch-off .bootstrap-switch-handle-on {
  opacity: 0;
  visibility: hidden;
}

.bootstrap-switch-off:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background: rgba(16, 16, 16, 0.7);
}

/* Torus multiple login */

/* Background */
.login-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: black;
  background-color: rgb(33, 33, 33, 0.46);
  border-color: rgb(33, 33, 33, 0.46);
}

/* Modal Container */
.modal-container {
  background-color: #ffffff;
  width: 500px;
  margin: 5% auto;
  padding: 30px;
  border: 1px solid #979797;
  border-radius: 6px;
}

/* Contents */
.close-container {
  text-align: right;
}

.close {
  color: #0f1222;
  font-size: 18px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



/* #login-modal .modal-content {
  padding: 0 0px; 
  border: 0px solid rgba(255, 255, 255, 0) !important; 
  font-family: 'Poppins', sans-serif !important;
} */

.logo-container img {
  height: 30px;
  width: auto;
}

.login-header {
  font-size: 32px;
  font-weight: 500;
  margin: 24px 0 5px;
  color: black;
}

.login-subtitle {
  font-size: 14px;
  margin: 0 0 15px;
  color: black;
}

.login-google {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 15px 10px;
  margin: 0 0 16px;
  text-align: left;
  font-size: 14px;
  height: 50px;
  width: 100%;
  border: 0;
  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.login-google img {
  margin-right: 20px;
}

.login-list {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 16px -4px;
  flex-direction: row;
  flex-wrap: wrap;
}

.login-list li {
  flex-basis: 33.3333333333%;
  flex-grow: 0;
  max-width: 33.3333333333%;
  padding: 0 4px 8px;
  box-sizing: border-box;
}

.login-list button {
  cursor: pointer;
  padding: 10px 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: inherit;
  border: 2px solid #e9eaea;
  border-radius: 2px;
  background-color: #ffffff;
}

.login-list img {
  height: 24px;
  width: auto;
}

.login-btn.login-btn--reddit {
  padding: 6px 0;
}

.login-btn.login-btn--reddit img {
  height: 32px;
}

.login-note {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin-bottom: 50px;
}

.login-note a {
  color: #0364ff;
  cursor: pointer;
  text-decoration: none;
}

/* Mobile */
@media (max-width: 598px) {
  .modal-container {
    width: 100%;
    padding: 0;
  }

  .modal-content {
    padding: 0 40px;
    /* border: 0px solid white !important; */
    font-family: 'Roboto', sans-serif !important;
  }

  .close-container {
    padding: 10px 20px;
  }
}

.torus-btn {
  border-radius: 10px;
  width: 200px;
}

/* AWESOME BUTTON */

.aws-btn {
  --button-default-height: 48px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 6px;
  --button-horizontal-padding: 20px;
  --button-raise-level: 5px;
  --button-hover-pressure: 2;
  --transform-speed: 0.185s;
  --button-primary-color: #00b8c4;
  --button-primary-color-dark: #d0b400;
  --button-primary-color-light: #ffe11d;
  --button-primary-color-hover: #00a0ab;
  --button-primary-border: 1px solid #ffe11d;
  --button-secondary-color: #ee3253;
  --button-secondary-color-dark: #d0b400;
  --button-secondary-color-light: #ffe11d;
  --button-secondary-color-hover: #ec1a3f;
  --button-secondary-border: 1px solid #ffe11d;
  --button-anchor-color: #293b83;
  --button-anchor-color-dark: #772250;
  --button-anchor-color-light: #b33377;
  --button-anchor-color-hover: #293b83;
  --button-anchor-border: 1px solid #9f2d6a;
}

/* CONNECT BUTTON */

/* LOGIN MODAL */

/* .modal {
  background: rgba(0, 0, 0, 0.85);
} */

.modal.modal-login .modal-dialog {
  max-width: 50%;
  background: #1f2251;
  /* transform: unset; TODO: why was this here?*/
}

.modal .card {
  margin-bottom: 0;
}
.modal .card .card-header .close {
  position: absolute;
  right: 5px;
  color: white;
  top: 5px;
  font-size: 14px;
  cursor: pointer;
  z-index: 9;
}

/* @media (max-width: 575.98px) {
  .modal .modal-dialog.modal-login {
    margin: 0.5rem auto;
  }
} */

.card-login {
  /* background-color: black; */
  overflow: hidden;
  text-align: left;
  z-index: 2;
}
.card-login .card-header {
  padding: 0 0 50px;
  overflow: hidden;
}
.card-login .card-header img {
  position: absolute;
  margin-left: -50px;
  margin-top: -220px;
  border-radius: 20%;
  width: 350px;
}
.card-login .card-header .card-title {
  position: relative;
  font-size: 5em;
  font-weight: 900;
  color: #222a42;
  text-transform: lowercase;
  margin-left: -5px;
  z-index: 1;
}
.card-login .card-footer {
  padding: 15px;
}

@media (max-width: 991.98px) {
  .card.card-login .card-img {
    margin-left: -45px;
  }
}

@media (max-width: 575.98px) {
  .card.card-login {
    margin-top: 50px;
  }
  .modal-content .card.card-login {
    margin-top: 0;
  }
}

/* LOGIN MODAL */

/* LOGIN BUTTON ICONS */

.fab {
  font-weight: 400;
  color: #ffffff49;
  margin-right: 5px;
  margin-left: 5px;
}

/* MAIN CONTENT TABS */

/* .nav-tabs-info {
  font-size: .93em;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .nav-tabs > .nav-item > .nav-link i.tim-icons {
  font-size: 14px;
  position: relative;
  top: 0px;
  margin-right: 3px;
} */

.nav-tabs.nav-tabs-info > .nav-item > .nav-link.active {
  /* border-color: #1d8cf8;
  color: #1d8cf8; */
  border-color: transparent;
  color: #ffffff;
}

#mainContentTabTitle {
  display: inline;
  /* font-size: 1.5em; */
}

.nav-tabs.nav-tabs-info > .nav-item > .nav-link.active > #mainContentTabTitle {
  display: inline;
}

.nav-tabs.nav-tabs-info > .nav-item > .nav-link > #mainContentTabTitle {
  display: none;
}

a.active.nav-link {
  color: white;
  font-weight: 600;
  text-transform: uppercase;
}

a.nav-link {
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

a.active.nav-link i {
  color: white;
}

.nav-item i {
  color: #ffffff5b;
  font-weight: 400;
  text-transform: uppercase;
}

/* .nav-tabs > .nav-item > .nav-link {
  color: #ffffff;
  margin: 0;
  margin-right: 0px;
  background-color: transparent;
  padding: 6px 8px;
  line-height: 1.2;
} */

/* .nav-item:not(.nav-item.active) i {
  color: #ffffff75
} */

.tab-content.tab-space {
  padding-top: 0px;
}

.nav-tabs > .nav-item > .nav-link {
  /* color: #ffffff75; */
  color: #ffffff24;
  border-radius: 0%;
  /* border-width: 0px;  */
  /* TO GET RID OF ROUND BORDER ^^^ */
}

/* .tab-space {
  padding: 20px 0 0px 0px;
} */

.tab-space {
  padding: 0px 0px 0px 0px;
}

/* MATCHES TAB */

/* MEME MATCH TOPPER */

/* .match-top-info.row {
  align-items: center;
  display: flex;
} */

.match-bottom-info.row {
  align-items: center;
  display: flex;
  /* justify-content: space-between; */
  /* flex-wrap: ???; */
}

h3 #matchNumberMatchText {
  display: inline;
  color: #2125297d;
  font-weight: 600;
}

#matchNumberSign {
  display: inline;
}

#matchNumberMatch {
  display: inline;
}

#matchNumberStatus {
  display: inline;
}

.card h1 {
  color: #212529;
}

/* h3#topper-match-title {
  line-height: 1.05;
  margin-bottom: 0px;
  text-transform: uppercase;
  text-align: left;
  color: #212529;
  font-weight: 700;
} */

/* COUNTDOWN */

/* #countdownText {
  color: #2125297d;
  display: inline;
  font-weight: 600;
  text-transform: uppercase;
} */

/* #countdownText {
  color: #2125297d;
  display: inline;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75em;
}

#countdownText .h3 {
  display: inline;
  font-weight: 600;
} */

#timeDisplayCol {
  /* display: flex;
  align-items: center; */
  text-align: center;
}

/* #countdownTime { */
  /* position: absolute; */
  /* display: inline; */
  /* font-weight: 600; */
  /* margin-left: 3px;
  margin-right: 3px; */
  /* padding-left: 3px;
  padding-right: 3px;
  text-transform: uppercase;
} */

/* #countdownTime .h3 {
  display: inline;
  font-weight: 600;
} */

h3.match-topper-countdown {
  /* position: absolute; */
  line-height: 1;
  margin-bottom: 0px;
  /* text-align: center; */
  color: #212529;
  text-transform: lowercase;
  font-weight: 600;
}

#matchPendingCountdown {
  margin-bottom: 0px;
  text-transform: uppercase;
}

/* #votingTimeLeftText {
  color: #2125297d;
  display: inline;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75em;
}

#votingTimeLeftText .h3 {
  display: inline;
  font-weight: 600;
} */

/* #votingTimeLeftSubtext {
  color: #2125297d;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75em;
}

#votingTimeLeftSubtext .h3 {
  font-weight: 600;
} */

/* #minutesUntilVoting {
  display: inline;
  font-weight: 700;
  text-align: center;
}

#secondsUntilVoting {
  display: inline;
  font-weight: 700;
  text-align: center;
}

#msUntilVoting {
  display: inline;
  font-weight: 700;
  text-align: center;
} */

#cancelledMatchMiddle {
  text-transform: uppercase;
  font-size: 0.85em;
  color: #00000078;
}

/* FORMAT BADGES */

.meme-topper-format {
  text-align: right;
}

/* .match-topper-badge { */
  /* text-align: right;
  padding: 0px; */
/* } */

#topperProposalCard .card.defaultProposalFooter.card-footer {
  display: none;
}

h1,
.match-top-info .h1 {
  margin-bottom: 0px;
}

.badge {
  text-transform: uppercase;
  line-height: 1em;
  border: none;
  text-decoration: none;
  margin-bottom: 0px;
  text-decoration: none;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 1em;
  font-weight: 700;
  background-color: transparent;
}

.badge-icon {
  padding: 0.4em 0.55em;
}
.badge-icon i {
  font-size: 0.8em;
}

#topperProposalCard .card .card-footer {
  display: none;
}

/* #topperExpandIcon {
  padding-right:.1em; */
/* margin-left: .2em; */
/* display: inline;
} */



#topperProposalCard .card .card-body {
  /* padding: 10px; */
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

/* #topperProposalCard .category-social {
  font-size: 1.75em;
} */

/* #topperProposalCard .category-social {
  font-size: 1.5em;
} */

.meme-topper-format.col-md-3 {
  display: flex;
  justify-content: flex-end;
}


#topperProposalCard .stats.stats-right {
  font-size: 1.25em;
  /* FUCKED UP 3 */
}

/* TOPPER VOTE DISPLAY */

#voterDisplay {
  /* display: flex;
  flex-direction: row; */
  /* margin-bottom: 0px; */
}

#voterDisplayCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#stakedFundsInfoRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.voterDisplayBadge .h3 {
  /* display: flex;
  flex-direction: row; */
}

span.voterDisplayBadge.badge.badge-voters-pending h3 {
  margin-bottom: 0px;
  color: white;
  font-weight: 600;
}

#numberVotersValue {
  display: inline;
  color: #2dce89;
  padding-left: 3px;
}

#numberVotersValueFixed {
  display: inline;
  color: #ffffff;
  padding-left: 3px;
}

#numberVoterCost {
  display: inline;
  color: #ffffff;
  /* padding-left: 3px; */
  padding-left: 5px;
}

#voterStakesValue {
  display: inline;
  color: #2dce89;
  padding-left: 5px;
}

#voterStakesValueFixed {
  display: inline;
  color: #ffffff;
  padding-left: 5px;
}

#competitorPrizeValue {
  display: inline;
  color: #2dce89;
  padding-left: 5px;
}

#competitorPrizeValueFixed {
  display: inline;
  color: #ffffff;
}

#joinVoterIcon {
  display: inline;
}

.btn-voterjoin.btn-link {
  color: #ffffff;
}

.btn-voterjoin.btn-link:hover,
.btn-voterjoin.btn-link:focus,
.btn-voterjoin.btn-link:active {
  background-color: transparent !important;
  background-image: none !important;
  color: #69c9d0 !important;
  text-decoration: none;
  text-shadow: 2px 2px #ee1d52;
  box-shadow: none;
}

#voterDisplayBadge {
}

#joiningAmount {
  display: inline;
}

#coinIcon {
  display: inline;
}

.badge-voters-pending {
  color: #ffffff;
  background-color: rgb(0, 0, 0);
}

.badge-staked-funds {
  color: #ffffff;
  background-color: rgb(0, 0, 0);
}

#votersLoadingIcon {
  display: inline;
}

#votersIcon {
  display: inline;
}

#votersText {
  display: inline;
}

/* #joinAsVoterButton.btn.btn-secondary {
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #fb6340;
  background-color: transparent;
  background-image: none;
} */

#cantStartTooltip {
  z-index: 5;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-family: inherit;
  background-color: #0009;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  /* font-size: 5em; */
}

#cannotStartMatchTooltip {
  z-index: 5;
  color: #00000073;
  font-weight: 600;
  font-size: 0.75em;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  background: #cecece;
  /* border-color: #fb6340; */
  /* background-color: #cecece; */
  /* background-image: none; */
  /* margin-left: 5px; */
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  opacity: 0.75;
  text-transform: uppercase;
}

.btn-cantStartMatch {
  background: #ff8d72;
  /* background-image: -webkit-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
  background-image: -o-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
  background-image: -moz-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
  background-image: linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72); */
  background-image: none;
  background-size: 210% 210%;
  background-position: top right;
  background-color: transparent;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
}
.btn-cantStartMatch.animation-on-hover:hover {
  /* background-position: bottom left;
    transition: 0.3s ease-in-out; } */
  background-image: none;
}
.btn-cantStartMatch:hover,
.btn-cantStartMatch:focus,
.btn-cantStartMatch:active,
.btn-cantStartMatch.active,
.btn-cantStartMatch:active:focus,
.btn-cantStartMatch:active:hover,
.btn-cantStartMatch.active:focus,
.btn-cantStartMatch.active:hover {
  background-color: #cecece !important;
  color: black;
  background-image: unset !important;
  /* background-image: linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important;
    background-image: -webkit-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important;
    background-image: -o-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important;
    background-image: -moz-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important; */
  /* color: #ffffff; */
  box-shadow: none;
}

/* #plaintextjoinAsVoterButton { */
  /* text-transform: uppercase; */
  /* font-family: 'Share Tech Mono', monospace; */
  /* font-size: 1.5em; */
  /* font-family: 'VT323', monospace; */
  /* font-weight: 600; */
  /* background-color: #ff1e1e00; */
  /* border-radius: inherit; */
  /* background: linear-gradient(225deg, #f9f9f9, #1c1f49); */
  /* box-shadow: -6px 6px 12px #181a3e; */
  /* margin-bottom: 15px; */
  /* padding: 1px; */
  /* border-style: solid;
  border-width: 2px; */
  /* border-color: #ffffff2e; */
  /* color: black; */
  /* box-shadow: 0px 0px 5px 0px #FF8080, -7px -2px 12px 2px #FFE488, -7px -2px 8px -1px #8CFF85, 4px -2px 8px 3px #80C7FF, 7px 4px 7px 3px #E488FF, -3px 5px 12px 4px #FF616B, -3px -3px 9px -1px #8E5CFF, -3px 8px 11px -2px rgba(0,0,0,0); */
  /* padding: 15px;
  z-index: 5; */
/* } */

/* .btn-plainvoterjoin.btn-link {
  font-size: 1.5em;
  background-color: #1f2251 !important;
  background-image: none !important;
  color:  #ffffff !important;
  text-shadow: 2px 2px #EE1D52; 
} */

.btn-plainvoterjoin.btn-link {
  font-size: 1.5em;
  /* background-color: #1f2251 !important; */
  /* background-image: none !important; */
  color: #ffffff !important;
  /* text-shadow: 2px 2px #EE1D52; */
  z-index: 5;
}

.btn-plainvoterjoin.btn-link:hover {
  font-size: 1.5em;
  /* background-color: #1f2251 !important;
    background-image: none !important; */
  /* box-shadow: 0px 0px 5px 0px #ff8080, -7px -2px 12px 2px #ffe488, -7px -2px 8px -1px #8cff85, 4px -2px 8px 3px #80c7ff,
    7px 4px 7px 3px #e488ff, -3px 5px 12px 4px #ff616b, -3px -3px 9px -1px #8e5cff, -3px 8px 11px -2px rgba(0, 0, 0, 0); */
  /* color: #69C9D0; */
  color: #ffffff !important;
  /* text-decoration: none;  */
  text-shadow: 2px 2px #ee1d52;
  /* box-shadow: none;  */
  /* z-index: 0 */
  opacity: 1 !important;
}
.btn-plainvoterjoin.btn-link:focus,
.btn-plainvoterjoin.btn-link:active {
  font-size: 1.5em;
  /* background-color: #1f2251 !important;
    background-image: none !important; */
  /* box-shadow: 0px 0px 5px 0px #ff8080, -7px -2px 12px 2px #ffe488, -7px -2px 8px -1px #8cff85, 4px -2px 8px 3px #80c7ff,
    7px 4px 7px 3px #e488ff, -3px 5px 12px 4px #ff616b, -3px -3px 9px -1px #8e5cff, -3px 8px 11px -2px rgba(0, 0, 0, 0); */
  /* color: #69C9D0; */
  color: #ffffff !important;
  /* text-decoration: none;  */
  text-shadow: 2px 2px #ee1d52;
  /* box-shadow: none;  */
  /* z-index: 0 */
  opacity: 1 !important;
}

button#joinAsVoterButton.btn {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.25em;
  border-radius: 0px;
  border-style: solid;
  border-width: 3px;
  /* background: #fb6340; */
  /* border-color: #fb6340; */
  background-color: #ff000000;
  background-image: none;
  /* margin-left: 5px; */
  /* padding-top: 10px; */
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.btn-joinasvoter.btn-link {
  color: #ffffff;
  margin: 0px;
  padding: 0px;
}

.btn-joinasvoter.btn-link:hover,
.btn-joinasvoter.btn-link:focus,
.btn-joinasvoter.btn-link:active {
  background-color: #2dce89 !important;
  background-image: none !important;
  color: #ffffff !important;
  text-decoration: none;
  /* text-shadow: 2px 2px #EE1D52; */
  box-shadow: none;
}

.btn-voterjoin {
  background: #ff8d72;
  /* background-image: -webkit-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
  background-image: -o-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
  background-image: -moz-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72);
  background-image: linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72); */
  background-image: none;
  background-size: 210% 210%;
  background-position: top right;
  background-color: transparent;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
}
.btn-voterjoin.animation-on-hover:hover {
  /* background-position: bottom left;
    transition: 0.3s ease-in-out; } */
  background-image: none;
}
.btn-voterjoin:hover,
.btn-voterjoin:focus,
.btn-voterjoin:active,
.btn-voterjoin.active,
.btn-voterjoin:active:focus,
.btn-voterjoin:active:hover,
.btn-voterjoin.active:focus,
.btn-voterjoin.active:hover {
  background-color: transparent !important;
  background-image: unset !important;
  /* background-image: linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important;
    background-image: -webkit-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important;
    background-image: -o-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important;
    background-image: -moz-linear-gradient(to bottom left, #ff8d72, #ff6491, #ff8d72) !important; */
  /* color: #ffffff; */
  box-shadow: none;
}

#joinButtonIcon {
  display: inline !important;
}

#joinButtonIcon .svg-inline--fa.fa-up-down.fa-w-16 {
  display: inline;
}

#voterEntryCost {
  display: inline;
}

/* FUNDS AT STAKE CATEGORY */

#stakesIcon {
  display: inline;
}

.badge-staked-funds {
  color: #000000;
  background-color: transparent;
}

#prizePoolText {
  color: #000000;
  font-weight: 600;
}

.stakedFundsDisplayBadge.badge.badge-staked-funds h3 {
  /* background-color: black; */
  margin-bottom: 0px;
  color: white;
  font-weight: 600;
}

/* #meme-match-top.card {
  display: flex;
  background: #fbff003a;
  margin-top: 0px;
} */

/* #meme-match-top.card-body {
  background: #002fff98;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
} */

/* MEME MATCH CARDS */

#memeMatchSimpleStats {
  display: flex;
  flex-direction: column;
  align-content: center;
  /* justify-content: center; */
  padding-top: 0px;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.15em;
  /* font-family: 'VT323', monospace; */
  font-weight: 600;
}

.numVotersGlowsUpdateLoad {
  animation: numvotersLoad 2s 1;
}

@keyframes numvotersLoad {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.numVotersGlowsUpdateOne {
  animation: numvotersFirst 2s 1;
}

@keyframes numvotersFirst {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.numVotersGlowsUpdateTwo {
  animation: numvotersSecond 2s 1;
}

@keyframes numvotersSecond {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  /* 25%     {
    background:transparent;
    color: white;
  }
  75%      {
    background:white;
    color: black;
  }    */
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.voterStakesGlowsUpdateLoad {
  animation: voterStakesLoad 2s 1;
}

@keyframes voterStakesLoad {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.voterStakesGlowsUpdateOne {
  animation: voterStakesFirst 2s 1;
}

@keyframes voterStakesFirst {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  /* 25%     {
    background:transparent;
    color: white;
  }
  75%      {
    background:white;
    color: black;
  }    */
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.voterStakesGlowsUpdateTwo {
  animation: voterStakesSecond 2s 1;
}

@keyframes voterStakesSecond {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  /* 25%     {
    background:transparent;
    color: white;
  }
  75%      {
    background:white;
    color: black;
  }    */
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.competitorPrizeGlowsUpdateLoad {
  animation: compUpdateLoad 2s 1;
}

@keyframes compUpdateLoad {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  /* 25%     {
    background:transparent;
    color: white;
  }
  75%      {
    background:white;
    color: black;
  }    */
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.competitorPrizeGlowsUpdateOne {
  animation: compUpdateFirst 2s 1;
}

@keyframes compUpdateFirst {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  /* 25%     {
    background:transparent;
    color: white;
  }
  75%      {
    background:white;
    color: black;
  }    */
  100% {
    background: transparent;
    color: #2dce89;
  }
}

.competitorPrizeGlowsUpdateTwo {
  animation: compUpdateSecond 2s 1;
}

@keyframes compUpdateSecond {
  0% {
    background: #2dce89;
    color: #2dce89;
  }
  /* 25%     {
    background:transparent;
    color: white;
  }
  75%      {
    background:white;
    color: black;
  }    */
  100% {
    background: transparent;
    color: #2dce89;
  }
}

#memeMatchStatsCenter {
  display: flex;
  flex-direction: row;
}

#SeeResultsButton {
  background: #2a6ff6;
  font-family: inherit;
  font-size: 1.25em;
  font-weight: 600;
}

#SeeResultsButton:hover {
  background-color: yellow;
  background: yellow;
  font-family: inherit;
  font-size: 1.25em;
  font-weight: 600;
}

#pendingMemeMatchHalf {
  display: flex;
  justify-content: center;
  /* align-items: center;
  align-content: center; */
}

#voterAppealText {
  display: inline;
}

h3.pendingCardHighBidderMessage {
  margin-bottom: 0px;
}

/* #pendingMatchSubmitButton {
  margin-bottom: 10px;
} */

.pendingMemeCard .card {
  margin-top: 0px;
  display: inherit;
  border-radius: 0px;
  background: #1f2251;
  box-shadow: none;
}

.pendingMemeCard.card {
  width: 80%;
  /* height: 250px; */
  margin-top: 0px;
  display: flex;
  align-items: center;
  /* margin-top: 30px;
  margin-bottom: 30px; */
  margin-top: 60px;
  margin-bottom: 60px;
}

.pendingMemeCard.left.card {
  /* border-radius: 8px;
  background: #5e72e4; */
  /* border-radius: 8px;
  background: #1f2251;
  box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65; */
  /* box-shadow:  -7px -7px 15px #11132d, 
               7px 7px 15px #2d3175; */
  /* background: #1f2251;
box-shadow: inset -10px 10px 25px #141634, 
            inset 10px -10px 25px #2a2e6e; */

  /* display: flex; */
}

.pendingMemeCard.right.card {
  /* border-radius: 8px; */
  /* border-radius: 8px;
  background: #1f2251;
  box-shadow: 7px 7px 14px #171a3d, -7px -7px 14px #272b65; */
  /* box-shadow:  7px -7px 15px #11132d, 
               -7px 7px 15px #2d3175; */
  /* border-radius: 8px;
background: #1f2251;
box-shadow: inset 10px 10px 25px #141634, 
            inset -10px -10px 25px #2a2e6e; */
  /* display: flex; */
}

/* #pendingMemeCardFooter {
  display: flex;
  justify-content: flex-end;
}

#pendingMemeCardFooter.card-footer h3 {
  margin-bottom: 0px;
}

.pendingMemeCardFooter .card .card-footer {
  margin-top: 0px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
} */

h3#noCompetitorYetText {
  display: inline;
  margin-bottom: 0px;
}

/* #currentHighBidText {
  display: inline;
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  font-weight: 600;
}

#currentHighBidTextNumber {
  display: inline;
  color: white;
}

#currentHighBidTextIcon {
  display: inline;
  color: white;
} */

#uTheHighBidderText {
  display: inline;
  color: rgba(255, 255, 255, 0.753);
  text-transform: uppercase;
  font-weight: 600;
}

#pendingCardGlowUpdateLoad {
  /* font-size: 1.5em; */
  animation: pendingCardLoad 2s 1;
  /* margin-bottom: 0px; */
}

@keyframes pendingCardLoad {
  0% {
    background: #2dce89;
    /* color: #2dce89; */
  }
  100% {
    background: transparent;
    /* color: #2dce89; */
  }
}

#pendingCardGlowUpdateOne {
  font-size: 1.5em;
  animation: pendingCardOne 2s 1;
}

@keyframes pendingCardOne {
  0% {
    background: #2dce89;
    /* color: #2dce89; */
  }
  100% {
    background: transparent;
    /* color: #2dce89; */
  }
}

#pendingCardGlowUpdateTwo {
  font-size: 1.5em;
  animation: pendingCardTwo 2s 1;
}

h3#pendingCardGlowUpdateTwo {
  margin-bottom: 0px;
}

@keyframes pendingCardTwo {
  0% {
    background: #2dce89;
    /* color: #2dce89; */
  }
  100% {
    background: transparent;
    /* color: #2dce89; */
  }
}

#pendingMemeCardBidPrompt {
  display: flex;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: flex-start;
  font-size: 1.25em;
}

/* #NoCompetitorYetIcon {
  display: inline;
} */

#collapseCompetitorIcon {
  font-size: 1.5em;
  margin-left: 3px;
}


/* #pendingMemeCardSubmitFooter {
  display: flex;
  justify-content: flex-end;
} */



.btn-pendinglogin.btn-link {
  color: #ffffff;
}

.btn-pendinglogin.btn-link:hover,
.btn-pendinglogin.btn-link:focus,
.btn-pendinglogin.btn-link:active {
  background-color: transparent !important;
  background-image: none !important;
  /* color: #69C9D0 !important; */
  text-decoration: none;
  text-shadow: 2px 2px #ee1d52;
  box-shadow: none;
}

#inputPlaceholder {
  display: inline;
  font-family: inherit;
  font-weight: 700;
  color: white;
}

/* ACTIVE MEMECARD */

.activeMemeCard .card {
  margin-top: 0px;
  display: inherit;
  border-radius: 0px;
  background: #1f2251;
  box-shadow: none;
}

.activeMemeCard.card {
  /* width: 80%; */
  /* height: 250px; */
  margin-top: 0px;
  display: flex;
  /* align-items: center; */
  /* margin-top: 30px; */
  /* margin-bottom: 30px; */
  /* transform: scale(0.85); */
}

.activeMemeCard.left.card {
  /* border-radius: 8px;
  background: #5e72e4; */
  border-radius: 8px;
  background: #1f2251;
  box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;
  /* box-shadow:  -7px -7px 15px #11132d, 
               7px 7px 15px #2d3175; */
  /* background: #1f2251;
box-shadow: inset -10px 10px 25px #141634, 
            inset 10px -10px 25px #2a2e6e; */

  display: flex;
}

#activeMemeCardLeft {
  border-radius: 8px;
  background: #1f2251;
  box-shadow: -7px 7px 14px #131532, 7px -7px 14px #2b2f70;
  transform: scale(0.75);
}

#activeMemeCardRight {
  border-radius: 8px;
  background: #1f2251;
  box-shadow: 7px 7px 14px #131532, -7px -7px 14px #2b2f70;
  transform: scale(0.75);
}

.activeMemeCard.right.card {
  /* border-radius: 8px; */
  border-radius: 8px;
  background: #1f2251;
  box-shadow: 7px 7px 14px #171a3d, -7px -7px 14px #272b65;
  /* box-shadow:  7px -7px 15px #11132d, 
               -7px 7px 15px #2d3175; */
  /* border-radius: 8px;
background: #1f2251;
box-shadow: inset 10px 10px 25px #141634, 
            inset -10px -10px 25px #2a2e6e; */
  display: flex;
}

#activeCardContent {
  /* padding: 1.25em; */
}

/* #activeMemeCardFooter {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding-bottom: 17.5px;
} */

#voteForEntryButton {
  /* color: #212529; */
  display: inline;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #5e72e4;
  background-image: none;
  /* background: linear-gradient( 60deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%), hsl(89, 85%, 66%), hsl(134, 85%, 66%), hsl(179, 85%, 66%) ); */
}

#voteForEntryButton.btn:hover {
  display: inline;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #170000;
  background-image: none;
  /* background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  ); */
}

/* OVER MEME CARD */

#overMatchFooter {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-bottom: 1.5em;
}

/* #overCollapseSubmitterPrompt {
  display: inline;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
} */

.progress-container.progress-info {
  display: flex;
}

.activeMemeCard.card .card-footer div {
  display: flex;
}

#voteCountProgressBar {
  display: contents;
}

.progress-container .progress-badge {
  color: #ffffff;
  font-size: 1.5em;
  text-transform: uppercase;
}

.progress {
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.875rem;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #4ee150;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.5rem 0.5rem;
}

.progress-container.progress-info .progress-bar {
  background: #3358f4;
  background-image: -webkit-linear-gradient(to bottom left, #3358f4, #1d8cf8, #3358f4);
  background-image: -o-linear-gradient(to bottom left, #3358f4, #1d8cf8, #3358f4);
  background-image: -moz-linear-gradient(to bottom left, #3358f4, #1d8cf8, #3358f4);
  background-image: linear-gradient(to bottom left, #3358f4, #1d8cf8, #3358f4);
  background-size: 210% 210%;
  background-position: top right;
}

.progress-container.progress-success .progress-bar {
  background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  );
  /* background-image: -webkit-linear-gradient(to bottom left, #0098f0, #00f2c3, #0098f0);
        background-image: -o-linear-gradient(to bottom left, #0098f0, #00f2c3, #0098f0);
        background-image: -moz-linear-gradient(to bottom left, #0098f0, #00f2c3, #0098f0);
        background-image: linear-gradient(to bottom left, #0098f0, #00f2c3, #0098f0);
        background-size: 210% 210%; */
  /* box-shadow: 0px 0px 5px 0px #FF8080, -7px -2px 12px 2px #FFE488, -7px -2px 8px -1px #8CFF85, 4px -2px 8px 3px #80C7FF, 7px 4px 7px 3px #E488FF, -3px 5px 12px 4px #FF616B, -3px -3px 9px -1px #8E5CFF, -3px 8px 11px -2px rgba(0,0,0,0);  */
  background-position: top right;
}

.activeMemeCard.winner {
  /* border-style: solid;
  border-color: yellow;
  border-width: 5px; */
  /* box-shadow: 0px 0px 5px 0px #FF8080, -7px -2px 12px 2px #FFE488, -7px -2px 8px -1px #8CFF85, 4px -2px 8px 3px #80C7FF, 7px 4px 7px 3px #E488FF, -3px 5px 12px 4px #FF616B, -3px -3px 9px -1px #8E5CFF, -3px 8px 11px -2px rgba(0,0,0,0) !important; */
  /* box-shadow: 3px 11px 10px 0px #FF8085, -18px -2px 21px -6px #FFE488, 7px -2px 17px 7px #8CFF85, 4px 18px 13px -15px #80C7FF, 2px -17px 25px 1px #8E5CFF !important; */
  /* box-shadow: 3px 11px 10px 0px #FF8085, -18px -2px 21px -6px #FFE488, 7px -2px 17px 7px #8CFF85, 2px -17px 25px 1px #1d8cf8 !important; */
  /* box-shadow: 1px 1px 10px 5px #FF8085, -4px 0px 15px 10px #FFE488, 4px 0px 15px 10px #8CFF85, 12px -15px 21px 7px #1d8cf8 !important */
  /* box-shadow: 0px 0px 39px 14px #1d8cf8 !important; */
}

/* MEME MATCH TAB */

/* .jumbotron {
  margin-bottom: 2rem;
  background-color: #5e72e4;
  border-radius: 0.4285rem;
  border-width: 0px;
  border-style: solid;
  border-color: #2dce8900;
} */

.jumbotron {
  padding: 4px;
  /* margin-bottom: 2rem; */
  /* background-color: #5e72e4; */
  /* border-radius: 0.4285rem; */
  /* border-width: 1px; */
  border-style: solid;
  /* border-color: #ffffff00; */
  /* z-index: 9; */
  /* background: linear-gradient( 60deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%), hsl(89, 85%, 66%), hsl(134, 85%, 66%), hsl(179, 85%, 66%) ); */
}

/* .block-gradient {
  position: relative;
  opacity: .99; */
/* margin: 10px; */
/* padding: 10px; */
/* border-style: solid; */
/* border-width: 5px;
  border-color: transparent; */
/* z-index: 1; */
/* background: black; */
/* background-color: black; */
/* background-image: none; */
/* background: linear-gradient( 60deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%), hsl(89, 85%, 66%), hsl(134, 85%, 66%), hsl(179, 85%, 66%) ); */
/* } */

/* .block-gradient{
  --border-width: 4px;
} */

/* .block-gradient {
  --border-width: 4px; */
/* padding: 10px; */
/* margin: 10px; */
/* position: relative;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width)); */
/* z-index: 0; */
/* width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient( 60deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%), hsl(89, 85%, 66%), hsl(134, 85%, 66%), hsl(179, 85%, 66%) );
  background-size: 300% 300%;
  background-position: 0 50%; */
/* border-radius: calc(2 * var(--border-width)); */
/* animation: moveGradient 12s alternate infinite;
  animation-duration: 12s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: moveGradient; */
/* animation: moveGradient 12s alternate infinite;
  border-style: solid;
  border-color: transparent;
  border-radius: .5em; */
/* border-width: 4px; */
/* } */

/* GOOD ONE!
.block-gradient {
  --border-width: 4px;
  position: relative;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient( 90deg, hsl(224, 85%, 66%), hsl(269, 85%, 66%), hsl(314, 85%, 66%), hsl(359, 85%, 66%), hsl(44, 85%, 66%), hsl(89, 85%, 66%), hsl(134, 85%, 66%), hsl(179, 85%, 66%) );
  background-size: 300% 300%;
  background-position: 15% 20%;
  background-repeat: no-repeat;
  animation: moveGradientRainbow 6s alternate infinite;
  border-style: solid;
  border-color: transparent;
  border-radius: .5em;
  border-width: 4px;
} */

/* .gradient-border {
  --border-width: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; */
/* color: white; */
/* background: #222;
  background-color: #222; */
/* border-radius: var(--border-width); */
/* z-index: 1; */
/* } */

/* .gradient-border ::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 12s alternate infinite;
    opacity: .0;
  } */

/* .gradient-border ::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient( 60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%) );
    background-size: 100% 100%;
    background-position: 0 50%;
    background-repeat: none;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 12s infinite;
    opacity: .1;
}

.gradient-border ::after {
  border-width: 0px;
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient( 60deg, hsl(236, 45%, 22%), hsl(231, 71%, 63%) );
  background-size: 500% 500%;
  background-position: 0 50%;
  background-repeat: none;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 12s infinite;
  opacity: .10;
} */

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
  /* 100% {
    background-position: 50% 100%;
  } */
}

@keyframes moveGradientRainbow {
  50% {
    background-position: 85% 80%;
  }
}

#memeMatchLoadingScreen {
  display: flex;
  justify-content: center;
  color: white;
  margin-bottom: 15px;
}

#loadingScreenSpinner {
  margin-left: 10px;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 0 0;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 15px;
}

/* PAGINATION */

.section-pagination {
  padding-bottom: 0px;
  padding-top: 0px;
}

/* #MemeMatchLoader > div.section.section-pagination > div > div > nav > ul > li > a {
  font-family: inherit;
  font-weight: 600;
  background: #00000075;
} */

#focusedMatchNumber {
  font-family: inherit;
  font-weight: 600;
  background: #00000075;
}

#MemeMatch_MemeMatchLoader__3fOSp > div.section.section-pagination > div > div > nav > ul {
  margin-bottom: 0px;
}

#paginateLeftArrow {
  background: none;
  display: inline;
}

button#paginateLeftArrow:hover {
  background: none;
  display: inline;
}

#paginateRightArrow {
  background: none;
  display: inline;
}

/* RIGHTSIDE */

/* .rightside .card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background: #171941;
  box-shadow:  -5px 5px 10px #10122e, 
               5px -5px 10px #1e2155;
  margin-top: 0px;
  margin-bottom: 10%;
  padding-left: 0px;
  padding-right: 0px;
} */

@media screen and (max-width: 768px) {
  .section-safe .card.card-stats {
    margin-top: 0px;
  }
  .section-safe .card.card-stats:nth-of-type(1),
  .section-safe .card.card-stats:nth-of-type(2),
  .section-safe .card.card-stats:nth-of-type(3) {
    width: auto;
  }
}

/* SETTINGS TAB */
#settings-container {
  position: relative;
  opacity: 0.99;
  background: #1f2251;
  min-height: 100px;
  /* TODO MOBILE ^^^ */
}

/* PLAYERS ONLINE */

/* #player-online-col {
  padding-left: 0px;
  padding-right: 0px;
} */

/* #playersOnlineNumber {
  color: white;
  font-size: 3em;
  font-weight: 500;
  margin-left: 25px;
} */

/* #playersOnlineNumber.h3 {
  margin-bottom: 0px;

}  */

/* .card-stats .numbers {
  text-align: right;
}
.card-stats .numbers .card-title {
  margin-top: 0;
}
.card-stats .numbers .card-category {
  margin-bottom: 0;
  color: #9a9a9a;
} */

/* .card-stats .info-icon {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background: #fb6340; 
  background-image: -webkit-linear-gradient(
    to bottom left,
    #e14eca,
    #ba54f5,
    #e14eca
  );
  background-image: -o-linear-gradient(
    to bottom left,
    #e14eca,
    #ba54f5,
    #e14eca
  );
  background-image: -moz-linear-gradient(
    to bottom left,
    #e14eca,
    #ba54f5,
    #e14eca
  );
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  background-size: 210% 210%;
  background-position: top right;
}
.card-stats .info-icon i {
  color: #ffffff;
  font-size: 2.55em;
  padding: 21px 19px;
}
.card-stats .info-icon.icon-primary {
  background: #e14eca;
  background-image: -webkit-linear-gradient(
    to bottom left,
    #e14eca,
    #ba54f5,
    #e14eca
  );
  background-image: -o-linear-gradient(
    to bottom left,
    #e14eca,
    #ba54f5,
    #e14eca
  );
  background-image: -moz-linear-gradient(
    to bottom left,
    #e14eca,
    #ba54f5,
    #e14eca
  );
  background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
  background-size: 210% 210%;
  background-position: top right;
} */

.card-stats .info-icon {
  background: #e14eca;
  background-image: none;
  background-position: top right;
}
.card-stats .info-icon i {
  color: #ffffff;
  font-size: 2.55em;
  padding: 21px 19px;
}
.card-stats .info-icon.icon-primary {
  background: #e14eca;
  background-image: none;
  background-position: top right;
}

/* AD SPOT */

#ad-spot-col {
  padding-left: 0px;
  padding-right: 0px;
}

/* NETWORK STATS */

/* .card-chart.card {
  margin-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

#dataChartRightSide {
  display: flex;
  justify-content: center;
} */

/* 
.meme-card-image{
  background-repeat:no-repeat;
  background-size:contain;
} */

/* PENDING MEMECARD */

#minBidIcon {
  display: inline;
}

/* MEME CARDS */

.card-background .full-background {
  /* background-position: center center;
  background-size: cover;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  position: relative; */
  /* position: absolute; */
  /* border-radius: 7px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all 1s cubic-bezier(0.2, 1, 0.22, 1);
  -webkit-transition: all 1s cubic-bezier(0.2, 1, 0.22, 1);
  opacity: 0.3;
  padding-bottom: 30px; */
  /* transform: scale(.85); */
}

/* .card-background .full-background {
  background-position: center center;
  background-size: cover;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  position: relative; */
  /* position: absolute; */
  /* border-radius: 7px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all 1s cubic-bezier(0.2, 1, 0.22, 1);
  -webkit-transition: all 1s cubic-bezier(0.2, 1, 0.22, 1); */
  /* opacity: 0.85; */
  /* padding-bottom: 30px; */
  /* transform: scale(.85); */
/* } */

/* .index-page .card.card-background[data-animation=true] {
  height:unset;
} */

.card-background.card-blog[data-animation='true']:after,
.card-background.card-blog[data-animation='zooming']:after {
  height: 74%;
  top: auto;
  /* border: 0px; */
  background: none;
}

.card-background::after {
  /* content: ""; RED GRADIENT ^^*/
  content: none;
}

.index-page .card.card-background[data-animation='true'] {
  /* height: 450px; */
  /* background: transparent; */
  /* box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.0); */
  /* border-style: solid; */
  /* border-color: #ced4da; */
  /* border-width: 2px; */
  /* border-bottom: 0px; */
}

.shadow-col {
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.671);
  padding-right: 0px;
  padding-left: 0px;
}

.meme-card.card-background {
  border-top-left-radius: 0.2857rem;
  border-top-right-radius: 0.2857rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.meme-card.full-background {
  border-top-left-radius: 0.2857rem;
  border-top-right-radius: 0.2857rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.card-background {
  /* background-position: center center;
  background-size: contain; */
  /* text-align: center;  */
  /* border-style: solid; */
  border-radius: 0px;
  border-width: 0px;
}

.card-background .card-body {
  /* position: relative; */
  /* z-index: 2; */
  /* min-height: 75px; */
  /* max-height: 100px; */
  padding-top: 0px;
  padding-bottom: 0px;
}

/* TODO MEME CARD SIZE */

.index-page .card.card-background[data-animation='true'] {
  background: transparent;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0);
  border-width: 2px;
  border-bottom: 0px;
  /* position: relative; */
}

/* .index-page .tiktok-holder .card.card-background[data-animation="true"] {

}

  #tiktok-holder{
    height: 720px;
  } */

.card-blog {
  min-height: 100px;
}

.meme-card {
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #ffffff00; */
  background-clip: border-box;
  margin-top: 0px;
  margin-bottom: 0px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1 rem;
}

.card-title {
  margin-bottom: 1.25rem;
}

.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #f6f9fc00;
  border-bottom: 0.0625rem solid rgb(208, 255, 0);
}

.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: #0b6ccc00;
  border-top: 0.0625rem solid rgba(34, 42, 66, 0.05);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.2857rem - 0.0625rem) calc(0.2857rem - 0.0625rem);
}

.card-img {
  width: 100%;
  border-radius: calc(0.2857rem - 0.0625rem);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.2857rem - 0.0625rem);
  border-top-right-radius: calc(0.2857rem - 0.0625rem);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.2857rem - 0.0625rem);
  border-bottom-left-radius: calc(0.2857rem - 0.0625rem);
}

.card-columns .card {
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/* TIKTOK MEME CARD */

/* .tiktok-div {
  border-style: solid;
  border-color: #fbff00;
  max-width: 340px;
  max-height: 700px;
  padding-bottom: 56.25%;
  background: rgb(129, 110, 1); 
} */

.iframe-container {
  /* border-style: solid;
  border-color: #fbff00; */
  /* max-width: 340px; */
  /* max-height: 720px; */
/* 
  min-height: 600px; */
  /* position: center;  */
}

/* #youtube-embed {
  position: relative;
  z-index: 2;

  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-style: solid;
  border-color: #fbff00;
} */

/* #tiktok-embed {
  height: 100%;
  width:100%;
  border-style: solid;
  border-color: #00aeff;
} */

/* IFRAME */

.card .iframe-container {
  border-radius: 0px;
  border-style: solid;
  margin-top: 0px;
  margin-bottom: 0px;
  border-width: 0px;
}
.iframe-container .card-body {
  padding: 0px;
}

.iframe-container {
  overflow: hidden;
  position: relative;
}

.iframe-container body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.embed body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

html body div#__APP_ROOT__.root div.embed {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* body {
  display: flex;
  flex-direction: row;
  justify-content: center;
} */
/* BID PROMPT CARD */

.bid-prompt {
  background: #1f2251;
  border: 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  /* TODO ^^^^ */
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-top: 0px;
}

/* MEME CARD COLLAPSE BOTTOM */

.collapse-style {
  background-color: rgba(0, 132, 255, 0.2);
  color: white;
  /* border-color: #0080ffbd;
    border-style: solid; */
  /* box-shadow: #0f1222; */
}

#down-button-meme-card {
  font-size: 35px;
  margin-top: 15px;
}

#collapse-title-meme-card {
  color: white;
  margin-top: 15px;
}

.dropdown-toggle[aria-expanded='true']:after,
a[data-toggle='collapse'][aria-expanded='true'] .caret,
.card-collapse .card a[data-toggle='collapse'][aria-expanded='true'] i,
.card-collapse .card a[data-toggle='collapse'].expanded i {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}


.tim-icons {
  color: #ffffff;
}

.card-plain {
  margin-top: -5px; /* TODO: change to percentage or something */
  border-style: solid;
  border-color: #0084ff00;
  border-radius: 15px;
}

/* MAIN */

.main {
  padding-left: 5%;
  padding-right: 5%;
  /* TODO */
}

/* CARD MORE */

/* NETWORK STATS */

#network-right {
  margin-bottom: 0px;
}

.card-chart {
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  /* TODO */
}
.card-chart .card-header .card-title i {
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.card-chart .card-header .card-category {
  margin-bottom: 5px;
}
.card-chart .card-body {
  padding-left: 5px;
  padding-right: 5px;
}
.card-chart .card-body .tab-space {
  padding: 0;
}
.card-chart .table {
  margin-bottom: 0;
}
.card-chart .table td {
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.card-chart .card-progress {
  margin-top: 30px;
  padding: 0 10px;
}
.card-chart .chart-area {
  width: 100%;
  height: 100%;
}
.card-chart .card-footer {
  margin-top: 15px;
}
.card-chart .card-footer .stats {
  color: #9a9a9a;
}
.card-chart .dropdown {
  position: absolute;
  right: 20px;
  top: 20px;
}
.card-chart .dropdown .btn {
  margin: 0;
}

/* AD SPOT */

#ad-spot {
  border-style: solid;
  border-color: #ff0000;
  border-radius: 15px;
}

/* 3BOX PROFILE HOVER 

.boxAddress {
  background: transparent;
  height: fit-content;
  padding: 0px;
  font-family: Arial, sans-serif;
  position: relative;
  display: block;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.boxAddress.tileStyle {
  border: 1px solid #edf2fb;
  background: white;
  border-radius: 5px;
}
.boxAddressContentWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.boxAddressContentWrapper.boxAddressLink {
  cursor: pointer;
}
.tileStyle .boxAddressContentWrapper {
  max-width: calc(200px + 50px + 40px);
  min-width: calc(92px + 50px + 40px);
}
.boxAddressContentWrapper {
  max-width: calc(200px + 50px + 40px);
}
.boxAddressFull {
  width: fit-content;
}
.boxAddressWrap {
  display: block;
  float: left;
  position: relative;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  background-clip: padding-box;
}
.boxAddress .boxImg {
  background: #074988;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.boxAddress.tileStyle .boxImg {
  border-radius: 5px 0px 0px 5px;
}
.boxAddress .boxImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: white;
}
.boxShortAddress {
  color: #636668;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.015em;
  display: block;
  padding: 0 16px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tileStyle .boxShortAddress {
  min-width: 100px;
}
.openHover_mobile {
  width: fit-content;
  height: fit-content;
  color: #b7bece;
  padding: 0;
  border: none;
  background-color: transparent;
}
.tileStyle .openHover_mobile {
  background-color: #f9f9f9;
  border-left: 1px solid #edf2fb;
  border-radius: 0 5px 5px 0;
  width: 50px;
  height: 50px;
}
.openHover_mobile svg {
  height: 14px !important;
  width: 14px !important;
}
.addressCopy {
  position: relative;
  color: #b7bece;
  border-left: 1px solid #edf2fb;
  padding: 9px 4px 4px 9px;
  width: 16px;
  font-size: 12px;
  height: 20px;
  display: inline-block;
}
.addressCopy:hover {
  cursor: pointer;
  color: #7c8aa7;
}
.addressCopy:active {
  background-color: #f7f7fb;
}
.addressAndCheck svg {
  margin-left: 6px;
}
.boxAddressWrap .hoverWrap {
  height: fit-content;
  width: fit-content;
  position: absolute;
  z-index: 2;
}
.boxAddressWrap .hoverWrap.top {
  padding-bottom: 20px;
  bottom: 90%;
  left: -10px;
}
.boxAddressWrap .hoverWrap.bottom {
  padding-top: 20px;
  top: 90%;
  left: -10px;
}
.boxAddressWrap .hoverWrap.right {
  padding-left: 20px;
  top: calc(50% - 33px);
  left: calc(100% + -5px);
}
.boxAddressWrap .hoverWrap.left {
  padding-right: 20px;
  top: calc(50% - 33px);
  right: calc(100% + -5px);
}
.boxAddressWrap .hoverProfile {
  border-radius: 10px;
  border: 1px solid #edf2fb;
  box-shadow: 0 3px 8px 0 rgba(87, 87, 87, 0.15);
  background: white;
  color: #181F21;
  width: 230px;
  z-index: 1111;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 20px 20px;
  position: relative;
}
.boxAddressWrap.isDesktop:hover .hoverWrap .hoverProfile {
  display: flex;
}
.boxAddressWrap .hoverWrap.showHoverMobile .hoverProfile {
  display: flex;
  box-sizing: border-box;
  min-width: 270px;
}
.hoverProfileEmpty {
  padding: 20px;
}
.hoverProfile p {
  letter-spacing: 0.4px;
}
.hoverProfile textarea {
  font-size: 18px;
}
.hoverProfile .coverPicture {
  position: absolute;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 1;
  margin-left: -20px;
}
.hoverProfile .coverPicture_image {
  min-width: 100%;
  min-height: 70px;
  max-width: 100%;
  max-height: 70px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f5f5f5;
}
.hoverProfile .profileValue {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 18px;
}
.hoverProfile .profileValue a {
  text-decoration: none;
  color: #487dec;
  font-size: 14px;
  width: 80%;
  white-space: nowrap;
}
.hoverProfile p {
  margin: 0;
  color: #ababab;
}
.hoverProfile .profileValuePicture {
  z-index: 2;
  margin-top: 10px;
  display: flex;
}
.hoverProfile .profileValuePicture .profileValuePicture_image {
  background: white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 14px;
  border: 3px solid white;
}
.hoverProfile .noProfileImgSpacer {
  margin-top: 65px;
}
.hoverProfile .profileDescription .profileDescription_text {
  font-size: 14px;
  margin-top: 4px;
  color: #949494;
  line-height: 18px;
  text-align: left;
}
.hoverProfile .profileValue .profileText {
  padding-left: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #487dec;
}
.hoverProfile .profileValue svg {
  margin-left: 8px;
  color: #487dec;
}
.hoverProfile .profileValueName {
  letter-spacing: 1px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.hoverProfile .profileValueName.noContent {
  margin-bottom: 20px;
}
.hoverProfile .profileValueName span {
  margin-left: 5px;
}
.hoverProfile .profileValueName svg {
  margin-left: 8px;
}
.hoverProfile .profileValueName .profileText {
  text-decoration: none;
  color: #181F21;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
}
.hoverProfile .profileText p {
  color: #487dec !important;
  font-size: 14px !important;
}
.hoverProfile .profileValueName .profileText:hover {
  text-decoration: underline;
}
.hoverProfile .profileValueName .profileText:visited {
  color: #181F21;
}
.hoverProfile .boxLink {
  font-size: 12px;
  border-radius: 0px 0px 3px 3px;
  color: #ababab;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}
.hoverProfile .boxLink .boxLinkText {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.3s all ease-in-out;
}
.hoverProfile .boxLink .boxLinkText:hover {
  color: #1168df !important;
}
.hoverProfile .boxLink .boxLinkText:hover .logo {
  opacity: 1 !important;
  filter: saturate(100%);
}
.hoverProfile .boxLink .boxLinkText img {
  margin-left: 12px;
}
.hoverProfile .boxLink .profileCheck {
  position: absolute;
  right: -16px;
}
.hoverProfile .logo {
  opacity: 0.1 !important;
  height: 18px;
  width: 18px;
  filter: saturate(0%);
  transition: 0.3s all ease-in-out;
}
.hoverProfile .boxLink a {
  color: #ababab !important;
  font-weight: 300 !important;
}
.hoverProfile .BoxLinkText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.hoverProfile .boxLinkEmpty {
  font-size: 12px;
  text-align: center;
  border-radius: 0px 0px 3px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.hoverProfile .boxLink a {
  text-decoration: none;
}
.hoverProfile .boxLink svg {
  color: #487dec;
}
.hoverProfile .boxLinkEmpty_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.hoverProfile .boxLinkEmpty a {
  text-decoration: none;
  color: #487dec;
  font-weight: 600;
  margin-right: 5px;
}
.hoverProfile .boxLinkEmpty span {
  color: #ababab;
}
.hoverProfile .boxLinkEmpty svg {
  color: #487dec;
}
.hoverProfile .profileDetails {
  margin: 24px 0;
  width: 100%;
  overflow: hidden;
}
.hoverProfile .profileValueKey {
  font-size: 12px;
  width: 20%;
  text-align: left;
}
.hoverProfile:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #edf2fb;
  border-right: 1px solid #edf2fb;
  background: white;
  z-index: 0;
}
.hoverWrap.bottom .hoverProfile:after {
  top: -6px;
  left: 14%;
  margin-left: -10px;
  -moz-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.hoverWrap.top .hoverProfile:after {
  bottom: -6px;
  left: 14%;
  margin-left: -10px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hoverWrap.right .hoverProfile:after {
  top: 37px;
  left: -6px;
  margin-top: -10px;
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.hoverWrap.left .hoverProfile:after {
  top: 37px;
  right: -6px;
  margin-top: -10px;
  -moz-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.hoverProfile .addressAndCheck {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}
.hoverProfile .addressWrapper {
  background-color: #f9f9f9;
  padding: 4px 6px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.hoverProfile .addressWrapper img {
  width: 13px;
  height: 13px;
  opacity: 0.3;
  margin-right: 6px;
}
.hoverProfile .address {
  font-size: 12px;
  color: #ababab;
}
.loadingText {
  text-align: center;
  padding: 2px;
  font-size: 13px;
}
.noMargin {
  margin: 0 !important;
}
.onClickOutsideMobile {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
} */

/* UPCOMING MATCHES TAB */

/* #nextUpProposal {
  margin-top: 0px;
  border-radius: 8px;
  background: #1f2251;
  box-shadow:  -6px 6px 14px #1a1c43, 
               6px -6px 14px #24285f
} */

#nextUpCol {
  display: flex;
  /* align-self: center; TODO: WHEN THERE'S 0 other proposals*/
}

/* DEFAULT PROPOSAL */

/* .defaultProposalCard.card {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #1f2251;
  z-index: 999;
  box-shadow:  -6px 6px 14px #1a1c43, 
                6px -6px 14px #24285f

} */

.defaultProposalCard .card-body {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  /* border-style: solid;
  background: #1f2251;
  z-index: 999;
  box-shadow:  -6px 6px 14px #1a1c43, 
                6px -6px 14px #24285f */
}

/* #proposalTab .card {
  border-radius: 4px;
  background: linear-gradient(145deg, #212457, #1c1f49);
  box-shadow:  18px 18px 21px #1a1c43, 
               -18px -18px 21px #24285f;
} */

/* .proposalCard.card {
  margin-top: 0px;
  display: inherit;
  border-radius: 8px;
  background: #1f2251;
  box-shadow:  -6px 6px 14px #1a1c43, 
               6px -6px 14px #24285f
} */

/* .proposalCard .card {
  margin-top: 0px;
  display: inherit;
  border-radius: 0px;
  background: #1f2251;
  box-shadow: none;
} */

/* GENERAL PROPOSAL */

.proposalCard.card {
  margin-top: 0px;
}

/* #proposalRow {
  margin-right: 0px;
}

#restProposalsList {
  display: flex;
}

#restProposals.card {
  margin-bottom: 15px;
}

#restProposals .proposalCard.card {
  margin-bottom: 10px;
} */

/* #restProposalsCard .proposalCard {
  transform: scale(0.92);
}

#restProposalsCard {
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #1f2251;
  box-shadow: inset 6px 6px 12px #0f1128, inset -6px -6px 12px #2f337a;
}

#restProposalsCard h6 {
  font-size: 18px;
  margin-bottom: 0px;
} */

/* #otherProposalsHeader > h6 > i {
  font-weight: 700;
  margin-right: 10px;
} */

h6.category-social {
  display: flex;
  /* align-content: center; */
  /* justify-content: center; */
  align-items: center;
}

#restProposalsCard h3:not(#proposalUpvoteCount) {
  font-size: 16px;
  margin-bottom: 0px;
  /* font-weight: 700; */
}

/* #defaultUpvoteCount {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 700;
} */

/* #defaultUpvoteCount .fa-arrow-up {
  font-size: 1em;
  text-transform: uppercase;
} */

#proposalUpvoteCount .fa-arrow-up {
  font-size: 1.5em;
}

#proposalUpvoteCount .fa-arrow-down {
  font-size: 1.5em;
}

/* #noOtherProposalsMessage {
  font-size: 20px;
} */

/* #proposalPromptText {
  font-weight: 700;
  font-size: 1.5em;
  text-align: end;
} */

#proposalPromptButton {
  /* padding: 0px; */
  padding: 0px 10px 0px 30px;
  /* TODO PADDING */
  background-image: none;
  background-color: transparent;
}

#proposalPromptButton:hover {
  /* padding: 0px; */
  padding: 0px 10px 0px 30px;
  background-image: none;
  background-color: transparent;
}
/* 
#otherProposalsHeader .btn {
  background-image: none;
  background-color: transparent;
}

#otherProposalsHeader .btn:hover {
  background-image: none;
  background-color: transparent;
} */

button#proposalPromptButton.btn.btn-secondary {
  background-color: transparent;
  text-align: left;
  text-transform: unset;
  font-weight: 700;
}

#proposalPromptButton {
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
}

button#proposalPromptButton.btn:hover.btn-secondary {
  background-color: transparent !important;
  background-image: none;
}

/* button#proposalPromptButton.btn.btn-secondary:hover{
  background-color:transparent !important;
} */

button#proposalPromptButton.btn:active.btn-secondary {
  /* background-color:red !important; */
  background-image: none;
}

button#proposalPromptButton.btn:hover.btn-secondary {
  background-color: transparent;
  background: none;
  background-image: none;
  text-align: left;
  text-transform: unset;
  font-weight: 700;
}

div.collapse.show h3.category-social {
  /* font-size: unset; */
}

.category-social input {
  font: inherit;
}

#proposalPromptHeader.card-header.collapse.show {
  font-size: unset;
}

/* PROPOSAL SUBMIT BUTTON */
.btn-primary.btn-simple {
  /* color: #e14eca;
  border-color: #e14eca; */
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none;
  /* font: inherit; */
  font-weight: 700;
}

.btn-primary.btn-simple:hover {
  /* color: #e14eca;
  border-color: #e14eca; */
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: 1px;
  /* font: inherit; */
  font-weight: 1000;
}

.card {
  background: #1f2251;
  border: 0;
  position: relative;
  width: 100%;
  /* box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-top: 30px;
}

.card[data-background-color='gray'] {
  background-color: #344675;
}
.card[data-background-color='purple'] {
  background-color: #e14eca;
}
.card[data-background-color='red'] {
  /* background-color: #fd5d93; */
  background-color: #5dd6fd3d;
}
.card[data-background-color='orange'] {
  /* background-color: #ff8d72; */
  background-color: #ff7272;
}
.card[data-background-color='blue'] {
  background-color: #1d8cf8;
}
.card[data-background-color='green'] {
  background-color: #00d9ae;
}
.card[data-background-color='black'] {
  background-color: #212529;
}

.card-body {
  padding: 1.25rem;
}
#proposalSubmitTitle {
  color: white;
}

/* NEXT UP PROPOSAL DISPLAY */

/* #upNextCard{
  margin-top: 10px;
  border-radius: 8px;
  background: linear-gradient(145deg, #1c1f49, #212457);
  box-shadow:  6px 6px 12px #10122a, 
               -6px -6px 12px #2e3278;
}



#upNextCard h6 {
  font-size: 18px;
  margin-top: 0px;
}

#upNextCard .card {
  margin-top: 20px;
  box-shadow: none;
} */

h6.category-social-upnext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes fa-blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}
.fa-blink {
  -webkit-animation: fa-blink 2s linear infinite;
  -moz-animation: fa-blink 2s linear infinite;
  -ms-animation: fa-blink 2s linear infinite;
  -o-animation: fa-blink 2s linear infinite;
  animation: fa-blink 2s linear infinite;
}

/* PROPOSAL HEADER  */
/* #proposalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
} */

/* PROPOSAL FOOTER  */
/* PROPOSAL FORMAT BADGE */

/* .defaultProposalFooter.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* h3#defaultUpvoteCount {
  font-size: 1.4em;
  margin-bottom: 0px;
} */

.badge-twitter {
  color: #ffffff;
  background-color: #1da1f2;
}

.badge-youtube {
  color: #ffffff;
  background-color: #c4302b;
}

.badge-gif {
  color: #ffffff;
  background-color: #2dce89;
}

.badge-image {
  color: #ffffff;
  background-color: #ff8d72;
}

.badge-tiktok {
  color: #ffffff;
  background-color: #212529;
}

/* PROPOSAL SUBMITTER SECTION */
#proposalDefaultSubmitter {
  width: 20%;
  height: 20%;
}

.card .card-header {
  padding: 15px 0px 15px 15px;
  border: 0;
  color: rgba(255, 255, 255, 0.8);
}

.proposalFooter {
  display: flex;
}

/* #proposalUpvoteCount {
margin-bottom: 0px;
font-weight: 400;
} */

#proposalUpvoteCount {
  font-size: 1.75em;
  margin-bottom: 0px;
  font-weight: 400;
}

#proposalUpvoteCount i {
  font-weight: 700;
}

.proposalButton {
  z-index: 3;
}

/* .promptAddressDisplay {
  z-index: 3;
} */

/* PROPOSAL PROMPT */

/* #proposalPrompt {
  background-color: #1d8cf824;
  margin-top: 0px;
  margin-bottom: 30px;
}

#proposalPrompt .dropdown-toggle[aria-expanded='true'] {
  background-color: #ffffff;
  margin-top: 20px;
  padding-bottom: 20px;
}

#proposalPromptHeader {
  padding: 10px;
  display: flex;
  justify-content: center;
}

#proposalPrompt .collapse.show {
  padding-bottom: 15px;
} */

/* h6.category-social-toggled {
  display: flex;
  flex-direction: column;
  align-content: center;
} */

/* #collapsePromptCard{
  padding-right: 0px;
  padding-left: 0px;
} */

#collapseProposalIcon {
  color: white;
  /* font-size: 35px; */
}

#react-select-2-input {
  width: 150px;
  /* TODO MAKE RELATIVE */
}

h3.category-social {
  font-weight: 700;
}

h6.category-social {
  display: flex;
  align-content: center;
}

.css-bg1rzq-control {
  width: 200px;
}

.css-1szy77t-control {
  width: 200px;
}

.css-kj6f9i-menu {
  color: black;
}


/* .category-social .badge {
  display: inline-flex;
} */

.proposalCard h2 {
  margin-bottom: 0px;
}

/* PROPOSAL PROMPT WARNING */

.btn-tiktok.btn-link {
  color: #ffffff;
}

.btn-tiktok.btn-link:hover,
.btn-tiktok.btn-link:focus,
.btn-tiktok.btn-link:active {
  background-color: transparent !important;
  background-image: none !important;
  color: #69c9d0 !important;
  text-decoration: none;
  text-shadow: 2px 2px #ee1d52;
  box-shadow: none;
}

.btn-image.btn-link {
  color: #000000;
}

.btn-image.btn-link:hover,
.btn-image.btn-link:focus,
.btn-image.btn-link:active {
  background-color: transparent !important;
  background-image: none !important;
  color: #ffffff !important;
  text-decoration: none;
  box-shadow: none;
}

.btn-upvote.btn-link {
  color: #ffffff;
}

.btn-upvote.btn-link:hover,
.btn-upvote.btn-link:focus,
.btn-upvote.btn-link:active {
  background-color: transparent !important;
  background-image: none !important;
  color: #2dce89 !important;
  text-decoration: none;
  box-shadow: none;
}

.btn-downvote.btn-link {
  color: #ffffff;
}

.btn-downvote.btn-link:hover,
.btn-downvote.btn-link:focus,
.btn-downvote.btn-link:active {
  background-color: transparent !important;
  background-image: none !important;
  color: #f5365c !important;
  text-decoration: none;
  box-shadow: none;
}

/* article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: ; } */

/* .card .card-footer div {
} */

/* .threeboxProfileHover__style_hoverWrap threeboxProfileHover__style_bottom {
  display: none;
} */

/* threeboxProfileHover__style_hoverProfile  */

/* #main-tabs-card > div.card-body > div > div.tab-pane.active > div > div:nth-child(3) > div > div > div.author > div {



  /* LEADERBOARD */

.leaderBoardCard {
  margin-top: 0px;
}

/* GRAPH IQ

.graphiq {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: flex;
}
.graphiq__graph-layout {
  -webkit-box-flex: 1;
          flex: 1;
  overflow-x: hidden;
}
.graphiq__graph-values {
  font-size: 0.5em;
  padding-right: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding-bottom: 1.25em;
  text-align: right;
  font-weight: bold;
}
.graphiq__line {
  fill: none;
  stroke-miterlimit: 10;
}
.graphiq__y-division {
  fill: none;
  stroke-miterlimit: 10;
  z-index: -1;
  -webkit-transition: 0.25s ease-in;
  transition: 0.25s ease-in;
}
.graphiq__graph-dot {
  -webkit-transition: stroke-width ease-in-out 0.2s;
  transition: stroke-width ease-in-out 0.2s;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.graphiq__value-dialog {
  display: block;
  position: absolute;
  background: red;
  color: white;
  font-size: 0.5em;
  line-height: 0;
  padding: 0;
  font-weight: bold;
  -webkit-animation: float 0.3s ease-out forwards;
          animation: float 0.3s ease-out forwards;
}

@-webkit-keyframes float {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

@keyframes float {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
} */
