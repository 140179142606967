$primary-color: #00ff77;
$secondary-color: #41a6e9;
$background-color: transparent;
$text-color: #eeeeee;
$highlight-color: #eaff00;
$green-color: #ffc400;

/* For miniaturized mode (changes requested) */
.miniaturizedContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: black; /* Black font color in miniaturized mode */

  .sbtGrid {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .sbtItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    background-color: #0a6afb;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 150px;
    text-align: center;

    &:hover {
      background-color: #e0e0e0;
      transform: translateY(-2px);
    }

    .sbtImage {
      width: 100%;
      img {
        max-width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }

    #miniSbtName {
      font-weight: bold;
      margin-top: 10px;
      color: black !important;
    }

    #miniSbtAddress {
      font-size: 0.8em;
      color: black;
      margin-top: 5px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    .sbtItem {
      width: 100%;
      max-width: 300px;
      margin: 10px auto;
    }
  }

  .sectionTitle {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.8em;
    font-weight: bold;
    color: black;
    border-bottom: 2px solid #00000052;
    padding-bottom: 10px;
  }
}

.sbtListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Share Tech Mono', monospace;
  color: $text-color;
  background-color: $background-color;
}

.sbtItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid $secondary-color;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  max-width: 800px;
  text-align: left;
  background-color: rgba($secondary-color, 0.6);
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba($secondary-color, 0.8);
    transform: translateY(-2px);
  }

  .sbtImage img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid $primary-color;
  }

  .sbtInfo {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    flex-grow: 1;

    .sbtName {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $primary-color !important;
      font-weight: bold;
      font-size: 1.4em;
      margin-bottom: 5px;
    }

    .sbtAddress {
      color: $highlight-color;
      font-weight: bold;
      font-size: 0.9em;
      margin-bottom: 10px;
    }

    .sbtDescription {
      color: $text-color !important;
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 10px;
    }

    .sbtNetMinted {
      color: $green-color;
      font-weight: 600;
      font-size: 1.1em;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: $text-color;
}

.expandIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $primary-color;
  font-size: 1.2em;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

.refreshButton,
.clearCacheButton {
  padding: 10px 20px;
  font-size: 1.15em;
  font-weight: 600;
  color: $text-color;
  background-color: $primary-color;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }

  &:disabled {
    background-color: lighten($secondary-color, 20%);
    cursor: not-allowed;
  }
}

.sectionTitle {
  width: 100%;
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.8em;
  font-weight: bold;
  color: $primary-color;
  text-align: left;
  border-bottom: 2px solid $primary-color;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .sbtListContainer {
    padding: 10px;
  }

  .sbtItem {
    flex-direction: row !important;
    align-items: center;
    padding: 15px 10px;

    .sbtImage img {
      width: 120px;
      height: 120px;
    }

    .sbtInfo {
      margin-left: 0;
      margin-top: 15px;
      text-align: center;
      color: black !important;
    }
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .refreshButton,
  .clearCacheButton {
    margin-bottom: 10px;
    width: 100%;
  }

  .sectionTitle {
    font-size: 1.5em;
    text-align: center;
    color: black !important;
  }
}

#featuredSBTsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

/* Added styles for lock icon and filter area */
.lockIcon {
  // margin-left: 8px;
  margin-right: 10px;
  font-size: 1.5wm;
  color: #ffffff;
  opacity: 0.6;
}

.filterContainer {
  display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      width: 100%;
      max-width: 800px;
      margin-top: 20px;
}

.filterLabel {
  font-size: 1em;
  color: $text-color;
  font-weight: 600;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    accent-color: $primary-color;
  }
}
