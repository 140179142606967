@import '../UserPage/UserPage.module.scss';

.simUserPage {
  @extend .userPage;

  .header {
    .avatarContainer {
      margin-right: 15px;
    }

    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }

    #userPageName {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      margin: 0;
    }

    .simulatedBadge {
      color: yellow;
      margin-left: 10px;
      font-size: 1.5rem;
      cursor: help;
    }
  }

  .content {
    display: flex;
    gap: 20px;
  }

  .leftColumn, .rightColumn {
    flex: 1;
  }

  .surveySection, .adviceSection {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 15px;
    }
  }

  .questionPreview {
    background: linear-gradient(145deg, #1e1e4a, #2a2a6a);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    color: white;

    .questionTitle {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .binaryAnswer, .ratingAnswer, .multichoiceAnswer, .freeformAnswer {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-weight: bold;
  }

  .ratingAnswer {
    display: flex;
    align-items: center;

    .ratingBar {
      height: 10px;
      background-color: #4DFFA4;
      border-radius: 5px;
      margin-right: 10px;
    }
  }

  .multichoiceAnswer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .choiceItem {
      background-color: #4DFFA4;
      color: #171941;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 0.9em;
    }
  }

  .freeformAnswer {
    font-style: italic;
  }

  .adviceItem {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;

    h3 {
      font-size: 1.2em;
      margin-bottom: 10px;
    }

    .highlightedText {
      font-size: 1.2em;
      font-weight: bold;
      color: #4DFFA4;
      line-height: 1.4;
    }
  }

  .actionButtons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .collapseButton {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #5e72e4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#5e72e4, 10%);
    }
  }

  .extraInfo {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
  }

  .influencingDocuments {
    margin-top: 15px;
    
    .document {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 10px;
    }
    
    .debates {
      margin-top: 10px;
      padding-left: 20px;
      
      .debate {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 5px;
        padding: 8px;
        margin-bottom: 5px;
      }
    }
  }

  .addDocument, .addProblem {
    margin-top: 20px;

    input, textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.3);
      color: white;
      border-radius: 5px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    button {
      background-color: #4DFFA4;
      color: #171941;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#4DFFA4, 10%);
      }
    }
  }

  // Modal styles
  .modalContent {
    background-color: #171941;
    color: white;
  }

  .modalHeader {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .modalBody {
    .modalSummary, .modalQuestions, .modalAdvice {
      margin-bottom: 30px;
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .simUserPage {
    .content {
      flex-direction: column;
    }

    .header {
      flex-direction: column;
      align-items: center;

      #userPageName {
        font-size: 1.75rem;
        text-align: center;
        margin-top: 10px;
      }
    }

    .collapseButton {
      width: 100%;
    }

    .addDocument, .addProblem {
      input, textarea, button {
        width: 100%;
      }
    }
  }
}