.container {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backButton {
  background: transparent;
  font-weight: 600;
  padding: 15px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
  margin-bottom: 20px;
}

#showResultsButton {
  background: transparent;
  font-weight: 600;
  padding: 15px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
  margin-bottom: 20px;
}

#buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  .button {
    margin-top: 10px;
  }
}

#createGroupPopout {
  // display:  ;
}

.sbtGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.featuredTitle {
  text-align: center;
  margin-top: 20px;
  color: white;
  font-size: 2em;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
}

#loadingIcon {
  display: block;
  margin: 20px auto;
  font-size: 2em;
  color: white;
}
