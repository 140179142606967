/* ---------------------------------------------------- GreetingModal.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #rulesModal {
    transform: none;
    // display: flex;
    // flex-direction: column-reverse;
    // margin-top: 35px;

    #rulesModalCard {
 
      justify-content: flex-end;
      // min-height: 500px;
    }

    #rulesModalCardBody {
      height: 450px; // TODO" ?
    }

    #optOutMainText {
      font-size: .9em;
    }

    #optOutDetailText {
      font-size: .75em;
      opacity: .5;
    }

    #optOutWeb3Text {
      font-size: 1em;
    }
  
    #closeModalIcon {
      // padding-top: 10px;
      font-size: 2.5em;
      // color: white;
      color: #1d8cf8;
      // opacity: .75;
      margin-bottom: 20px;
      
    }
  }

  #closeModalIcon {
    // padding-top: 10px;
    font-size: 1.5em;
    // color: white;
    color: #1d8cf8;
    // opacity: .75;
    margin-bottom: 5px;
    
    
  }

  #betaInfoEmbed {
    /* font-family: Poppins; */
    display: flex;
    flex-direction: column;
    // align-items: center;
    /* border-style: solid; */
    /* border-color: #1d8cf8; */
    /* border-width: 1.5px; */
    background-color: #0f0f1300;
    border-radius: 0px;
    /* margin-bottom: 10px; */
    width: 100%;
    padding: 0px;

  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
    #optOutMetrics {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      padding-left: 5px;
      /* align-items: center; */
      /* align-content: center; */
      /* justify-content: center; */
      /* justify-items: center; */
      /* margin-bottom: 10px; */
      // padding-left: 10px;
      // padding-top: 15px;

      #optOutLabel {
        display: inline-flex;
        color: #ffffff33;
        // padding-top: 5px;
      }

      #optOutSelect {
        align-self: center;
        margin-top: 0px;
        transform: scale(0.7);
        opacity: .6; // TODO opacity
      }
      
    }

  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
  #optOutMetricsSidebar {
  #optOutMetrics {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
    /* align-items: center; */
    /* align-content: center; */
    /* justify-content: center; */
    /* justify-items: center; */
    /* margin-bottom: 10px; */
    // padding-left: 10px;
    // padding-top: 15px;

    #optOutLabel {
      padding-top: 10px;

      // padding-bottom: 5px;
      display: flex;
      flex-direction: row;
      color: #ffffff33;
      width: 100%;
    }

    #optOutSelect {
      align-self: center;
      margin-top: 0px;
      transform: scale(0.7);
      opacity: .6; // TODO opacity
    }
    
  }}

    #optOutMainText {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      justify-self: center;
      font-family: Poppins;
      font-weight: 600;
      text-transform: uppercase;
      // font-size: .85em;
      font-size: .8em;
      color: #ffff00b0;
    }

    #metricsInfoIcon {
      // display: inline;
      align-self: flex-start;
      color: #ffd60060;
      font-size: 1.20em;
      padding-left: 2px;
    }
    

    #optOutDetailText {
      display: inline-flex;
      // font-weight: 600;
      // text-transform: uppercase;
      opacity: .5;
      font-size: .75em;
    }

  #betaTabSideBar {
    width: 45%;
    display: flex;
    flex-direction: column;
    background: #0000005e;
    border-top-right-radius: 5px;

  }

  #betaSidebarDisappeared {
    display: none;
  }

  #explainerAndUpdates {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

    // #optOutMetrics {
    //   margin-top: 0px;
    //   display: flex;
    //   flex-direction: row;
    //   /* align-items: center; */
    //   /* align-content: center; */
    //   /* justify-content: center; */
    //   /* justify-items: center; */
    //   /* margin-bottom: 10px; */
    //   padding-left: 0px;
    //   padding-top: 0px;
    //   padding-left: 5px;

    //   #optOutLabel {
    //     display: inline-flex;
    //   }

    //   #optOutLabelEmbed {
    //     display: inline-flex;


    //   }

    //   #optOutSelect {
    //     align-self: center;
    //     margin-top: 0px;
    //   }
      
    // }



    #emailFormLabel {
      align-self: flex-start;
      font-size: .95em;
      margin: 0;
      text-transform: uppercase;
      color: #0088ffad;
      font-weight: 600;
      background: transparent;

      #noSpam {
        display: inline;
        opacity: .35;
      }
    }

    #emailSubjects {
      display: flex;
      flex-direction: column;
      padding-left: 3px;
      padding-bottom: 5px;

      .emailSubject {
        display: flex;
        flex-direction: column;
        font-size: .75em;
        // color: #ffd600; 
        // color: #00f2c3; 
        // color: #ffd600;
        // color: #1d8cf8;
        // color: #5cf81d7a;
        // color: #ffffff36;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    #enterEmail {
      // border-style: solid;
      // border-radius: 3px;
      // border-width: 1px;
      // border-color: #1d8cf800;
      // background: #00000052;
      // color: #8e7979;
      // font-size: 1em;
      // /* opacity: 1; */
      // margin-bottom: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 25px;
      border-style: solid;
      border-radius: 3px;
      border-width: 1px;
      border-color: #1d8cf800;
      // background: #0c130d;
      background: #ffffff;
      color: #ffffff;
      font-size: 1em;
      /* opacity: 1; */
      margin-bottom: 0px;
  

      #emailInput {
        // opacity: .3;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
        color: #00abff;
        font-size: .6em;
      }
    }


  }

  #explainerAndUpdates {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0;
    margin-top: 10px;
    background: #00000000;
    width: 100%;
    padding: 0px;



    #getUpdates {
      // width: 50%;
      // padding-left: 5px;
      // padding-right: 5px;
      // color: white;
      // padding-top: 10px;
      // padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      justify-content: center;
      // width: 40%; // TODO: when get updates is only thing on beta tab sidebar
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      color: white;
      padding-top: 5px;
      padding-bottom: 10px;
      padding-left: 5px;
      padding-right: 5px;
      // background: #0000005e;
      // border-top-right-radius: 5px;
      background: transparent;
      // border-top-right-radius: 5px;
      opacity: .6; // TODO opacity
      height: 50%;
    }

    #siteExplainer {
      width: 65%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // background-image: url(/static/media/explainer_rabbit_button_full_green.png);
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: center center;
      background: transparent;
      border-style: solid;
      border-color: transparent;
      border-width: 0px;
      height: 155px;
    }
  }




  #exitButton {
    // width: 50%;
    display: flex;
    // align-items: center;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
  }
}
}

@media (min-width: 320px) and (max-width: 465px) {

    #rulesModal {
        transform: none;
        // display: flex;
        // flex-direction: column-reverse;
        // margin-top: 35px;

        #rulesModalCard {
     
          justify-content: flex-end;
          // min-height: 500px;
        }

        #rulesModalCardBody {
          height: 500px; // TODO" ?
        }

        #optOutMainText {
          font-size: 1em;
        }
    
        #optOutDetailText {
          font-size: .75em;
        }
    
        #optOutWeb3Text {
          font-size: 1em;
        }
      
        #closeModalIcon {
          // padding-top: 10px;
          font-size: 2.5em;
          color: white;
          opacity: .75;
        }
      }

      #closeModalIcon {
        // padding-top: 10px;
        font-size: 2em;
        // color: white;
        color: #1d8cf8;
        // opacity: .75;
        margin-bottom: 10px;
        
        
      }

      #betaInfoEmbed {
        /* font-family: Poppins; */
        display: flex;
        flex-direction: column;
        // align-items: center;
        /* border-style: solid; */
        /* border-color: #1d8cf8; */
        /* border-width: 1.5px; */
        background-color: #0f0f1300;
        border-radius: 0px;
        /* margin-bottom: 10px; */
        width: 100%;
        padding: 0px;
    
      // NOTE: this should be used when 
      // opt-out option is above the email 
      // updates sidebar / explainer button
        #optOutMetrics {
          margin-top: 0px;
          display: flex;
          flex-direction: row;
          padding-left: 5px;
          /* align-items: center; */
          /* align-content: center; */
          /* justify-content: center; */
          /* justify-items: center; */
          /* margin-bottom: 10px; */
          // padding-left: 10px;
          // padding-top: 15px;
    
          #optOutLabel {
            display: inline-flex;
            color: #ffffff33;
            // padding-top: 5px;
          }
    
          #optOutSelect {
            align-self: center;
            margin-top: 0px;
            transform: scale(0.7);
            opacity: .6; // TODO opacity
          }
          
        }
    
      // NOTE: this should be used when 
      // opt-out option is above the email 
      // updates sidebar / explainer button
      #optOutMetricsSidebar {
      #optOutMetrics {
        margin-top: 0px;
        display: flex;
        flex-direction: row;
        padding-left: 0px;
        padding-right: 0px;
        /* align-items: center; */
        /* align-content: center; */
        /* justify-content: center; */
        /* justify-items: center; */
        /* margin-bottom: 10px; */
        // padding-left: 10px;
        // padding-top: 15px;
    
        #optOutLabel {
          padding-top: 10px;
    
          // padding-bottom: 5px;
          display: flex;
          flex-direction: row;
          color: #ffffff33;
          width: 100%;
        }
    
        #optOutSelect {
          align-self: center;
          margin-top: 0px;
          transform: scale(0.7);
          opacity: .6; // TODO opacity
        }
        
      }}
    
        #optOutMainText {
          display: flex;
          flex-direction: row;
          align-items: center;
          align-self: center;
          justify-self: center;
          font-family: Poppins;
          font-weight: 600;
          text-transform: uppercase;
          // font-size: .85em;
          font-size: .8em;
          color: #ffff00b0;
        }
    
        #metricsInfoIcon {
          // display: inline;
          align-self: flex-start;
          color: #ffd60060;
          font-size: 1.20em;
          padding-left: 2px;
        }
        
    
        #optOutDetailText {
          display: inline-flex;
          // font-weight: 600;
          // text-transform: uppercase;
          opacity: .5;
          font-size: .75em;
        }
    
      #betaTabSideBar {
        width: 45%;
        display: flex;
        flex-direction: column;
        background: #0000005e;
        border-top-right-radius: 5px;
    
      }
    
      #betaSidebarDisappeared {
        display: none;
      }
    
      #explainerAndUpdates {
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
    
        // #optOutMetrics {
        //   margin-top: 0px;
        //   display: flex;
        //   flex-direction: row;
        //   /* align-items: center; */
        //   /* align-content: center; */
        //   /* justify-content: center; */
        //   /* justify-items: center; */
        //   /* margin-bottom: 10px; */
        //   padding-left: 0px;
        //   padding-top: 0px;
        //   padding-left: 5px;
    
        //   #optOutLabel {
        //     display: inline-flex;
        //   }
    
        //   #optOutLabelEmbed {
        //     display: inline-flex;
    
    
        //   }
    
        //   #optOutSelect {
        //     align-self: center;
        //     margin-top: 0px;
        //   }
          
        // }
    
    
    
        #emailFormLabel {
          align-self: flex-start;
          font-size: .95em;
          margin: 0;
          text-transform: uppercase;
          color: #0088ffad;
          font-weight: 600;
          background: transparent;
    
          #noSpam {
            display: inline;
            opacity: .35;
          }
        }
    
        #emailSubjects {
          display: flex;
          flex-direction: column;
          padding-left: 3px;
          padding-bottom: 5px;
    
          .emailSubject {
            display: flex;
            flex-direction: column;
            font-size: .75em;
            // color: #ffd600; 
            // color: #00f2c3; 
            // color: #ffd600;
            // color: #1d8cf8;
            // color: #5cf81d7a;
            // color: #ffffff36;
            color: #ffffff;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
    
        #enterEmail {
          // border-style: solid;
          // border-radius: 3px;
          // border-width: 1px;
          // border-color: #1d8cf800;
          // background: #00000052;
          // color: #8e7979;
          // font-size: 1em;
          // /* opacity: 1; */
          // margin-bottom: 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 25px;
          border-style: solid;
          border-radius: 3px;
          border-width: 1px;
          border-color: #1d8cf800;
      // background: #0c130d;
      background: #ffffff;
          color: #ffffff;
          font-size: 1em;
          /* opacity: 1; */
          margin-bottom: 0px;
      
    
          #emailInput {
            // opacity: .3;
            border: none;
            padding-left: 10px;
            padding-top: 10px;
            color: #00abff;
            font-size: 0.75em;
          }
        }
    
    
      }
    
      #explainerAndUpdates {
    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-top: 0;
        margin-top: 10px;
        background: #00000000;
        width: 100%;
        padding: 0px;
    
    
    
        #getUpdates {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          padding-left: 0px;
          padding-right: 0px;
          color: white;
          padding-top: 10px;
          padding-bottom: 15px;
          padding-left: 10px;
          padding-right: 10px;
          background: transparent;
          opacity: .6;
          height: 50%;
        }
    
        #siteExplainer {
          width: 65%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          // background-image: url(/static/media/explainer_rabbit_button_full_green.png);
          // background-size: cover;
          // background-repeat: no-repeat;
          // background-position: center center;
          background: transparent;
          border-style: solid;
          border-color: transparent;
          border-width: 0px;
          height: 155px;
        }
      }
    
    
    
    
      #exitButton {
        // width: 50%;
        display: flex;
        // align-items: center;
        align-items: flex-end;
        justify-content: center;
        padding: 10px;
      }
    }
      
}


@media (min-width: 466px) and (max-width: 768px) { 
  #rulesModal {
    transform: none;
    // display: flex;
    // flex-direction: column-reverse;
    // margin-top: 35px;

    #rulesModalCard {
 
      justify-content: flex-end;
      // min-height: 500px;
    }

    #rulesModalCardBody {
      height: 575px; // TODO" ?
    }

    #optOutMainText {
      font-size: 1.15em;
    }

    #optOutDetailText {
      font-size: 1em;
    }

    #optOutWeb3Text {
      font-size: 1.25em;
    }
  
    #closeModalIcon {
      // padding-top: 10px;
      font-size: 2.5em;
      color: white;
      opacity: .75;
    }
  }

  #closeModalIcon {
    // padding-top: 10px;
    font-size: 2.25em;
    // color: white;
    color: #1d8cf8;
    // opacity: .75;
    margin-bottom: 10px;
    
    
  }

  #betaInfoEmbed {
    /* font-family: Poppins; */
    display: flex;
    flex-direction: column;
    // align-items: center;
    /* border-style: solid; */
    /* border-color: #1d8cf8; */
    /* border-width: 1.5px; */
    background-color: #0f0f1300;
    border-radius: 0px;
    /* margin-bottom: 10px; */
    width: 100%;
    padding: 0px;

  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
    #optOutMetrics {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      padding-left: 5px;
      /* align-items: center; */
      /* align-content: center; */
      /* justify-content: center; */
      /* justify-items: center; */
      /* margin-bottom: 10px; */
      // padding-left: 10px;
      // padding-top: 15px;

      #optOutLabel {
        display: inline-flex;
        color: #ffffff33;
        // padding-top: 5px;
      }

      #optOutSelect {
        align-self: center;
        margin-top: 0px;
        transform: scale(0.7);
        opacity: .6; // TODO opacity
      }
      
    }

  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
  #optOutMetricsSidebar {
  #optOutMetrics {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    padding-right: 0px;
    /* align-items: center; */
    /* align-content: center; */
    /* justify-content: center; */
    /* justify-items: center; */
    /* margin-bottom: 10px; */
    // padding-left: 10px;
    // padding-top: 15px;

    #optOutLabel {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffffff33;
      width: 100%;
    }

    #optOutSelect {
      align-self: center;
      margin-top: 0px;
      transform: scale(0.7);
      opacity: .6; // TODO opacity
    }
    
  }}

    #optOutMainText {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      justify-self: center;
      font-family: Poppins;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.35em;
      color: #ffff00b0;
    }

    #metricsInfoIcon {
      align-self: flex-start;
      color: #ffd60060;
      font-size: 1.4em;
      padding-left: 4px;
    }
    

    #optOutDetailText {
      display: inline-flex;
      // font-weight: 600;
      // text-transform: uppercase;
      opacity: .5;
      font-size: .75em;
    }

  #betaTabSideBar {
    width: 40%;
    display: flex;
    flex-direction: column;
    background: #0000005e;
    border-top-right-radius: 5px;

  }

  #betaSidebarDisappeared {
    display: none;
  }

  #explainerAndUpdates {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

    // #optOutMetrics {
    //   margin-top: 0px;
    //   display: flex;
    //   flex-direction: row;
    //   /* align-items: center; */
    //   /* align-content: center; */
    //   /* justify-content: center; */
    //   /* justify-items: center; */
    //   /* margin-bottom: 10px; */
    //   padding-left: 0px;
    //   padding-top: 0px;
    //   padding-left: 5px;

    //   #optOutLabel {
    //     display: inline-flex;
    //   }

    //   #optOutLabelEmbed {
    //     display: inline-flex;


    //   }

    //   #optOutSelect {
    //     align-self: center;
    //     margin-top: 0px;
    //   }
      
    // }



    #emailFormLabel {
      align-self: flex-start;
      font-size: .95em;
      margin: 0;
      text-transform: uppercase;
      color: #0088ffad;
      font-weight: 600;
      background: transparent;
      margin-top: 10px;

      #noSpam {
        display: inline;
        opacity: .35;
      }
    }

    #emailSubjects {
      display: flex;
      flex-direction: column;
      padding-left: 3px;
      padding-bottom: 5px;

      .emailSubject {
        display: flex;
        flex-direction: column;
        font-size: .75em;
        // color: #ffd600; 
        // color: #00f2c3; 
        // color: #ffd600;
        // color: #1d8cf8;
        // color: #5cf81d7a;
        // color: #ffffff36;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    #enterEmail {
      // border-style: solid;
      // border-radius: 3px;
      // border-width: 1px;
      // border-color: #1d8cf800;
      // background: #00000052;
      // color: #8e7979;
      // font-size: 1em;
      // /* opacity: 1; */
      // margin-bottom: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 25px;
      border-style: solid;
      border-radius: 3px;
      border-width: 1px;
      border-color: #1d8cf800;
      // background: #0c130d;
      background: #ffffff;
      color: #ffffff;
      font-size: 1em;
      /* opacity: 1; */
      margin-bottom: 0px;
  

      #emailInput {
        border: none;
        padding-left: 10px;
        padding-top: 10px;
        color: #00abff;
        font-size: .85em;
      }
    }


  }

  #explainerAndUpdates {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0;
    margin-top: 10px;
    background: #00000000;
    width: 100%;
    padding: 0px;



    #getUpdates {
      // width: 50%;
      // padding-left: 5px;
      // padding-right: 5px;
      // color: white;
      // padding-top: 10px;
      // padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      justify-content: center;
      // width: 40%; // TODO: when get updates is only thing on beta tab sidebar
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      color: white;
      padding-top: 10px;
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
      // background: #0000005e;
      // border-top-right-radius: 5px;
      background: transparent;
      // border-top-right-radius: 5px;
      opacity: .6; // TODO opacity
      height: 50%;
      font-size: 1.2em;
    }

    #siteExplainer {
      width: 65%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // background-image: url(/static/media/explainer_rabbit_button_full_green.png);
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: center center;
      background: transparent;
      border-style: solid;
      border-color: transparent;
      border-width: 0px;
      height: 225px;
    }
  }




  #exitButton {
    // width: 50%;
    display: flex;
    // align-items: center;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
  }
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #rulesModal {
    transform: none;
    // display: flex;
    // flex-direction: column-reverse;
    // margin-top: 35px;
    // font-size: 2.5em;

    #rulesModalCard {
 
      justify-content: flex-end;
      // min-height: 500px;
    }

    #rulesModalCardBody {
      height: 650px; // TODO" ?
    }

    #optOutMainText {
      font-size: 1.5em;
    }

    #optOutDetailText {
      font-size: 1.25em;
    }

    #optOutWeb3Text {
      font-size: 1.5em;
    }
  
    #closeModalIcon {
      // padding-top: 10px;
      font-size: 2.5em;
      color: white;
      opacity: .75;
    }
  }


  #closeModalIcon {
    // padding-top: 10px;
    font-size: 2.5em;
    // color: white;
    color: #1d8cf8;
    // opacity: .75;
    margin-bottom: 15px;
    
  }


  #betaInfoEmbed {
    /* font-family: Poppins; */
    display: flex;
    flex-direction: column;
    // align-items: center;
    /* border-style: solid; */
    /* border-color: #1d8cf8; */
    /* border-width: 1.5px; */
    background-color: #0f0f1300;
    border-radius: 0px;
    /* margin-bottom: 10px; */
    width: 100%;
    padding: 0px;

  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
    #optOutMetrics {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      padding-left: 5px;
      /* align-items: center; */
      /* align-content: center; */
      /* justify-content: center; */
      /* justify-items: center; */
      /* margin-bottom: 10px; */
      // padding-left: 10px;
      // padding-top: 15px;

      #optOutLabel {
        display: inline-flex;
        color: #ffffff33;
        // padding-top: 5px;
      }

      #optOutSelect {
        align-self: center;
        margin-top: 0px;
        transform: scale(0.7);
        opacity: .6; // TODO opacity
      }
      
    }

  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
  #optOutMetricsSidebar {
  #optOutMetrics {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 10px;
    /* align-items: center; */
    /* align-content: center; */
    /* justify-content: center; */
    /* justify-items: center; */
    /* margin-bottom: 10px; */
    // padding-left: 10px;
    // padding-top: 15px;

    #optOutLabel {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffffff33;
      width: 100%;
    }

    #optOutSelect {
      align-self: center;
      margin-top: 0px;
      transform: scale(0.75);
      opacity: .6; // TODO opacity
    }
    
  }}

    #optOutMainText {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      justify-self: center;
      font-family: Poppins;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.5em;
      color: #ffff00b0;
    }

    #metricsInfoIcon {
      align-self: flex-start;
      color: #ffd60060;
      font-size: 1.4em;
      padding-left: 4px;
    }
    

    #optOutDetailText {
      display: inline-flex;
      // font-weight: 600;
      // text-transform: uppercase;
      opacity: .5;
      font-size: .75em;
    }

  #betaTabSideBar {
    width: 40%;
    display: flex;
    flex-direction: column;
    background: #0000005e;
    border-top-right-radius: 5px;

  }

  #betaSidebarDisappeared {
    display: none;
  }

  #explainerAndUpdates {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

    // #optOutMetrics {
    //   margin-top: 0px;
    //   display: flex;
    //   flex-direction: row;
    //   /* align-items: center; */
    //   /* align-content: center; */
    //   /* justify-content: center; */
    //   /* justify-items: center; */
    //   /* margin-bottom: 10px; */
    //   padding-left: 0px;
    //   padding-top: 0px;
    //   padding-left: 5px;

    //   #optOutLabel {
    //     display: inline-flex;
    //   }

    //   #optOutLabelEmbed {
    //     display: inline-flex;


    //   }

    //   #optOutSelect {
    //     align-self: center;
    //     margin-top: 0px;
    //   }
      
    // }



    #emailFormLabel {
      align-self: flex-start;
      font-size: .95em;
      margin: 0;
      text-transform: uppercase;
      color: #0088ffad;
      font-weight: 600;
      background: transparent;
      margin-top: 10px;

      #noSpam {
        display: inline;
        opacity: .35;
      }
    }

    #emailSubjects {
      display: flex;
      flex-direction: column;
      padding-left: 3px;
      padding-bottom: 5px;

      .emailSubject {
        display: flex;
        flex-direction: column;
        font-size: .75em;
        // color: #ffd600; 
        // color: #00f2c3; 
        // color: #ffd600;
        // color: #1d8cf8;
        // color: #5cf81d7a;
        // color: #ffffff36;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    #enterEmail {
      // border-style: solid;
      // border-radius: 3px;
      // border-width: 1px;
      // border-color: #1d8cf800;
      // background: #00000052;
      // color: #8e7979;
      // font-size: 1em;
      // /* opacity: 1; */
      // margin-bottom: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 35px;
      border-style: solid;
      border-radius: 3px;
      border-width: 1px;
      border-color: #1d8cf800;
      // background: #0c130d;
      background: #ffffff;
      color: #ffffff;
      font-size: 1em;
      /* opacity: 1; */
      margin-bottom: 0px;
  

      #emailInput {
        border: none;
        padding-left: 10px;
        padding-top: 10px;
        color: #00abff;
        font-size: .85em;
      }
    }


  }

  #explainerAndUpdates {
    flex-wrap: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0px;
    margin-top: 10px;
    background: #00000000;
    width: 100%;
    padding: 0px;



    #getUpdates {
      // width: 50%;
      // padding-left: 5px;
      // padding-right: 5px;
      // color: white;
      // padding-top: 10px;
      // padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      justify-content: center;
      // width: 40%; // TODO: when get updates is only thing on beta tab sidebar
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      color: white;
      padding-top: 15px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      // background: #0000005e;
      // border-top-right-radius: 5px;
      background: transparent;
      // border-top-right-radius: 5px;
      opacity: .6; // TODO opacity
      height: 50%;
      font-size: 1.3em;
    }

    #siteExplainer {
      width: 65%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // background-image: url(/static/media/explainer_rabbit_button_full_green.png);
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: center center;
      background: transparent;
      border-style: solid;
      border-color: transparent;
      border-width: 0px;
      height: 275px;
    }
  }




  #exitButton {
    // width: 50%;
    display: flex;
    // align-items: center;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
  }
  }

}

@media (min-width: 1367px) {
  #rulesModal {
    transform: none;
    // display: flex;
    // flex-direction: column-reverse;
    // margin-top: 35px;

    #rulesModalCard {
 
      justify-content: flex-end;
      // min-height: 500px;
    }

    #rulesModalCardBody {
      height: 1000px; // TODO" ?
    }

    #optOutMainText {
      font-size: 1.75em;
    }

    #optOutDetailText {
      font-size: 1.25em;
    }

    #optOutWeb3Text {
      font-size: 1.5em;
    }
  
    #closeModalIcon {
      // padding-top: 10px;
      font-size: 3.5em;
      color: white;
      opacity: .75;
    }
  }

  #closeModalIcon {
    // padding-top: 10px;
    font-size: 3em;
    // color: white;
    color: #1d8cf8;
    // opacity: .75;
    margin-bottom: 15px;
    
  }

  #betaInfoEmbed {
    /* font-family: Poppins; */
    display: flex;
    flex-direction: column;
    // align-items: center;
    /* border-style: solid; */
    /* border-color: #1d8cf8; */
    /* border-width: 1.5px; */
    background-color: #0f0f1300;
    border-radius: 0px;
    /* margin-bottom: 10px; */
    width: 100%;
    padding: 0px;
    // margin-top: 180px;
    margin-top: 0px;


  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
    #optOutMetrics {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      padding-left: 5px;
      /* align-items: center; */
      /* align-content: center; */
      /* justify-content: center; */
      /* justify-items: center; */
      /* margin-bottom: 10px; */
      // padding-left: 10px;
      // padding-top: 15px;

      #optOutLabel {
        display: inline-flex;
        color: #ffffff33;
        // padding-top: 5px;
      }

      #optOutSelect {
        align-self: center;
        margin-top: 0px;
        transform: scale(0.85);
        opacity: .6; // TODO opacity
      }
      
    }

  // NOTE: this should be used when 
  // opt-out option is above the email 
  // updates sidebar / explainer button
  #optOutMetricsSidebar {
  #optOutMetrics {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-top: 15px;
    /* align-items: center; */
    /* align-content: center; */
    /* justify-content: center; */
    /* justify-items: center; */
    /* margin-bottom: 10px; */
    // padding-left: 10px;
    // padding-top: 15px;

    #optOutLabel {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffffff33;
      width: 100%;
    }

    #optOutSelect {
      align-self: center;
      margin-top: 0px;
      // transform: scale(0.7);
      opacity: .7; // TODO opacity
    }
    
  }}

    #optOutMainText {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      justify-self: center;
      font-family: Poppins;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.75em;
      color: #ffff00b0;
    }

    #metricsInfoIcon {
      align-self: center;
      color: #ffd60060;
      font-size: 2.4em;
      padding-left: 9px;
    }
    

    #optOutDetailText {
      display: inline-flex;
      // font-weight: 600;
      // text-transform: uppercase;
      opacity: .5;
      font-size: .75em;
    }

  #betaTabSideBar {
    width: 40%;
    display: flex;
    flex-direction: column;
    background: #0000005e;
    border-top-right-radius: 5px;
    font-size: 1.25em;

  }

  #betaSidebarDisappeared {
    display: none;
  }

  #explainerAndUpdates {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

    // #optOutMetrics {
    //   margin-top: 0px;
    //   display: flex;
    //   flex-direction: row;
    //   /* align-items: center; */
    //   /* align-content: center; */
    //   /* justify-content: center; */
    //   /* justify-items: center; */
    //   /* margin-bottom: 10px; */
    //   padding-left: 0px;
    //   padding-top: 0px;
    //   padding-left: 5px;

    //   #optOutLabel {
    //     display: inline-flex;
    //   }

    //   #optOutLabelEmbed {
    //     display: inline-flex;


    //   }

    //   #optOutSelect {
    //     align-self: center;
    //     margin-top: 0px;
    //   }
      
    // }



    #emailFormLabel {
      align-self: flex-start;
      font-size: .95em;
      margin: 0;
      text-transform: uppercase;
      color: #0088ffad;
      font-weight: 600;
      background: transparent;
      margin-top: 10px;

      #noSpam {
        display: inline;
        opacity: .35;
      }
    }

    #emailSubjects {
      display: flex;
      flex-direction: column;
      padding-left: 3px;
      padding-bottom: 5px;

      .emailSubject {
        display: flex;
        flex-direction: column;
        font-size: .75em;
        // color: #ffd600; 
        // color: #00f2c3; 
        // color: #ffd600;
        // color: #1d8cf8;
        // color: #5cf81d7a;
        // color: #ffffff36;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    #enterEmail {
      // border-style: solid;
      // border-radius: 3px;
      // border-width: 1px;
      // border-color: #1d8cf800;
      // background: #00000052;
      // color: #8e7979;
      // font-size: 1em;
      // /* opacity: 1; */
      // margin-bottom: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      border-style: solid;
      border-radius: 3px;
      border-width: 1px;
      border-color: #1d8cf800;
      // background: #0c130d;
      background: #ffffff;
      color: #ffffff;
      font-size: 1em;
      /* opacity: 1; */
      margin-bottom: 0px;
  

      #emailInput {
        border: none;
        padding-left: 10px;
        padding-top: 10px;
        color: #00abff;
        font-size: .85em;
      }
    }


  }

  #explainerAndUpdatesImgRight {
    flex-direction: reverse-row !important;
  }

  #explainerAndUpdates {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0PX;
    // margin-top: 10px;
    background: #00000000;
    width: 100%;
    padding: 0px;


    #getUpdates {
      // width: 50%;
      // padding-left: 5px;
      // padding-right: 5px;
      // color: white;
      // padding-top: 10px;
      // padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      justify-content: center;
      // width: 40%; // TODO: when get updates is only thing on beta tab sidebar
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      color: white;
      padding-top: 15px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      // background: #0000005e;
      // border-top-right-radius: 5px;
      background: transparent;
      // border-top-right-radius: 5px;
      opacity: .6; // TODO opacity
      height: 50%;
      font-size: 1.4em;

    }

    #siteExplainer {
      width: 70%;
      display: flex;
      flex-direction: row;
      // justify-content: center;
      // justify-content: flex-start;
      align-items: center;
      // background-image: url(/static/media/explainer_rabbit_button_full_green.png);
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: center center;
      background: transparent;
      border-style: solid;
      border-color: transparent;
      border-width: 0px;
      height: 380px;
    }
  }




  #exitButton {
    // width: 50%;
    display: flex;
    // align-items: center;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
  }
  }
}

#invisibleModal {
  display: none;
}

#rulesModal {
    // transform: none;
    // transform: translate(0, 05%);
    // width: 90%;
    // max-width: 90%;
    // height: 0px; // TODO

    width: 95%;
    height: 100%; // TODO
    display: flex !important;
    flex-direction: column;
    // align-self: center;
    justify-content: flex-end;

    // display: flex !important;
    // flex-direction: column;
    // /* align-content: stretch; */
    // justify-content: flex-end;
    
    // margin-top: 35px;

    // background-image: url("../assets/img/frontliner-modal.png");

  
    #rulesModalCard {
        
      display: flex;
      flex-direction: column;
      // flex-direction: column-reverse;
     //   justify-content: center;
    //   align-items: center;
      // margin-top: 0px;
      // background: #ffffff !important;
      overflow: hidden;
      background-color: #ffffff00 !important;
      // text-align: left;
      z-index: 10; // TODO:
      padding: 0px;
      border-radius: 0px;
      // height: 586px;
      // height: 568px;
    // background: transparent;
    // height: 600px;
    // /* transform: none; */
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // /* margin-top: 35px; */
    // padding: 0px;
    }

    #rulesModalCardBody {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background-size: contain;
      background-color: #ffffff00 !important;
      z-index: 10; // TODO:
      padding: 0px;
      border-radius: 0px;
      // height: 480px; // TODO" ?
    // background: transparent;
    // height: 600px;
    // /* transform: none; */
      width: 100%;
      // height: 80%; // TODO: turn this off if you want greeting modal back

    }

    #rulesModalFooter {
      display: flex;
      // flex-direction: column-reverse;
          display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
      // padding-top: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 0px;
    }

    #optOutMetrics {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      /* align-items: center; */
      /* align-content: center; */
      /* justify-content: center; */
      /* justify-items: center; */
      /* margin-bottom: 10px; */
      padding-left: 10px;
      padding-top: 25px;


      #optOutLabel {
        display: inline-flex;
      }

      #optOutLabelEmbed {
        display: inline-flex;


      }

      #optOutSelect {
        align-self: center;
        margin-top: 0px;
      }
      
    }

    #optOutMainText {
      font-family: Poppins;
      display: inline;
      font-weight: 600;
      text-transform: uppercase;
      padding-left: .5em;
      // font-size: .9em;
    }

    #optOutDetailText {
      display: inline-flex;
      // font-weight: 600;
      // text-transform: uppercase;
      opacity: .5;
      font-size: .75em;
    }

    #optOutWeb3Text {
      display: inline;
      font-weight: 600;
      text-transform: uppercase;
      opacity: 1;
      color: #00f2c3;
      // font-size: 1em;
    }


  }

#betaInfo {
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: #1d8cf8;
  border-width: 1.5px;
  background-color: #0f0f13;
  border-radius: 5px;
  margin-bottom: 10px;

  // TODO: 
  #emailAndOptOut {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  

    #emailFormLabel {
      align-self: center;
      font-size: .95em;
      margin: 0;
      padding-top: 5px;
      padding-left: 5px;
      text-transform: uppercase;
      // color: #00f2c3;
      color: #1d8cf8; 
      font-weight: 600;
      background: transparent;

      #noSpam {
        display: inline;
        opacity: .35;
      }
    }

    #emailSubjects {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-bottom: 5px;

      .emailSubject {
        display: flex;
        flex-direction: column;
        font-size: .8em;
        // color: #ffd600; 
        // color: #00f2c3; 
        color: white;
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    #enterEmail {
      display: inline;
      // border: none;
      border-style: solid;
      border-radius: 5px;
      border-width: 1px;
      border-color: #1d8cf8;
      // background: #0000006e;
      // background: #0c130d;
      background: #ffffff !important; // TODO
      // color: #00f2c3;
      // color: white;
      color: black;
      font-size: 0.9em;
      opacity: 1;
      width: 80%;


      #emailInput {
        border: none;
        // color: #00f2c3;
        // font-size: 1em;
        padding-left: 10px;
        padding-top: 10px;
      }
    }


  #exitButton {
    // width: 50%;
    display: flex;
    // align-items: center;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
  }
}

#betaInfoInvisible {
  display: none;
}

#metricDetailsButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  padding: 0px;
  margin-bottom: 2.5px;
  border: #ffffff00 solid .0px;

  #metricsInfoIcon {
    color: #ffffff50;
  }
}

#invisibleMetricsDetails {
  display: none;
}

#visibleMetricsDetails {
  display: flex;
  padding: 0px;
  margin: 0px;
  background: #00800000;
  // font-size: .75em;
  border: 1px #ffffff00 solid;
  border-radius: 0px;

  #metricsDetailsTitle {
    color: white;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    padding-left: 10px;

    #emailFormLabel {
      // font-size: .85em;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      text-transform: uppercase;
      // color: #0088ffad;
      color: #0094ff;
      font-weight: 600;
      background: transparent;
      padding: 5px;
    }

    #metricsDetailsSubtitle {
      // font-size: .85em;
      margin: 0;
      text-transform: uppercase;
      // color: green;
      color: #ffffff;
      font-weight: 600;
      background: transparent;
      padding: 5px;
    }

  }

  #metricsDetailsPoints {
    display: flex;
    flex-direction: column;
    /* padding-left: 5px; */
    // margin-top: 5px;
    align-items: center;


    #emailSubjects {
      display: flex;
      flex-direction: column;
      // padding-left: 10px;
      padding-left: 0px;
      padding-bottom: 0px;
      // font-size: .95em;
      margin-top: 5px;
      margin-bottom: 5px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;

      .emailSubject {
        display: flex;
        flex-direction: column;
        // font-size: .85em;
        // color: #ffd600; 
        // color: #00f2c3; 
        // color: #ffd600;
        // color: #1d8cf8;
        // color: #5cf81d7a;
        // color: #ffffff36;
        color: #ffffff75;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    #metricsDetailsButtons {
      padding-top: 5px;
      // padding-bottom: 5px;
      padding-bottom: 0px;
    }
  }

  #metricsCollectedText {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    // font-size: .85em;
    padding-bottom: 5px;
  }

}

#invisibleGetUpdates {
  display: none;
}

#invisibleSubmitEmailButton {
  display: none;
}

// #betaInfoEmbed {
//     /* font-family: Poppins; */
//     display: flex;
//     flex-direction: column;
//     // align-items: center;
//     /* border-style: solid; */
//     /* border-color: #1d8cf8; */
//     /* border-width: 1.5px; */
//     background-color: #0f0f1300;
//     border-radius: 0px;
//     /* margin-bottom: 10px; */
//     width: 100%;
//     padding: 0px;

//   // NOTE: this should be used when 
//   // opt-out option is above the email 
//   // updates sidebar / explainer button
//     #optOutMetrics {
//       margin-top: 0px;
//       display: flex;
//       flex-direction: row;
//       padding-left: 5px;
//       /* align-items: center; */
//       /* align-content: center; */
//       /* justify-content: center; */
//       /* justify-items: center; */
//       /* margin-bottom: 10px; */
//       // padding-left: 10px;
//       // padding-top: 15px;

//       #optOutLabel {
//         display: inline-flex;
//         color: #ffffff33;
//         // padding-top: 5px;
//       }

//       #optOutSelect {
//         align-self: center;
//         margin-top: 0px;
//         transform: scale(0.7);
//         opacity: .6; // TODO opacity
//       }
      
//     }

//   // NOTE: this should be used when 
//   // opt-out option is above the email 
//   // updates sidebar / explainer button
//   #optOutMetricsSidebar {
//   #optOutMetrics {
//     margin-top: 0px;
//     display: flex;
//     flex-direction: row;
//     padding-left: 0px;
//     padding-right: 0px;
//     /* align-items: center; */
//     /* align-content: center; */
//     /* justify-content: center; */
//     /* justify-items: center; */
//     /* margin-bottom: 10px; */
//     // padding-left: 10px;
//     // padding-top: 15px;

//     #optOutLabel {
//       padding-top: 10px;

//       // padding-bottom: 5px;
//       display: flex;
//       flex-direction: row;
//       color: #ffffff33;
//       width: 100%;
//     }

//     #optOutSelect {
//       align-self: center;
//       margin-top: 0px;
//       transform: scale(0.7);
//       opacity: .6; // TODO opacity
//     }
    
//   }}

//     #optOutMainText {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       align-self: center;
//       justify-self: center;
//       font-family: Poppins;
//       font-weight: 600;
//       text-transform: uppercase;
//       // font-size: .85em;
//       font-size: .8em;
//       color: #ffff00b0;
//     }

//     #metricsInfoIcon {
//       // display: inline;
//       align-self: flex-start;
//       color: #ffd60060;
//       font-size: 1.20em;
//       padding-left: 2px;
//     }
    

//     #optOutDetailText {
//       display: inline-flex;
//       // font-weight: 600;
//       // text-transform: uppercase;
//       opacity: .5;
//       font-size: .75em;
//     }

//   #betaTabSideBar {
//     width: 45%;
//     display: flex;
//     flex-direction: column;
//     background: #0000005e;
//     border-top-right-radius: 5px;

//   }

//   #betaSidebarDisappeared {
//     display: none;
//   }

//   #explainerAndUpdates {
//     display: flex;
//     flex-direction: column;
//     padding-top: 5px;
//     padding-bottom: 5px;
//     padding-left: 5px;
//     padding-right: 5px;

//     // #optOutMetrics {
//     //   margin-top: 0px;
//     //   display: flex;
//     //   flex-direction: row;
//     //   /* align-items: center; */
//     //   /* align-content: center; */
//     //   /* justify-content: center; */
//     //   /* justify-items: center; */
//     //   /* margin-bottom: 10px; */
//     //   padding-left: 0px;
//     //   padding-top: 0px;
//     //   padding-left: 5px;

//     //   #optOutLabel {
//     //     display: inline-flex;
//     //   }

//     //   #optOutLabelEmbed {
//     //     display: inline-flex;


//     //   }

//     //   #optOutSelect {
//     //     align-self: center;
//     //     margin-top: 0px;
//     //   }
      
//     // }



//     #emailFormLabel {
//       align-self: center;
//       font-size: .95em;
//       margin: 0;
//       text-transform: uppercase;
//       color: #0088ffad;
//       font-weight: 600;
//       background: transparent;

//       #noSpam {
//         display: inline;
//         opacity: .35;
//       }
//     }

//     #emailSubjects {
//       display: flex;
//       flex-direction: column;
//       padding-left: 3px;
//       padding-bottom: 5px;

//       .emailSubject {
//         display: flex;
//         flex-direction: column;
//         font-size: .75em;
//         // color: #ffd600; 
//         // color: #00f2c3; 
//         // color: #ffd600;
//         // color: #1d8cf8;
//         // color: #5cf81d7a;
//         // color: #ffffff36;
//         color: #ffffff;
//         text-transform: uppercase;
//         font-weight: 600;
//       }
//     }

//     #enterEmail {
//       // border-style: solid;
//       // border-radius: 3px;
//       // border-width: 1px;
//       // border-color: #1d8cf800;
//       // background: #00000052;
//       // color: #8e7979;
//       // font-size: 1em;
//       // /* opacity: 1; */
//       // margin-bottom: 0px;
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       height: 25px;
//       border-style: solid;
//       border-radius: 3px;
//       border-width: 1px;
//       border-color: #1d8cf800;
//       background: #0c130d;
//       color: #ffffff;
//       font-size: 1em;
//       /* opacity: 1; */
//       margin-bottom: 0px;
  

//       #emailInput {
//         // opacity: .3;
//         border: none;
//         padding-left: 10px;
//         padding-top: 10px;
//         color: #00abff;
//         font-size: .6em;
//       }
//     }


//   }

//   #explainerAndUpdates {

//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: flex-end;
//     margin-top: 0;
//     margin-top: 10px;
//     background: #00000000;
//     width: 100%;
//     padding: 0px;



//     #getUpdates {
//       // width: 50%;
//       // padding-left: 5px;
//       // padding-right: 5px;
//       // color: white;
//       // padding-top: 10px;
//       // padding-bottom: 10px;
//       display: flex;
//       flex-direction: column;
//       // justify-content: flex-end;
//       justify-content: center;
//       // width: 40%; // TODO: when get updates is only thing on beta tab sidebar
//       width: 100%;
//       padding-left: 0px;
//       padding-right: 0px;
//       color: white;
//       padding-top: 5px;
//       padding-bottom: 5px;
//       padding-left: 5px;
//       padding-right: 5px;
//       // background: #0000005e;
//       // border-top-right-radius: 5px;
//       background: transparent;
//       // border-top-right-radius: 5px;
//       opacity: .6; // TODO opacity
//       height: 50%;
//     }

    #siteExplainer {
      // width: 65%;
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      // // background-image: url(/static/media/explainer_rabbit_button_full_green.png);
      // // background-size: cover;
      // // background-repeat: no-repeat;
      // // background-position: center center;
      // background: transparent;
      // border-style: solid;
      // border-color: transparent;
      // border-width: 0px;
      // mix-blend-mode: difference;
      // filter: invert(1);
    }

    #siteExplainerMultiply {
      width: 65%;
      height: 390px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: transparent;
      border-style: solid;
      border-color: transparent;
      border-width: 0px;
      // mix-blend-mode: multiply !important;
      // mix-blend-mode: normal !important;
      // mix-blend-mode: plus-lighter !important;
      // filter: invert(1);
      // opacity: 0.3;
    }

  // #siteExplainerLighten {
  //   width: 65%;
  //   height: 390px;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   background: transparent;
  //   border-style: solid;
  //   border-color: transparent;
  //   border-width: 0px;
  //   mix-blend-mode: saturation !important;
  // }
    /* opacity: 0.2;
      /* height: 275px;
    }
  // }

  // .siteExplainerButton {
  //   // height: 600px;
  //   // transform: perspective(401px) rotateY(-12deg) scale(1.1);
  //   // transform: scale(1.75);
  //   // transform: scale(1.25);
  //   // marginTop: -7px;
  //   // mixBlendMode: inherit;
  //   // mixBlendMode: difference;
  //   // opacity: 0;
  //   // height: 155px;
  //   // marginTop: 50px;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   padding: 0px;
  //   mix-blend-mode: lighten;
  // }
  





//   #exitButton {
//     // width: 50%;
//     display: flex;
//     // align-items: center;
//     align-items: flex-end;
//     justify-content: center;
//     padding: 10px;
//   }
// }

  // TODO organize this whole shit-show CSS file
  #closeExplainerModalButton {
    margin-bottom: 50px;
  }

  #closeModalIcon {
      // padding-top: 10px;
      // font-size: 1.5em;
      color: white;
      // color: #1d8cf8;
      // opacity: .75;
      // opacity: .85;
      // opacity: 1;
      padding-bottom: 5px;
  }

// CHECKBOX STYLES (TODO: ?)

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  // background-color: #d1d3d1;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: black;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}


/* ---------------------------------------------------- SiteLoadOptions.jsx ---------------------------------------------------- */




@media (min-width: 320px) and (max-width: 465px) {
  #betaExplainerBulletText {
    padding: 10px;
    font-size: 0.9em !important;
  }

  #betaViewerRobot {
    align-self: center !important;
  }
}

@media (min-width: 466px) and (max-width: 768px) { 
  #betaExaplainerList {
    align-self: flex-start;
  }

  #betaExplainerBulletpoint {
    align-self: flex-start;
    font-size: 1em;
  }

  #betaExplainerBulletText {
    padding: 10px;
    font-size: 1.05em !important;
  }

  #betaViewerRobot {
    align-self: center !important;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #betaExaplainerList {
    align-self: center;
  }

  #betaExplainerBulletpoint {
    align-self: center;
  }
}

@media (min-width: 1366px) {
  #betaExaplainerList {
    align-self: center;
  }

  #betaExplainerBulletpoint {
    align-self: center;
  }
}

#rabbitImage {
  max-height: 110%;
  max-width: 100%;
  align-self: flex-end;
  mix-blend-mode: lighten !important;
}

#frontlinerImage {
  max-height: 110%;
  max-width: 130%;
  align-self: flex-end;
  // mix-blend-mode: luminosity !important;
  mix-blend-mode: color-dodge !important;
  justify-content: unset !important;
  opacity: 0.8;
}

#betaViewerImage {
  max-height: 100%;
  max-width: 100%;
  // padding: 15px;
  // mix-blend-mode: multiply;
  filter: opacity(0.2);
}

#betaViewerRobot {
  max-height: 100%;
  max-width: 170%;
  // mix-blend-mode: saturation;
  mix-blend-mode: color-dodge !important;
  align-self: flex-start;
  filter: opacity(0.5);
}

#betaViewerShip {
  // max-height: 140%; // ship.png
  max-height: 116%;    // jump.png
  max-width: 100%;
  mix-blend-mode: lighten;
  align-self: flex-end;
  filter: invert(1);
}

#betaViewerSeedsman {
  max-height: 140%;
  max-width: 100%;
  mix-blend-mode: color-dodge;
  align-self: flex-end;
  // filter: invert(1);
}

#seedsmanImage {
  mix-blend-mode: multiply;
}


#emailFormLabel {
  margin-top: 0px !important;
  color: #909ff8 !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#twitterUpdatesLogo {
  color: white;
  font-size: 1.5em;
}

// .siteExplainerContainer {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
// }

// .siteExplainerButton {
//   // Existing styles
// }

#betaExaplainerList {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

#betaExplainerBulletpoint {
  font-size: 20px;
  // align-self: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.siteExplainerText {
  font-size: 50px; // Adjust size as needed
  color: black;
  // Additional styles if needed
}

.bulletPoint {
  font-size: 40px; // Adjust as needed
  color: black;

  .bold {
    font-size: 50px;
  }

  .strong {
    font-size: 32px;
    text-transform: uppercase;
  }
}

#betaExplainerBulletText {
  padding: 10px;
  font-size: 1.25em;
}

ul li, ol li {
  // color: #ffffff00;
}



