/* ---------------------------------------------------- Main.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
    #main {
        display: flex;
        // flex-direction: column-reverse;
        flex-direction: column;
        width: 100%;
    }
}

@media (min-width: 320px) and (max-width: 465px) {
    #main {
        display: flex;
        // flex-direction: column-reverse;
        flex-direction: column;
        width: 100%;
    }

    #rulesModal {
        transform: none;
        width: 90%;
        display: flex;
        margin-top: 35px;

        #rulesModalCard {
          justify-content: flex-end;
          min-height: 500px;
        }
      
        #closeModalIcon {
          font-size: 2em;
          color: white;
          opacity: .3;
        }
      }
}


@media (min-width: 466px) and (max-width: 768px) { 
    #main {
        display: flex;
        // flex-direction: column-reverse;
        flex-direction: column;
        width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1366px) {
    #main {
        display: flex;
        // flex-direction: row;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        margin-top: 2%;
    }
}

@media (min-width: 1367px) {
    #main {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
    }
}

#rulesModal {
    transform: none;
    // transform: translate(0, 05%);
    width: 90%;
    display: flex;
    flex-direction: row;
    // align-self: center;
    margin-top: 35px;

    // background-image: url("../assets/img/frontliner-modal.png");

  
    #rulesModalCard {
        
      display: flex;
      // flex-direction: row;
     //   justify-content: center;
    //   align-items: center;
      // margin-top: 0px;
      // background: #ffffff !important;
      overflow: hidden;
      background-color: #ffffff00 !important;
      // text-align: left;
      z-index: 10; // TODO:
      padding: 0px;
      border-radius: 0px;
      min-height: 500px;
    }
  
    #closeModalIcon {
      font-size: 2em;
      color: white;
      opacity: .3;
    }
  }


/* ---------------------------------------------------- Main.jsx ---------------------------------------------------- */


