.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  
    #loadingScreenInitButton,
    #startMatchButton {
      background: transparent;
      font-weight: 600;
      padding: 10px;
      font-size: 1.15em;
      color: white;
      text-transform: uppercase;
      border: white 1px solid;
      border-radius: 10px;
      opacity: 0.5;
    }
  
    .button {
      font-size: 1.15em;
      font-weight: 600;
      color: white;
      // background-color: #89CFF0; // Baby blue
      border: none;
      padding: 0.75em 1.5em;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .loading {
      background-color: #89CFF0;
    }
  
    .success {
      background-color: green;
    }
  
    .error {
      background-color: red;
    }
  
    .loadingIcon {
      font-size: 2em;
      font-weight: bold;
    }
  
    .successIcon, .errorIcon, .externalLinkIcon {
      margin-left: 10px;
      font-size: 1.5em;
    }
  
    .errorIcon {
      animation: blink 1s infinite;
    }
  
    @keyframes blink {
      50% {
        opacity: 0;
      }
    }
  }
  

  
  #startMatchButton {
    background: transparent;
    font-weight: 600;
    padding: 10px;
    font-size: 1.15em;
    color: white;
    text-transform: uppercase;
    border: white 1px solid;
    border-radius: 10px;
    opacity: 0.5;
  }