/* SingleQuestionResponse.module.scss */

/* Container for the question card in "fullscreen" mode */
.fullscreenQuestionContainer {
    margin-bottom: 20px;
    background: #1f2251;
    border-radius: 8px;
    // box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;
    box-shadow: none !important;
  }
  
  /* Container for the question card in "mini" mode */
  .miniQuestionContainer {
    margin-bottom: 10px;
    background: #24264d; /* Slightly darker to differentiate mini from fullscreen */
    border-radius: 6px;
    box-shadow: -3px 3px 6px #171a3d, 3px -3px 6px #272b65;
    padding: 10px;
  }
  
  /* Common styling for question card body */
  .questionTitleBody {
    padding: 15px;
  }
  
  /* Common styling for question title text */
  .questionTitle {
    font-weight: 600;
    font-size: 1.25em;
    opacity: 0.8;
    color: white;
    margin-bottom: 10px;
  }
  
  /* Additional comments area */
  .additionalCommentsSection {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .additionalCommentsLabel {
    font-weight: bold;
    margin-bottom: 5px;
    color: #ccc;
  }
  
  .additionalCommentsContent {
    color: #fff !important;
    opacity: 0.9;
    line-height: 1.4;
    background: #0000ff54;
    padding: 10px;
    font-weight: 500;
  }
  
  /* The "Decrypt Answer" button */
  .decryptQuestionButton {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    background: #ffc107;
    border: none;
    color: #212529;
    margin-top: 5px;
  
    &:hover {
      background: #e0a800;
    }
  }
  
  /* freeform answer style */
  .freeformAnswer {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 15px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  /* multi-choice styling */
  .multiChoice {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 10px;
  }
  
  .checkboxOptionText {
    font-size: 1em;
    font-weight: 600;
    color: #ccc;
  }
  
  /* rating slider */
  .importanceSlider {
    margin-top: 10px;
    margin-bottom: 10px;
    .rangeslider__fill {
      background: #2dce89;
    }
    .rangeslider__handle {
      background: #1f2251;
      border: 2px solid #2dce89;
    }
  }
  .ratingLabelText {
    margin-left: 10px;
    font-weight: 600;
    color: white;
    font-size: 1.1em;
    margin-right: 10px;
  }
  .ratingSlider {
    width: 200px;
  }
  
  /* binary styling */
  .binaryChoice {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
  
    .radioOptionText {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5rem;
      font-size: 1rem;
      color: #ddd;
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      transition: font-weight 0.3s ease;
  
      &.selected {
        font-weight: 900;
        font-style: italic;
      }
  
      input[type="radio"] {
        display: none;
      }
  
      &:hover {
        background-color: #333;
      }
  
      &.agree {
        background-color: #4caf50;
        color: white;
  
        &:before {
          font-weight: 900;
          margin-right: 0.5rem;
        }
      }
  
      &.unsure {
        background-color: #ffeb3b;
      }
  
      &.disagree {
        background-color: #f44336;
        color: white;
  
        &:before {
          font-weight: 900;
          margin-right: 0.5rem;
        }
      }
  
      .optionIcon {
        margin-right: 0.5rem;
      }
    }
  }
  
  /* importance text style */
  .importanceText {
    font-size: 1em;
    opacity: 0.8;
    margin-top: 5px;
    color: white;
  }
  
  /* 
     MINI MODE ADDITIONS 
     (Only relevant if props.mode === 'mini')
  */
  
  .miniHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .miniQuestionSummary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .miniPromptAbbrev {
    font-weight: 600;
    font-size: 1em;
    color: #ddd;
    opacity: 0.9;
  }
  
  .miniExpandButton {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 1.25em;
    cursor: pointer;
  
    &:hover {
      color: #ffe680;
    }
  }
  
  .miniAnswer {
    background-color: rgba(255, 255, 255, 0.15);
    padding: 8px;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  /* 
     Additional styles for special answer types in mini mode
  */
  .multichoiceAnswer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-weight: bold;
  
    .choiceItem {
      background-color: #4DFFA4;
      color: #171941;
      padding: 5px 10px;
      border-radius: 15px;
      font-size: 0.9em;
    }
  }
  
  .ratingAnswer {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-weight: bold;
  
    .ratingBar {
      height: 10px;
      background-color: #4DFFA4;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
  
  .binaryAnswer {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-weight: bold;
  }
  
  /* 
     Additional detail row for the expanded mini mode 
  */
  .miniExpandedTopRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .miniIdLabel {
    font-size: 0.9em;
    color: #ccc;
    font-weight: 600;
  }
  
  .externalLink {
    color: #fff;
    opacity: 0.8;
    transition: opacity 0.2s ease;
  
    &:hover {
      opacity: 1;
    }
  }
  
  /* 
     AGGREGATOR MODE ADDITIONS
  */
  
  /* Container for aggregator content to ensure consistent look/feel. */
  .aggregatorContainer {
    background-color: #1f2251;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  /* White text, larger size, heavier weight for aggregator mode. */
  .aggregatorText {
    color: #fff;
    font-size: 1.3em;
    font-weight: 700;
  } 
  
  .aggregatorParagraph {
    margin-bottom: 0.75em;
    color: white !important;
    font-size: 1.15em;
  }
  
  /* In aggregator binary responses, each item. */
  .binaryAggregatorItem {
    margin-bottom: 0.5em;
  }
  
  /* For aggregator freeform expansions. */
  .freeformAggregatorList {
    margin-top: 10px;
  }
  
  /* Multi-choice aggregator. */
  .multiChoiceOption {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 700;
  }
  
  .optionLabel {
    margin-right: 10px;
  }
  
  .optionStats {
    font-weight: 700;
    color: #fff;
  }
  
  /* 
     Mark the selected multi-choice answer 
  */
  .selected {
    font-weight: 700;
  }
  
  .encryptedResponseText {
    color: white !important;
  }