.aiPolicyAtlasWrapper {
  all: initial;
  padding: 20px;
  min-height: 100vh;
  box-sizing: border-box;
  color: white;
  
  .aiPolicyAtlas {
    font-family: Arial, sans-serif;
    width: 100%;
    overflow-x: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(128, 128, 128, 0.5);

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: white;
    }

    .legendContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
    }
    
    .legendItem {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    
    .legendDot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 5px;
    
      &.category { background-color: #2563eb; }
      &.subcategory { background-color: #0d9488; }
      &.topic { background-color: #4ade80; }
      &.instance { background-color: #fde047; }
    }
    
    .legendText {
      font-size: 1rem;
      text-transform: uppercase;
      opacity: 0.75;
    }

    .controls {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      /* Add a small gap if needed to separate toggles from filters visually */
      flex-wrap: wrap;
    }

    .orderToggle,
    .demoToggle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .toggleLabel {
        margin-right: 10px;
      }

      .toggleCheckbox {
        cursor: pointer;
      }
    }

    .nodeTypeFilter,
    .sbtFilter {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .filterLabel {
        margin-right: 10px;
      }

      .filterSelect {
        padding: 5px;
        border-radius: 4px;
        background: #ffffff;
        color: #333;
        border: 1px solid #ccc;
      }
    }

    .categorySelector {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      overflow-x: auto;
      white-space: nowrap;

      .categoryButton {
        padding: 10px 15px;
        background-color: rgba(240, 240, 240, 0.1);
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        color: white;
        margin-right: 10px;

        &:hover {
          background-color: rgba(224, 224, 224, 0.2);
        }

        &.active {
          background-color: #2563eb;
        }
      }
    }

    .nodesContainer {
      margin-bottom: 20px;
    }

    .modalOverlay {
      position: fixed; /* Ensure the overlay covers the whole screen */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
      display: flex;
      justify-content: center; /* Center the modal horizontally */
      align-items: center; /* Center the modal vertically */
      z-index: 1000; /* Ensure the modal is on top */
    }
  
    .modalContent {
      background-color: white;
      color: black !important;
      padding: 30px;
      border-radius: 12px;
      max-width: 800px;
      width: 90%;
      max-height: 80vh;
      overflow-y: auto;
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
      z-index: 1001; /* Ensure it stays above the overlay */

      .relatedDocumentsSection {
        margin-bottom: 30px;

        .sectionTitle {
          font-size: 20px;
          color: #333 !important;
          margin-bottom: 15px;
          font-weight: bold;
        }

        .relatedDocumentsList {
          list-style-type: none;
          padding: 0;

          .relatedDocument {
            background-color: #f8f9fa;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 4px;
            color: #333 !important;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
              background-color: #e9ecef;
            }

            .documentTitle {
              font-weight: bold;
              margin-right: 10px;
            }

            .documentRelevance {
              font-size: 0.9em;
              color: #6c757d;
            }
          }
        }

        .highlightedTextContainer {
          background-color: #fff3cd;
          border: 1px solid #ffeeba;
          border-radius: 4px;
          padding: 15px;
          margin-top: 15px;

          h4 {
            color: #856404;
            margin-top: 0;
            margin-bottom: 10px;
          }

          .highlightedText {
            color: #333 !important;
            font-style: italic;
          }
        }

        .uploadButton {
          background-color: #28a745;
          color: white !important;
          border: none;
          border-radius: 4px;
          padding: 10px 15px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-top: 15px;

          &:hover {
            background-color: #218838;
          }

          svg {
            margin-right: 5px;
          }
        }
      }

      .questionDisplay {
        background-color: #f8f9fa;
        border-radius: 8px;
        padding: 15px;
        margin-bottom: 20px;
        color: #333 !important;
        position: relative;

        .questionText {
          font-size: 1.2em;
          margin-bottom: 10px;
          font-weight: 600;
          padding-right: 40px;
        }

        .answerDisplay {
          background-color: #ffffff;
          border-radius: 4px;
          padding: 10px;
        }

        .freeformAnswer {
          width: 100%;
          min-height: 100px;
          background-color: #ffffff;
          border: 1px solid #ced4da;
          border-radius: 4px;
          padding: 10px;
          color: #333 !important;
          font-family: inherit;
          font-size: 1em;
          resize: vertical;
        }

        #binaryChoice {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          margin-top: 1rem;
        
          .radioOptionText {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0.5rem;
            font-size: 1.2rem;
            color: #333 !important;
            cursor: pointer;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            transition: all 0.3s ease;
        
            &.selected {
              font-weight: 700;
            }
        
            input[type="radio"] {
              display: none;
            }
        
            &:hover {
              background-color: rgba(0, 0, 0, 0.05);
            }
        
            &.agree {
              background-color: rgba(76, 175, 80, 0.1);
              &.selected { background-color: rgba(76, 175, 80, 0.3); }
            }
        
            &.pass {
              background-color: rgba(255, 235, 59, 0.1);
              &.selected { background-color: rgba(255, 235, 59, 0.3); }
            }
        
            &.disagree {
              background-color: rgba(244, 67, 54, 0.1);
              &.selected { background-color: rgba(244, 67, 54, 0.3); }
            }
        
            .optionIcon {
              margin-right: 0.5rem;
            }
          }
        }

        .ratingAnswer {
          display: flex;
          align-items: center;
          
          .ratingSlider {
            flex-grow: 1;
            margin-right: 10px;
            -webkit-appearance: none;
            width: 100%;
            height: 15px;
            border-radius: 5px;
            background: #ddd;
            outline: none;
            opacity: 0.7;
            transition: opacity .2s;

            &:hover {
              opacity: 1;
            }

            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background: #2563eb;
              cursor: pointer;
            }

            &::-moz-range-thumb {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background: #2563eb;
              cursor: pointer;
            }
          }

          .ratingValue {
            font-size: 1.2em;
            font-weight: bold;
            color: #333 !important;
          }
        }

        .multichoiceAnswer {
          list-style-type: none;
          padding-left: 0;

          .multichoiceOption {
            display: block;
            background-color: #ffffff;
            margin-bottom: 5px;
            padding: 10px;
            border-radius: 4px;
            color: #333 !important;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              background-color: #f0f0f0;
            }

            input[type="checkbox"] {
              margin-right: 10px;
            }
          }
        }

        .statementCheckbox {
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }
    }

    .treeContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    /* 
      Revised tree styling to better reflect a "connected" diagram-like look, 
      with lines between parents and children, etc.
    */
    .treeNode {
      position: relative;
      min-width: 200px;
      margin-right: 10px;
      margin-top: 20px;
      margin-bottom: 20px;

      /* Vertical line from parent to children */
      &::before {
        content: "";
        position: absolute;
        top: -10px; /* lines up with parent's bottom edge */
        left: 0;    
        height: 10px;
        width: 2px;
        background-color: rgba(255, 255, 255, 0.3);
        transform: translateX(-20px);
      }

      .nodeContent {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 5px;
        transition: background-color 0.3s;
        position: relative;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        .expandButton,
        .expandModal,
        .bookmark,
        .addNode {
          cursor: pointer;
          margin-right: 5px;
          color: white;
          transition: color 0.3s;
          font-weight: bold;

          &:hover {
            color: #fde047;
          }
        }

        .nodeName {
          flex-grow: 1;
          color: white;
        }

        .upvotes,
        .commentCount {
          display: flex;
          align-items: center;
          gap: 3px;
          margin-right: 5px;
          margin-left: 5px;
          color: white;
          font-weight: bold;
        }

        .bookmark.bookmarked {
          color: #fde047;
        }
      }

      .children {
        margin-left: 40px;
        padding-left: 20px;
        border-left: 2px dashed rgba(255, 255, 255, 0.3);
        position: relative;
        
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -2px; 
          width: 2px;
          height: 20px; /* small vertical line from node to first child */
          background-color: rgba(255, 255, 255, 0.3);
        }
      }

      &.depth0 > .nodeContent {
        background-color: rgba(37, 99, 235, 0.5); /* #2563eb */
      }
      &.depth1 > .nodeContent {
        background-color: rgba(13, 148, 136, 0.5); /* #0d9488 */
      }
      &.depth2 > .nodeContent {
        background-color: rgba(74, 222, 128, 0.5); /* #4ade80 */
      }
      &.depth3 > .nodeContent {
        background-color: rgba(253, 224, 71, 0.5); /* #fde047 */
      }
    }

    .flatNode {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .flatNodePath {
        flex-grow: 1;
        color: white;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;

        .pathButton {
          background: none;
          border: none;
          color: white;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 4px;
          margin-right: 5px;
          opacity: 0.5;
          transition: opacity 0.3s, background-color 0.3s;

          &:hover {
            opacity: 1;
          }

          &.depth0 { background-color: rgba(37, 99, 235, 0.5); } // #2563eb
          &.depth1 { background-color: rgba(13, 148, 136, 0.5); } // #0d9488
          &.depth2 { background-color: rgba(74, 222, 128, 0.5); } // #4ade80
          &.depth3 { background-color: rgba(253, 224, 71, 0.5); } // #fde047
        }

        .pathArrow {
          margin: 0 5px;
          color: white;
        }
      }

      .upvotes,
      .commentCount,
      .bookmark,
      .addNode,
      .expandModal {
        margin-left: 10px;
        cursor: pointer;
        color: white;
        transition: color 0.3s;
        font-weight: bold;

        &:hover {
          color: #fde047;
        }
      }

      .bookmark.bookmarked {
        color: #fde047;
      }
    }

    .modalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 15px;

      .modalTitle {
        font-size: 24px;
        font-weight: bold;
        color: #333 !important;
        margin: 0;
      }

      .modalIdContainer {
        display: flex;
        align-items: center;

        .modalIdLabel {
          margin-right: 5px;
          color: #888 !important;
        }

        .modalId {
          font-size: 12px;
          color: #888 !important;
          margin-right: 10px;
        }

        .copyButton {
          background: none;
          border: none;
          cursor: pointer;
          color: #888;
          transition: color 0.3s;

          &:hover {
            color: #333;
          }
        }
      }

      .closeIcon {
        cursor: pointer;
        font-size: 20px;
        color: #666;
        transition: color 0.3s;

        &:hover {
          color: #333;
        }
      }
    }

    .modalText {
      color: #333 !important;
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 20px;
    }

    .voteSection {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      .upvoteButton,
      .downvoteButton,
      .castVotesButton {
        background: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px 15px;
        margin-right: 15px;
        cursor: pointer;
        transition: all 0.3s;
        color: #333 !important;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #f0f0f0;
        }

        svg {
          margin-right: 5px;
        }
      }

      .voteInput {
        width: 60px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-right: 15px;
        color: #333 !important;
      }
    }

    .relatedNodesSection,
    .questionsSection,
    .commentsSection {
      margin-bottom: 30px;

      .sectionTitle {
        font-size: 20px;
        color: #333 !important;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .relatedNodesList {
        list-style-type: none;
        padding: 0;

        li {
          background-color: #f8f9fa;
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
          color: #333 !important;
        }
      }

      .question,
      .comment {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        background-color: #f8f9fa;
        border-radius: 8px;
        padding: 15px;

        .userAvatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
          object-fit: cover;
        }

        .questionContent,
        .commentContent {
          flex-grow: 1;

          .questionUser,
          .commentUser {
            font-weight: bold;
            color: #333 !important;
            margin-bottom: 5px;
            display: block;
          }

          .questionText,
          .commentText {
            color: #333 !important;
            font-size: 14px;
            line-height: 1.6;
            margin: 0;
          }
        }
      }
    }

    .addCommentSection {
      .addCommentTitle {
        font-size: 20px;
        color: #333 !important;
        margin-bottom: 15px;
        font-weight: bold;
      }

      .actionButtons {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .submitCommentButton {
          background-color: #2563eb;
          color: white !important;
          border: none;
          border-radius: 4px;
          padding: 10px 15px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-right: 15px;

          &:hover {
            background-color: #1d4ed8;
          }

          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
      }
    }

    .suggestNode {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-top: 10px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    .aiPolicyAtlasWrapper {
      .aiPolicyAtlas {
        .categorySelector {
          flex-wrap: wrap;

          .categoryButton {
            margin-bottom: 10px;
          }
        }

        .treeContainer {
          flex-direction: column;
        }

        .treeNode {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .modalContent {
        width: 95%;
        padding: 20px;
      }

      .voteSection {
        flex-wrap: wrap;

        button {
          margin-bottom: 10px;
        }
      }

      .addCommentSection {
        .actionButtons {
          flex-wrap: wrap;

          button, .voteInput {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.suggestNodeInput {
  color: black;
  font-weight: 500;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
}

#parentNodeText {
  color: black !important;
  font-weight: 600;
  margin-bottom: 10px;
}