/* ---------------------------------------------------- Navbar.jsx ---------------------------------------------------- */


@media (min-width: 0px) and (max-width: 319px) {
  #navbarContainer {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #navbarContainerLoggedIn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    // margin-bottom: 10px;
    margin-bottom: 0px;
    // margin-left: 2%;
    // margin-right: 2%;
  }
  

  #navbarLogoCol {
    display: flex;
    align-content: center;
    justify-items: center;
    padding: 0px;
    width: 40%;
  }

  #navbarLogoColLoggedIn {
    display: flex;
    align-content: center;
    justify-items: center;
    padding: 0px;
  }

  #mainLogo {
    object-fit: contain;
    // max-height: 50px;
    min-height: 75px;
  }

  #mainLogoLoggedIn{
    object-fit: contain;
    max-width: 120px
  }

  #navbarSearchCol {
    display: none;
  }

  #emptyCol {
    display: none;
  }

  #accountSection {
    width: 70%;
    padding: 0px;
  }

  #accountSectionLoggedIn {
    justify-content: center;
    width: 100%;
    padding: 0px;
  }
  
}

@media (min-width: 320px) and (max-width: 465px) {

  #navbarContainer {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-between;
    // align-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 0px;
    // margin-left: 5%;
    // margin-right: 2%;
    margin-bottom: 0px;
  }

  #navbarContainerLoggedIn {
    // transform: scale(0.9);
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
     // align-items: center;
    //  align-items: end;
    //  align-items: flex-start;
    //  margin-top: 10px;
     margin-bottom: 0px;
    // margin-left: 2%;
    // margin-right: 2%;
    margin-top: 10px;
  }

  #navbarLogoCol {
    width: 40%;
    display: flex;
    align-content: center;
    justify-items: center;
    padding: 0px;
  }

  #navbarLogoColLoggedIn {
    // margin-top: 12px; TODO
    // width: 25%;
    // width: 30%;
    // width: 20%;
    // display: flex;
    // align-content: center;
    // justify-items: flex-start;
    // padding: 0px;
    // width: 30%;
    // width: 35%;
    // display: flex;
    // align-content: center;
    // justify-items: center;
    // padding-left: 2%;
    // padding: 0px;
        /* width: 25%; */
        display: flex;
        /* align-content: center; */
        /* justify-items: center; */
        /* padding-left: 2%; */
  }

  #mainLogo {
    object-fit: contain;
    // max-width: 150px;
  }

  #mainLogoLoggedIn{
    // object-fit: contain;
    // // max-width: 250px
    // // min-height: 50px;
    max-width: 125px;
    // max-width: 150px;
    object-fit: contain;
    // max-width: 150px;
    // min-height: 75px;
  }

  #navbarSearchCol {
    display: none;
  }

  #emptyCol {
    display: none;
  }

  #accountSection {
    width: 60%;
    padding: 0px;
  }

  #accountSectionLoggedIn { // TODO: this is outer layer of the two accountSection
    // justify-content: flex-end;
    display: flex;
    justify-content: flex-end;
    // width: 75%;
    // width: 60%;
    // width: 65%;
    width: 70%;
    // width: 80%;
    padding: 0px;
  }
}

@media (min-width: 466px) and (max-width: 768px) {

  #navbarContainer {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    // align-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 0px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #navbarContainerLoggedIn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 0px;

    // margin-bottom: 10px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #navbarLogoCol {
    width: 40%;
    display: flex;
    align-content: center;
    // justify-items: center;
    justify-content: center;
    padding: 0px;
  }

  #navbarLogoColLoggedIn {
    width: 30%;
    display: flex;
    align-content: center;
    justify-items: center;
    padding-left: 2%;
    padding: 0px;
  }

  #mainLogo {
    object-fit: contain;
    // max-width: 250px;
    min-height: 75px;
  }

  #mainLogoLoggedIn{
    object-fit: contain;
    max-width: 200px;
    min-height: 75px;
  }

  #accountSection {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // // width: 60%;
    // padding: 0px;
    object-fit: contain;
  }

  #accountSectionLoggedIn { // TODO: confusing naming difference between account.. and Account...
    display: flex;
    justify-content: flex-end;
    width: 70%;
    padding: 0px;
    // padding-top: 10px;
    // padding-left: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1366px)  {

  #navbarContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #navbarContainerLoggedIn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #navbarLogoCol {
    width: 35%;
    display: flex;
    align-content: center;
    // justify-items: center;
    // justify-content: center;
    padding-left: 2%;
    padding: 0px;
  }


  #mainLogo {
    max-width: 300px;
    // padding-left: 10%;
  }

  #mainLogoLoggedIn{
    object-fit: contain;
    max-width: 225px;
    min-height: 75px;
  }

  #accountSection {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    padding: 0px;
  }

  #accountSectionLoggedIn {
    justify-content: flex-end;
    width: 63%;
    padding: 0px;
  }
}

@media (min-width: 1367px) {

  #navbarContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    // align-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #navbarContainerLoggedIn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #navbarLogoCol {
    width: 30%;
    display: flex;
    align-content: center;
    justify-items: center;
    padding-left: 2%;
    padding: 0px;
    // margin
  }

  #navbarLogoColLoggedIn {
    width: 25%;
    display: flex;
    align-content: center;
    justify-items: center;
    // justify-content: center;
    // padding-left: 2%;
    padding: 0px;
  }

  #mainLogo {
    object-fit: contain;
    max-width: 325px;
    // padding-left: 10%;
  }

  #mainLogoLoggedIn {
    object-fit: contain;
    max-width: 325px;
  }

  #accountSection {
    width: 40%;
    padding: 0px;
  }

  #accountSectionLoggedIn {
    width: 45%;
    padding: 0px;
  }

}

#mainLogo {
  // filter: opacity(0.5);
  // filter: brightness(0.5);
  // mix-blend-mode: color-dodge;
  filter: opacity(0.15);
}

#mainLogoLoggedIn {
  filter: opacity(0.15);
}


/* ---------------------------------------------------- AccountSection.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

  #AccountSectionLoggedIn {
    width: 100%;
    transform: scale(0.85);
    display: flex;
    // flex-direction: row;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    /* flex-direction: column; */
    align-items: center;
    border-radius: 2px;
    padding: 0px;
  }

  #AccountHover {
    width: 75%;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin: 5px;
  }

  #walletSettingsIcon {
    font-size: 28px;
    padding-top: 5px;
    display: inline;
  }
}

@media (min-width: 320px) and (max-width: 465px) {

  #AccountSectionLoggedIn {
  //   // transform: scale(0.65);
  //   // transform: scale(0.75);
  //   transform: scale(0.8);
  //   display: flex;
  //   flex-direction: column-reverse;
  //   /* flex-wrap: wrap; */
  //   /* flex-wrap: nowrap; */
  //   /* justify-content: flex-end; */
  //   /* flex-direction: column; */
  //   align-items: flex-end;
  //   /* justify-items: center; */
  //   /* border-radius: 2px; */
  //   /* padding: 0px; */
  //   // padding-right: 0;
  //   /* padding: 10px; */
  // // margin-right: 10px;
  // padding-left: 25px;
  transform: scale(0.65);
    display: flex;
    flex-direction: column-reverse;
    /* flex-wrap: wrap; */
    /* flex-wrap: nowrap; */
    /* justify-content: flex-end; */
    /* flex-direction: column; */
    align-items: flex-end;
    /* justify-items: center; */
    /* border-radius: 2px; */
    /* padding: 0px; */
    /* padding: 10px; */
    padding-left: 0px;
  }

  #AccountHover {
  justify-content: flex-end;
  padding-left: 10px;
  padding-right: 10px;
  // margin-top: 5px;
  // margin-bottom: 5px;
  // margin-left: 5px;
  // margin-right: 5px;
  // margin: 5px;
  }

  #walletSettingsIcon {
    font-size: 28px;
    padding-top: 5px;
    display: inline;
  }
}


@media (min-width: 466px) and (max-width: 768px) { 

  #AccountSectionLoggedIn {
    transform: scale(0.9);
    display: flex;
    flex-direction: column-reverse;
    /* flex-wrap: wrap; */
    /* flex-wrap: nowrap; */
    /* justify-content: flex-end; */
    /* flex-direction: column; */
    align-items: flex-end;
    /* justify-items: center; */
    /* border-radius: 2px; */
    /* padding: 0px; */
    padding-right: 0;
    padding: 10px;
  }

  #AccountHover {
    padding: 10px;
    }

  
 #walletSettingsIcon {
    font-size: 28px;
    padding-top: 5px;
    display: inline;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #AccountSectionLoggedIn {
    // transform: scale(.9);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #ffffff38;
    border-radius: 2px;
    padding: 0px;
  }

  #AccountHover {
    padding: 10px;
  }

  #walletSettingsIcon {
    font-size: 28px;
    padding-top: 5px;
    display: inline;
  }
}

@media (min-width: 1367px) {

  #AccountSectionLoggedIn {
    // transform: scale(.95);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #ffffff38;
    border-radius: 2px;
    padding: 0px;
  }

  #AccountHover {
    padding: 10px;
  }

  #walletSettingsIcon {
    font-size: 28px;
    padding-top: 5px;
    display: inline;
  }
}


#AccountHover {
  // justify-content: flex-end;
  // margin-top: 5px;
  // margin-bottom: 5px;
  // margin-left: 5px;
  // margin-right: 5px;
  // margin: 5px;
}

#threeboxAddress {
  // width: 50%;
  align-self: center;
  padding-left: 10px;
}

#walletSettingsButton {
  background-image: none;
  background-color: #34467500;
}

/* ---------------------------------------------------- BalanceDisplay.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

  #XPBalanceText {
    // font-size: 24px;
    font-size: 1.5em;
    padding-top: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px;
    // margin-right: 2px;
    
  }

  #XPBalanceIcon {
    padding-top: 5px;
    display: inline;
  }

  #XPBalanceDisplay {
    padding-top: 5px;
    width: 50%;
  }
  

}

@media (min-width: 320px) and (max-width: 465px) {

  #XPBalanceText {
    font-size: 24px;
    padding-top: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 5px;
    // padding-right: 10px;
  }

  #XPBalanceIcon {
    padding-top: 5px;
    display: inline;
  }

  #XPBalanceDisplay {
    // padding-top: 5px;
    padding-top: 0px;
  }
  

}


@media (min-width: 466px) and (max-width: 768px) { 

  #XPBalanceText {
    font-size: 26px;
    padding-top: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 5px;
  }

  #XPBalanceIcon {
    padding-top: 5px;
    display: inline;
  }

  #XPBalanceDisplay {
    // padding-top: 5px; 
    padding-right: 0px;
  }  
}

@media (min-width: 769px) and (max-width: 1366px) {

  #XPBalanceText {
    font-size: 28px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 20px;
  }

  #XPBalanceIcon {
    padding-left: 5px;
    display: inline;
  }

}

@media (min-width: 1367px) {

  #XPBalanceText {
    font-size: 30px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 30px;
  }

  #XPBalanceIcon {
    padding-left: 5px;
    display: inline;
  }

}

#balanceText {
  display: inline;
  opacity: 0.175;
  color: white;
  font-weight: 600;
}

#balanceNumber {
  display: inline;
  // opacity: 0.25;
  color: white;
  font-weight: 600;
}


#XPBalanceIcon {
  // padding-top: 5px;
  display: inline;
}

#ETHBalanceIcon {
  padding-top: 5px;
  display: inline;
}

#XPBalanceDisplay {
  // padding-top: 5px;
  display: inline-flex;
  // max-width: 100%;
  /* display: inline; */
  // width: 50%;
}


#ETHBalanceText {
  padding-top: 5px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

.glow {
  /* display: inline; */
  color: #fff;
  /* text-align: center; */
  -webkit-animation: glow 1s ease-in-out 6 alternate;
  -moz-animation: glow 1s ease-in-out 6 alternate;
  animation: glow 1s ease-in-out 6 alternate;
}

@-webkit-keyframes glow {
  50% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073,
      0 0 70px #e60073;
  }

  100% {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6,
      0 0 80px #ff4da6;
  }
}

@keyframes glow {
  50% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073,
      0 0 70px #e60073;
  }

  100% {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6,
      0 0 80px #ff4da6;
  }
}

/* ---------------------------------------------------- AccountDisplay.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #addressDisplay {
    width: 50%;
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  
}


@media (min-width: 466px) and (max-width: 768px) { 
}

@media (min-width: 769px) and (max-width: 1366px) {
  #addressDisplay {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1367px) {
  #addressDisplay {
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}


#profileHover {
  display: inline;
  color: white;
}

#addressDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;
  // color: white;
  // padding-right: 10px;
}

.addressButton:hover {
  transform: scale(0.96);
  cursor: pointer;
}

// balanceDisplay.jsx

.balanceWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.balanceText {
  font-size: 1.5em;
  font-weight: 600;
  color: #000;
}

.balanceNumber {
  font-size: 1.5em;
  font-weight: 600;
  color: #000;
}

.balanceIcon {
  color: #000;
  font-size: 1.5em;
  margin-left: 0.25rem;
}

.glow {
  transition: text-shadow 0.3s ease;
  
  &:hover {
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }
}

.ethBalanceText, .xpBalanceText {
  margin: 0;
  padding: 0.5rem;
}

// Modal Styles
.modalContainer {
  .modal-content {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.modalTitle {
  font-size: 1.5em;
  font-weight: 600;
  color: #000;
}

.modalBody {
  padding: 1.5rem;
}

.modalText {
  font-size: 1.1em;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1.5rem;
}

.modalLink {
  color: #0066cc;
  text-decoration: none;
  font-weight: 600;
  
  &:hover {
    text-decoration: underline;
  }
}

.imageWrapper {
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
}

.modalImage {
  height: 200px;
  object-fit: contain;
  border-radius: 8px;
}

.modalSubtitle {
  font-size: 1.3em;
  font-weight: 600;
  color: #000;
  margin: 1.5rem 0 1rem;
}

.votesList {
  list-style-type: none;
  padding-left: 0;
  
  li {
    font-size: 1.1em;
    padding: 0.75rem 0;
    border-bottom: 1px solid #eee;
    
    &:last-child {
      border-bottom: none;
    }
  }
}

.modalFooter {
  border-top: 1px solid #eee;
  padding: 1rem 1.5rem;
}

.modalButton {
  font-size: 1.1em;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  background-color: #f5f5f5;
  border: none;
  color: #333;
  font-weight: 500;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #e5e5e5;
  }
}

.ethBalance {
  font-size: 1.5em;
  font-weight: 600;
  color: #000;
}

// Force white background for modal
.modalContent {
  background-color: white !important;
  
  :global(.modal-content) {
    background-color: white !important;
    transform: none !important;
  }
}

#voteUseStyle {
  font-weight: 600;
  font-size: 1.25em;
  color: black !important;
}

.modalContent {
  transform: none !important;
}

:global(.modal-dialog) {
  transform: none !important;
}