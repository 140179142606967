/* ---------------------------------------------------- Footer.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #footer {
    padding: 0;
    border-color: #1d8cf8;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    background: transparent;
    box-sizing: unset;

    #copyright {
      text-align: center;
      line-height: 1.5;
      font-family: inherit;
    }
  }
}

@media (min-width: 320px) and (max-width: 465px) {
  #footer {
    padding: 0;
    border-color: #1d8cf8;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    background: transparent;
    box-sizing: unset;

    #copyright {
      text-align: center;
      line-height: 1.5;
      font-family: inherit;

    }
  }
}

@media (min-width: 466px) and (max-width: 768px) {
  #footer {
    padding: 0;
    border-color: #1d8cf8;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    background: transparent;
    box-sizing: unset;

    #copyright {
      text-align: center;
      line-height: 1.5;
      font-family: inherit;
    }
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
  #footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-color: #1d8cf8;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    background: transparent;

    #copyright {
      justify-self: flex-end;
      font-family: inherit;
    }
  }
}

@media (min-width: 1367px) {
  #footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2.5em;
    padding-right: 2.5em;
    border-color: #1d8cf8;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    background: transparent;

    #copyright {
      justify-self: flex-end;
      font-family: inherit;
      color: #ffffff5e;
    }
  }
}

#footer {
  margin-top: 3%;

  #copyright {
    font-size: 1rem;
    a {
      color: #1d8cf8;
      font-weight: 600;
    }
  }
}

#footerLink {
  font-size: 1rem;
  margin-left: 5px;
  margin-right: 5px;
}