/* PolisReport.module.scss */

/**************************************************************
 * PolisReport.module.scss
 *
 * SCSS styles for the PolisReport component, including
 * new classes for the PolisBoxPlot color-coded horizontal bar,
 * disclaimers, hovered circle color on the beeswarm,
 * cluster color swatches, collapsible sections, half-transparent
 * backgrounds for cluster groups, PDF printing toggles, etc.
 * 
 * Existing styles remain. We add .toggleButtonActive and
 * .toggleButtonInactive for your new participant graph toggles
 * to appear blue or grey accordingly, and unify the outline hull.
 **************************************************************/

 .polisReportContainer {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    position: relative;
    background: #fafafa;
  }
  
  .heading {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .demoToggleRow {
    margin-bottom: 8px;
  }
  
  .demoToggleLabel {
    font-size: 0.9rem;
    color: #555;
    cursor: pointer;
  }
  
  .demoToggleCheckbox {
    margin-right: 5px;
    cursor: pointer;
  }
  
  .disclaimerBox {
    border: 1px solid #ccc;
    background: #ffffe0;
    padding: 8px;
    margin-bottom: 12px;
    font-size: 0.9rem;
  }
  
  .noData {
    font-style: italic;
    color: #666;
  }
  
  .statsSection {
    margin-bottom: 20px;
    padding: 12px;
    background: #fff;
    border: black solid 0.5px;
  }
  
  .statsSectionCollapsible {
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  .statsRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
  }
  
  .statsItem {
    margin-right: 20px;
    margin-bottom: 4px;
  }
  
  .statLabel {
    font-weight: 600;
    margin-right: 4px;
  }
  
  .statValue {
    color: #333;
  }
  
  .groupFilterBox {
    border: 1px solid #ccc;
    padding: 10px;
    background: #f7f7f7;
    margin-bottom: 20px;
  }
  
  .graphSection {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .graphItem {
    margin-right: 30px;
    margin-bottom: 20px;
  }
  
  .participantSvg {
    border: 1px solid #ddd;
    background: #fff;
  }
  
  .swarmContainer {
    position: relative;
  }
  
  .beeswarmSvg {
    border: 1px solid #ddd;
    background: #fff;
    overflow: visible;
  }
  
  /* Non-hover circle style */
  .beeswarmCircle {
    fill: steelblue;
  }
  
  /* Hovered circle style */
  .beeswarmCircleHover {
    fill: #ff9900;
  }
  
  /* Tooltip: a bit wider */
  .beeTooltip {
    position: absolute;
    width: 300px;
    background: #ffffff;
    border: 1px solid #bbb;
    padding: 10px;
    font-size: 0.85rem;
    pointer-events: none;
    z-index: 999;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
  
  .tooltipPrompt {
    font-weight: 600;
    margin-bottom: 6px;
    color: #333;
    font-size: 0.78rem;
  }
  
  .polisBoxPlotContainer {
    display: inline-block;
    margin-left: 10px;
  }
  
  .polisBoxPlotSvg {
    display: block;
    border: black solid 0.5px;
  }
  
  /* The .pdfIgnore class is hidden when generating the PDF */
  .pdfIgnore {
    display: block;
  }
  
  @media print {
    .pdfIgnore {
      display: none !important;
    }
    /* Hide tooltips in PDF/print */
    .beeTooltip {
      display: none !important;
    }
  }
  
  .sectionCollapse {
    margin-bottom: 20px;
  }
  
  .sectionHeaderRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .sectionTitle {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  #sectionHeader {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #333;
    font-weight: 600;
  }
  
  /* For color-coding cluster swatches */
  .clusterSwatchSvg {
    vertical-align: middle;
  }
  
  /* Beeswarm text description */
  .beeswarmDescription {
    margin-top: 10px;
    font-size: 0.85rem;
    color: #444;
  }
  
  .beeswarmDescText {
    margin: 0;
    line-height: 1.4;
  }
  
  /* Simple icon for tooltips */
  .tooltipIcon {
    display: inline-block;
    margin-left: 4px;
    color: #555;
    cursor: help;
  }
  
  /* For half-transparent backgrounds behind each cluster grouping */
  .clusterContainer {
    position: relative;
  }
  
  .clusterBackgroundRect {
    fill: rgba(255, 0, 0, 0.2);
  }
  
  .clusterSectionDiv {
    margin-bottom: 12px;
  }
  
  /* Add a row for the top toggles */
  #settingsRow {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #8080805e;
    padding: 10px;
  }
  
  /* PDF Mode classes */
  .pdfMode {
    /* Show/hide pieces specifically for PDF if needed */
  }
  
  .pdfMode .showWhenPdf {
    display: inline;
  }
  
  .showWhenPdf {
    display: none;
  }
  
  /* Usually display these, but hide them in pdfMode */
  .hiddenInPdf {
    display: block;
  }
  
  .pdfMode .hiddenInPdf {
    display: none !important;
  }
  
  /* Toggle button states */
  .toggleButtonActive {
    background-color: #03a9f4;
    color: white;
    border: 1px solid #03a9f4;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.8rem;
  }
  
  .toggleButtonInactive {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #999;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.8rem;
  }
  
  .pdfMode .beeTooltip {
    display: none !important;
  }
  