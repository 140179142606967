/* ---------------------------------------------------- LoginModal.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
  #navConnectContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  #loginIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 0px;
    transform: scale(0.75);
    padding-left: 1em;
    padding-right: 1em;
    opacity: .3;

    .img {
        vertical-align: top;
        transform: scale (0.65);
    }
  }

  #loginIcon {
    margin-right: 0.05em;
    margin-left: 0.05em;
    padding: 0px;
    transform: scale(0.75);
    vertical-align: top;
  }

  #navConnectButton {
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    justify-content: center;
    transform: scale(0.90);
  }

  #loginPromptText {
    font-size: 1.75em;
  }

  #metamaskIcon {
      margin-left: .1em;
    display: inline;
    }

    #modalLoginOptions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        padding-left: 5px;
        padding-right: 5px;
    

        #torusLoginOption {
          width: 100%;
        }
    }

    #testnetFaucetNotice {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 0.9em;
      color: #236313;

      #faucetLinkAndDetail {
        font-size: 1em;
        align-self: center;
        padding: 3px;
      }

    
      #testETHFaucetLink {
        align-self: flex-start;
        padding-top: 10px;
        padding-bottom: 0px;
        font-size: 1em;
      }


      
    #testnetLinkDetail {
      // display: inline;
      font-size: .65em;
      // padding-bottom: 3px;
    }

    #rpcURLNotice {
      display: flex;
      flex-direction: column;
      align-items: center;  
      justify-content: flex-end;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: .75em;
      margin-bottom: 5px;
    }

    #rpcURLTitle {
      align-self: flex-start;
    }

    #rpcURL {
      display: flex;
      flex-direction: row;
      justify-content: center;
      // padding-top: 5px;
      // padding-bottom: 5px;
      // padding-left: 3px;
      // padding-right: 3px;
      font-size: .7em;
    }

    #rpcURLText {
      width: 80%;
      flex-direction: row;
      flex-wrap: wrap;
      font-weight: 600;
      color: #146923;
      margin-bottom: 0;
      padding-left: 10px;
      align-self: center;
      font-size: 1.5em;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 3px;
    }

    #copyLinkButton {
      flex-direction: row;
      // width: 20%;   
      padding-right: 10px; 
      // margin-right: 10px;
    
      #copyButtonBeforePress {
        flex-direction: row;
        font-size: 2em;
      }
    
      #copyButtonAfterPress {
        flex-direction: row;
        color: black;
        font-size: 2em;
      }
    
    }
    }

    #testnetFaucetNoticeSmall {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 0.9em;
      color: #236313;

      #faucetLinkAndDetail {
        font-size: 1em;
        align-self: center;
        padding: 3px;
      }

    
      #testETHFaucetLink {
        align-self: flex-start;
        padding-top: 10px;
        padding-bottom: 0px;
        font-size: 1em;
      }


      
    #testnetLinkDetail {
      // display: inline;
      font-size: .65em;
      // padding-bottom: 3px;
    }

    #rpcURLNotice {
      display: flex;
      flex-direction: column;
      align-items: center;  
      justify-content: flex-end;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: .75em;
      margin-bottom: 5px;
    }

    #rpcURLTitle {
      align-self: flex-start;
    }

    #rpcURL {
      display: flex;
      flex-direction: row;
      justify-content: center;
      // padding-top: 5px;
      // padding-bottom: 5px;
      // padding-left: 3px;
      // padding-right: 3px;
      font-size: .7em;
    }

    #rpcURLText {
      width: 80%;
      flex-direction: row;
      flex-wrap: wrap;
      font-weight: 600;
      color: #146923;
      margin-bottom: 0;
      padding-left: 10px;
      align-self: center;
      font-size: 1.5em;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 3px;
    }

    #copyLinkButton {
      flex-direction: row;
      // width: 20%;   
      padding-right: 10px; 
      // margin-right: 10px;
    
      #copyButtonBeforePress {
        flex-direction: row;
        font-size: 2.5em;
      }
    
      #copyButtonAfterPress {
        flex-direction: row;
        color: black;
        font-size: 2.5em;
      }
    
    }

    }
}

@media (min-width: 320px) and (max-width: 465px) {
  #navConnectContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  #navConnectButton {
    padding-bottom: 7.5px;
    padding-top: 7.5px;
    // padding-left: 5px;
    // padding-right: 5px;
    display: flex;
    justify-content: center;
    justify-content: center;
    transform: scale(0.90);
  }

  #loginIcons {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 0px;
    transform: scale(0.80);
    padding-left: 1em;
    padding-right: 1em;
    opacity: .5;

    .img {
        vertical-align: top;
        transform: scale (0.8);
    }
  }

  #loginIcon {
    margin-right: 0.1em;
    // margin-left: 0.1em;
    padding: 0px;
    transform: scale(0.8);
    vertical-align: top;
  }

  #loginPromptText {
    font-size: 1.75em;
  }

  #metamaskIcon {
    margin-left: .1em;
    display: inline;
}

#modalLoginOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-right: 5px;


    #torusLoginOption {
      width: 100%;
    }
}

#testnetFaucetNotice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.9em;
  color: #236313;

  #faucetLinkAndDetail {
    font-size: 1em;
    align-self: center;
    padding: 5px;
  }


  #testETHFaucetLink {
    align-self: flex-start;
    padding-top: 10px;
    padding-bottom: 0px;
    font-size: 1em;
  }

  #testnetLinkDetail {
    // display: inline;
    font-size: .7em;
    // padding-bottom: 5px;
  }

#rpcURLNotice {
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: .75em;
  margin-bottom: 5px;
}

#rpcURLTitle {
  align-self: flex-start;
  font-size: 1.25em;
}

#rpcURL {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 3px;
  // padding-right: 3px;
  font-size: .7em;
}

#rpcURLText {
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 600;
  color: #146923;
  margin-bottom: 0;
  padding-left: 10px;
  align-self: center;
  font-size: 1.5em;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 3px;
}

#copyLinkButton {
  flex-direction: row;
  // width: 20%;   
  padding-right: 10px; 
  // margin-right: 10px;

  #copyButtonBeforePress {
    flex-direction: row;
    font-size: 3em;
  }

  #copyButtonAfterPress {
    flex-direction: row;
    // color: black;
    font-size: 3em;
  }

}
}

#testnetFaucetNoticeSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.9em;
  color: #236313;

  #faucetLinkAndDetail {
    font-size: 1em;
    align-self: center;
    padding: 5px;
  }


  #testETHFaucetLink {
    align-self: flex-start;
    padding-top: 10px;
    padding-bottom: 0px;
    font-size: 1em;
  }

  #testnetLinkDetail {
    // display: inline;
    font-size: .7em;
    // padding-bottom: 5px;
  }

#rpcURLNotice {
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: .75em;
  margin-bottom: 5px;
}

#rpcURLTitle {
  align-self: flex-start;
  font-size: 1.25em;
}

#rpcURL {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-left: 3px;
  // padding-right: 3px;
  font-size: .7em;
}

#rpcURLText {
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 600;
  color: #146923;
  margin-bottom: 0;
  padding-left: 10px;
  align-self: center;
  font-size: 1.5em;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 3px;
}

#copyLinkButton {
  flex-direction: row;
  // width: 20%;   
  padding-right: 10px; 
  // margin-right: 10px;

  #copyButtonBeforePress {
    flex-direction: row;
    font-size: 3em;
  }

  #copyButtonAfterPress {
    flex-direction: row;
    // color: black;
    font-size: 3em;
  }

}
}

}

@media (min-width: 466px) and (max-width: 768px) {
    #navConnectContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      flex-direction: row;
      // width: 92%;
      height: 100%;
      padding: 0px;
      // margin-right: 5%;
      // margin-left: 2%;
    }
  
    #navConnectButton {
      // padding-bottom: 6px;
      // padding-top: 6px;
      display: flex;
      justify-content: center;
      // justify-content: center;
    //   transform: scale(0.75);
      // padding: 5% 2% 5% 2%;
      padding: 3% 0% 3% 0%;
    }
  
    #loginIcons {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-top: 5px;
      transform: scale(0.8);
      padding-left: 1em;
      padding-right: 1em;
      opacity: .4;
  
      .img {
          vertical-align: top;
          transform: scale (0.8);
      }
    }
  
    #loginIcon {
      margin-right: 0.1em;
      margin-left: 0.1em;
      padding: 0px;
    //   transform: scale(0.8);
      vertical-align: top;
    }
  
    #loginPromptText {
      font-size: 2em;
    }
  
    #metamaskIcon {
      margin-left: .1em;
      display: inline;
  }

  #modalLoginOptions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 5%;
    padding-right: 5%;

    #torusLoginOption {
      width: 100%;
    }
}

  #testnetFaucetNotice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.9em;
    color: #236313;

    #faucetLinkAndDetail {
      font-size: 1.25em;
      align-self: center;
      padding: 10px;
    }


    #testETHFaucetLink {
      align-self: flex-start;
      padding-top: 10px;
      padding-bottom: 0px;
      font-size: 1em;
    }

    #testnetLinkDetail {
      // display: inline;
      font-size: .75em;
      // padding-bottom: 10px;
    }

  #rpcURLNotice {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: .75em;
    margin-bottom: 5px;
  }

  #rpcURLTitle {
    align-self: flex-start;
    font-size: 1.5em;
  }

  #rpcURL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 3px;
    // padding-right: 3px;
    font-size: .7em;
  }

  #rpcURLText {
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;
    color: #146923;
    margin-bottom: 0;
    padding-left: 10px;
    align-self: center;
    font-size: 1.75em;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 3px;
  }

  #copyLinkButton {
    flex-direction: row;
    // width: 20%;   
    padding-right: 10px; 
    // margin-right: 15px;

    #copyButtonBeforePress {
      flex-direction: row;
      font-size: 3.5em;
    }

    #copyButtonAfterPress {
      flex-direction: row;
      color: black;
      font-size: 3.5em;
    }

  }
  }

  #testnetFaucetNoticeSmall {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.9em;
    color: #236313;
  
    #faucetLinkAndDetail {
      font-size: 1.25em;
      align-self: center;
      padding: 10px;
    }
  
  
    #testETHFaucetLink {
      align-self: flex-start;
      padding-top: 10px;
      padding-bottom: 0px;
      font-size: 1em;
    }
  
    #testnetLinkDetail {
      // display: inline;
      font-size: .75em;
      // padding-bottom: 10px;
    }
  
  #rpcURLNotice {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: .75em;
    margin-bottom: 5px;
  }
  
  #rpcURLTitle {
    align-self: flex-start;
    font-size: 1.5em;
  }
  
  #rpcURL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 3px;
    // padding-right: 3px;
    font-size: .7em;
  }
  
  #rpcURLText {
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;
    color: #146923;
    margin-bottom: 0;
    padding-left: 10px;
    align-self: center;
    font-size: 1.75em;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 3px;
  }
  
  #copyLinkButton {
    flex-direction: row;
    // width: 20%;   
    // padding-right: 10px; 
    // margin-right: 10px;
    padding: 10px;
  
    #copyButtonBeforePress {
      flex-direction: row;
      font-size: 3em;
    }
  
    #copyButtonAfterPress {
      flex-direction: row;
      color: black;
      font-size: 3em;
    }
  
  }
  }
}

@media (min-width: 769px) and (max-width: 1366px) {
    #navConnectContainer {
        display: flex;
        // align-items: center;
        align-content: center;
        justify-content: flex-end;
        flex-direction: row;
        /* width: 100%; */
        height: 100%;
        // padding: 0px;
        margin-right: 0;
    }
  
    #navConnectButton {
      display: flex;
      justify-content: center;
      padding: 4% 0% 4% 0%;
    }
  
    #loginIcons {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      // margin-top: 5px;
      transform: scale(0.85);
      padding-left: 1em;
      padding-right: 1em;
      opacity: .5;
  
      .img {
          vertical-align: top;
        //   transform: scale (0.8);
      }
    }
  
    #loginIcon {
      margin-right: 0.15em;
      margin-left: 0.15em;
      padding: 0px;
    //   transform: scale(0.8);
      vertical-align: top;
    }
  
    #loginPromptText {
      font-size: 2em;
      padding-bottom: 7.5px;
    }
  
    #metamaskIcon {
      margin-left: .1em;
      display: inline;
  }

  #modalLoginOptions {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    justify-content: space-evenly;
    align-items: center;
    // flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;

    #torusLoginOption {
      width: 50%;
      // width: 45%; // TODO
      height: 100%;
      margin-left: 20px;
      // padding-left: 15px;
      // padding-right: 15px;
    }

    #metamaskInstructions {
      width: 50%;
      // transform: scale(.85); // TODO
      // transform: scale(.8); // TODO
      margin-bottom: 0px;
    }
}

  #testnetFaucetNotice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
    font-size: 0.9em;
    color: #236313;

    #faucetLinkAndDetail {
      font-size: 1.5em;
      align-self: center;
      padding: 12.5px;
    }


    #testETHFaucetLink {
      align-self: flex-start;
      padding-top: 10px;
      padding-bottom: 0px;
      font-size: 1em;
    }

    #testnetLinkDetail {
      // display: inline;
      font-size: .85em;
      // padding-bottom: 15px;
    }

  #rpcURLNotice {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: .75em;
    margin-bottom: 5px;
  }

  #rpcURLTitle {
    align-self: flex-start;
    font-size: 1.75em;
  }

  #rpcURL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 3px;
    // padding-right: 3px;
    font-size: .7em;
  }

  #rpcURLText {
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;
    color: #146923;
    margin-bottom: 0;
    padding-left: 10px;
    align-self: center;
    font-size: 2em;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 3px;
  }

  #copyLinkButton {
    flex-direction: row;
    // width: 20%;   
    // padding-right: 10px; 
    // margin-right: 20px;
    // margin-top: 10px;
    //   margin-bottom: 10px;
      // padding-top: 10px;
      // padding-bottom: 10px;
      padding: 10px;

    #copyButtonBeforePress {
      flex-direction: row;
      font-size: 3.5em;
    }

    #copyButtonAfterPress {
      flex-direction: row;
      color: black;
      font-size: 2.5em;
    }

  }
  }

  #testnetFaucetNoticeSmall {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
    font-size: 0.9em;
    color: #236313;
  
    #faucetLinkAndDetail {
      font-size: 1.5em;
      align-self: center;
      padding: 12.5px;
    }
  
  
    #testETHFaucetLink {
      align-self: flex-start;
      padding-top: 10px;
      padding-bottom: 0px;
      font-size: 1em;
    }
  
    #testnetLinkDetail {
      // display: inline;
      font-size: .85em;
      // padding-bottom: 15px;
    }
  
  #rpcURLNotice {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: .75em;
    margin-bottom: 5px;
  }
  
  #rpcURLTitle {
    align-self: flex-start;
    font-size: 1.75em;
  }
  
  #rpcURL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 3px;
    // padding-right: 10px;
    font-size: .7em;
  }
  
  #rpcURLText {
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;
    color: #146923;
    margin-bottom: 0;
    padding-left: 10px;
    align-self: center;
    font-size: 2em;
    line-height: 1.25;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  #copyLinkButton {
    flex-direction: row;
    // width: 20%;   
    // padding-right: 10px; 
    // margin-right: 20px;
    // margin-top: 10px;
    //   margin-bottom: 10px;
      // padding-top: 10px;
      // padding-bottom: 10px;
      padding: 10px;
  
    #copyButtonBeforePress {
      flex-direction: row;
      font-size: 3.5em;
    }
  
    #copyButtonAfterPress {
      flex-direction: row;
      color: black;
      font-size: 3.5em;
    }
  
  }
  }
}

@media (min-width: 1367px) {
    #navConnectContainer {
        display: flex;
        // align-items: center;
        align-content: center;
        justify-content: flex-end;
        flex-direction: row;
        /* width: 100%; */
        height: 100%;
        // padding: 0px;
    }
  
    #navConnectButton {
      // padding-bottom: 10px;
      // padding-top: 10px;
      display: flex;
      justify-content: center;
      justify-content: center;
      padding: 2% 0% 2% 0%;
    //   transform: scale(0.75);
    }
  
    #loginIcons {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-top: 0px;
      transform: scale(0.95);
      padding-left: 1em;
      padding-right: 1em;
      opacity: .5;
  
      .img {
          vertical-align: top;
        //   transform: scale (0.8);
      }
    }
  
    #loginIcon {
      margin-right: 0.2em;
      margin-left: 0.2em;
      padding: 0px;
      vertical-align: top;
    }
  
    #loginPromptText {
      font-size: 2.5em;
      padding-bottom: 10px;
    }
  
    #metamaskIcon {
      margin-left: .1em;
      display: inline;
  }

  #modalLoginOptions {
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    justify-content: space-evenly;
    // flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;

    #torusLoginOption {
      width: 50%;
      margin-left: 20px;
      // height: 100%;
      // padding-left: 25px;
      // padding-right: 25px;
    }

    #metamaskInstructions {
      width: 50%;
      // transform: scale(.8); // TODO
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }

  #testnetFaucetNotice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    font-size: 1em;
    color: #236313;

    #faucetLinkAndDetail {
      font-size: 1.75em;
      align-self: center;
      padding: 15px;
    }

  
    #testETHFaucetLink {
      align-self: flex-start;
      padding-top: 10px;
      padding-bottom: 0px;
      font-size: 1em;
    }

    #testnetLinkDetail {
      // display: inline;
      font-size: 1em;
      // padding-bottom: 15px;
    }

  #rpcURLNotice {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: .75em;
    margin-bottom: 5px;
  }

  #rpcURLTitle {
    align-self: flex-start;
    font-size: 1.95em;
  }

  #rpcURL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 3px;
    // padding-right: 3px;
    font-size: .7em;
  }

  #rpcURLText {
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;
    color: #146923;
    margin-bottom: 0;
    padding-left: 10px;
    align-self: center;
    font-size: 2.5em;
    line-height: 1.25;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 3px;
  }

  #copyLinkButton {
    flex-direction: row;
    // width: 20%;   
    // padding-right: 10px; 
    // margin-right: 25px;
    // margin-top: 15px;
    // margin-bottom: 15px;
    // padding-top: 15px;
    // padding-bottom: 15px;
    padding: 15px;
  
    #copyButtonBeforePress {
      flex-direction: row;
      font-size: 4em;
    }
  
    #copyButtonAfterPress {
      flex-direction: row;
      color: black;
      font-size: 4em;
    }
  
  }
  }

  #testnetFaucetNoticeSmall {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    font-size: 1em;
    color: #236313;

    #faucetLinkAndDetail {
      font-size: 1.75em;
      align-self: center;
      padding: 15px;
    }

  
    #testETHFaucetLink {
      align-self: flex-start;
      padding-top: 10px;
      padding-bottom: 0px;
      font-size: 1em;
    }

    #testnetLinkDetail {
      // display: inline;
      font-size: 1em;
      // padding-bottom: 15px;
    }

  #rpcURLNotice {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: flex-end;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: .75em;
    margin-bottom: 5px;
  }

  #rpcURLTitle {
    align-self: flex-start;
    font-size: 1.95em;
  }

  #rpcURL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 3px;
    // padding-right: 10px;
    font-size: .7em;
  }

  #rpcURLText {
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;
    color: #146923;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    align-self: center;
    font-size: 2.5em;
    line-height: 1.25;
  }

  #copyLinkButton {
    flex-direction: row;
    // width: 20%;   
    // padding-right: 10px; 
    // margin-right: 25px;
    // margin-top: 15px;
    // margin-bottom: 15px;
    // padding-top: 15px;
    // padding-bottom: 15px;
    padding: 15px;
  
    #copyButtonBeforePress {
      flex-direction: row;
      font-size: 4em;
    }
  
    #copyButtonAfterPress {
      flex-direction: row;
      color: black;
      font-size: 4em;
    }
  
  }
  }
}

h1 {
  color: white;
  font-weight: 700;
}

#navConnectButton {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  // background-color: #5e72e4 !important;
  // background: #5e72e4 !important;
  background: #2a63ca !important;
  box-shadow: -5px 5px 10px #10122e, 5px -5px 10px #1e2155
  

  h1 {
    margin-bottom: 0px;
    font-weight: 700;
  }
}

#navConnectButton:hover.btn.btn-blue {
  background-color: #5e72e4;
  background: #5e72e4;
}

#loginPromptText {
  color: white;
}

#loginModal {

 
}

#closeModalIcon {
    font-size: 2em;
    color: white;
}



#torusInfoModal {
  display: flex;
  flex-direction: column;
}

#torusInfoModalTitle {
  padding: 5px;
  color: white;
  font-weight: 600;
  text-shadow: 2px 2px #5e72e4;
}

#acceptModalInfoButton {
  color: white;
  border-color: white;
  width: 50%;
  justify-self: flex-start;
}

#modalXPicon {
  display: inline;
  color: white;
  text-shadow: 2px 2px #5e72e4;
  margin-left: .1em;
}

#betaTesterText {
  font-weight: 700;

  background-color: transparentize($color: #eeff00, $amount: .5);
}

#torusLoginOption {
  // width: 100%;

}



#metamaskInstructions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* background: linear-gradient(45deg, #212457, #1c1f49); */
  // box-shadow: 5px -5px 10px #141533, -5px 5px 10px #2a2f6f;
  border-radius: 5px;
  // border: solid #ffa50061 0.55px;
               
}



#MetamaskRPCText {
// display: inline;
text-transform: uppercase;
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 0px;
}

#connectMetamaskSteps {
display: flex;
flex-direction: column;
justify-content: flex-start;
// margin-bottom: 5px;
}

#connectMetamaskStep {
  font-weight: 600;
  font-size: .85em;
  margin-bottom: 0px;
  opacity: .75;
  color: #1d8cf8;
}

#loginModalCard {
  border-radius: 10px;
}

#testnetFaucetNoticeSmall {
width: 100%;
display: flex;
flex-direction: column-reverse;
// justify-content: center;
justify-content: space-evenly;
// font-size: 1.5em;
// text-transform: uppercase;
border-radius: 7px;
// background: linear-gradient(45deg, #212457, #1c1f49);
// box-shadow:  5px -5px 10px #141533, 
//              -5px 5px 10px #2a2f6f;
font-weight: 600;
margin-bottom: 0px;
margin-top: 0px;
#testETHFaucetLink {
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  //   font-size: 1em;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: underline;
    color: white;
  }
  
  #faucetLinkAndDetail {
    // align-items: center;
    width: 100%;
    // font-size: 1.5em;
    display: flex;
    // flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    // padding: 10px;
  }
  
  #faucetButton {
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    background-color: #f7f1f1d1;
    text-transform: uppercase;
    font-weight: 600;
    padding: 6px;
    border-radius: 2.5px;
  }
  
  #testnetLinkDetail {
    display: inline;
    color: #0000004f;
    text-transform: uppercase;
    padding-left: 5px;
    font-weight: 600;
  }
  
  #rpcURLNotice {
    display: flex;
    // flex-direction: row;
    // align-items: center;  
    // justify-content: flex-end;
    // font-size: .65em;
    // display: inline;
    // margin-top: 5px;
    // margin-bottom: 5px;
    // background: #fb6340;
    background:#e45f4000;
    // border: 1px solid #ffffff40;
    // border-radius: 2.5px;
    color: #ffffffe0;
  
    // // padding-top: 5px;
    // // padding-bottom: 5px;
    // // padding-left: 10px;
    // // padding-right: 10px;
    
    // font-weight: 600;
    // font-size: .75em;
  }
  
  #rpcURLTitle {
    align-self: flex-start;
    text-transform: uppercase;
    color: #ffffff85;
    // padding-bottom: 5px;
    padding-bottom: 5px;
  }
  
  #rpcURL {
    height: 100%;
    width: 100%;
    display: flex;
    // flex-direction: row;
    // justify-content: center;
    // background: #16b749;
    background: #fb634091;
    // border: 1px solid white;
    // border-radius: .5px;
    color: #332d2d;
    // // font-size: 1em;
    // // font-size: .65em;
    // padding-top: 5px;
    // padding-bottom: 5px;
    // padding-left: 10px;
    // padding-right: 10px;
    // margin-top: 5px;
    // margin-bottom: 5px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  
  #rpcURLText {
    // width: 50%;
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;
    // color: #146923;
    color: #fb6340;
    // margin-bottom: 0;
    // padding-left: 10px;
    align-self: center;
    line-height: 1.25;
  }
  
  
  // TODO: should be class, not ID
  #copyLinkButton {
  
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    // width: 20%;
    justify-content: center;
    align-items: center;
    // background-color: black;
    background-color: transparent;
    // background-color: #ff4122;
  
  
    #copyButtonBeforePress {
  
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      text-transform: uppercase;
      // color: black;
      color: #0000007a;
      // font-size: 2em;
      background: transparent;
      border: none;
  
    }
  
    #copyButtonAfterPress {
      display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      text-transform: uppercase;
      // color: black;
      // font-size: 2em;
      background: transparent;
      border: none;
    }
  
  }
}

#testnetFaucetNotice {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  // justify-content: center;
  justify-content: space-evenly;
  // font-size: 1.5em;
  // text-transform: uppercase;
  border-radius: 7px;
  // background: linear-gradient(45deg, #212457, #1c1f49);
  // box-shadow:  5px -5px 10px #141533, 
  //              -5px 5px 10px #2a2f6f;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 10px; // TODO

  #testETHFaucetLink {
    //   padding-top: 10px;
    //   padding-bottom: 10px;
    //   font-size: 1em;
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: underline;
      color: white;
    }
    
    #faucetLinkAndDetail {
      // align-items: center;
      width: 100%;
      // font-size: 1.5em;
      display: flex;
      // flex-direction: row;
      align-self: center;
      align-items: center;
      justify-content: center;
      align-content: center;
      // padding: 10px;
    }
    
    #faucetButton {
      width: 100%;
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
      background-color: #f7f1f1d1;
      text-transform: uppercase;
      font-weight: 600;
      padding: 6px;
      border-radius: 2.5px;
    }
    
    #testnetLinkDetail {
      display: inline;
      color: #0000004f;
      text-transform: uppercase;
      padding-left: 5px;
      font-weight: 600;
    }
    
    #rpcURLNotice {
      display: flex;
      // flex-direction: row;
      // align-items: center;  
      // justify-content: flex-end;
      // font-size: .65em;
      // display: inline;
      // margin-top: 5px;
      // margin-bottom: 5px;
      // background: #fb6340;
      background:#e45f4000;
      // border: 1px solid #ffffff40;
      // border-radius: 2.5px;
      color: #ffffffe0;
    
      // // padding-top: 5px;
      // // padding-bottom: 5px;
      // // padding-left: 10px;
      // // padding-right: 10px;
      
      // font-weight: 600;
      // font-size: .75em;
    }
    
    #rpcURLTitle {
      align-self: flex-start;
      text-transform: uppercase;
      color: #ffffff85;
      // padding-bottom: 5px;
      padding-bottom: 5px;
    }
    
    #rpcURL {
      width: 100%;
      display: flex;
      // flex-direction: row;
      // justify-content: center;
      background: #16b749;
      // border: 1px solid white;
      // border-radius: .5px;
      color: #332d2d;
      // // font-size: 1em;
      // // font-size: .65em;
      // padding-top: 5px;
      // padding-bottom: 5px;
      // padding-left: 10px;
      // padding-right: 10px;
      // margin-top: 5px;
      margin-bottom: 5px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    
    #rpcURLText {
      // width: 50%;
      display: flex;
      // flex-direction: row;
      flex-wrap: wrap;
      font-weight: 600;
      color: #146923;
      // color: #fb6340;
      // margin-bottom: 0;
      // padding-left: 10px;
      align-self: center;
      line-height: 1.25;
    }
    
}



// TODO: should be class, not ID
#copyLinkButton {

  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
  // width: 20%;
  justify-content: center;
  align-items: center;
  background-color: transparent;


  #copyButtonBeforePress {

    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
      // color: black;
      color: #0000007a;
    // font-size: 2em;
    background: transparent;
    border: none;

  }

  #copyButtonAfterPress {
    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    // color: black;
    // font-size: 2em;
    background: transparent;
    border: none;
  }

}

#downloadMetamaskLink {
  display: inline;
  color:  #fb6340;
}

  #testETHFaucetLink {
    // color: #fb6340;
    // color:  #1d8cf8;
  }

  #web3ModalLoginText {
    font-weight: 600;
    font-size: 50px;
    margin-bottom: 10px;
    padding-left: 20px;
  }

  

/* ---------------------------------------------------- TorusLoginButton.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

  #torusButtonContainer {
    // margin-left: 10px;
    margin-left: 0px;
  }

    #inModalTorusButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        // flex: 1;
        background: #5e72e4;
        // padding: 0em 0.5em 0em 0.5em;
        // margin-bottom: 1em;
        // padding: 6.5px;
        width: 100%;


        #torusConnectText {
            font-size: 1.5em;
            color: white;
            margin-top: .5em;
            margin-bottom: .5em;
        }

        #loginIconInModal {
            margin-right: 0.1em;
            margin-left: 0.1em;
            font-size: 1em;
        }
      }

      #loginIconsInsideModal {
        width: 100%;
        transform: scale(.8);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        /* margin-top: 0.5em; */
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
}

@media (min-width: 320px) and (max-width: 465px) {

  #torusButtonContainer {
    // margin-left: 10px;
    margin-left: 0px;
  }

    #inModalTorusButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        // flex: 1;
        background: #5e72e4;
        // padding: 1em 1em 1em 1em;
        // margin-bottom: 1em;
        // padding: 7.5px;
        width: 100%;


        #torusConnectText {
            font-size: 2em;
            color: white;
            margin-top: .5em;
            margin-bottom: .5em;
        }

        #loginIconInModal {
            margin-right: 0.1em;
            margin-left: 0.1em;
            font-size: 1em;
        }
      }

      #loginIconsInsideModal {
        width: 100%;
        // transform: scale(.95);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        /* margin-top: 0.5em; */
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
}


@media (min-width: 466px) and (max-width: 768px) { 

  #torusButtonContainer {
    // margin-left: 15px;
    margin-left: 0px;
  }

    #inModalTorusButton {
      
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #5e72e4;
        margin-top: 10px;
        // padding: 10px;
        width: 100%;
        // padding: 15px 15px 15px 15px;
margin: 7px;


        #torusConnectText {
            font-size: 2em;
            color: white;
            margin-top: .5em;
            margin-bottom: .5em;
        }

        #loginIconInModal {
            margin-right: 0.1em;
            margin-left: 0.1em;
            font-size: 1em;
        }
      }

      #loginIconsInsideModal {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        /* margin-top: 0.5em; */
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
}

@media (min-width: 769px) and (max-width: 1366px) {

  #torusButtonContainer {
    // margin-left: 15px;
    margin-left: 0px;
  }

    #inModalTorusButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        // flex: 1;
        // margin-top: 10px;
        // margin-left: 15px;
        // margin-right: 15px;
        // padding: 10px;
        width: 100%;
        // padding: 15px 15px 15px 15px;
margin: 7px;

        #torusConnectText {
            font-size: 2em;
            color: white;
            margin-top: .5em;
            margin-bottom: .5em;
        }

        #loginIconInModal {
            margin-right: 0.1em;
            margin-left: 0.1em;
            font-size: 1.25em;
        }
      }

      #loginIconsInsideModal {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        /* margin-top: 0.5em; */
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
}

@media (min-width: 1367px) {

  #torusButtonContainer {
    // margin-left: 15px;
    margin-left: 0px;
  }
  
    #inModalTorusButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        // flex: 1;
        background: #5e72e4;
        // margin-right: 3em;
        // margin-left: 15px;
        // margin-right: 15px;
        // padding: 10px;
        width: 100%;
        // padding: 15px 15px 15px 15px;
margin: 7px;


        #torusConnectText {
            font-size: 2em;
            color: white;
            margin-top: .5em;
            margin-bottom: .5em;
        }

        #loginIconInModal {
            margin-right: 0.1em;
            margin-left: 0.1em;
            font-size: 1.25em;
        }
      }

      #loginIconsInsideModal {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        /* margin-top: 0.5em; */
        color: white;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
}

#torusButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
// background: #171941;
// box-shadow:  8px 8px 12px #10112c, 
//              -8px -8px 12px #1e2156;
}

#inModalTorusButton {
  background: #5e72e4 !important;
  // background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  
  // box-shadow: none; // TODO
  // padding: 10px;
}

#inModalTorusButton.btn:hover {
  background: #5e72e4;
  // background-color: #fdd5d5 !important;
  // background-image: linear-gradient(to bottom left, #ffffff, #ffffff, #ffffff) !important;
}

#inModalTorusButton.btn:active {
  background: #5e72e4;
  // background-color: #fdd5d5 !important;
  // background-image: linear-gradient(to bottom left, #ffffff, #ffffff, #ffffff) !important;
}

// button#inModalTorusButton:hover {
//   // TODO hover not working
//   flex: 1;
//   text-align: center;
//   background-color: #5e72e4 !important;
//   background: #5e72e4;
// }

// #loginIconsInsideModal {
  // width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-content: center;
//   margin-top: 0.5em;
//   color: white;
//   padding-left: 2em;
//   padding-right: 2em;
// }

/* ---------------------------------------------------- MetamaskLoginButton.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

    #modalMetamaskButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fb6340;
        color: white;

        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 1.5em;
          color: white;
          margin-top: .5em;
          margin-bottom: .5em;
        }
    }

    #modalMetamaskSmallButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-evenly;
        justify-content: center;
        background: #d9791d69;
        padding: 10px 10px 10px 10px;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 10px;

        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 1.25em;
          color: white;
          margin-left: 10px;
          margin-top: 0px;
          margin-bottom: 0px;
        }
    }

    #metamaskInstructions {
      width: 100% !important;
      // margin-bottom: 10px;
    }
      

}

@media (min-width: 320px) and (max-width: 465px) {
    #modalMetamaskButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fb6340;
        color: white;

        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 2em;
          color: white;
          margin-top: .5em;
          // margin-bottom: .5em;
        }
        }
      


      #modalMetamaskSmallButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-evenly;
        justify-content: center;
        background: #d9791d69;
        padding: 10px 15px 10px 15px;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 10px;

        
        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 1.35em;
          color: white;
          margin-top: 0px;
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }


    #metamaskInstructions {
      width: 100% !important;
      margin-bottom: 10px;
    }
}


@media (min-width: 466px) and (max-width: 768px) { 
    #modalMetamaskButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fb6340;
        color: white;

        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 2em;
          color: white;
          margin-top: .5em;
          // margin-bottom: .5em;
        }
  
        }
      

      #modalMetamaskSmallButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        background: #d9791d69;
        padding: 15px 15px 15px 15px;
        margin-right: 15px;
        margin-left: 15px;
        margin-top: 15px;

        
        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 1.75em;
          color: white;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      #metamaskInstructions {
        width: 100% !important;
        margin-bottom: 15px;
      }
}

@media (min-width: 769px) and (max-width: 1366px) {
    #modalMetamaskButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fb6340;
        color: white;
        // margin: 1em;

        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 2em;
          color: white;
          margin-top: .5em;
          // margin-bottom: .5em;
        }

        }
    

        #modalMetamaskSmallButton {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center ;
          background: #d9791d69;
          padding: 15px 15px 15px 15px;
          margin-right: 15px;
          margin-left: 15px;
          margin-top: 15px;
          
  
          
          #metamaskModalIcon {
            // margin-bottom: 0.85em;
          }
    
    
          #metamaskConnectText{
            font-size: 2em;
            color: white;
            margin-top: 0px;
            margin-bottom: 0px;
            padding-left: 10px;
          }
        }
  
        #metamaskInstructions {
          width: 50%;
          // margin-bottom: 15px;
          margin-bottom: 0px;
          margin-right: 20px;
        }
}

@media (min-width: 1367px) {
    #modalMetamaskButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fb6340;
        color: white;
        }
      
      #metamaskModalIcon {
        // margin-bottom: 0.85em;
      }


      #metamaskConnectText{
        font-size: 2em;
        color: white;
        margin-top: .5em;
        // margin-bottom: .5em;
      }

      #modalMetamaskSmallButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center ;
        background: #d9791d69;
        padding: 15px 15px 15px 15px;
        margin-right: 15px;
        margin-left: 15px;
        margin-top: 15px;
        

        
        #metamaskModalIcon {
          // margin-bottom: 0.85em;
        }
  
  
        #metamaskConnectText{
          font-size: 2em;
          color: white;
          margin-top: 0px;
          margin-bottom: 0px;
          padding-left: 10px;
        }
      }

      #metamaskInstructions {
        width: 50%;
        // margin-bottom: 15px;
        margin-bottom: 0px;
      }
}

#modalMetamaskButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // flex: 5;
  // text-align: center;
  // background: #fb6340;
  // color: white;
  // padding: 10px;
}

#modalMetamaskSmallButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-evenly;
    background: #fb6340;      

      #metamaskModalIcon {
        height: 30px;
        width: 30px;
      }

      #metamaskConnectText{
        color: white;
      }
}


#metamaskModalIcon {
  // margin-bottom: 0.85em;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  // height: 60px;
  // width: 60px;
  height: 60px;
  width: 60px;
}

// #metamaskModalIconSmallButton {
//   height: 30px;
//   width: 30px;
// }

/* ---------------------------------------------------- Web3Modal.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
}

@media (min-width: 320px) and (max-width: 465px) {

  #torusModalCard {
    padding: 0px;
  }

  #torusModal {
    padding: 0px;
  }

  #torusModalBody {
    flex-direction: column;
  }

  #loginNotice {
    width: 100%;
  }
}


@media (min-width: 466px) and (max-width: 768px) { 

  #torusModalCard {
    padding: 5px;
    margin-top: 0px;
  }

  #torusModal {
    padding: 5px
  }

  #torusModalBody {
    flex-direction: column;
  }

  #loginNotice {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {

  #torusModal {
    padding: 10px;
    margin-top: 0px;
  }

  #torusModalBody {
    flex-direction: column;
  }

  #loginNotice {
    width: 100%;
  }
}

@media (min-width: 1367px) {

  #torusModal {
    padding: 25px;
  }
  
  #torusModalBody {
    flex-direction: column;
    align-items: flex-start;
  }

  #loginNotice {
    width: 100%;
  }
}

#torusModal {
  background: #a39ce763;
  border-radius: 15px;
  // padding: 20px;
}

.Web3SettingsModalTitle {
  margin-left: 15px;
  margin-top: 15px;
  // font-family: 'Roboto', sans-serif !important;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: 600;
  // color: white;
  color: #9cafff;
  font-size: 2.5em;
  opacity: .5;
}

.Web3SettingsModalSubtitle {
  padding: 15px 0px 15px 15px;
  margin-left: 15px;
  margin-top: 15px;
  // font-family: 'Roboto', sans-serif !important;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 6px;
  text-transform: uppercase;
  font-weight: 600;
  // color: white;
  color: #9cafff;
  font-size: 2.5em;
  opacity: .5;
}

#settingsShortText {
  // width: 100%;
  // width: 50%;
  // order: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  justify-content: center;
  align-content: center;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.5em;
  /* font-family: 'VT323', monospace; */
  // margin-left: 1.5em;
}

#torusModalBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#loginNotice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 0px;
  background: #ed1c1ca6;
  border: solid 3px #fe0635;
  border-radius: 15px;
  font-weight: 525;
  margin-right: 20px;
  margin-left: 20px;
  letter-spacing: normal !important;
  text-transform: none !important;
  // width: 100%;
}

#loadingIconContainer {
  display: flex;
  flex-direction: row;
  min-height: 200px;
  align-items: center;
  justify-content: center;

  h3 {
    color: #1d2951;
    text-transform: lowercase;
    font-size: 4em;
    margin-right: .5em;
    font-weight: 600;
  }

  #verifyingTXloadingIcon {
    color: #1d2951;
    font-size: 5em;
  }
}

#metamaskModalPanel {
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    display: flex;
    flex-direction: row;

    button:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

#userModalAddress {
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  margin-left: 15px;  
}

.copyButton {
  background: none;
  border: none;
  // color: $button-color;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  font-size: 1rem;
  &:hover,
  &:focus {
    outline: none;
    // color: $clickable-color;
    color: #1d8cf8;
  }
}

#torusWalletRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin-top: 10px;
}

#settingsFooter {
  display: flex;
  /* flex-direction: row-reverse; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.networkSwitchButton {
  background: transparent;
  font-weight: 600;
  padding: 15px;
  font-size: 1.15em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
  transition: opacity 0.3s, text-shadow 0.3s;
  margin: 10px 0;
  // width: 100%;
  
  &:hover {
    opacity: 1;
  }

  &.glow {
    animation: glow 1s ease-in-out infinite alternate;
  }
}

@keyframes glow {
  50% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  100% {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.networkInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    justify-content: center;
    align-content: center;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1.5em;
    /* font-family: 'VT323', monospace; */
    // margin-left: 1.5em;
  }
  
  .networkLabel {
    color: #fff;
    font-size: 1em;
    margin-right: 5px;
  }
  
  .networkValue {
      font-family: 'Share Tech Mono', monospace;
      // font-size: 1.5em;
  }
  
  .infoIcon {
    margin-left: 5px;
    color: #fff;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s;
    
    &:hover {
      opacity: 1;
    }
  }

  .settingsContainer {
    display: flex !important;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-right: 20px;
    margin-left: 20px;
}

.sendTestnetFundsButton {
  background: transparent;
  font-weight: 600;
  padding: 10px;
  font-size: 1.15em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
}

.networkInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}


/* ---------------------------------------------------- TORUS WIDGET IFRAME ---------------------------------------------------- */

#torusIframe {
  width: 390px;
  height: 320px;
  border: #ffffff00;;
  border-width: 1px;
  border-radius: 20px;
  border-style: solid;
  display: none;
}

/* ---------------------------------------------------- LoginModal.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
}

@media (min-width: 320px) and (max-width: 465px) {
}


@media (min-width: 466px) and (max-width: 768px) { 
}

@media (min-width: 769px) and (max-width: 1366px) {
}

@media (min-width: 1367px) {
}