#createGroupButton {
  background: transparent;
  font-weight: 600;
  padding: 15px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;

  .tooltip {
    margin-left: 5px;
  }
}

#createGroupExpanded {
  justify-self: center !important;
  background-color: #586372;
  padding: 20px;
  border: 1px solid #393e46;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
  width: 75% !important;

  .headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .createGroupTitle {
    font-size: 2.5em;
    font-weight: 700;
    color: #00ff9d !important;
    margin-bottom: 0px;
    font-family: 'Share Tech Mono', monospace;
  }

  .sbtInfo {
    margin-bottom: 10px;
    font-weight: 600;
    a {
      color: #007bff;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .inputColumn {
    display: flex;
    flex-direction: column;

    input[type="text"] {
      font-size: 1.25em;
      margin-bottom: 0px;
      background: #393e46;
      border: 1px solid #00ff9d;
      color: #eeeeee;
      padding: 8px;
      border-radius: 5px;
      font-family: 'Share Tech Mono', monospace;
      width: 90%;
    }
  }

  .imageUploadContainer {
    margin-bottom: 10px;

    .imageUploadLabel {
      font-size: 1.25em;
      font-weight: 600;
      // margin-bottom: 5px;
      // margin-left: 7.5px;
      color: #eeeeee;
    }

    .imageUploadOptionLabel {
      justify-self: center;
      margin-right: 7.5px;
    }

    .imageUploadOptions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 5px;
      font-weight: 600;
      color: #eeeeee;

      .imageUploadOption {
        display: flex;
            flex-direction: row;
            justify-content: center;
            cursor: pointer;
            margin-left: 5px;
      }
    }

    .disabledOption {
      opacity: 0.5;
      pointer-events: none;
      margin-left: 5px;
    }

    .fileUploadContainer {
      display: flex;
      flex-direction: row;



      .fileUploadButton {
        padding: 5px 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        font-size: 1em;
        background-color: #00adb5;
        color: #eeeeee;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-family: 'Share Tech Mono', monospace;

        &:hover {
          background-color: #00ff9d;
          color: #000;
        }
      }

      .selectedFile {
        margin-left: 10px;
        font-size: 1em;
        color: #eeeeee;
      }
    }
  }

  .imagePreview {
    margin-top: 10px;
    margin-bottom: 20px;
    img {
      max-width: 200px;
      border: 2px solid #00ff9d;
      border-radius: 10px;
    }
  }

  .blackText {
    color: black !important;
  }

  .datePicker {
    color: black;
    margin-bottom: 10px;
  }

  .collapsibleSection {
    margin-top: 10px;
    // background: #393e46;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    // border: 1px solid #00adb5;

    h3 {
      font-size: 1.5em;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #00ff9d;
      font-weight: 700;
      background: linear-gradient(45deg, #00adb5, #00ff9d);
      padding: 10px 15px;
      border-radius: 8px;
      transition: background 0.3s ease;
      font-family: 'Share Tech Mono', monospace;

      svg {
        margin-left: 10px;
      }

      &:hover {
        background: linear-gradient(45deg, #00ff9d, #00adb5);
      }
    }
  }

  .sbtTokenOptions,
  .distributionOptions {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    label {
      margin-bottom: 10px;
      font-size: 1em;
      color: #eeeeee;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'Share Tech Mono', monospace;

      input[type="checkbox"],
      input[type="radio"] {
        margin-right: 5px;
      }

      select {
        margin-left: 10px;
        background: #393e46;
        color: #eeeeee;
        border: 1px solid #00ff9d;
        padding: 5px;
        border-radius: 5px;
        font-family: 'Share Tech Mono', monospace;
      }

      input[type="number"] {
        margin-left: 5px;
        font-size: 1em;
        background: #393e46;
        border: 1px solid #00ff9d;
        color: #eeeeee;
        padding: 5px;
        border-radius: 5px;
        width: 80px;
        font-family: 'Share Tech Mono', monospace;
        margin-top: 10pxg;
      }
    }
  }

  #timeLimitedMintingText {
    margin-top: 10px;
  }

  .networkButton {
    background: transparent;
    border: none;
    color: #00ff9d;
    cursor: pointer;
    font-size: 1em;
    font-family: 'Share Tech Mono', monospace;

    &:hover {
      text-decoration: underline;
    }
  }

  .timeLimitedOptions {
    display: flex;
    align-items: center;
    // margin-top: 5px;
    margin-bottom: 10px;

    .unixTimestamp {
      margin-left: 10px;
      color: #eeeeee;
      font-family: 'Share Tech Mono', monospace;
    }
  }

  #burnAdminAddressInput {
    margin-left: 7.5px;
    padding: 5px;
  }

  .mintingSteps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

    button {
      padding: 10px 20px;
      font-size: 1.5em;
      font-weight: 600;
      background-color: #00adb5;
      color: #eeeeee;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      min-width: 200px;
      font-family: 'Share Tech Mono', monospace;

      &:hover {
        background-color: #00ff9d;
        color: #000;
      }

      &:disabled {
        background-color: #555;
        cursor: not-allowed;
      }
    }
  }

  .progressIndicator {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    border: 1px solid black;
    color: #eeeeee;
    background: #393e46;
    border-radius: 5px;
    padding: 10px;

    .step,
    .stepCompleted {
      display: flex;
      align-items: center;
      margin-right: 20px;

      span {
        margin-left: 5px;
        color: #eeeeee;
        font-family: 'Share Tech Mono', monospace;
      }
    }

    .stepCompleted {
      color: #00ff9d;
    }
  }

  .sbtContractAddress {
    margin-top: 10px;
    font-size: 1em;
    font-weight: 600;
    color: #eeeeee;
    font-family: 'Share Tech Mono', monospace;

    a {
      color: #00adb5;
      text-decoration: none;
      margin-left: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .sbtInviteLinks {
    margin-top: 20px;
    background-color: #393e46;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #00adb5;

    h3 {
      font-size: 1.25em;
      margin-bottom: 10px;
      color: #00ff9d;
      font-family: 'Share Tech Mono', monospace;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 10px;
    }

    .inviteLinkItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      background-color: #222831;
      padding: 10px;
      border-radius: 3px;
      border: 1px solid #00ff9d;

      .inviteLink {
        color: #eeeeee;
        font-weight: 600;
        word-break: break-all;
        flex-grow: 1;
        margin-right: 10px;
        font-family: 'Share Tech Mono', monospace;
      }

      .copyButton {
        background: none;
        border: none;
        cursor: pointer;
        color: #00adb5;
        font-size: 1.2em;
        padding: 5px;
        flex-shrink: 0;

        &:hover {
          color: #00ff9d;
        }
      }
    }

    .exportOptions {
      display: flex;
      align-items: center;

      .exportFormatSelect {
        margin-right: 10px;
        background: #222831;
        border: 1px solid #00ff9d;
        color: #eeeeee;
        padding: 5px;
        border-radius: 3px;
        font-family: 'Share Tech Mono', monospace;
      }

      .exportButton {
        background-color: #00adb5;
        color: #000;
        border: none;
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;
        font-family: 'Share Tech Mono', monospace;

        &:hover {
          background-color: #00ff9d;
          color: #000;
        }
      }
    }
  }

  .distributionOptions {
    .inviteLinksOptions {
      margin-top: 10px;

      label {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }

  .tooltip {
    color: black !important;
    margin-left: 10px;
  }

  .networkButton {
    &:hover {
      text-decoration: underline;
    }
  }
}

ul li, ol li {
  color: inherit;
}


//

#addDocUrlSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}