/* Base styling for the Survey Tool */
.surveyTool {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1f2251;
    padding: 20px;
    border-radius: 8px;
    box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;
  }

  #surveysRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  #surveysDropdown {
    display: flex;
    flex-direction: row;
  }


  /* Styling for Survey Selector */

  #surveySelectorRow {
    display: flex;
    flex-direction: column;
    font-size: 1em;
  }
  

  .surveySelector {
    // width: 100%;
    margin-bottom: 20px;
  
    .dropdown-toggle {
      text-transform: uppercase;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.25);
      background: #1f2251;
      border-color: white;
  
      &:hover {
        background: #272b65;
      }
    }
  
    .dropdown-menu {
      background: #1f2251;
  
      .dropdown-item {
        color: rgba(255, 255, 255, 0.25);
  
        &:hover {
          background: #272b65;
        }
      }
    }
  }

  #dropdownToggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    font-size: 1.45em;
    background-image: unset;
    border: 2px white solid;
    opacity: 0.9;
    border-radius: 10px;
  }

  #dropdownToggleCaret {
    font-size: 1.25em;
    margin-left: 10px;
  }

  #dropdownMenu {
    // width: 50%;
  }

  .dropdownItem {
    color: black !important;
    text-transform: uppercase;
    font-size: 1.5em !important;
    font-weight: 600 !important;
  }
  
  /* Styling for Survey Questions */
  .surveyQuestions {
    width: 100%;
  
    .questionCard {
      margin-bottom: 20px;
      background: #1f2251;
      border-radius: 8px;
      box-shadow: -7px 7px 14px #171a3d, 7px -7px 14px #272b65;
  
      .card-body {
        h5 {
          color: white;
          font-weight: 600;
          margin-bottom: 10px;
        }
  
        label {
          color: rgba(255, 255, 255, 0.25);
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  
    .binaryChoice {
      display: flex;
      justify-content: space-between;
  
      button {
        width: 48%;
      }
    }
  
    .slider {
      margin-top: 20px;
      max-width: 200px;
  
      .rangeslider__fill {
        background: #2dce89;
      }
  
      .rangeslider__handle {
        background: #1f2251;
        border: 2px solid #2dce89;
      }
    }
  
    .spectrum {
      // ... styles for spectrum ...
    }
  
    .multiChoice {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
  
      .option {
        flex: 1;
        margin: 5px;
        padding: 10px;
        border: 1px solid #272b65;
        border-radius: 4px;
        background: #1f2251;
        cursor: pointer;
        transition: all 0.3s ease;
  
        &:hover {
          background: #272b65;
        }
      }
    }
  
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      button {
        color: white;
        background: #1f2251;
        border-color: white;
        text-transform: uppercase;
        font-weight: 600;
  
        &:hover {
          background: #272b6500;
        }
      }
    }
  }
  

  #surveySlider {
    max-width: 150px;
  }

  #responsePreview {
    color: white !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: transparent;
    // border: 1px white solid;
    font-size: 1em;
    border-radius: 5px;
    overflow: scroll;
    font-family: 'Courier New', Courier, monospace;
    margin: 10px;
    margin-top: 20px;
    padding: 15px;
    border-radius: 0px;
    background: #171941;
    box-shadow:  5px 5px 10px #0d0e24,
                -5px -5px 10px #21245e;
                background-color: transparent;
                padding: 15px;
                margin-top: 10px;
                border-radius: 5px;
                overflow-x: auto;
  }

  #encryptCheckbox {
    margin-top: 5px;
    margin-bottom: 5px;
    scale: 1.5;
    // margin-left: 10px;
    margin-right: 10px;
  }

  #ratingInput {
      // margin-top: 20px;
      padding: 20px;
      margin-right: 20px;
      max-width: 200px;
  
      // .rangeslider__fill {
      //   background: #2dce89;
      // }
  
      // .rangeslider__handle {
      //   background: #1f2251;
      //   border: 2px solid #2dce89;
      // }
    }

  #textInput {
    min-height: 100px;
  }

#ratingLabelText {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  font-size: 1.5em;
  margin-right: 10px;
}

#multiChoice {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    font-size: 1.5em;
}


// ... (previous styles remain the same)

#binaryChoice {
  display: flex;
  flex-direction: row;
  // justify-content: space-around;
  align-items: center;
  margin-top: 1rem;

  .radioOptionText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: font-weight 0.3s ease;

    &.selected {
      font-weight: 900;
      font-style: italic;
    }

    input[type="radio"] {
      display: none;
    }

    &:hover {
      background-color: #f0f0f0;
    }

    &.agree {
      background-color: #4caf50;
      color: white;

      &:before {
        font-weight: 900;
        margin-right: 0.5rem;
      }
    }

    &.unsure {
      background-color: #ffeb3b;
    }

    &.disagree {
      background-color: #f44336;
      color: white;

      &:before {
        font-weight: 900;
        margin-right: 0.5rem;
      }
    }

    .optionIcon {
      margin-right: 0.5rem;
    }
  }
}

.checkboxOptionText {
  font-size: 0.9em !important;
  font-weight: 600;
}

#surveyFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

#submitSurveyButton {
  font-size: 2em;
  background: transparent;
  font-weight: 600;
  text-transform: uppercase;
  border: white 1px solid;
    opacity: 0.7;
}

#encryptAdditionalThoughts {
  // margin-bottom: 10px;
  border: 0px transparent solid;
}

#inputGroupTextBox {
  border: 1px solid transparent;
  border-radius: 0px;
  // font-size: 1.5em;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  
}

// encrypt button 

@media (min-width: 0px) and (max-width: 319px) {
  #inputGroupTextBox {
    font-size: 1em;
  }

  #encryptIcon {
    font-size: 0.75em;
    margin-right: 5px;
    // margin-top: 10px;
    // padding: 10px;
  }

  #binaryChoice {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
  }

}

@media (min-width: 320px) and (max-width: 465px) {

  #inputGroupTextBox {
    font-size: 1em !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  #encryptIcon {
    font-size: 1.5em !important;
    margin-right: 0px !important;
    // margin-top: 10px;
    // padding: 10px;
  }

  #responseInputSection {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  #binaryChoice {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0.5rem;
    gap: 7.5px;
  }

}


@media (min-width: 466px) and (max-width: 768px) { 

  #inputGroupTextBox {
    font-size: 1em !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-left: 0px !important;
    padding-right: 1px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  #encryptIcon {
    font-size: 1.5em !important;
    margin-right: 0px !important;
    // margin-top: 10px;
    // padding: 10px;
  }

}

@media (min-width: 769px) and (max-width: 1366px) {

  #inputGroupTextBox {
    font-size: 1.25em !important;
    margin-left: 7.5px !important;
    margin-right: 7.5px !important;
    padding-left: 5px !important;
  }
  #encryptIcon {
    font-size: 1.5em !important;
    margin-right: 10px !important;
    // margin-top: 10px;
    // padding: 10px;
  }

}

@media (min-width: 1367px) {
  #inputGroupTextBox {
    font-size: 1.5em !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  #encryptIcon {
    font-size: 2em !important;
    margin-right: 15px !important;
    // margin-top: 10px;
    // padding: 10px;
  }
}

// tree

.tree {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: white;

  .treeItem {
    color: white;
    display: flex;
    flex-direction: row;

    .keyValueContainer {
      display: flex;
      align-items: center;
      justify-content: start;

      > span {
        margin-right: 5px;
      }
    }
  }
}


//

#questionTitleBody {

}

.questionTitleBody {
background-color: #0067ff;
}

#questionTitle {
  font-weight: 600;
  font-size: 1.5em;
  padding: 10px;
  opacity: 0.8;
  margin-bottom: 5px;
  color: white !important;
}

.additionalCommentsSection {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.additionalCommentsLabel {
  margin-top: 10px;
  // margin-bottom: 10px;
  font-size: 1.05em;
  font-weight: 600;
  // text-transform: ;
}

.additionalCommentsContent {
  opacity: 0.8;
  font-weight: 600;
  font-size: 1.15em;
}

.questionTitle {
  font-weight: 600;
  font-size: 1.5em;
  padding: 10px;
  opacity: 0.8;
}

#importanceText {
  font-size: 1.25em;
  opacity: 0.5;
  margin-top: 10px;
}

//

#encryptOptionButton {
  display: flex;
  // flex-direction: column;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  // background: #0080008a;
  background-color: #00048080;
  // border-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  cursor: crosshair;
}

#encryptIcon {
  font-size: 2em !important;
  margin-right: 15px;
  // margin-top: 10px;
  // padding: 10px;
  color: white;
  opacity: 0.25;
}

#surveyButtonText {
  display: inline;
  flex-direction: row;
  align-items: center;
}

// CreateSurvey

.createSurveyContainer {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 20px;
  color: black !important;
}

#createSurveyButton {
  background: transparent;
  font-weight: 600;
  padding: 10px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
}

.surveyTitleInput, #newSurveyTitleInput {
  font-size: 2em;
  font-weight: 600;
  background-color: transparent;
  color: black !important;
  border-radius: 0px;
  background: #f9f9f9;
  box-shadow: 5px 5px 10px #d6d6d6, -5px -5px 10px #ffffff;
  margin-bottom: 20px;
  min-height: 50px;
}

.questionContainer {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.questionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.questionTypeText, #questionTypeText {
  color: black;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.75;
  margin-right: 5px;
}

.removeQuestionButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  
  &:hover {
    background-color: #c82333;
  }
}

.questionPromptInput, #newQuestionInput {
  font-size: 1.25em;
  margin-bottom: 10px;
  color: black !important;
}

.optionsContainer {
  margin-top: 10px;
}

.optionItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: black !important;
}

.optionInput {
  flex-grow: 1;
  margin-right: 10px;
  color: black !important;
}

.removeOptionButton {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 8px;
  border-radius: 3px;
  
  &:hover {
    background-color: #5a6268;
  }
}

.addOptionButton {
  margin-top: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  
  &:hover {
    background-color: #218838;
  }
}

.addQuestionContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.questionTypeSelect, #newQuestionSelect {
  flex-grow: 1;
  margin-right: 10px;
  font-size: 1.5em;
  font-weight: 600;
  padding: 5px;
}

.addQuestionButton, #addQuestionButton {
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
}

.surveyIdPreview, #createSurveyIDPreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black !important;
  font-size: 1em;
  font-weight: 600;
  margin-top: 10px;
  font-family: monospace;
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 5px;
}

.surveySubmittedContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.copySurveyLinkButton, .viewSurveyLink {
  flex: 1;
  margin: 0 5px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
}

.copySurveyLinkButton {
  background-color: #28a745;
  
  &:hover {
    background-color: #218838;
  }
}

.viewSurveyLink {
  background-color: #17a2b8;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #138496;
    color: white;
  }
}

.createSurveyButton, #submitNewSurveyButton {
  width: 100%;
  margin-top: 20px;
  background-color: black;
  color: white;
  border: 1px black solid;
  padding: 10px;
  font-size: 1.75em;
  font-weight: 600;
  text-transform: uppercase;
  
  &:hover {
    background-color: #333;
  }
  
  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
}

.progressBarContainer {
  margin-top: 10px;
  background-color: #e9ecef;
  border-radius: 5px;
  overflow: hidden;
}

.progressBar {
  height: 10px;
  background-color: #007bff;
  transition: width 0.5s ease;
}

.toggleJsonButton {
  margin-top: 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  
  &:hover {
    background-color: #5a6268;
  }
}

.jsonDisplay {
  margin-top: 20px;
  // background-color: #f8f9fa;
  background-color: #2931da;
  padding: 15px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-break: break-all;
}

#expanded {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #e0e0e0;
  margin-top: 10px;
  transition: max-height 0.5s ease-out;
}

#ratingSlider, .importanceSlider {
  width: 200px;
}

.questionId {
  font-size: 0.65rem;
  opacity: 0.65;
  font-weight: 600;
  margin-top: 5px;
}

// AudioInput.jsx

.textarea {
  overflow: hidden;
  word-wrap: break-word;
  resize: none; // if you don't want users to manually resize the textbox, otherwise use 'vertical' to allow vertical resizing
}

#answerSurveyButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  background-color: #2963ca;
}

// In SurveyTool.module.scss

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;

  .tooltipText {
    display: flex;
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    /* margin-left: -100px; */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 1em;
    text-transform: none;
    margin-right: 5px;
    margin-left: 5px;
  }

  &:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
}


/* ---------------------------------------------------- Component.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {
   
  #encryptOptionButton {
    flex-direction: column !important;
  }

}

@media (min-width: 320px) and (max-width: 465px) {

  #encryptOptionButton {
    flex-direction: column !important;
    min-width: 105px;
  }

}


@media (min-width: 466px) and (max-width: 768px) { 

  #encryptOptionButton {
    flex-direction: column !important;
  }
  
}

@media (min-width: 769px) and (max-width: 1366px) {

}

@media (min-width: 1367px) {

}

/* Add or update the following styles */

.modalBody {
  display: flex;
  flex-direction: column;
  color: black !important;
}

.resultsModal {
  // Ensure the modal has a fixed width and doesn't reconfigure when dropdown is clicked
  max-width: 800px;
  margin: auto;
  max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
}

.exportContainer {
  display: flex;
  flex-direction: column; /* Change to column to stack elements vertically */
  align-items: flex-start; /* Align items to the start */
  margin-top: 20px;
}

.exportOptions {
  display: flex;
  flex-direction: row; /* Keep buttons in a row */
  margin-top: 10px;
}

.exportLabel {
  font-weight: bold;
}

.exportDropdown {
  margin-right: 10px;
}

.downloadButton {
  // Add any necessary styling for the download button
}

/* Ensure that the dropdown menu doesn't affect the layout */
.exportDropdown .dropdown-menu {
  position: absolute;
  will-change: transform;
}

/* Optional: Adjust the modal body to prevent reflow */
.modal-body {
  overflow-y: auto;
}


/* SurveyResults.module.scss */

.resultsModal {
  max-width: 80%;
  width: 100%;
  background-color: white;
  // max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  
  :global(.modal-dialog) {
    max-width: 80%;
    width: 100%;
  }
  
  :global(.modal-content) {
    // max-height: 90vh;
    // overflow-y: auto;
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
}

.modalTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  color: black;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6c757d;
  
  &:hover {
    color: #343a40;
  }
}

.filterContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.filterInput {
  flex-grow: 1;
  margin-right: 0.5rem;
  color: black !important;
  font-weight: 600;
}

.searchIcon {
  font-size: 1.25rem;
  color: #6c757d;
}

.responseList {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.responseItem {
  margin-bottom: 1rem;
}

.responseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
}

.responderAddress {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.externalLink {
  display: inline-flex;
  margin-left: 0.5rem;
  color: white;
  
  &:hover {
    color: #f8f9fa;
  }
}

.lockIcon {
  margin-left: 0.5rem;
}

.caretIcon {
  font-size: 1.25rem;
}

.responseCard {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0 0 0.25rem 0.25rem;
}

.responseItem {
  margin-bottom: 1rem;
  
  p {
    margin-bottom: 0.5rem;
  }

  strong {
    font-weight: bold;
  }
}

.questionTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: white !important;
}

.exportContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.exportLabel {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 1rem;
}

.exportOptions {
  display: flex;
  align-items: center;
}

.exportDropdown {
  background-color: white;
  border: 1px solid #ced4da;
  color: #495057;
  font-weight: bold;
  margin-right: 0.5rem;

  &:hover, &:focus {
    background-color: #f8f9fa;
    color: #212529;
  }
}

.downloadButton {
  background-color: #28a745;
  border: none;
  color: white;
  font-weight: bold;

  &:hover, &:focus {
    background-color: #218838;
  }
}

/* Add these new styles to your existing SurveyTool.module.scss file */

.responderLink {
  color: white;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

.externalLink {
  display: inline-flex;
  margin-left: 0.5rem;
  color: white;
  
  &:hover {
    color: #f8f9fa;
  }
}

.responderAddress {
  display: flex;
  align-items: center;
}

/* Update the existing styles */

.responseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
}

.lockIcon {
  margin-left: 0.5rem;
}

#filterButton {
  background: transparent;
  font-weight: 600;
  padding: 10px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.9;
}

#showResultsButton {
  // margin-top: 20px;
  background: transparent;
  font-weight: 600;
  padding: 10px;
  font-size: 1.5em;
  color: white;
  text-transform: uppercase;
  border: white 1px solid;
  border-radius: 10px;
  opacity: 0.5;
  // border-radius: 10px;
}

// Add these styles to the end of your existing SurveyTool.module.scss file

.createSurveyButton {
  width: 100%;
  padding: 15px;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  color: white;
  transition: background-color 0.3s ease;
  
  &:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    color: white;
  }
}

.progressBarContainer {
  margin-top: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

.progressBar {
  height: 10px;
  background-color: #28a745;
  transition: width 0.5s ease;
}

.surveySubmissionConfirmation {
  margin-top: 30px;
  padding: 20px;
  background-color: #d4edda;
  border-radius: 8px;
  border: 1px solid #c3e6cb;

  h3 {
    color: #155724;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.1em;
    color: #1e7e34;
  }
}

.surveyActions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.copySurveyLinkButton, .viewSurveyLink {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.copySurveyLinkButton {
  background-color: #28a745;
  color: white;
  border: none;
  margin-right: 10px;
  
  &:hover {
    background-color: #218838;
  }
}

.viewSurveyLink {
  background-color: #17a2b8;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #138496;
    color: white;
    text-decoration: none;
  }
}

.multipleResponsesContainer {
  margin-top: 30px;
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 8px;

  h4 {
    margin-bottom: 15px;
    color: #495057;
  }
}

.responseNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover:not(:disabled) {
      background-color: #5a6268;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  span {
    font-size: 1.1em;
    font-weight: 600;
  }
}

.toggleJsonButton {
  margin-top: 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #5a6268;
  }
}

.jsonDisplay {
  margin-top: 20px;
  background-color: #037df8;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 0.9em;
}

// Styles for the existing user response notice
.userResponseNotice {
  margin-bottom: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 20px;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.25em;

  p {
    font-size: 1.5em;
    color: #495057;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }
}

.viewAddressHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

#startFreshButton, #decryptEditButton, #editButton, #viewAnswersButton {
  margin-right: 10px;
  font-size: 1em;
  font-weight: 600;
  padding: 8px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

#startFreshButton {
  background-color: #28a745;
  border-color: #28a745;
  color: white;

  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
}

#decryptEditButton, #editButton {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #212529;

  &:hover {
    background-color: #e0a800;
    border-color: #d39e00;
  }
}

#viewAnswersButton {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: white;

  &:hover {
    background-color: #138496;
    border-color: #117a8b;
  }
}

// Add question vs Add Survey 

.modeToggle {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.25em;
  color: black;
  text-transform: uppercase;
}
.questionMetadata {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

.questionId {
  margin-bottom: 5px;
}

.associatedSurveyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.associatedSurveyLabel {
  display: flex;
  align-items: center;
  margin-right: 10px;
  white-space: nowrap;
  color: #666 !important;
}

.addCurrentLink {
  margin-left: 5px;
  color: #007bff;
  text-decoration: underline;
  
  &:hover {
    color: #0056b3;
  }
}

.associatedSurveyInput {
  flex-grow: 1;
  color: black !important;
  font-size: 1em;
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.toggleLabel {
  font-size: 1.15em;
  color: black !important;
  font-weight: 600;
  opacity: 0.65;
  margin-bottom: 0px !important;
}

//

// #copySurveyIdButton {
//   margin-left: 10px;
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
// }

.copySurveyIdButton {
  font-size: 1em;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 15px;
}
.surveyIdContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-weight: 600;
  color: grey;
  opacity: 0.75;
}

.surveyIdText {
  font-size: 1.25em;
  margin-left: 5px;
  margin-right: 5px;
}

.copySurveyIdButton {
  font-size: 1.25em;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 5px;
}

//

.errorButton {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.errorMessage {
  margin-top: 10px;
  color: #dc3545;
  font-weight: bold;
}

//

/* Styles for the question filtering modal */
.questionsDashboard .modal-content {
  background-color: white !important;
  color: black !important;
  font-size: 1.25em;
}

.questionsDashboard .modal-header,
.questionsDashboard .modal-footer {
  border: none;
}

.questionsDashboard .modal-header .modal-title {
  font-weight: bold;
}

.questionsDashboard .form-group label {
  font-size: 1.25em;
}

.questionsDashboard .form-check-label {
  font-size: 1.25em;
  color: black !important;
  font-weight: 600;
}

.questionsDashboard .form-check-input {
  width: 1.5em;
  height: 1.5em;
  font-size: 1.25em;
  color: black !important;
  font-weight: 600;
}

.questionsDashboard .modal-footer .btn {
  font-size: 1.25em;
  font-size: 1.25em;
  color: black !important;
  font-weight: 600;
}

.questionsDashboard .modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

/* Style for the gear icon beside the survey selector */
// #filterButton {
//   background: transparent;
//   border: none;
//   color: white;
//   font-size: 1.1em;
//   margin-left: 10px;
//   padding: 0px;
//   cursor: pointer;

//   &:hover {
//     color: #ccc;
//   }
// }

/* Ensure the surveysRow aligns items properly */
#surveysRow {
  display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: #80808082;
      padding: 20px;
      border-radius: 10px;
      margin-left: 15px;
      margin-right: 15px;
}

/* Style for the "See Questions / See Surveys" toggle */
.seeQuestionsCheckbox {
  margin-bottom: 0px;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  display: inline-block;
  margin-left: 0px;
  /* Add additional styles as needed */
  font-weight: 600;
}

/* Style for the "Create Survey" button when clicked */
.createSurveyButton:disabled {
  background-color: #6c757d !important;
  color: white !important;
}

/* Style for the "Add Option" button in multichoice questions */
.addOptionButton {
  background-color: #28a745;
  color: white;
  border: none;

  &:hover {
    background-color: #218838;
  }
}

/* Ensure the survey ID copy line looks good */
.surveyIdPreview, #createSurveyIDPreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white !important;
  font-size: 1em;
  font-weight: 600;
  margin-top: 10px;
  font-family: monospace;
  background-color: #1f2251;
  padding: 10px;
  border-radius: 5px;
}

#copySurveyIdIcon {
  font-size: 1.5em;
  margin-left: 10px;
  margin-right: 10px;
}

/* Center and style the link after survey response */
.submissionStatus .submissionStatusBox {
  text-align: center;
  margin-top: 20px;
}

.submissionStatus .submissionStatusBox a {
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// Filter Modal

.filterModalOption {
  color: black !important;
  font-size: 1.5em;
  font-weight: 600;
}

.cumulativeCheckLabel {
  color: black !important;
  font-size: 1.5em;
}

.filterModalCheckbox {
  color: black !important;
  font-size: 1.5em;
    visibility: initial !important;
    opacity: 1 !important;
}

/* Style for the filter modal */

.filterModal .modal-content {
  background-color: white !important;
  color: black !important;
  font-size: 1.5em;
}

.filterModal .modal-header,
.filterModal .modal-footer {
  border: none;
}

.filterModal .form-group label {
  font-size: 1.25em;
}

.filterModal .form-check-label {
  font-size: 1.25em;
}

.filterModal .form-check-input {
  width: 1.5em;
  height: 1.5em;
}

.filterModal .modal-footer .btn {
  font-size: 1.25em;
}

/* Style for the "Uploaded Questions" heading */
.uploadedQuestionsList h4 {
  color: #343a40;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Style for the question IDs and icons to align correctly */
.uploadedQuestionsList ul {
  list-style: none;
  padding: 0;
}

.uploadedQuestionsList li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.uploadedQuestionsList li a {
  flex-grow: 1;
  color: #007bff;
  text-decoration: none;
}

.uploadedQuestionsList li a:hover {
  text-decoration: underline;
}

.bookmarkQuestionButton,
.copyQuestionIdButton {
  background: none;
  border: none;
  color: #6c757d;
  font-size: 1.25em;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    color: #343a40;
  }
}

/* Adjust the button styles to prevent white text on white background */
.createSurveyButton,
.addOptionButton {
  &:active,
  &:focus,
  &:disabled {
    background-color: #007bff !important; // Replace with your desired color
    color: #fff !important; // Ensure text is visible
  }
}

/* Style the response URL box after survey submission */
.submissionStatusBox .responseUrlBox {
  text-align: center;
  background-color: #f8f9fa; // Light background
  padding: 15px;
  margin-top: 10px;
  border-radius: 5px;
}

/* Inline styles for the filter modal (added directly in the JSX for immediate effect) */

.responseUrlBox {
  font-size: 1.25em;
  color: black !important;
}

.loadingContainer {
  text-align: center;
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
}

//

.freeformAnswer {
  font-style: italic;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 15px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
}

.ratingAnswer {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;

  .ratingBar {
    height: 10px;
    background-color: #4DFFA4;
    border-radius: 5px;
    margin-right: 10px;
  }

  span {
    font-size: 1em;
  }
}

.multichoiceAnswer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;

  .choiceItem {
    background-color: #4DFFA4;
    color: #171941;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.9em;
  }
}

.binaryAnswer {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
}

.importanceSlider .rangeslider__fill {
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
}

// miniaturized create survey

#miniCreateSurveyLabel {
  font-weight: 600;
  font-size: 1.5em;
  color: black !important;
}

.tagsInput {
  color: black !important;
}

//

/* Example CSS */
.jsonContainer {
  position: relative;
}

.copyJsonButton {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: none !important;

  // Ensure link inside the button also inherits the style
  a.copyJsonButton {
    color: black !important;
    font-size: 1.5em !important;
    text-decoration: none !important; // Ensures no underline on links
  }

  // Ensures all pseudo-elements have no hover or focus effects
  &:hover, &:active, &:focus {
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    color: inherit !important; // No color change on focus or hover
    transform: none !important;
  }
}


.copyJsonButton:hover {
  color: green;
}

// Survey Results

.filterSection {
  margin-bottom: 1rem;
}

.filterOptions {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.sbtOptions {
  margin-top: 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 0.25rem;
}

.modalSubtitle {
  font-size: 0.9rem;
  color: #6c757d;
  margin-top: 0.5rem;
}

.questionSummaryCard {
  background-color: grey !important;
  margin-bottom: 5px !important;
}

.responseItem {
  margin-bottom: 1rem;
  background-color: #588ff78c !important;

  .questionTitle {
    color: white !important;
  }
  
  .questionTitleBody {
    background-color: #4d8dcd !important;
    border: 1px solid #dee2e6;
  }

  .responseHeader {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.25rem;
    background-color: #2c10ffcf !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .responderAddress {
    font-family: monospace;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .externalLink {
    color: inherit;
    opacity: 0.7;
    
    &:hover {
      opacity: 1;
    }
  }

  .lockIcon {
    color: #6c757d;
  }

  .caretIcon {
    transition: transform 0.2s ease;
  }
}

.responseCard {
  padding: 1rem;
  background-color: grey !important;
}

.exportContainer {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

//

.binaryOptionResults {
  display: flex;
  flex-direction: column;
}

.binaryOptionResponse {
  margin-left: 5px;
  font-weight: 600;
  font-size: 1.25em;
}

//

.dropdownItem {
  color: black !important;
  text-transform: uppercase;
  font-size: 1.5em !important;
  font-weight: 600 !important;
  
  &.questionsItem {
    background-color: #ffe6e6 !important; // pastel pink
    font-style: italic;
    
    &:hover, &.active {
      background-color: #ffcccc !important; // darker pastel pink
    }
  }
  
  &.surveyItem {
    background-color: #ffffd1 !important; // pastel yellow
    
    &:hover, &.active {
      background-color: #ffff99 !important; // darker pastel yellow
    }
  }
}

// #filterButton {
//   background: transparent;
//   border: none;
//   color: white;
//   font-size: 1.1em;
//   margin-left: 10px;
//   padding: 0px;
//   cursor: pointer;

//   &:hover {
//     color: #ccc;
//   }
// }

.topButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

//

@media (min-width: 466px) and (max-width: 768px) {

  #surveysRow {
    flex-direction: column;
    gap: 15px;
  }

}

@media (max-width: 466px) {
    #surveysRow {
      flex-direction: column;
      gap: 15px;;
    }
}

//

#uploadingEncryptingText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}