.leaderboard {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 1.2rem;  // Increased font size

    th, td {
        border: 1px solid white;
        padding: 10px;
        text-align: left;
        position: relative;
        
        &:after { // Creates a pseudo element to give the gray border illusion
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid #ccc;
          z-index: -1; // Places the gray border behind the content
        }
    }

    th {
        background-color: #d4d4d4; // A bit darker shade for header
        color: black;
        border-top: 3px solid white;  // Added a thicker border on top for clear separation
        border-bottom: 2px solid #ccc; // A bit thicker than the rest for clear separation
    }

    a {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}
