.contractPage {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contractList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: transparent;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
}

.contractCard {
  border: 1px solid #ccc;
  padding: 20px;
  background: #00f7ff63 !important;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  width: 100%;
  max-width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 300px;

  @media (min-width: 768px) {
    max-width: none;
  }
}

.contractHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    flex-grow: 1;
    text-align: left;
  }

  a:hover {
    text-decoration: underline;
  }
}

.contractAddress {
  display: flex;
  align-items: center;

  .testnetLabel {
    margin-left: 5px;
  }
}

.coinbaseIcon {
  width: 30px;
  height: 30px;
}

.contractDetails {
  margin-top: 10px;
  color: white;

  pre {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-family: 'Courier New', Courier, monospace;
    background-color: transparent;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.5em;
    font-weight: 600;
  }

  ul {
    list-style-type: disc;
    margin: 10px 0 0 20px;
    padding: 0;
    color: white; /* Make explanation text white */
  }

  li {
    margin: 5px 0;
    color: white; /* Make explanation text white */
  }
}

.converterSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px dotted #ccc;
  border-radius: 10px;
  padding: 20px;
  background: #f0f0f0;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
}

.converterSectionTitle {
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
}

.converterSectionUtil {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .converterRow {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  div {
    margin-top: 8px;
    font-weight: bold;
  }
}

.sbtButton {
  font-size: 1.5em;
  justify-content: center;
}