/* ---------------------------------------------------- MainAreaTabs.jsx ---------------------------------------------------- */

@media (min-width: 0px) and (max-width: 319px) {

  #mainTabsCard {
    // z-index: -1; TODO HERE 3BOX PROFILE HOVER
      min-height: 100%;
      // background: #1f2251;
      background: #00000000;
  }
  #navTabIcon {
    // font-size: .5em;
    // margin-right: 2px; 
  }

  // #mainContentTabTitle {
  //   display: inline;

  //   // font-size: .5em;
  // }

  #mainAreaTabs {
    // width: 100%;
    // margin-top: 5%;
    // margin-left: 2%;
    // margin-right: 2%;
    // padding-left: 0;
    // padding-right: 0;
    background: transparent;
    margin-top: 0px;
    /* margin-left: 2%; */
    /* margin-right: 2%; */
  }

  #mainTabsCardHeader {
    padding: 15px 5px 15px 5px;
  }

  #mainAreaCardBody {
    padding: 0px;
  }

}

@media (min-width: 320px) and (max-width: 465px) {

  #mainTabsCard {
    // z-index: -1; TODO HERE 3BOX PROFILE HOVER
      min-height: 100%;
      // background: #1f2251;
      background: #00000000;
  }
  
  #navTabIcon {
    // font-size: .5em;
    // margin-right: 2px; 
  }

  // #mainContentTabTitle {
  //   display: inline;

  //   // font-size: .5em;
  // }

  #mainAreaTabs {
    background: transparent;
    margin-top: 0px;
    /* margin-left: 2%; */
    /* margin-right: 2%; */
  }

  #mainTabsCardHeader {
    padding: 15px 10px 15px 10px;
  }

  #mainAreaCardBody {
    padding: 0px;
  }

}


@media (min-width: 466px) and (max-width: 768px) { 

  #mainTabsCard {
    // z-index: -1; TODO HERE 3BOX PROFILE HOVER
      min-height: 100%;
      // background: #1f2251;
      background: #00000000;
      border-radius: 7px;
      // background: #171941;
      background: transparent
  }

  #mainAreaTabs {
    // width: 100%;
    // margin-top: 2%;
    margin-top: 17.5px;
    // margin-left: 2%;
    // margin-right: 2%;
  }

  #mainTabsCardHeader {
    padding: 15px 10px 15px 10px;
  }

  #mainAreaCardBody {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1366px) {

  #mainTabsCard {
    // z-index: -1; TODO HERE 3BOX PROFILE HOVER
      min-height: 100%;
      // background: #1f2251;
      background: #171941;
      border-radius: 7px;
      background: #171941;
      box-shadow:  -6px 6px 12px #0f102a, 
                   6px -6px 12px #1f2258;
  }


  #mainAreaTabs {
    width: 75%;
    height: 100%;
    min-height: 100%;
    // padding-left: 2%;
    // padding-right: 2%;;
    // margin-top: 20px;
    margin-top: 5px;
    margin-right: 2%;
    // margin-top: 2%;
  }

  #mainTabsCardHeader {
    padding: 15px 10px 15px 10px;
  }

  #mainAreaCardBody {
    padding: 0px 15px 15px 15px;
  }

}

@media (min-width: 1367px) {

  #mainTabsCard {
    // z-index: -1; TODO HERE 3BOX PROFILE HOVER
      min-height: 100%;
      // background: #1f2251;
      background: #171941;
      border-radius: 7px;
      background: #171941;
      box-shadow:  -6px 6px 12px #0f102a, 
                   6px -6px 12px #1f2258;
  }

  #mainAreaTabs {
    width: 75%;
    min-height: 100%;
    // padding-left: 2%;
    margin-top: 7.5px;
    margin-right: 2%;
    // padding-right: 2%;
  }

  #mainAreaTabsAlt {
    width: 75%;
    min-height: 100%;
    // padding-left: 2%;
    margin-top: 7.5px;
    margin-right: 2%;
    // padding-right: 2%;
  }

  #mainTabsCardHeader {
    padding: 15px 10px 15px 10px;
  }

  #mainAreaCardBody {
    min-height: 500px;
    padding: 0px 25px 25px 25px;
  }

}

// #mainAreaTabs {
//   width: 100%;
//   margin-left: 2%;
//   margin-right: 2%;
// }

#mainTabsCard {
  // z-index: -1; TODO HERE 3BOX PROFILE HOVER
    min-height: 100%;
    // background: #1f2251;
    // background: #171941;
    // border: 0;
    // position: relative;
    // width: 100%;
    // box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.185);
    // border-radius: 7px;
    // background: #171941;
    // box-shadow:  -6px 6px 12px #0f102a, 
    //              6px -6px 12px #1f2258;
    margin-top: 0px;
    // z-index: 1;

    #mainAreaCardBody {
      // padding-left: 10px;
      // padding-right: 10px;
      // padding-top: 0px;
      // padding-bottom: 0px;
    }
}

#trelloEmbed {
  display: flex;
}

// #navTabIcon {
//     font-size: .5em;
//     margin-right: 10px; 
//   }

//   #mainContentTabTitle {
//     display: inline;

//     font-size: .5em;
//   }


/* ---------------------------------------------------- LatestMatchTab.jsx ---------------------------------------------------- */

#matchesContainer{
    position: relative;
    opacity: .99;
    background: #1f2251;
    min-height: 100px;
    /* TODO MOBILE ^^^ */
  }
  
  
  @media (min-width: 0px) and (max-width: 319px) {
  }
  
  @media (min-width: 320px) and (max-width: 465px) {
  }
  
  
  @media (min-width: 466px) and (max-width: 768px) { 
  }
  
  @media (min-width: 769px) and (max-width: 1366px) {
  }
  
  @media (min-width: 1367px) {
  }

  /* ---------------------------------------------------- BetaTab.jsx ---------------------------------------------------- */
  
  @media (min-width: 0px) and (max-width: 319px) {
    #betaTab {
      display: flex;
      flex-direction: column;
      padding-right: 0px;
      padding-left: 0px;
    }

    #betaInfo {
      // width: 100%;
      justify-content: center;
      align-items: center;
    }

    #betaTabTitle {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 2em;
    }

    #betaSurvey {
      display: flex;
      flex-direction: row;
      justify-content: center;
      /* width: 100%; */
      background: transparent;
      /* border: white solid; */
      // border-style: solid;
      // border-color: #010201;
      // border-radius: 5px;
      min-height: 50px;
    }

    #openSidebarButton {
      font-size: .85em;
    }

    .getStartedButton {
      height: unset !important;
    }

    #takeSurveyPrompt {
      margin-bottom: 0px;
      text-transform: uppercase;
      font-size: .85em;
      font-weight: 600;
      color: white;
    }

    #takeSurveyIcon {
      display: inline-flex;
      margin-left: 5px;
      margin-right: 5px;
      font-size: 45px;
    }

    #takeSurveyButton {
      // display: none; // TODO: bring back survey button
      // VISIBLE:
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: .85em;
      // color: #ffffff1c;
      color: #ffffffcf;
      border: none;
      background: black;
      // background: transparent;
      justify-self: flex-end;
      align-items: self-end;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // background-image: linear-gradient(48deg, #000000c7, #000000f2);
    }

    #feedbackPromptText {
      display: inline-flex;
    }

    #feedbackPromptDetailText {
      display: inline-flex;
    }
  }
  
  @media (min-width: 320px) and (max-width: 465px) {
    #betaTab {
      display: flex;
      flex-direction: column;
      padding-right: 0px;
      padding-left: 0px;
    }

    #betaInfo {
      // width: 100%;
      justify-content: center;
      align-items: center;
    }

    #betaTabTitle {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 2em;
    }

    #betaSurvey {
      display: flex;
      flex-direction: row;
      justify-content: center;
      /* width: 100%; */
      background: transparent;
      /* border: white solid; */
      // border-style: solid;
      // border-color: #010201;
      // border-radius: 5px;
      min-height: 50px;
    }

    #openSidebarButton {
      font-size: .85em;
    }

    .getStartedButton {
      height: unset !important;
    }

    #takeSurveyPrompt {
      margin-bottom: 0px;
      text-transform: uppercase;
      font-size: .85em;
      font-weight: 600;
      color: white;
    }

    #takeSurveyIcon {
      display: inline-flex;
      margin-left: 5px;
      margin-right: 5px;
      font-size: 55px;
    }

    #takeSurveyButton {
      // display: none; // TODO: bring back survey button
      // VISIBLE:
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: .85em;
      color: #000000a6;
      border: none;
      background: #07c0f929;
      justify-self: flex-end;
      align-items: self-end;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // background-image: linear-gradient(48deg, #000000c7, #000000f2);
    }

    #feedbackPromptText {
      display: inline-flex;
    }

    #feedbackPromptDetailText {
      display: inline-flex;
    }
  }
  
  
  @media (min-width: 466px) and (max-width: 768px) { 
    #betaTab {
      display: flex;
      flex-direction: column;
      padding-right: 0px;
      padding-left: 0px;
    }

    #betaInfo {
      // width: 100%;
      justify-content: center;
      align-items: center;
    }

    #betaTabTitle {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 2.5em;
    }

    #betaSurvey {
      display: flex;
      flex-direction: row;
      justify-content: center;
      /* width: 100%; */
      background: transparent;
      /* border: white solid; */
      // border-style: solid;
      // border-color: #010201;
      // border-radius: 5px;
      min-height: 75px;
    }

    #openSidebarButton {
      font-size: 1.25em;
    }

    .getStartedButton {
      height: unset !important;
    }

    #takeSurveyPrompt {
      margin-bottom: 0px;
      text-transform: uppercase;
      font-size: .85em;
      font-weight: 600;
      color: white;
    }

    #takeSurveyIcon {
      display: inline-flex;
      margin-left: 5px;
      margin-right: 5px;
      font-size: 55px;
    }

    #takeSurveyButton {
      // display: none; // TODO: bring back survey button
      // VISIBLE:
      width: 100%;
      height: 75px;
      padding-top: 5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.25em;
      color: #ffffffcf;
      border: none;
      background: black;
      justify-self: flex-end;
      align-items: self-end;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // background-image: linear-gradient(48deg, #000000c7, #000000f2);
    }

    #feedbackPromptText {
      display: inline-flex;
    }

    #feedbackPromptDetailText {
      display: inline-flex;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1366px) {
    #betaTab {
      display: flex;
      flex-direction: column;
      padding-right: 0px;
      padding-left: 0px;
    }

    #betaInfo {
      // width: 70%;
      // min-height: 400px;
      align-items: center;
    }

    #betaTabTitle {
      align-self: center;
      font-weight: 600;
      color: #ffffff2e;
      text-transform: uppercase;
      margin-bottom: 0px;
      /* padding-left: 25px; */
      padding-top: 25px;
      padding-bottom: 15px;
      font-size: 3em;
    }

    #betaSurvey {
      display: flex;
      flex-direction: row;
      justify-content: center;
      /* width: 100%; */
      background: transparent;
      /* border: white solid; */
      // border-style: solid;
      // border-color: #010201;
      // border-radius: 5px;
      min-height: 75px;
    }

 

    #takeSurveyButton {
      // display: none; // TODO: bring back survey button
      // VISIBLE:
      // width: 100%;
      height: 80px;
      padding-top: 5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.75em;
      color: #ffffffcf;
      border: none;
      background: black;
      justify-self: flex-end;
      align-items: self-end;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // background-image: linear-gradient(48deg, #000000c7, #000000f2);
    }

    #openSidebarButton {
      font-size: 1.35em;
    }

    .getStartedButton {
      height: unset !important;
    }

    #takeSurveyPrompt {
      margin-bottom: 0px;
      text-transform: uppercase;
      font-size: .85em;
      font-weight: 600;
      color: white;
    }

    #takeSurveyIcon {
      display: inline-flex;
      margin-left: 5px;
      margin-right: 5px;
      font-size: 55px;
    }

    #takeSurveyButton {
      // display: none; // TODO: bring back survey button
      // VISIBLE:

      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.35em;
      color: #ffffffcf;
      border: none;
      background: black;
      justify-self: flex-end;
      align-items: self-end;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // height: 100%;
      height: 100px;

      // background-image: linear-gradient(48deg, #000000c7, #000000f2);
    }

    #feedbackPromptText {
      display: inline-flex;
    }

    #feedbackPromptDetailText {
      display: inline-flex;
    }
  }
  
  @media (min-width: 1367px) {
    #betaTab {
      display: flex;
      flex-direction: row;
      padding-right: 0px;
      padding-left: 0px;
    }

    #betaInfo {
      // width: 70%;
      width: 100%;
      align-items: flex-start;
    }

    #betaTabTitle {
      align-self: flex-start;
      font-weight: 600;
      color: #ffffff2e;
      text-transform: uppercase;
      margin-bottom: 0px;
      padding-left: 35px;
      padding-top: 35px;
      // font-size: 3em;
      // font-size: 4.5em;
      font-size: 4.25em;
    }

    #betaSurvey {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: flex-end;
      /* width: 100%; */
      background: transparent;
      /* border: white solid; */
      // border-style: solid;
      // border-color: #010201;
      // border-radius: 5px;
      width: 30%;
      // height: 655px;
      // height: 680px;
      // height: 750px;
      // height: 575px;
      height: 500px;
    }

    #sidebarOpen {
      height: 100%;
    }

    #openSidebarButton {
      font-size: 1.75em;
      // border-top-left-radius: 5px;
      border-style: solid;
      border-color: transparent;
      border-width: 0px;
      height: 100% !important;
    }

    .getStartedButton {
      height: unset !important;
    }

    #takeSurveyPrompt {
      margin-bottom: 0px;
      text-transform: uppercase;
      font-size: .85em;
      font-weight: 600;
      color: white;
    }

    #takeSurveyIcon {
      display: inline-flex;
      margin-left: 5px;
      margin-right: 5px;
      // font-size: 100px;
      font-size: 80px;

    }

    #takeSurveyButton {
      // display: none; // TODO: bring back survey button
      // VISIBLE:
      height: 100%;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.75em;
      // color: #ffffffcf;
      border: none;
      color: #487dec7a;
      // border: none;
      background: #000000a1;
      justify-self: flex-end;
      align-items: self-end;
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // background-image: linear-gradient(48deg, #000000c7, #000000f2);
    }

    #feedbackPromptText {
      display: inline-flex;
    }

    #feedbackPromptDetailText {
      display: inline-flex;
    }
  }

  .getStartedButton {
    filter: opacity(1) !important;
  }

  #betaTab {
    display: flex;
    // flex-direction: row;
    align-content: center;
    height: 100%;
    // position: relative;
    // opacity: 0.99;
    // background: #1f2251b8;
    // background: #1f2251e8;
    // background: #0a0a0ccf;
    // background: rgb(10 10 12/ 89%);
    // background: #0a0a0cd9;
    // background: #0a0a0c00;
    background: #0a0a0c7d;
    // padding-right: 0px;
    // padding-left: 10px;
    
    #betaTabTitleArea {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-self: center;
      // padding: 0px;
    }


    #betaTabTitle {
      // font-size: 2em;
      // font-weight: 600;
      font-weight: 700;
      // color: #ffffff2e;
      // color: #ffffffb0;
      // color: #ffffff63;
      color: #ffffff8a;
      // color: #000000bf;
      text-transform: uppercase;
      margin-bottom: 0px;
      // padding: 0px;
    }

    #betaInfo {
      // width: 60%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      // background-image: url(https://raw.githubusercontent.com/0x404-6dE/0x404-6dE.github.io/master/assets/black_bg.jpg);
      background-size: auto;
      background-blend-mode: hue;
      // justify-content: flex-end;
      justify-content: space-between;
    }
    

    #betaSurvey {
      display: flex;
      // flex-direction: column;
      // flex-direction: row;
      // min-height: 400px;
      // width: 40%;
      // opacity: .5;
    }

    #sidebarOpen {
      width: 100%;
      // height: 100%;
    }

    #openSidebarButton {
      // // font-size: .85em;
      // width: 100%;
      // height: 100%;
      // cursor: pointer;
      // text-transform: uppercase;
      // font-weight: 600;
      width: 100%;
      height: 100%;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 600;
      // background: #0000ff00;
      // // color: #767a828a;
      // // border: none; 
      // // border: white solid .5px;
      // border: #000000a1 solid 1.5px;
      // background: #0000004d;
      // background: #0000009c;
      background: #00000082;
      // border: #000000a1 solid 1px;
      // // color: #cbff00b5;
      // color: #07c0f9c4;
      border: #00000000 solid 1px;
      color: #8b909294;
      filter: opacity(0.3);
    }
  }

  #takeSurveyButton {
    color: #000000a6;
    border: none;
    background: #07c0f929;
    // background: transparent;
    // background-color: transparent;
    // height: 75px;
    // height: 100%;
    // height: 100px;
  }

  #typeformEmbed {
    height: 0;
    width: 0;
  }

  /* ---------------------------------------------------- MetricsCollapse.jsx ---------------------------------------------------- */

  #metricsDetailsToggleButton {
    background: yellow;
    color: black;
  }